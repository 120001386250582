import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import * as Func from "src/pack/js/func";
import { Config } from "src/app/app-config.service";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { LoadingService } from "src/app/loading/loading.service";
import { FormControl } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import {number} from '@amcharts/amcharts4/core';
import {ToolbarItemModel} from "../../../../models/toolbar.model";

@Component({
  selector: "app-order-new",
  templateUrl: "./order-new.component.html",
  styleUrls: ["./order-new.component.scss"],
})
export class OrderNewComponent implements OnInit {
  isRepresentetiveOrder: boolean = false;
  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;
  date = new Date();

  control = new FormControl();
  controlorder = new FormControl();
  buyer: number;

  credit: number = 0;
  credit_map = new Map();
  order: any[] = [];

  statuses: any[] = [
    { id: true, label: "فعال" },
    { id: false, label: "غیر فعال" },
  ];
  status: boolean = true;

  types: any[] = [];
  type = "";

  transportations: any[] = [];
  transportation = "";

  methods: any[] = [];
  method = "";

  customers: any[] = [{ id: null, label: "در حال بارگذاری" }];
  filteredCustomers;
  filteredProducts;
  customer = "";
  @ViewChild("form")
  form:ElementRef;
  flag;
  availabilities: any[] = [
    { id: true, label: "موجود" },
    { id: false, label: "ناموجود" },
  ];
  availability: string = "";

  products: any[] = [];
  orgProducts: any[] = [];
  selected: boolean[] = [];
  productsApi: string =
    Config.settings.api + Config.settings.product.product;
  usersApi: string = Config.settings.api + Config.settings.user.user;
  toolbarItemList: ToolbarItemModel[] = [

    {
      name: "save",
      icon: "fas fa-save",
      text: "ذخیره و ویرایش",
      type: "button",
      btn_type: "primary"
    },
    {
      name: "saveNew",
      icon: "fas fa-save",
      text: "ذخیره و جدید",
      type: "button",
      btn_type: "primary"
    },
    {
      name: "saveClose",
      icon: "fas fa-save",
      text: "ذخیره و بستن",
      type: "button",
      btn_type: "primary"
    },
    {
      name: "space",
      text: "space",
      type: "space",
    },
    {
      name: "cancel",
      text: "لغو",
      type: "icon-button",
      icon: "fal fa-times-circle fa-2x text-danger",
    },
    {
      name: "print",
      text: "چاپ",
      type: "icon-button",
      icon: "fal fa-print fa-2x text-info",
    },
    {
      name: "excel",
      text: "خروجی اکسل",
      type: "icon-button",
      icon: "fal fa-file-excel fa-2x text-success",
    },
    {
      name: "space",
      text: "space",
      type: "space",
    },
    {
      name: "operations",
      text: "عملیات",
      icon: "fas fa-cogs",
      type: "menu",
      btn_type: "primary",
      items: [
        {
          name: "cancel",
          text: "لغو",
          type: "icon-button",
          icon: "fal fa-times-circle",
        },
        {
          name: "print",
          text: "چاپ",
          type: "icon-button",
          icon: "fal fa-print ",
        },
        {
          name: "excel",
          text: "خروجی اکسل",
          type: "icon-button",
          icon: "fal fa-file-excel",
        }
      ]
    }
  ]

  func = Func;
  math = Math;
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private loading: LoadingService,
    private route: ActivatedRoute
  ) {}

  user;

  user_id;

  private _filter(value: string): string[] {
    const filterValue = value;

    return this.customers.filter((option) =>
      option.label.includes(filterValue)
    );
  }
  private _filterP(value: string): string[] {
    const filterValue = value;

    return this.products.filter((option) =>
      option.label.includes(filterValue)
    );
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((qp) => {
      if (qp["rep"]) this.isRepresentetiveOrder = true;
      else this.isRepresentetiveOrder = false;
    });

    this.add();
    try {
      this.user = await this.auth.get_info();

      this.credit = this.user.max_credit;
    } catch {}
    this.load();

    this.loadUser();

    let methodApi: string =
      Config.settings.api + Config.settings.order.method;
    this.http.get(methodApi).subscribe(
      (response: any) => {
        console.log(response);
        response.forEach((element) => {
          this.methods.push({
            id: element.id,
            label: element.method,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );

    let typeApi: string =
      Config.settings.api + Config.settings.order.type;
    this.http.get(typeApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.types.push({
            id: element.id,
            label: element.type,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );

    let transportationApi: string =
      Config.settings.api + Config.settings.order.transportation;
    this.http.get(transportationApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.transportations.push({
            id: element.id,
            label: element.transportation,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  customerRef: Subject<void> = new Subject<void>();

  load() {
    this.customers = [];

    this.http.get(Config.settings.api + "user/user/summary/" + "?q=4").subscribe(
      (response: any) => {
        console.log(response);
        let tc = [];
        response.results.forEach((element) => {
            tc.push({
              id: element.id,
              label: element.name,
            });
          // this.credit_map.set(element.id, element.max_credit);
        });
        this.customerRef.next();
        this.customers = tc;
        this.filteredCustomers = this.control.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    this.loading.add();
    this.orgProducts = [];
    this.http.get(this.productsApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.orgProducts.push({
            id: element.id,
            name: element.name,
            group: element.brand_name,
            type: element.type_name,
            status: element.is_active,
            code: element.code,
            unit: element.unit_name,
            number_in_pallet: element.number_in_pallet,
            weight: element.weight,
          });
          this.func.sort(this.orgProducts, "name");
        });
        let tc = [];
        this.orgProducts.forEach((element, i) => {
          tc.push({
            id: i,
            label: element.name,
          });
        });
        this.products = tc;
        this.filteredProducts = this.controlorder.valueChanges.pipe(
          startWith(""),
          map((value) => this._filterP(value))
        );
        this.loading.remove();
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        this.loading.remove();
        console.log(error);
      }
    );
  }

  loadUser(){
    this.user_id = localStorage.getItem("customer_id");
    if (this.user_id !== undefined){
      this.http.get(this.usersApi + this.user_id).subscribe(
          (response: any) => {
            this.control.setValue({
              id: response.id,
              label: response.company_name
            });
            this.customer =  response.id;
          },
          (error: HttpErrorResponse) => {
            debugger;
            //TODO: server errors
            console.log(error);
          }
      );
    }
  }

  changeProduct(el, i) {
    console.log(el, i);
    this.order[i].pindex = el.id;
    this.order[i].product_id = this.orgProducts[el.id].id;
  }
  changeCustomer(el) {
    this.customer = el.id;
    // this.credit = this.credit_map.get(el.id);
    this.http.get(this.usersApi + el.id).subscribe(
      (response: any) => {
        console.log(response);
        this.credit = response.max_credit;
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }
  showC(el) {
    return el && el.label;
  }
  _pallet_sum: number;
  _weight_sum: number;

  get count_sum() {
    let sum: number = 0;
    this.order.forEach((el) => {
      if (parseInt(el.count)) sum += parseInt(el.count);
    });
    return sum;
  }

  get weight_sum() {
    let sum: number = 0;
    this.order.forEach((el) => {
      sum +=
        el.pindex != null && el.count !== null
          ? this.math.ceil(el.count * this.orgProducts[el.pindex].weight)
          : 0;
    });
    return sum;
  }
  get pallet_sum() {
    let sum: number = 0;
    this.order.forEach((el) => {
      sum +=
        el.pindex != null && el.count !== null
          ? (1.0 * el.count) /
            this.orgProducts[el.pindex].number_in_pallet
          : 0;
    });
    return Math.floor(sum);
  }

  changeType($event) {
    this.type = $event;
  }

  register(form) {
    let data = form.value;
    // data.buyer = this.isCustomer ? this.user.id : this.customer;
    if (!this.isCustomer) data.buyer = this.customer;
    // data.id = this.user.id;
    data.date = this.date.toISOString();
    data.state = 1;
    if (
      this.user.role !== 2 &&
      this.user.role !== 3 &&
      this.user.role !== 4
    )
      data.state = 2;

    if (this.isRepresentetiveOrder) data.state = 12;

    data.order_details = [];
    this.order.forEach((el) =>
      data.order_details.push({
        product: el.product_id,
        sheet_number: el.count,
      })
    );
    console.log(data);
    let url = Config.settings.api + Config.settings.order.order;
    console.log(JSON.stringify(data));
    this.http.post(url, data).subscribe(
      (response: any) => {
        console.log("shod");
        console.log(response);
        this.success = true;
        this.isWrong = false;
        this.msg = `سفارش ذخیره شد.`;
        switch (this.flag){
          case 1:
            this.router.navigate(["userArea", "order", `${response.id}`]).then(r => '/404');
            break;
          case 2:
            location.reload();
            break;
          case 3:
            if (this.user_id !== undefined && this.user_id != null) {
              this.router.navigate(['userArea/order/' + this.user_id]).then(r => '/404');
            }else {
              this.router.navigate(['/userArea/order']).then(r => '/404');
            }
        }

      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }

  get isCustomer(): boolean {
    return (
      (this.user?.role == 2 ||
        this.user?.role == 3 ||
        this.user?.role == 4) &&
      !(this.user?.role == 4 && this.isRepresentetiveOrder)
    );
  }

  add() {
    this.order.push({
      pindex: null,
      product_id: null,
      unit_of_measure: null,
      number_in_pallet: null,
      count: 0,
    });
  }

  remove(i) {
    this.order.splice(i, 1);
  }


  itmeClickHandler(event) {
    console.log(event);
    switch (event.name) {
      case 'cancel':
        if (this.user_id !== undefined && this.user_id != null) {
          this.router.navigate(['userArea/customer/' + this.user_id]).then(r => '/404');
        }else {
          this.router.navigate(['/userArea/order']).then(r => '/404');
        }
        break;
      case 'save':
        this.flag = 1 ;
        this.register(this.form);
        break;
      case 'saveNew':
        this.flag = 2;
        this.register(this.form);
        break;
      case 'saveClose':
        this.flag = 3;
        this.register(this.form);
        break;
      case 'excel':

    }
  }

}
