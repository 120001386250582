import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-information-lists',
  templateUrl: './product-information-lists.component.html',
  styleUrls: ['./product-information-lists.component.scss']
})
export class ProductInformationListsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
