<div class="m-3 ml-5 mt-4">
  <app-title title="فرم ارزیابی رضایت مشتریان"></app-title>

  <div *ngIf="success" class="" role="">
    <div
      class="d-flex text-success justify-content-center align-items-center"
    >
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ msg }}
    </div>
  </div>
  <div *ngIf="isWrong" class="" role="">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ errorMsg }}
    </div>
  </div>
  <form
    #form="ngForm"
    (ngSubmit)="register(form)"
    class="d-flex flex-column align-items-center"
  >
    <div class="w-100 mr-5 my-3 d-flex align-items-center">
      <i class="fa fa-circle-notch text-success"></i>
      <p class="m-0 col-auto">
        فرم ارزیابی شماره {{ survey ? (survey.toString() | toFa) : null }}
      </p>
    </div>

    <div class="order-header w-100">
      <div class="row justify-content-between">
        <div class="item">
          شماره سفارش:{{ id ? (id.toString() | toFa) : null }}
        </div>
        <div class="item">
          شماره فاکتور:{{ remittance_number | toFa }}
        </div>
        <div class="item">
          شماره ارزیابی:
          {{ id ? (id.toString() | toFa) : null }}
        </div>
        <div class="item">مخاطب:{{ name }}</div>
      </div>

      <div class="row justify-content-between">
        <div class="item">
          ثبت کننده:
          {{ creator_name | toFa }}
        </div>
        <div class="item">
          تاریخ سفارش:{{ date.toISOString() | jDate }}
        </div>
        <div class="item">
          تاریخ ارسال سفارش:{{ sendDate.toISOString() | jDate }}
        </div>
        <div class="item">
          تاریخ ارزیابی:{{ survayDate.toISOString() | jDate }}
        </div>
      </div>
    </div>

    <div class="list mx-3 w-100">
      <table class="table table-bordered">
        <thead class="thead-light headerless">
          <tr>
            <th scope="col">#</th>
            <th scope="col">شرح موارد</th>
            <th scope="col">بسیار خوب</th>
            <th scope="col">خوب</th>
            <th scope="col">متوسط</th>
            <th scope="col">ضعیف</th>
            <th scope="col">خیلی ضعیف</th>
            <th scope="col">توضیحات</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let el of questions; let i = index">
            <th>{{ i + 1 }}</th>
            <td class="">
              <div>
                {{ el.body }}
                <div class="text-muted">{{ el.detail }}</div>
              </div>
            </td>

            <td class="p-0">
              <input
                type="radio"
                [disabled]="!can_edit"
                [value]="10"
                [name]="i"
                [(ngModel)]="responses[i].answer"
                [ngModelOptions]="{ standalone: true }"
              />
            </td>
            <td class="p-0">
              <input
                type="radio"
                [disabled]="!can_edit"
                [value]="7"
                [name]="i"
                [(ngModel)]="responses[i].answer"
                [ngModelOptions]="{ standalone: true }"
              />
            </td>
            <td class="p-0">
              <input
                type="radio"
                [disabled]="!can_edit"
                [value]="5"
                [name]="i"
                [(ngModel)]="responses[i].answer"
                [ngModelOptions]="{ standalone: true }"
              />
            </td>
            <td class="p-0">
              <input
                type="radio"
                [disabled]="!can_edit"
                [value]="3"
                [name]="i"
                [(ngModel)]="responses[i].answer"
                [ngModelOptions]="{ standalone: true }"
              />
            </td>
            <td class="p-0">
              <input
                type="radio"
                [disabled]="!can_edit"
                [value]="1"
                [name]="i"
                [(ngModel)]="responses[i].answer"
                [ngModelOptions]="{ standalone: true }"
              />
            </td>

            <td class="p-0">
              <textarea
                class="form-control border-0"
                (keydown)="can_edit || deny($event)"
                [(ngModel)]="responses[i].detail"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="list-footer">
        <div class="row justify-content-between">
          <div class="item">
            میانگین رضایت مشتری:
            {{ pscore.toString() + "٪" | toFa }}
          </div>
          <div class="item">
            امتیاز ایده‌آل:
            {{ perfect_score.toString() | toFa }}
          </div>
          <div class="item">
            امتیاز به دست آمده:
            {{ score.toString() | toFa }}
          </div>
          <div class="item">
            امتیاز کیفی:
            {{ pscore ? score_name[math.round(pscore / 20)] : null }}
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 form-container my-3">
      <label>توضیحات</label>
      <textarea
        [(ngModel)]="detail"
        class="form-control"
        (keydown)="can_edit || deny($event)"
        name="detail"
      ></textarea>
    </div>

    <div class="row w-100 justify-content-end">
      <button
        *ngIf="can_edit"
        type="submit"
        class="btn mr-auto ml-3 w-25 btn-success"
      >
        ذخیره
      </button>
      <button
        (click)="loc.back()"
        type="button"
        class="btn ml-4 w-25 btn-secondary"
      >
        بازگشت
      </button>
    </div>
  </form>
</div>
