import {ToRPipe} from 'src/app/fa.pipe';
import * as Func from 'src/pack/js/func';

export class Check {
    id: number;
    type: number;
    check_number: string;
    date: string;
    check_state_date: string;
    amount: number;
    bounced_check: number;

    get amountt() {
        if (!this.amount) {
            return null;
        }
        let toR: ToRPipe = new ToRPipe();
        return toR.transform(this.amount.toString());
    }

    set amountt(e: string) {
        this.amount = Func.toNumber(e);
    }

    card_number: string;
    name: string;
    manager_confirmation: boolean;
    expert_confirmation: boolean;
    customer_confirmation: boolean;
    load_report: string;
    detail: string;
    check_state: number;
    image_url_1: string;
    image_url_2: string;

    constructor(
        id = null,
        check_number = null,
        date = null,
        amount = null,
        card_number = null,
        name = null,
        manager_confirmation = false,
        expert_confirmation = false,
        customer_confirmation = false,
        load_report = null,
        check_state = 4,
        check_state_date = null,
        detail = null,
        image_url_1 = null,
        image_url_2 = null,
        bounced_check = null
    ) {
        this.id = id;
        this.type = 2;
        this.check_number = check_number;
        this.date = date;
        this.amount = parseInt(amount);
        this.card_number = card_number;
        this.name = name;
        this.manager_confirmation = manager_confirmation;
        this.expert_confirmation = expert_confirmation;
        this.customer_confirmation = customer_confirmation;
        this.load_report = load_report;
        this.check_state = check_state;
        this.check_state_date = check_state_date;
        this.detail = detail;
        this.image_url_1 = image_url_1;
        this.image_url_2 = image_url_2;
        this.bounced_check = bounced_check;
    }

    public clone(): Check {
        var cloneObj = new Check() as any;
        for (var attribut in this) {
            cloneObj[attribut] = this[attribut];
        }
        return cloneObj;
    }
}

export class Cash {
    id: number;
    type: number;
    date: string;
    amount: number;
    image_url_1: string;
    image_url_2: string;
    bounced_check: number;

    get amountt() {
        if (!this.amount) {
            return null;
        }
        let toR: ToRPipe = new ToRPipe();
        return toR.transform(this.amount.toString());
    }

    set amountt(e: string) {
        this.amount = Func.toNumber(e);
    }

    name: string;
    manager_confirmation: boolean;
    expert_confirmation: boolean;
    customer_confirmation: boolean;
    load_report: string;
    bank_account: string;
    deposit_type: string;
    source_bank_account: string;
    detail: string;

    constructor(
        id = null,
        date = null,
        amount = null,
        name = null,
        manager_confirmation = false,
        expert_confirmation = false,
        customer_confirmation = false,
        load_report = null,
        bank_account = null,
        deposit_type = null,
        source_bank_account = null,
        detail = null,
        image_url_1 = null,
        image_url_2 = null,
        bounced_check= null,
) {
        this.id = id;
        this.type = 1;
        this.date = date;
        this.amount = amount;
        this.name = name;
        this.manager_confirmation = manager_confirmation;
        this.expert_confirmation = expert_confirmation;
        this.customer_confirmation = customer_confirmation;
        this.load_report = load_report;
        this.bank_account = bank_account;
        this.deposit_type = deposit_type;
        this.source_bank_account = source_bank_account;
        this.detail = detail;
        this.image_url_1  = image_url_1;
        this.image_url_2 = image_url_2;
        this.bounced_check = bounced_check;
    }

    public clone(): Cash {
        var cloneObj = new Cash() as any;
        for (var attribut in this) {
            cloneObj[attribut] = this[attribut];
        }
        return cloneObj;
    }
}
