<div class="bg-gray-light" [ngbCollapse]="isCollapsed">
    <div class="form-container p-3">
        <form #form="ngForm" (ngSubmit)="submit(form)">
            <div class="row mb-1">
                <ng-container *ngFor="let item of filter_items; let i = index">
                    <div
                            *ngIf="item.type != 'hidden'"
                            class="col-6 col-md-4 col-lg-3 form-group mb-2"
                    >
                        <label class="mx-1 lead" *ngIf="item.type != null"> {{ item.label }} </label>
                        <input
                                *ngIf="item.type == 'string'"
                                class="form-control"
                                type="text"
                                [(ngModel)]="item.res"
                                [name]="item.name"
                        />

                        <input
                                *ngIf="item.type == 'date'"
                                matInput
                                [matDatepicker]="dpicker"
                                (click)="dpicker.open()"
                                class="form-control"
                                type="text"
                                [(ngModel)]="filter_items[i].res"
                                [name]="item.name"
                        />

                        <mat-datepicker #dpicker></mat-datepicker>

                        <select
                                *ngIf="item.type == 'list'"
                                class="form-control"
                                [class.text-muted]="is_empty(item.res)"
                                [(ngModel)]="filter_items[i].res"
                                [name]="item.name"
                                (change)="changeUser($event.target.value,item.name)"
                        >
                            <option *ngFor="let el of item.options" [value]="el.id">
                                {{ el.label }}
                            </option>
                        </select>
                        <label class="mx-1 lead" *ngIf="item.isUser"> {{ item.label }} </label>
<!--                        <select-->
<!--                                *ngIf="item.isUser"-->
<!--                                class="form-control"-->
<!--                                [class.text-muted]="is_empty(item.res)"-->
<!--                                [(ngModel)]="filter_items[i].res"-->
<!--                                [name]="item.name"-->
<!--                        >-->
<!--                            <option *ngFor="let el of userList" [value]="el.id">-->
<!--                                {{ el.label }}-->
<!--                            </option>-->
<!--                        </select>-->
                        <input
                                *ngIf="item.isUser"
                                matInput
                                [class.text-muted]="is_empty(item.res)"
                                [(ngModel)]="filter_items[i].res"
                                [name]="item.name"
                                class="form-control"
                                [formControl]="control"
                                [matAutocomplete]="auto"
                        />

                        <mat-autocomplete
                            #auto="matAutocomplete"
                            [displayWith]="showC"
                        >
                            <mat-option
                                    *ngFor="let el of filteredUsers | async"
                                    [value]="el"
                            >
                                {{ el.label }}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </ng-container
                >
            </div>
            <div class="row justify-content-center">
                <button class="btn btn-primary" type="submit">فیلتر</button>
            </div>
        </form>
    </div>
</div>
