import { Injectable } from '@angular/core';
import {
   HttpRequest,
   HttpHandler,
   HttpEvent,
   HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
   constructor() {}

   intercept(req: HttpRequest<any>, next: HttpHandler) {
      const authReq = req.clone({
         // setHeaders: {
         //    'Cache-Control': 'no-cache',
         //    Pragma: 'no-cache',
         // },
      });
      return next.handle(authReq);
   }
}
