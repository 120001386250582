import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToolbarItemModel } from 'src/app/models/toolbar.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input()
  toolbarItems: ToolbarItemModel[];
  @Output()
  clickHandler:EventEmitter<ToolbarItemModel> = new EventEmitter();
  ngOnInit(): void {
  }
  
  itemClickHandler(item: ToolbarItemModel):void {
    console.log(item);
      this.clickHandler.emit(item);
  }
  
}
