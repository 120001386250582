import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-box',
  templateUrl: './panel-box.component.html',
  styleUrls: ['./panel-box.component.scss']
})
export class PanelBoxComponent implements OnInit {

  @Input()
  caption:string="";
  @Input()
  arrowIcon:string="fas fa-arrow";
  @Input()
  collapsed:boolean=false;
  constructor() { }

  ngOnInit(): void {
  }
  toggleCollpse(event){
    this.collapsed = !this.collapsed;
  }

}
