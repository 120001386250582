import {Component, OnInit, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpClient} from '@angular/common/http';
import {Router, ActivatedRoute, UrlSegment} from '@angular/router';
import {Config} from 'src/app/app-config.service';
import {FormControl} from '@angular/forms';
import {debounceTime, throttleTime} from 'rxjs/operators';
import * as Func from 'src/pack/js/func';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from 'src/app/loading/loading.service';
import {Check} from '../models';
import {AuthService} from 'src/app/auth/auth.service';

@Component({
    selector: 'app-checkout-check-list',
    templateUrl: './checkout-check-list.component.html',
    styleUrls: ['./checkout-check-list.component.scss'],
})
export class CheckoutCheckListComponent implements OnInit {
    page_number: number = 1;
    object_count: number = 0;
    page_count: number = 0;

    imgUrl1: string;
    imgUrl2: string;
    usersApi: string = Config.settings.api + Config.settings.user.user;

    check_states_map = Func.check_states;
    parseint = parseInt;

    load_reports: any[] = [];

    check_states: any[] = [
        {id: null, label: 'همه'},
        ...Array.from(Func.check_states, ([id, label]) => ({
            id,
            label,
        })),
    ];

    confirmation_states: any[] = [
        {id: 0, label: 'عدم تایید'},
        {id: 1, label: 'تایید مشتری'},
        {id: 2, label: 'تایید کارشناس'},
        {id: 3, label: 'تایید مالی'},
    ];
    search_confirmation_states: any[] = [
        {id: 0, label: 'عدم تایید'},
        {id: 1, label: 'تایید مشتری'},
        {id: 2, label: 'تایید کارشناس'},
        {id: 3, label: 'تایید مالی'},
    ];

    isCollapsed = true;
    filter_items = [
        {
            name: 'company_name',
            label: 'نام فروشگاه',
            type: 'string',
        },
        {
            name: 'reference',
            label: 'نام فروشنده مادر',
            type: 'string',
        },
        {
            name: 'order_id',
            label: 'شماره سفارش',
            type: 'string',
        },
        {
            name: 'invoice_number',
            label: 'شماره پیش فاکتور',
            type: 'string',
        },
        {
            name: 'remittance_number',
            label: 'شماره فاکتور',
            type: 'string',
        },
        {
            name: 'check_number',
            label: 'شماره چک',
            type: 'string',
        },
        {
            name: 'name',
            label: 'صاحب حساب',
            type: 'string',
        },
        {
            name: 'start_order_date',
            label: 'تاریخ سفارش از',
            type: 'date',
        },
        {
            name: 'end_order_date',
            label: 'تاریخ سفارش تا',
            type: 'date',
        },
        {
            name: 'start_created_at',
            label: 'تاریخ ثبت چک از',
            type: 'date',
        },
        {
            name: 'end_created_at',
            label: 'تاریخ ثبت چک تا',
            type: 'date',
        },
        {
            name: 'start_date',
            label: 'تاریخ سررسید چک از',
            type: 'date',
        },
        {
            name: 'end_date',
            label: 'تاریخ سررسید چک تا',
            type: 'date',
        },
        {
            name: 'check_state',
            label: 'وضعیت چک',
            type: 'list',
            options: [],
            res: null,
        },
        {
            name: 'confirmation_state',
            label: 'وضعیت تایید',
            type: 'list',
            options: this.search_confirmation_states,
            res: null,
        },
        {
            name: "creator_first_name",
            label: "کاربر ثبت کننده",
            type: "string",
        }
    ];

    checks: any[] = [];
    orgChecks: any[] = [];
    searchControl = new FormControl();
    selected: boolean[] = [];
    checksApi: string = Config.settings.api + 'order/checkout-detail/';
    func = Func;
    searchTerm: string;
    sheet_sum: number = 0;
    page_sheet_sum: number = 0;
    amount_sum: number = 0;
    page_size: any;
    page: number;
    is_today: boolean;

    constructor(
        private router: Router,
        private auth: AuthService,
        private http: HttpClient,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private loading: LoadingService
    ) {
    }

    can_del = true;
    user;

    async ngOnInit(): Promise<void> {
        this.route.queryParams.subscribe((filter) => {
            this.search(filter);
            this.filter_items.forEach(
                (el) => (el.res = filter[el.name] ? filter[el.name] : null)
            );
        });

        this.searchControl.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe((term) => this.search(term));
        this.loadStates();
        this.load();

        this.user = await this.auth.get_info();
    }

    disable_load = false;

    load(page = 1) {
        if (this.disable_load) {
            return;
        }
        this.disable_load = true;
        this.loading.add();

        this.orgChecks = [];
        this.http
            .get(
                this.checksApi +
                `?cash=0&page=${page}&${this.searchTerm}&ordering=${this.sortcol}`
            )
            .subscribe(
                (response: any) => {
                    console.log(response);
                    this.page = page;
                    this.page_count = response.page_count;
                    this.object_count = response.object_count;
                    this.page_number = response.page_number;
                    this.amount_sum = response.amount_sum;
                    this.page_size = response.page_size;

                    response.results.forEach((element) => {
                        this.orgChecks.push(element);
                    });
                    this.checks = this.orgChecks;

                    this.loading.remove();
                    this.disable_load = false;
                },
                (error: HttpErrorResponse) => {
                    //TODO: server errors
                    console.log(error);
                    this.orgChecks = [];
                    this.checks = [];
                    this.page_count = 0;
                    this.page_number = 0;
                    this.object_count = 0;
                    this.amount_sum = 0;
                    this.sheet_sum = 0;
                    this.page_sheet_sum = 0;
                    this.loading.remove();

                    this.disable_load = false;
                }
            );
    }

    loadStates() {
        let tc = [{id: null, label: 'همه'}];
        Func.check_states.forEach((element, id) => {
            tc.push({
                id: id,
                label: element,
            });
        });
        this.filter_items.map((el) => {
            let ret = el;
            if (el.name == 'check_state') {
                ret.options = tc;
            }
            return ret;
        });
    }

    search(filter: any) {
        console.log(filter);

        this.searchTerm = '';
        Object.keys(filter).forEach((el) => {
            this.searchTerm += el + '=' + filter[el] + '&';
            // console.log(el);
        });

        if (this.searchTerm != '') {
            this.searchTerm = this.searchTerm.slice(0, -1);
        }

        let qp = filter;
        // qp['q'] = this.searchTerm;

        this.router.navigate([], {
            queryParams: qp,
            // queryParamsHandling: "merge",
        });
        this.load();
        this.isCollapsed = true;
    }

    del(content, i) {
        console.log(this.checksApi);
        if (!i) {
            return;
        }
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.checksApi + i + '/').subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    editing_check: Check = new Check();

    async edit(content, i) {
        let el = this.checks[i];
        this.editing_check = new Check(
            el.id,
            el.check_number,
            el.date,
            el.amount,
            el.card_number,
            el.name,
            el.manager_confirmation,
            el.expert_confirmation,
            el.customer_confirmation,
            el.load_report,
            el.check_state,
            el.check_state_date,
            el.detail,
            el.image_url_1,
            el.image_url_2,
        );
        this.imgUrl1 = this.editing_check.image_url_1;
        this.imgUrl2 = this.editing_check.image_url_2;
        this.loading.add();
        await this.load_loadreports(this.checks[i].order_id);
        this.loading.remove();

        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                console.log(result);
                console.log(this.editing_check);
                this.http
                    .patch(
                        this.checksApi + this.editing_check.id + '/',
                        this.editing_check
                    )
                    .subscribe(
                        (response: any) => {
                            this.load(this.page);
                        },
                        (error: HttpErrorResponse) => {
                            //TODO: server errors
                            console.log(error);
                        }
                    );
            }
        });
    }

    async load_loadreports(order_id) {
        let orderApi: string =
            Config.settings.api + Config.settings.order.order + `${order_id}/`;

        await this.http
            .get(orderApi)
            .toPromise()
            .then((response: any) => {
                console.log(response);
                let c = [];
                c.push({id: null, label: 'خالی'});
                response.load_reports.forEach((el) => {
                    if (el.remittance_number) {
                        c.push({id: el.id, label: el.remittance_number});
                    }
                });
                this.load_reports = c;
            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                if (error.status === 404) {
                    this.router.navigate(['/404']);
                }
                console.log(error);
            });
    }

    sortcol: string = null;

    sort(col: string) {
        if (this.sortcol == col) {
            this.sortcol = '-' + col;
        } else {
            this.sortcol = col;
        }
        this.load();
    }

    conf = Config;

    async change_image_1(e) {
        const url: string = Config.settings.api + Config.settings.info.file;
        const formData: FormData = new FormData();
        formData.append(
            'file',
            e.target.files.item(0),
            e.target.files.item(0).name
        );
        const headers = {dt: 'yes'};
        this.http.post(url, formData, {headers}).subscribe(
            (res: any) => {
                // let data = {image_url: res.url.slice(1)};
                this.imgUrl1 = res.url.slice(1);
                this.editing_check.image_url_1 = res.url.slice(1);
            },
            (error: HttpErrorResponse) => {
            }
        );
    }

    async change_image_2(e) {
        const url: string = Config.settings.api + Config.settings.info.file;
        const formData: FormData = new FormData();
        formData.append(
            'file',
            e.target.files.item(0),
            e.target.files.item(0).name
        );
        const headers = {dt: 'yes'};
        this.http.post(url, formData, {headers}).subscribe(
            (res: any) => {
                // let data = {image_url: res.url.slice(1)};
                this.imgUrl2 = res.url.slice(1);
                this.editing_check.image_url_2 = res.url.slice(1);
            },
            (error: HttpErrorResponse) => {
            }
        );
    }

    async del_image() {
        this.imgUrl1 = null;
        this.editing_check.image_url_1 = null;
    }

    async del_image_2() {
        this.imgUrl2 = null;
        this.editing_check.image_url_2 = null;
    }

    ngOnDestroy() {
    }
}
