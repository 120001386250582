import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Config } from "src/app/app-config.service";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";
import { AuthService } from "src/app/auth/auth.service";
import * as Func from "src/pack/js/func";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ThrowStmt } from "@angular/compiler";
import { Subject } from "rxjs";
import { LoadingService } from "src/app/loading/loading.service";

@Component({
  selector: "app-cpersonnel-list",
  templateUrl: "./cpersonnel-list.component.html",
  styleUrls: ["./cpersonnel-list.component.scss"],
})
export class CPersonnelListComponent implements OnInit {
  func = Func;

  jobs = [];
  statuses = [
    {
      id: true,
      label: "فعال",
    },

    {
      id: false,
      label: "غیر فعال",
    },
  ];

  show: boolean = false;
  personnels: any[] = [];
  orgpersonnels: any[] = [];
  searchControl = new FormControl();
  personnelApi: string = Config.settings.api + "information/personnel/";

  personnel = {
    user: 0,
    first_name: "",
    last_name: "",
    job: 1,
    start_date: "",
    date_of_birth: "" ,
    is_active: true,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalService: NgbModal,
    private loading: LoadingService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    let jobApi: string = Config.settings.api + "information/job/";
    this.http.get(jobApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.jobs.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    this.load();
  }

  disable_load = false;
  load(page = 1) {
    debugger;
    if (this.disable_load) return;
    this.disable_load = true;
    this.loading.add();

    this.orgpersonnels = [];

    this.http.get(this.personnelApi).subscribe(
      (response: any) => {
        console.log(response);

        response.forEach((element) => {
          this.orgpersonnels.push(element);
        });
        this.personnels = this.orgpersonnels;
      },
      (error: HttpErrorResponse) => {
        this.personnels = [];
        //TODO: server errors
        console.log(error);
        this.loading.remove();
        this.disable_load = false;
      },
      () => {
        this.loading.remove();
        this.disable_load = false;
      }
    );
  }
  editing_personnel;
  edit(content, i) {
    let el = this.personnels[i];
    this.editing_personnel = {
      id: el.id,
      first_name: el.first_name,
      last_name: el.last_name,
      job: el.job,
      start_date: el.start_date,
      date_of_birth: el.date_of_birth,
      is_active: el.is_active,
    };

    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http
            .patch(
              this.personnelApi + this.editing_personnel.id + "/",
              this.editing_personnel
            )
            .subscribe(
              (response: any) => {
                this.load();
              },
              (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
              }
            );
        }
      });
  }

  del(content, i) {
    if (!i) return;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http.delete(this.personnelApi + i + "/").subscribe(
            (response: any) => {
              this.load();
            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
          );
        }
      });
  }

  sortcol: string = "-created_at";

  sort(col: string) {
    if (this.sortcol == col) this.sortcol = "-" + col;
    else this.sortcol = col;
    this.load();
  }

  register() {
    console.log(this.personnel);
    this.personnel.user = parseInt(localStorage.getItem("user_id"));
    this.http.post(this.personnelApi, this.personnel).subscribe(
      (response: any) => {
        console.log("shod");
        this.load();
        this.personnel = {
          user: 0,
          first_name: "",
          last_name: "",
          job: 1,
          start_date: "",
          date_of_birth: "",
          is_active: true,
        };
        this.show = false;
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  ngOnDestroy() {}
}
