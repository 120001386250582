<div class="m-3 ml-5 mt-4">
  <app-title title="ارسال سفارش"></app-title>

  <div *ngIf="success" class="" role="">
    <div
      class="d-flex text-success justify-content-center align-items-center"
    >
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ msg }}
    </div>
  </div>
  <div *ngIf="isWrong" class="" role="">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ errorMsg }}
    </div>
  </div>

  <div class="order-header">
    <div class="row">
      <div class="col item">
        شماره سفارش:{{ id ? (id.toString() | toFa) : null }}
      </div>
      <div class="col item">
        تاریخ سفارش:{{ date.toISOString() | jDate }}
      </div>
      <div class="col item">مخاطب:{{ name }}</div>
      <div class="col item">
        ایجاد کننده:{{
          orderd?.creator_first_name + " " + orderd?.creator_last_name
        }}
      </div>
      <div class="col item">
        وضعیت:{{ func.order_states[state]
        }}{{
          orderd?.state == 7 || orderd?.state > 11
            ? " (" + orderd.representative_company_name + ")"
            : null
        }}
      </div>
    </div>
    <div class="row">
      <div class="col item">
        تعداد:{{ sheet_sum ? (sheet_sum.toString() | toFa) : null }}
      </div>
      <div class="col item">
        تعداد ارسال شده:{{
          (sheet_sum - remaining_sheet_number)?.toString() | toFa
        }}
      </div>
      <div class="col item">
        باقی مانده:{{ remaining_sheet_number?.toString() | toFa }}
      </div>
      <div class="col item">مبلغ: {{ orderd?.debt_amount | toR }}</div>
      <div class="col item">
        وضعیت تسویه: {{ orderd?.checkout_state }}
      </div>
    </div>
  </div>

  <form
    #form="ngForm"
    (ngSubmit)="register(form)"
    class="d-flex flex-column align-items-center"
  >
    <div *ngIf="user?.role != 4">
      <div class="box">
        <div class="row">
          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> شماره پیش فاکتور </label>
            <input
              type="text"
              [(ngModel)]="invoice_number"
              name="invoice_number"
              class="form-control form-control-lg"
            />
          </div>

          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> شماره فاکتور </label>
            <input
              type="text"
              [(ngModel)]="remittance_number"
              name="remittance_number"
              class="form-control form-control-lg"
            />
          </div>

          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> تاریخ خرید </label>
            <input
              matInput
              [matDatepicker]="picker3"
              (focus)="picker3.open()"
              type="text"
              [(ngModel)]="purchaseDate"
              name="buying_date"
              class="form-control-lg form-control"
            />
            <mat-datepicker #picker3></mat-datepicker>
          </div>

          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> تعداد ورق </label>
            <input
              type="text"
              value=""
              [(ngModel)]="count"
              name="sheet_number"
              (keydown)="func.numControl($event)"
              class="form-control form-control-lg"
            />
          </div>
          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> نوع خرید </label>
            <select
              [ngModel]="null"
              name="car_type"
              class="custom-select custom-select-lg"
            >
              <option *ngFor="let el of types" [ngValue]="el.id">
                {{ el.label }}
              </option>
            </select>
          </div>

          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> پلاک ماشین </label>
            <input
              type="text"
              value=""
              ngModel
              name="car_plaque"
              class="form-control-lg form-control"
            />
          </div>

          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> شماره بارنامه </label>
            <input
              type="text"
              ngModel
              name="bill_number"
              class="form-control-lg form-control"
            />
          </div>

          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> نام راننده </label>
            <input
              type="text"
              ngModel
              name="driver_name"
              class="form-control-lg form-control"
            />
          </div>

          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> کدملی راننده </label>
            <input
              type="text"
              ngModel
              name="driver_national_code"
              class="form-control-lg form-control"
            />
          </div>

          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> تلفن همراه راننده </label>
            <input
              type="text"
              ngModel
              name="driver_phone_number"
              class="form-control-lg form-control"
            />
          </div>

          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> تاریخ ارسال </label>
            <input
              matInput
              [matDatepicker]="picker"
              (focus)="picker.open()"
              (click)="picker.open()"
              type="text"
              [(ngModel)]="sendDate"
              name="sending_date"
              class="form-control-lg form-control"
            />
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <div class="form-input col-lg-3 col-md-6 col-12">
            <label class="mx-1 lead"> تاریخ دریافت </label>
            <input
              matInput
              [matDatepicker]="picker2"
              (focus)="picker2.open()"
              (click)="picker2.open()"
              type="text"
              [(ngModel)]="receiveDate"
              name="receiving_date"
              class="form-control-lg form-control"
            />
            <mat-datepicker #picker2></mat-datepicker>
          </div>

          <div class="form-input col-12">
            <label class="mx-1 lead"> توضیحات </label>
            <textarea
              class="form-control-lg form-control"
              ngModel
              name="detail"
            ></textarea>
          </div>

          <div *ngIf="images.length" class="box w-100 d-flex">
            <div
              *ngFor="let image of images; let i = index"
              class="w-25 p-3 position-relative"
            >
              <div class="img">
                <img
                  [src]="conf.settings.back + image"
                  style="width: 100%"
                />
              </div>
              <div
                (click)="remove(i)"
                class="cursor-pointer img-remove text-white bg-primary"
              >
                <i class="fa fa-times"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="form-input col-lg-3 col-md-6 col-12">
          <label class="mx-1 lead">انتخاب شماره پالت های ارسالی</label>
          <ng-multiselect-dropdown
                  [placeholder]="'لطفا انتخاب کنید'"
                  [settings]="dropdownSettings"
                  [data]="dropdownList"
                  [(ngModel)]="selectedItem"
                  (onSelect)="onItemSelect($event)"
                  (onDeSelect)="onDeSelect($event)"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="list m-3">
        <table class="table table-bordered">
          <thead class="thead-light headerless">
          <tr>
            <th scope="col">#</th>
            <th scope="col">شماره پالت</th>
            <th
                    scope="col"
                    class="d-lg-table-cell d-none"
            >
              عنوان محصول
            </th>
            <th scope="col">تعداد ورق</th>
            <th scope="col">x</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let el of addPallets; let i = index">
            <th scope="row">
              {{ i }}
            </th>
            <td>{{ el.code }}</td>
            <td>{{ el.product_name }}</td>
            <td class="d-lg-table-cell d-none">
              {{ el.number_in_pallet }}
            </td>
            <td
                    class="cursor-pointer"
                    (click)="onDeSelect(el)"
            >
              <i class="fal fa-trash-alt"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div *ngIf="user?.role == 4" class="w-100">
      <div class="row form-container mt-1">
        <div class="row">
          <div class="form-input col-lg-3 col-md-6 col-12">
            <input
              type="text"
              value=""
              [(ngModel)]="count"
              name="sheet_number"
              (keydown)="func.numControl($event)"
              class="form-control-lg form-control ng-untouched ng-pristine ng-valid"
              required
            />
            <label
              placeholder="تعداد ورق"
              alt="تعداد ورق"
              req="true"
            ></label>
          </div>
          <div class="form-input col-4">
            <input
              matInput
              [matDatepicker]="picker"
              (focus)="picker.open()"
              (click)="picker.open()"
              type="text"
              [(ngModel)]="sendDate"
              name="sending_date"
              class="form-control-lg form-control"
              required
            />
            <label
              placeholder="تاریخ ارسال"
              req="true"
              alt="تاریخ ارسال"
            ></label>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <div class="form-input col-4 pr-0">
            <input
              matInput
              [matDatepicker]="picker2"
              (focus)="picker2.open()"
              (click)="picker2.open()"
              type="text"
              [(ngModel)]="receiveDate"
              name="receiving_date"
              class="form-control-lg form-control"
              required
            />
            <label
              placeholder="تاریخ دریافت"
              alt="تاریخ دریافت"
              req="true"
            ></label>
            <mat-datepicker #picker2></mat-datepicker>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="user && user.id === buyer" class="row w-100">
      <button
        [routerLink]="['../']"
        type="button"
        class="btn col-2 btn-secondary"
      >
        بازگشت
      </button>
    </div>
    <div
      *ngIf="user && user.id !== buyer"
      class="row justify-content-end w-100 px-3"
    >
      <div class="row col-10 align-items-center">
        <button
          type="button"
          (click)="register(form)"
          [disabled]="disable_send"
          class="btn mr-auto ml-3 w-20 btn-success"
        >
          ثبت
        </button>

        <label class="btn ml-3 w-20 btn-success">
          <input
            type="file"
            accept="image/*"
            (change)="add_image($event)"
            [disabled]="disable_send"
            style="opacity: 0; display: none"
          />
          ارسال تصویر
        </label>
        <p
          class="ml-3 my-auto"
          *ngIf="this.remaining_sheet_number - this._count < 0"
        >
          تعداد ورق ارسالی نمی‌تواند از باقی‌مانده سفارش بیشتر باشد.
        </p>
      </div>
      <button
        (click)="loc.back()"
        type="button"
        class="btn col-2 btn-secondary"
      >
        بازگشت
      </button>
    </div>
  </form>
</div>
