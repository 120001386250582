<div id="collapse"  class="box">
    <div class="row">
        <div class="form-group col-md-6 col-12">
            <label>عنوان انبار</label>
            <input
                    type="text"
                    [(ngModel)]="warehouse.name"
                    name="name"
                    class="form-control form-control-lg"
                    required
            />
        </div>


        <div class="form-group col-md-6 col-12">
            <app-select
                    [options]="provinces"
                    [key]="province"
                    placeholder="استان"
                    (selectChange)="changeProvince($event)"
                    req="true"
            ></app-select>
        </div>

        <div class="form-group col-md-6 col-12">
            <app-select
                    [options]="cities"
                    [key]="city"
                    placeholder="شهرستان"
                    (selectChange)="changeCity($event)"
                    req="true"
            ></app-select>

            <input
                    type="hidden"
                    value=""
                    [(ngModel)]="city"
                    name="city"
                    class="d-none"
                    required
            />
        </div>
<!--        <div class="form-group col-md-6 col-12">-->
<!--            <app-select-->
<!--                    [options]="regionSupervisors"-->
<!--                    placeholder="سرپرست منطقه"-->
<!--                    (selectChange)="warehouse.supervisor = $event"-->
<!--                    req="true"-->
<!--            ></app-select>-->
<!--        </div>-->
<!--        <div class="form-group col-md-6 col-12">-->
<!--            <app-select-->
<!--                    [options]="marketingExperts"-->
<!--                    placeholder="کارشناس بازاریابی"-->
<!--                    (selectChange)="warehouse.marketing_expert = $event"-->
<!--                    req="true"-->
<!--            ></app-select>-->
<!--        </div>-->
<!--        <div class="form-group col-md-6 col-12">-->
<!--            <app-select-->
<!--                    [options]="saleExperts"-->
<!--                    placeholder="کارشناس فروش"-->
<!--                    (selectChange)="warehouse.sale_expert = $event"-->
<!--                    req="true"-->
<!--            ></app-select>-->
<!--        </div>-->

        <div class="row w-100 my-3">
            <button
                    (click)="register()"
                    class="btn mr-auto ml-3 w-25 btn-success"
            >
                ثبت
            </button>
        </div>
    </div>
</div>
