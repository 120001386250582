import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-signin',
   templateUrl: './signin.component.html',
   styleUrls: ['./signin.component.scss'],
   host: {
      class: 'w-100'
   }
})
export class SigninComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
