import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Config} from 'src/app/app-config.service';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {untilDestroyed} from 'ngx-take-until-destroy';
import * as Func from 'src/pack/js/func';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-unit-list',
    templateUrl: './unit-list.component.html',
    styleUrls: ['./unit-list.component.scss'],
})
export class UnitListComponent implements OnInit {
    show: boolean = false;
    func = Func;
    units: any[] = [];
    orgUnits: any[] = [];
    searchControl = new FormControl();
    unitsApi: string = Config.settings.api + Config.settings.add.unit;
    selected: boolean[] = [];

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe((term) => this.search(term));
        this.load();
    }

    load() {
        this.orgUnits = [];
        this.http.get(this.unitsApi).subscribe(
            (response: any) => {
                let mx = 0;
                response.forEach((element) => {
                    this.orgUnits.push({
                        id: element.id,
                        name: element.name,
                        status: element.is_active,
                    });
                    mx = Math.max(mx, element.id);
                });
                // this.selected = new Array(mx + 1).fill(false);
                this.units = this.orgUnits;
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    search(value: string) {
        this.units = this.orgUnits.filter((option) =>
            option['name'].includes(value)
        );
    }

    editing_unit;

    edit(content, i) {
        let el = this.units[i];
        this.editing_unit = {
            id: el.id,
            name: el.name,
            is_active: el.status,
        };

        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http
                    .patch(
                        this.unitsApi + this.editing_unit.id + '/',
                        this.editing_unit
                    )
                    .subscribe(
                        (response: any) => {
                            this.load();
                        },
                        (error: HttpErrorResponse) => {
                            //TODO: server errors
                            console.log(error);
                        }
                    );
            }
        });
    }

    del(content, i) {
        if (!i) {
            return;
        }
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.unitsApi + i + '/').subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    statuses = [
        {
            id: true,
            label: 'فعال',
        },

        {
            id: false,
            label: 'غیر فعال',
        },
    ];

    unit = {
        name: '',
        is_active: false,
    };

    register(content) {
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.post(this.unitsApi, this.unit).subscribe(
                    (response: any) => {
                        this.load();
                        this.unit = {
                            name: '',
                            is_active: false,
                        };
                        this.show = false;
                        window.scroll(0, 0);
                    },
                    (error: HttpErrorResponse) => {
                        console.log(error);
                    }
                );
            }
        });
    }

    ngOnDestroy() {
}
}
