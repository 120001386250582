<div class="m-3 mt-4">
  <app-title title="لیست پرداخت‌های نقدی"></app-title>
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item cursor-pointer" (click)="load()">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
      <div class="list-header-item">
        تعداد ردیف:&nbsp;
        <div
          class="text-success"
          style="font-size: 1.3rem; font-weight: 600"
        >
          {{ object_count.toString() | toFa }}
        </div>
      </div>
      <div class="list-header-item">
        مجموع کل:&nbsp;
        <div
          class="text-success"
          style="font-size: 1.3rem; font-weight: 600"
        >
          {{ amount_sum.toString() | toR }}
        </div>
      </div>
      <div
        class="list-header-item mr-auto cursor-pointer"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="far fa-file-search"></i>فیلتر
      </div>
    </div>

    <app-filter
      [isCollapsed]="isCollapsed"
      [filter_items]="filter_items"
      (changeFilter)="search($event)"
    ></app-filter>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col" (click)="sort('company_name')">نام فروشگاه</th>
          <th scope="col" (click)="sort('order_date')">تاریخ سفارش</th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('order_id')"
          >
            سفارش
          </th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('invoice_number')"
          >
            پیش‌فاکتور
          </th>
          <th scope="col" class="d-lg-table-cell d-none" (click)="sort('remittance_number')">فاکتور</th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('invoice_number')"
          >
            نوع پرداخت
          </th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('name')"
          >
            پرداخت کننده
          </th>
          <th scope="col"  (click)="sort('amount')">مبلغ</th>
          <th scope="col" (click)="sort('date')">تاریخ واریز</th>
          <th   class="d-lg-table-cell d-none"  scope="col" (click)="sort('creator_last_name')">کاربر ثبت کننده</th>
          <th scope="col"  class="d-lg-table-cell d-none" (click)="sort('confirmation_state')">
            وضعیت تایید
          </th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of cashes; let i = index">
          <th scope="row">
            {{ (page_number - 1) * page_size + i + 1 | toFa }}
          </th>
          <td>{{ el.company_name }}</td>
          <td>{{ el.order_date | jDate | toFa }}</td>
          <td class="d-lg-table-cell d-none">{{ el.order_id | toFa }}</td>
          <td class="d-lg-table-cell d-none">
            {{ el.invoice_number | toFa }}
          </td>
          <td class="d-lg-table-cell d-none">{{ el.remittance_number | toFa }}</td>
          <td class="d-lg-table-cell d-none">
            {{ deposit_types_map.get(parseint(el.deposit_type)) }}
          </td>
          <td class="d-lg-table-cell d-none">{{ el.name | toFa }}</td>
          <td>{{ el.amount | toR }}</td>
          <td>{{ el.date | jDate | toFa }}</td>
          <td  class="d-lg-table-cell d-none" >{{el.creator_first_name +" "+ el.creator_last_name}}</td>
          <td  class="d-lg-table-cell d-none">{{ el.confirmation_text }}</td>
          <td class="cursor-pointer" [routerLink]="[el.id]">
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    <app-pagination
      [page_count]="page_count"
      [page_number]="page_number"
      (changePage)="load($event)"
    ></app-pagination>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      آیا از حذف این رکورد اطمینان دارید؟
    </h4>
  </div>
  <div class="modal-body">
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(true)"
    >
      بله
    </button>
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(false)"
    >
      خیر
    </button>
  </div>
</ng-template>

<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ویرایش پرداخت</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-lg-4 col-md-6 col-12">
        <label class="mx-1 lead"> شماره فاکتور </label>
        <select
          [ngModel]="editing_cash.load_report"
          class="custom-select custom-select-lg"
          (ngModelChange)="editing_cash.load_report = $event"
        >
          <option *ngFor="let el of load_reports" [ngValue]="el.id">
            {{ el.label }}
          </option>
        </select>
      </div>

      <div class="form-group col-lg-4 col-md-6 col-12">
        <label class="mx-1 lead"> مبلغ </label>
        <input
          type="text"
          [(ngModel)]="editing_cash.amountt"
          class="form-control form-control-lg"
        />
      </div>

      <div class="form-group col-lg-4 col-md-6 col-12">
        <label class="mx-1 lead"> تاریخ واریز </label>
        <div class="input-group">
          <input
            matInput
            [matDatepicker]="picker3"
            [(ngModel)]="editing_cash.date"
            class="form-control form-control-lg"
          />

          <span class="input-group-append" (click)="picker3.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
          <mat-datepicker #picker3></mat-datepicker>
        </div>
      </div>

      <div class="form-group col-lg-4 col-md-6 col-12">
        <label class="mx-1 lead"> نوع پرداخت </label>
        <select
          [ngModel]="editing_cash.deposit_type"
          class="custom-select custom-select-lg"
          (ngModelChange)="editing_cash.deposit_type = $event"
        >
          <option *ngFor="let el of deposit_types" [ngValue]="el.id">
            {{ el.label }}
          </option>
        </select>
      </div>

      <div class="form-group col-lg-4 col-md-6 col-12">
        <label class="mx-1 lead"> شماره حساب مقصد </label>
        <select
          [ngModel]="editing_cash.bank_account"
          class="custom-select custom-select-lg"
          (ngModelChange)="editing_cash.bank_account = $event"
        >
          <option *ngFor="let el of bank_accounts" [ngValue]="el.id">
            {{ el.label }}
          </option>
        </select>
      </div>

      <div class="form-group col-lg-4 col-md-6 col-12">
        <label class="mx-1 lead"> شماره حساب مبدا </label>
        <input
          type="text"
          [(ngModel)]="editing_cash.source_bank_account"
          class="form-control form-control-lg"
        />
      </div>

      <div class="form-group col-lg-4 col-md-6 col-12">
        <label class="mx-1 lead"> نام واریز کننده </label>
        <input
          type="text"
          [(ngModel)]="editing_cash.name"
          class="form-control form-control-lg"
        />
      </div>
    </div>
    <div class="row m-2" style="z-index: 99999;">
      <div class="col-sm-6 col-md-6 col-lg-6" >
        <i
                (click)="del_image()"
                class="fas fa-trash-alt cursor-pointer">
        </i>
        <label>
          <input
                  type="file"
                  (change)="change_image_1($event)"
                  style="opacity: 0; display: none"/>

          <img
                  class="profile-image"
                  [src]="imgUrl1 != null ? conf.settings.back + imgUrl1 :
                             '../../../../assets/img/upload.jpg'" style="width: 200px;height: 150px"
          /></label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6" >
        <i
                (click)="del_image_2()"
                class="fas fa-trash-alt cursor-pointer">
        </i>
        <label>
          <input
                  type="file"
                  (change)="change_image_2($event)"
                  style="opacity: 0; display: none"/>
          <img    class="profile-image zoom"
                  [src]="imgUrl2 != null ? conf.settings.back + imgUrl2 :
                             '../../../../assets/img/upload.jpg'" style="width: 200px;height: 150px;"
          />
        </label>
      </div>
    </div>

    <div class="row m-3">
      <label class="mx-1 lead">توضیحات</label>
      <textarea
        class="w-100 form-control"
        [(ngModel)]="editing_cash.detail"
        name="detail"
        (keydown)="(1 == 1)"
      ></textarea>
    </div>
    <div class="row box m-3">
      <div
        class="form-group col d-flex align-items-center justify-content-between m-3"
      >
        <label class="mx-3 lead" style="font-size: 1.4rem">
          تایید مشتری
        </label>
        <input
          type="checkbox"
          class="ml-3"
          [(ngModel)]="editing_cash.customer_confirmation"
          [disabled]="
            !(
              user.role == 1 ||
              user.role == 2 ||
              user.role == 3 ||
              user.role == 4 ||
              user.role == 5 ||
              user.role == 10
            )
          "
        />
      </div>
      <div
        class="form-group col d-flex align-items-center justify-content-between m-3"
      >
        <label class="mx-3 lead" style="font-size: 1.4rem">
          تایید کارشناس
        </label>
        <input
          type="checkbox"
          class="ml-3"
          [(ngModel)]="editing_cash.expert_confirmation"
          [disabled]="
            !editing_cash.customer_confirmation ||
            ((user.role < 5 || user.role > 10) && user.id != 1)
          "
        />
      </div>
      <div
        class="form-group col d-flex align-items-center justify-content-between m-3"
      >
        <label class="mx-3 lead" style="font-size: 1.4rem">
          تایید حسابداری
        </label>
        <input
          type="checkbox"
          class="ml-3"
          [(ngModel)]="editing_cash.manager_confirmation"
          [disabled]="
            !editing_cash.expert_confirmation ||
            (user.role != 14 && user.role != 13 && user.id != 1)
          "
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(true)"
      >
        تایید
      </button>
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>
