<div class="m-3 mt-4">
    <app-title title="گزارش وضعیت موجودی انبار"></app-title>
    <div class="list my-3 mx-auto">
        <div class="list-header">
            <div class="list-header-item cursor-pointer">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div class="list-header-item">
                تعداد ردیف:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 600"
                >
                    {{ object_count.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item">
                تعداد ورق ورودی صفحه:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 600"
                >
                    {{ page_input_sheet_sum.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item d-lg-table-cell d-none">
                تعداد ورق ورودی کل:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 600"
                >
                    {{ total_input_sheet_sum.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item d-lg-table-cell d-none">
                تعداد ورق خروجی صفحه:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 600"
                >
                    {{ page__output_sheet_sum.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item d-lg-table-cell d-none">
                تعداد ورق خروجی کل:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 600"
                >
                    {{ total_output_sheet_sum.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item d-lg-table-cell d-none">
                تعداد باقی مانده:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 600"
                >
                    {{ total_input_output_sheet_sum.toString() | toFa }}
                </div>
            </div>

            <div
                    class="list-header-item mr-auto cursor-pointer"
                    (click)="isCollapsed = !isCollapsed"
            >
                <i class="far fa-file-search"></i>فیلتر
            </div>
        </div>
        <app-filter
                [isCollapsed]="isCollapsed"
                [filter_items]="filter_items"
                (changeFilter)="search($event)"
        ></app-filter>

        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col" (click)="sort('warehouse_name')">
                    عنوان انبار فروش
                </th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('product_code')">
                    کد محصول
                </th>
                <th scope="col"  (click)="sort('product_type_name')">نوع محصول</th>
                <th
                        scope="col"
                        (click)="sort('product_name')"
                >
                    نام محصول
                </th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('product_brand_name')">برند</th>
                <th scope="col" class="d-lg-table-cell d-none"(click)="sort('product_degree_name')">درجه بندی</th>
                <th scope="col" class="d-lg-table-cell d-none"
                    (click)="sort('product_thickness_name')">ضخامت</th>
                <th
                        scope="col"
                        class="d-lg-table-cell d-none"
                        (click)="sort('product_dimension_name')"
                >
                    ابعاد
                </th>
                <th
                        scope="col"
                        class="d-lg-table-cell d-none"
                        (click)="sort('input_sheet_sum')"
                >
                    تعداد ورودی
                </th>
                <th
                        scope="col"

                        (click)="sort('output_sheet_sum')"
                >
                   تعداد خروجی
                </th>
                <th scope="col" (click)="sort('تعداد ورق‌های موجود')" >تعداد باقی مانده</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('warehouse_responsible_company_name')">مسئول انبار</th>



            </tr>
            </thead>
            <tbody>
            <tr
                    *ngFor="let el of warehouseStock; let i = index"

            >
                <th scope="row">
                    {{ (page_number - 1) * page_size + i + 1 | toFa }}
                </th>
                <td>{{ el.warehouse_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.product_code }}</td>
                <td >{{ el.product_type_name }}</td>
                <td >{{ el.product_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.product_brand_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.product_degree_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.product_thickness_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.product_dimension_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.input_sheet_sum }}</td>
                <td>{{ el.output_sheet_sum }}</td>
                <td>{{ el.input_sheet_sum  -  el.output_sheet_sum }}</td>
                <td class="d-lg-table-cell d-none">{{ el.warehouse_responsible_company_name }}</td>




            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="warehouseStock.length==0"
         style="width: 100%;
    height: 30px;
    text-align: center;padding-top: 7px;">
        <h5>
            اطلاعاتی یافت نشد
        </h5>

    </div>
    <div *ngIf="warehouseStock.length!=0" class="d-flex justify-content-center">
        <app-pagination
                [page_count]="page_count"
                [page_number]="page_number"
                (changePage)="load($event)"
        ></app-pagination>
    </div>

</div>
