import {Component, OnInit, OnDestroy, ElementRef, ViewChild, Input} from "@angular/core";
import {HttpErrorResponse, HttpClient} from "@angular/common/http";
import {Router, ActivatedRoute, UrlSegment} from "@angular/router";
import {Config} from "src/app/app-config.service";
import {FormControl} from "@angular/forms";
import {debounceTime, throttleTime} from "rxjs/operators";
import * as Func from "src/pack/js/func";
import {untilDestroyed} from "ngx-take-until-destroy";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoadingService} from "src/app/loading/loading.service";
import {AuthService} from "src/app/auth/auth.service";
import {ToolbarItemModel} from "src/app/models/toolbar.model";

@Component({
    selector: "app-order-list",
    templateUrl: "./order-list.component.html",
    styleUrls: ["./order-list.component.scss"],
    host: {class: "w-100"},
})
export class OrderListComponent implements OnInit {
    @Input()
    customer_id?: number;

    @Input()
    is_title?: boolean;

    isTitle = true;
    page_number: number = 1;
    object_count: number = 0;
    page_count: number = 0;
    @ViewChild("reportContent")
    reportContent: ElementRef;
    usersApi: string = Config.settings.api + Config.settings.user.user;

    isCollapsed = true;
    filter_items = [
        {
            name: "in_company",
            type: "hidden",
        },
        {
            name: "searching_term",
            label: "عبارت جست‌وجو",
            type: "string",
        },
        {
            name: "buyer",
            label: "کد مشتری",
            type: "string",
        },
        {
            name: "id",
            label: "شماره سفارش",
            type: "string",
        },
        {
            name: "request_number",
            label: "شماره درخواست",
            type: "string",
        },
        {
            name: "invoice_number",
            label: "شماره پیش فاکتور",
            type: "string",
        },
        {
            name: "start_date",
            label: "تاریخ سفارش از",
            type: "date",
        },
        {
            name: "end_date",
            label: "تاریخ سفارش تا",
            type: "date",
        },
        {
            name: "company_name",
            label: "نام فروشگاه",
            type: "string",
        },
        {
            name: "reference",
            label: "نام فروشنده مادر",
            type: "string",
        },
        {
            name: "expert_name",
            label: "نام کارشناس",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "type",
            label: "نوع خرید",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "method",
            label: "نحوه خرید",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "state",
            label: "وضعیت سفارش",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "checkout_state",
            label: "وضعیت تسویه حساب",
            type: "list",
            options: [
                {id: null, label: "همه"},
                {
                    id: 1,
                    label: "عدم تسویه حساب",
                },
                {
                    id: 2,
                    label: "در حال تسویه حساب",
                },
                {
                    id: 3,
                    label: "تسویه حساب کامل",
                },
            ],
            res: null,
        },
        {
            name: "source",
            label: "منبع تامین سفارش",
            type: "list",
            options: [
                {id: null, label: "همه"},
                {
                    id: 1,
                    label: "کارخانه",
                },
                {
                    id: 2,
                    label: "انبار های استانی",
                }
            ],
            res: null,
        },
        {
            name: "warehouse_id",
            label: "نام انبار استانی",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "region",
            label: "منطقه",
            type: "list",
            options: [],
            res: null,
        },
    ];
    toolbarItemList: ToolbarItemModel[] = [
        {
            name: "new",
            icon: "fas fa-plus",
            text: "جدید",
            type: "button",
            btn_type: "info"
        },
        {
            name: "refresh",
            icon: "fas fa-sync",
            text: "به‌روزرسانی",
            type: "button",
            btn_type: "primary"
        },
        {
            name: "multiple",
            icon: "fal fa-ballot-check",
            text: "انتخاب چند گانه",
            type: "button",
            btn_type: "primary"
        },
        {
            name: "space",
            text: "space",
            type: "space",
        },
        {
            name: "cancel",
            text: "لغو",
            type: "icon-button",
            icon: "fal fa-times-circle fa-2x text-danger",
        },
        {
            name: "print",
            text: "چاپ",
            type: "icon-button",
            icon: "fal fa-print fa-2x text-info",
        },
        {
            name: "excel",
            text: "خروجی اکسل",
            type: "icon-button",
            icon: "fal fa-file-excel fa-2x text-success",
        },
        {
            name: "space",
            text: "space",
            type: "space",
        },
        {
            name: "operations",
            text: "عملیات",
            icon: "fas fa-cogs",
            type: "menu",
            btn_type: "primary",
            items: [
                {
                    name: "cancel",
                    text: "لغو",
                    type: "icon-button",
                    icon: "fal fa-times-circle",
                },
                {
                    name: "print",
                    text: "چاپ",
                    type: "icon-button",
                    icon: "fal fa-print ",
                },
                {
                    name: "excel",
                    text: "خروجی اکسل",
                    type: "icon-button",
                    icon: "fal fa-file-excel",
                }
            ]
        }
    ]
    title: string = 'لیست سفارشات';
    orders: any[] = [];
    orgOrders: any[] = [];
    searchControl = new FormControl();
    selected: boolean[] = [];
    ordersApi: string = Config.settings.api + Config.settings.order.order;
    func = Func;
    conf = Config;
    status = 1;
    success: boolean;
    msg: string;
    searchTerm: string;
    sheet_sum: number = 0;
    page_sheet_sum: number = 0;
    all_discount_amounts: number = 0;
    all_order_amounts: number = 0;
    show_warehouse: boolean = false;
    page_size: any;
    is_today: boolean;
    buyer_company_name: boolean;
    date: boolean;
    id: boolean;
    invoice_number: boolean;
    sum: boolean;
    debt_amount: boolean;
    discount_amoun: boolean;
    pure_amount: boolean;
    creator_last_name: boolean;
    buyer_marketing_expert_last_name: boolean;
    state_name: boolean;
    checkout_state: boolean;
    field = {
        fields: null
    };

    fields: any [] = [];

    constructor(
        private auth: AuthService,
        private router: Router,
        private http: HttpClient,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private loading: LoadingService
    ) {
    }

    can_del = true;
    user;

    async ngOnInit(): Promise<void> {
        debugger;
        if (this.customer_id === undefined) {
            localStorage.removeItem("customer_id");
        } else {
            localStorage.setItem("customer_id", this.customer_id.toString());
        }
        if (this.is_title !== undefined){
            this.isTitle = this.is_title;
        }
        this.user = await this.auth.get_info();
        // this.route.queryParams.subscribe((f) => {
        this.route.queryParamMap.subscribe((f) => {
            let filter = {};
            f.keys.forEach((k) => (filter[k] = f.get(k)));
            console.log(filter);
            console.log(filter["in_company"]);

            if (this.user.role == 4 && filter["in_company"] == "0") {
                this.ordersApi += "customer_orders/";
                delete filter["in_company"];
            } else if (
                this.user.role <= 4 &&
                this.user.role >= 2 &&
                filter["in_company"] == "1"
            ) {
                delete filter["in_company"];
            }
            this.show_warehouse = false;
            if (filter["source"] == "2") {
                this.show_warehouse = true;
                this.title = "لیست سفارشات خروجی از انبار";
            }

            this.filter_items.forEach(
                (el) => (el.res = filter[el.name] ? filter[el.name] : null)
            );
            this.search(filter);
        });

        this.searchControl.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe((term) => this.search(term));
        this.loadExperts();
        this.loadMethods();
        this.loadTypes();
        this.loadStates();
        this.loadWarehouse();
        this.loadRegion();
    }

    disable_load = false;

    load(page = 1) {
        debugger;
        if (this.disable_load) return;
        this.disable_load = true;
        this.loading.add();

        console.log(this.ordersApi);

        if (this.customer_id !== null && this.customer_id !== undefined){
            this.searchTerm =  this.searchTerm + "&buyer=" + this.customer_id ;
        }
        this.orgOrders = [];
        this.http
            .get(
                this.ordersApi +
                `?is_canceled=0&page=${page}&${this.searchTerm}&ordering=${this.sortcol}`
            )
            .subscribe(
                (response: any) => {
                    console.log(response);
                    this.page_count = response.page_count;
                    this.object_count = response.object_count;
                    this.page_number = response.page_number;
                    this.page_size = response.page_size;
                    this.sheet_sum = response.sheet_sum;
                    this.all_discount_amounts = response.all_discount_amounts;
                    this.all_order_amounts = response.all_order_amounts;
                    this.page_sheet_sum = response.page_sheet_sum;

                    response.results.forEach((element) => {
                        this.orgOrders.push(element);
                    });
                    this.orders = this.orgOrders;

                    this.loading.remove();
                    this.disable_load = false;
                },
                (error: HttpErrorResponse) => {
                    //TODO: server errors
                    console.log(error);
                    this.orgOrders = [];
                    this.orders = [];
                    this.page_count = 0;
                    this.page_number = 0;
                    this.object_count = 0;
                    this.sheet_sum = 0;
                    this.all_discount_amounts = 0;
                    this.all_order_amounts = 0;
                    this.page_sheet_sum = 0;
                    this.loading.remove();

                    this.disable_load = false;
                }
            );
    }

    loadExperts() {
        let tc = [{id: null, label: "همه"}];

        this.http.get(this.usersApi + "?q=5").subscribe(
            (response: any) => {
                response.forEach((element) => {
                    if (element.name)
                        tc.push({
                            id: element.id,
                            label: element.name,
                        });
                });
                // this.filter_items[4].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "expert_name") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    loadMethods() {
        let tc = [{id: null, label: "همه"}];
        let methodApi: string =
            Config.settings.api + Config.settings.order.method;
        this.http.get(methodApi).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.method,
                    });
                });
                // this.filter_items[6].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "method") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    loadTypes() {
        let tc = [{id: null, label: "همه"}];
        let typeApi: string =
            Config.settings.api + Config.settings.order.type;
        this.http.get(typeApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.type,
                    });
                });
                // this.filter_items[5].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "type") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    loadStates() {
        let tc = [{id: null, label: "همه"}];
        let typeApi: string =
            Config.settings.api + Config.settings.order.state;
        this.http.get(typeApi).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    if (element.id != 12)
                        tc.push({
                            id: element.id,
                            label: element.state,
                        });
                });
                // this.filter_items[7].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "state") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }


    loadWarehouse() {
        let tc = [{id: null, label: "همه"}];
        let warehouseApi: string =
            Config.settings.api + "warehouse/warehouse";
        this.http.get(warehouseApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.name,
                    });
                });
                // this.filter_items[7].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "warehouse_id") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    loadRegion() {
        let tc = [{id: null, label: "همه"}];
        let regionApi: string =
            Config.settings.api + "information/region";
        this.http.get(regionApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.name,
                    });
                });
                // this.filter_items[7].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "region") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }


    downloadFile(content) {
        if (this.status === 1) {
            this.status = 0;
            this.modalService
                .open(content, {ariaLabelledBy: "modal-basic-title"})
                .result.then((result) => {
                if (result) {
                    this.setFields();
                    this.field = {
                        fields: this.fields
                    };
                    console.log(this.field);
                    this.http.post(Config.settings.api + `order/order/download/?${this.searchTerm}`, this.field).subscribe(
                        (response: any) => {
                            this.status = 1;
                            this.success = false;
                            this.msg = " ";
                            this.fields = [];
                            window.open(Config.settings.download + response.link, "_blank");
                        },
                        (error: HttpErrorResponse) => {
                            this.fields = [];
                            console.log(error);
                            this.status = 1;
                        }
                    );
                } else {
                    this.fields = [];
                    this.status = 1;
                }
            });
        } else {
            this.success = true;
            this.msg = "فایل در حال دانلود می باشد...";
        }

    }

    setFields() {
        if (this.id) {
            this.fields.push("id");
        }
        if (this.checkout_state) {
            this.fields.push("checkout_state");
        }
        if (this.buyer_company_name) {
            this.fields.push("buyer_company_name");
        }
        if (this.date) {
            this.fields.push("date");
        }
        if (this.invoice_number) {
            this.fields.push("invoice_number");
        }
        if (this.sum) {
            this.fields.push("sum");
        }
        if (this.debt_amount) {
            this.fields.push("debt_amount");
        }
        if (this.discount_amoun) {
            this.fields.push("discount_amoun");
        }
        if (this.pure_amount) {
            this.fields.push("pure_amount");
        }
        if (this.creator_last_name) {
            this.fields.push("creator_last_name");
        }
        if (this.buyer_marketing_expert_last_name) {
            this.fields.push("buyer_marketing_expert_last_name");
        }
        if (this.state_name) {
            this.fields.push("state_name");
        }
    }

    search(filter: any) {
        console.log(filter);

        this.searchTerm = "";
        Object.keys(filter).forEach((el) => {
            this.searchTerm += el + "=" + filter[el] + "&";
            // console.log(el);
        });

        if (this.searchTerm != "")
            this.searchTerm = this.searchTerm.slice(0, -1);

        let qp = filter;
        // qp['q'] = this.searchTerm;

        this.router.navigate([], {
            queryParams: qp,
            // queryParamsHandling: "merge",
        });
        this.load();
        this.isCollapsed = true;
    }

    edit(i = null) {
        if (i) {
            this.router.navigate(["/userArea/order/", i]);
        }
        this.selected.forEach((el, i) => {
            if (el) {
                this.router.navigate(["/userArea/order/", i]);
            }
        });
    }

    del(content, i) {
        if (!i) return;
        this.modalService
            .open(content, {ariaLabelledBy: "modal-basic-title"})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.ordersApi + i + "/").subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    sortcol: string = null;

    sort(col: string) {
        if (this.sortcol == col) this.sortcol = "-" + col;
        else this.sortcol = col;
        this.load();
    }

    itmeClickHandler(event) {
        console.log(event);
        switch (event.name) {
            case 'new':
                this.router.navigate(['userArea/order/new']);
                break;
            case 'refresh':
                this.load();
                break;
            case 'excel':
                this.downloadFile(this.reportContent);
        }
    }

    ngOnDestroy() {
    }
}
