<div class="m-3 ml-5 mt-4">
    <app-title title="ثبت محصولات"></app-title>

    <div *ngIf="success" class="" role="">
        <div
                class="d-flex text-success justify-content-center align-items-center"
        >
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ msg }}
        </div>
    </div>
    <div *ngIf="isWrong" class="" role="">
        <div class="d-flex justify-content-center align-items-center">
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ errorMsg }}
        </div>
    </div>
    <div class="box">
        <div class="row">
            <div class="form-group col-md-4 col-12">
                <label>نام محصول</label>
                <input
                        type="text"
                        [(ngModel)]="product.name"
                        name="name"
                        class="form-control form-control-lg"
                        required
                />
            </div>
            <div class="form-group col-md-4 col-12">
                <label>کد محصول</label>
                <input
                        type="text"
                        [(ngModel)]="product.code"
                        name="code"
                        class="form-control form-control-lg"
                        required
                />
            </div>

            <div class="form-group col-md-4 col-12">
                <label>تعداد در پالت</label>
                <input
                        type="text"
                        [(ngModel)]="product.number_in_pallet"
                        (keydown)="func.numControl($event)"
                        name="number_in_pallet"
                        class="form-control form-control-lg"
                        required
                />
            </div>
            <div class="form-group col-md-4 col-12">
                <label>وزن</label>
                <input
                        type="text"
                        [(ngModel)]="product.weight"
                        name="weight"
                        (keydown)="func.numControl($event)"
                        class="form-control form-control-lg"
                        required
                />
            </div>

            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="unit_of_measures"
                        [key]="product.unit"
                        placeholder="واحد"
                        (selectChange)="product.unit = $event"
                        req="true"
                ></app-select>
            </div>

            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="thicknesses"
                        placeholder="ضخامت"
                        (selectChange)="product.thickness = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="types"
                        placeholder="نوع"
                        (selectChange)="product.type = $event"
                        req="true"
                ></app-select>
            </div>

            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="brands"
                        placeholder="برند"
                        (selectChange)="product.brand = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="degrees"
                        placeholder="درجه بندی"
                        (selectChange)="product.degree = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="suppliers"
                        placeholder="تامین کننده"
                        (selectChange)="product.supplier = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="dimensions"
                        placeholder="ابعاد"
                        (selectChange)="product.dimension = $event"
                        req="true"
                ></app-select>
            </div>
        </div>
        <div>
            <div class="row justify-content-end w-100 px-3">
                <div
                        class="form-group col-2 d-flex align-items-center justify-content-between">
                    <button class="btn btn-primary w-100" (click)="register()">
                        ثبت
                    </button>
                </div>
                <div
                        class="form-group col-2 d-flex align-items-center justify-content-between">
                    <button class="btn btn-secondary w-100 " (click)="loc.back()">
                        بازگشت
                    </button>
                </div>
            </div>
        </div>
    </div>
