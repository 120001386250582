<div class="w-100">
   <form
      class="d-flex flex-column justify-content-center"
      #form="ngForm"
      (ngSubmit)="login(form)"
   >
      <input
         type="text"
         class="form-control form-control-lg my-2"
         placeholder="شماره همراه"
         name="phoneNumber"
         ngModel
      />
      <input
         *ngIf="isValidating"
         type="text"
         class="form-control form-control-lg my-2 rounded"
         placeholder="کد تایید"
         name="validationCode"
         ngModel
      />
      <!-- <p class="text-secondary text-center mt-1 h6">
         کد تایید تا 30 ثانیه دیگر برای شما ارسال می شود.
      </p> -->

      <input type="hidden" [(ngModel)]="trackingCode" name="trackingCode" />
      <button
         *ngIf="!isValidating"
         type="submit"
         class="btn btn-lg mx-auto btn-success m-2 mt-4 w-50"
         (click)="(1 == 1)"
      >
         ارسال کد تایید
      </button>
      <button
         *ngIf="isValidating"
         type="submit"
         class="btn btn-lg mx-auto btn-success m-2 mt-4 w-50"
         (click)="(1 == 1)"
      >
         ورود
      </button>
   </form>
</div>
