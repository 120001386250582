import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import * as Func from "src/pack/js/func";
import { Config } from "src/app/app-config.service";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ToRPipe } from "src/app/fa.pipe";
import { LoadingService } from "src/app/loading/loading.service";

@Component({
  selector: "app-survey-watch",
  templateUrl: "./survey-watch.component.html",
  styleUrls: ["./survey-watch.component.scss"],
})
export class SurveyWatchComponent implements OnInit {
  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;
  sendDate = new Date();
  date = new Date();
  survayDate = new Date();
  purchaseDate = null;
  name: string;
  remittance_number: string;
  imgUrl: string;
  conf = Config;

  parseInt = parseInt;
  order: any;
  creator_name: string;

  get score(): number {
    let res = 0.0;
    this.questions.forEach((el, i) => {
      res += el.value * this.responses[i].answer;
    });

    return res;
  }

  get pscore(): number {
    return this.perfect_score
      ? Number(((this.score * 100.0) / this.perfect_score).toFixed(2))
      : 0;
  }

  get perfect_score(): number {
    let sum = 0;
    this.questions.forEach((el, i) => {
      sum += el.value;
    });

    sum *= 10;

    return sum;
  }

  score_name: string[] = [
    "خیلی ضعیف",
    "ضعیف",
    "متوسط",
    "خوب",
    "بسیار خوب",
    "بسیار خوب",
  ];
  surveys: any[] = [];
  survey: number;

  responses: { question: number; answer: number; detail: string }[] = [];
  questions: any[] = [];

  detail: string;
  func = Func;
  math = Math;
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public loc: Location,
    private loading: LoadingService
  ) {}

  id: number;

  toR: ToRPipe = new ToRPipe();

  user;
  buyer: number;
  state: number = 0;
  async ngOnInit(): Promise<void> {
    this.user = await this.auth.get_info();
    await this.load_survays();
    this.load();
  }

  get can_edit() {
    return this.user?.permission_codes.includes(163);
  }

  async load() {
    this.route.params.subscribe((params) => {
      if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
        this.router.navigate(["/404"]);
      } else {
        this.id = parseInt(params.id);
        let shippingApi: string =
          Config.settings.api +
          Config.settings.evaluation.shipping_report +
          `${this.id}/`;
        this.loading.add();
        this.http.get(shippingApi).subscribe(
          async (response: any) => {
            console.log(response);
            this.detail = response.detail;
            this.creator_name =
              response.creator_first_name +
              " " +
              response.creator_last_name;

            let shippingApi: string =
              Config.settings.api +
              Config.settings.order.shipping +
              `${response.load_report}/`;
            this.loading.add();
            this.http.get(shippingApi).subscribe(
              (response: any) => {
                console.log(response);
                this.name =
                  response.buyer_first_name +
                  " " +
                  response.buyer_last_name;
                this.date = new Date(response.order_date);
                this.sendDate = new Date(response.sending_date);
                this.survayDate = new Date();
                this.order = response.order;
                this.remittance_number = response.remittance_number;
                this.loading.remove();
              },
              (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
                this.loading.remove();
              }
            );
            await this.changeSurvey(response.evaluation);
            this.responses = [];
            response.responses.forEach((el) => {
              this.add(el.id, el.answer, el.detail);
            });
            this.loading.remove();
          },
          (error: HttpErrorResponse) => {
            //TODO: server errors
            console.log(error);
            this.loading.remove();
          }
        );
      }
    });
  }

  async load_survays() {
    let evaluationApi: string =
      Config.settings.api + Config.settings.evaluation.evaluation;
    await this.http
      .get(evaluationApi)
      .toPromise()
      .then((response: any) => {
        response.forEach((el) => {
          this.surveys.push({
            id: el.id,
            label: el.id.toString(),
          });
        });
      })
      .catch((error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      });
  }

  register(form) {
    let data = form.value;
    data.responses = this.responses;
    data.responses.map((el) => {
      el.answer = parseInt(el.answer);
      return el;
    });
    console.log(data);
    let url =
      Config.settings.api +
      Config.settings.evaluation.shipping_report +
      `${this.id}/`;
    console.log(JSON.stringify(data));
    this.http.patch(url, data).subscribe(
      (response: any) => {
        console.log("shod");
        this.success = true;
        this.isWrong = false;
        window.scroll(0, 0);
        this.msg = `فرم رضایت‌سنجی ویرایش شد.`;
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }

  async changeSurvey($event) {
    this.survey = $event;
    this.responses = [];
    let evaluationApi: string =
      Config.settings.api +
      Config.settings.evaluation.evaluation +
      `${$event}/`;
    await this.http
      .get(evaluationApi)
      .toPromise()
      .then((response: any) => {
        console.log(response);
        this.questions = response.questions;
        this.questions.forEach((el) => {
          this.add(el.id);
        });
      })
      .catch((error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      });
  }

  add(q = null, a = null, d = null) {
    this.responses.push({
      question: q,
      answer: a,
      detail: d,
    });
  }

  deny(e): void {
    e.preventDefault();
  }
}
