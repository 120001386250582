import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-customer-profile",
  templateUrl: "./customer-profile.component.html",
  styleUrls: ["./customer-profile.component.scss"],
})
export class CustomerProfileComponent implements OnInit, OnDestroy {
  user_id = 0;
  @Input()
  id:number=0;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    localStorage.removeItem("user_id");
  }

  ngOnInit() {
    console.log(this.id);
    // let user = await this.auth.get_info();
    // this.user_id = user.id;
      if (isNaN(this.id) || !Number.isInteger(this.id / 1)) {

        this.router.navigate(["/404"]);
      } else {
        this.user_id = this.id;
        localStorage.setItem("user_id", this.user_id.toString());
      }
  }
}
