<div class="m-3 ml-5 mt-4">
    <!-- <app-title title="ثبت حساب کاربری مشتریان"></app-title> -->
    <div *ngIf="success" class="" role="">
        <div
                class="d-flex text-success justify-content-center align-items-center"
        >
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ msg }}
        </div>
    </div>
    <div *ngIf="isWrong" class="" role="">
        <div class="d-flex justify-content-center align-items-center">
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ errorMsg }}
        </div>
    </div>
    <app-toolbar [toolbarItems]="toolbarItemList" (clickHandler)="itmeClickHandler($event)"></app-toolbar>

    <form
        #form="ngForm"
        *ngIf="customer"
        (ngSubmit)="register(form)"
        class="d-flex flex-column align-items-center">
        <div class=" w-100">
            <app-panel-box [caption]="'اطلاعات عمومی'">
                <div class="row">
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>کد مشتری</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.code"
                                name="code"
                                class="form-control form-control-lg"
                                [disabled]="true"
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>ماهیت مشتری</label>
                        <input
                                type="text"
                                [(ngModel)]="natureName"
                                name="nature_name"
                                class="form-control form-control-lg"
                                [disabled]="true"
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="user_types"
                                [key]="user_type"
                                placeholder="نوع مخاطب"
                                (selectChange)="changeUserType($event)"
                                req="true"
                        ></app-select>
                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="user_type"
                                name="role"
                                class="d-none"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="familiarityList"
                                [key]="familiarity"
                                placeholder="نحوه آشنایی"
                                (selectChange)="changeFamiliarity($event)"
                                req="true"
                        ></app-select>
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12" *ngIf="showIntroducer">
                        <label>کد معرف</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.introducer_code"
                                name="introducer_code"
                                class="form-control form-control-lg"
                        />
                    </div>
                </div>
            </app-panel-box>
        </div>
        <div class=" w-100"
             *ngIf="
        user && user.role !== 2 && user.role !== 3 && user.role !== 4
      "
        >
            <app-panel-box [caption]="'اطلاعات مخاطب'">
                <div class="row">
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="states"
                                [key]="state"
                                placeholder="نوع شخصیت"
                                (selectChange)="changeState($event)"
                                req="true"
                        ></app-select>

                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="state"
                                name="type"
                                class="d-none"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>نام فروشگاه/شرکت</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.company_name"
                                name="company_name"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12" *ngIf="showTitle">
                        <label>شماره ثبت</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.registration_number"
                                name="registration_number"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12" *ngIf="showTitle">
                        <label>شناسه ملی</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.national_id"
                                (keydown)="func.numControl($event)"
                                name="national_id"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12" *ngIf="showTitle">
                        <label>کد اقتصادی</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.economical_code"
                                name="economical_code"
                                (keydown)="func.numControl($event)"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12" >
                        <app-select
                                [options]="genders"
                                [key]="gender"
                                placeholder="جنسیت"
                                (selectChange)="gender = $event"
                                req="true"
                        ></app-select>

                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="gender"
                                name="gender"
                                class="d-none"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>نام مدیرعامل</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.first_name"
                                name="first_name"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>نام‌خانوادگی مدیرعامل</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.last_name"
                                name="last_name"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>کد ملی مدیرعامل</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.national_code"
                                name="national_code"
                                (keydown)="func.numControl($event)"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label class="mx-1 lead"> تاریخ تولد </label>
                        <div class="input-group">
                            <input
                                    matInput
                                    [matDatepicker]="picker3"
                                    [(ngModel)]="date"
                                    name="date_of_birth"
                                    class="form-control form-control-lg"
                            />

                            <span class="input-group-append" (click)="picker3.open()">
              <i class="fa fa-calculator input-group-text"></i>
            </span>
                            <mat-datepicker #picker3></mat-datepicker>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>محل تولد</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.city_of_birth"
                                name="city_of_birth"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>نام پدر</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.father_name"
                                name="father_name"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="degrees"
                                [key]="degree"
                                placeholder="مدرک تحصیلی"
                                (selectChange)="degree = $event"
                                req="true"
                        ></app-select>

                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="degree"
                                name="degree"
                                class="d-none"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="fields"
                                [key]="field"
                                placeholder="رشته‌ی تحصیلی"
                                (selectChange)="field = $event"
                                req="true"
                        ></app-select>

                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="field"
                                name="field"
                                class="d-none"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label class="mx-1 lead"> تاریخ تاسیس </label>
                        <div class="input-group">
                            <input
                                    matInput
                                    [matDatepicker]="picker4"
                                    [(ngModel)]="date"
                                    name="establishment_date"
                                    class="form-control form-control-lg"
                            />

                            <span class="input-group-append" (click)="picker4.open()">
              <i class="fa fa-calculator input-group-text"></i>
            </span>
                            <mat-datepicker #picker4></mat-datepicker>
                        </div>
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="jobs"
                                [key]="job"
                                placeholder="سمت سازمانی"
                                (selectChange)="job = $event"
                                req="true"
                        ></app-select>

                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="job"
                                name="job"
                                class="d-none"
                                required
                        />
                    </div>

                </div>
            </app-panel-box>
        </div>
        <div class=" w-100" >
            <app-panel-box [caption]="'اطلاعات تماس پیش فرض'">
                <div class="row">
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="addressTitles"
                                [key]="addressTitle"
                                placeholder="عنوان"
                                (selectChange)="changeTitleAddress($event)"
                                req="true"
                        ></app-select>
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="provinces"
                                [key]="province"
                                placeholder="استان"
                                (selectChange)="changeProvince($event)"
                                req="true"
                        ></app-select>
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="cities"
                                [key]="city"
                                placeholder="شهرستان"
                                (selectChange)="changeCity($event)"
                                req="true"
                        ></app-select>

                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="city"
                                name="city"
                                class="d-none"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-8 col-md-16 col-24">
                        <label>آدرس پستی</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.address"
                                name="address"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>کد پستی</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.postal_code"
                                name="postal_code"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>تلفن ثابت</label>
                        <input
                                type="text"
                                placeholder="××××××××-××۰"
                                [(ngModel)]="customer.landing_number"
                                name="landing_number"
                                class="form-control form-control-lg"
                                maxlength="12"
                                required
                                (keydown)="func.numControl($event)"
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>تلفن همراه</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.phone_number"
                                name="phone_number"
                                class="form-control form-control-lg"
                                maxlength="11"
                                (keydown)="func.numControl($event)"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>وب سایت</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.website_url"
                                name="website_url"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>

                </div>
            </app-panel-box>
        </div>
        <div class="w-100">
            <app-panel-box [caption]="'اطلاعات کاربری'">
                <div class="row">

                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>کد مالی مشتری</label>
                        <input
                                (keydown)="func.numControl($event)"
                                type="text"
                                [(ngModel)]="financial_code"
                                name="financial_code"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="references"
                                placeholder="فروشنده مادر"
                                [key]="reference"
                                (selectChange)="reference = $event"
                        ></app-select>

                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="reference"
                                name="reference"
                                class="d-none"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="statuses"
                                placeholder="وضعیت مشتری"
                                [key]="true"
                                (selectChange)="changeStatus($event)"
                                req="true"
                        ></app-select>

                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="status"
                                name="is_active"
                                class="d-none"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <app-select
                                [options]="credits"
                                placeholder="وضعیت اعتباری مشتری"
                                [key]="credit"
                                (selectChange)="changeCredit($event)"
                                req="true"
                        ></app-select>

                        <input
                                type="hidden"
                                value=""
                                [(ngModel)]="credit"
                                name="credit_state"
                                class="d-none"
                                required
                        />
                    </div>
                    <div class="form-group col-lg-4 col-md-6 col-12">
                        <label>سقف اعتبار مشتری به صورت ریالی</label>
                        <input
                                type="text"
                                [(ngModel)]="max_credit"
                                name="max_credit"
                                (keydown)="func.numControl($event)"
                                class="form-control form-control-lg"
                                required
                                [disabled]="!credit"
                        />
                    </div>


                    <!-- <div class="form-group col-lg-4 col-md-6 col-12">
                           <input
                              type="text"
                              value=""
                              ngModel
                              name="name"
                              class="form-control form-control-lg"
                              required
                           />
                           <label placeholder="سمت" alt="سمت"></label>
                        </div> -->





                    <div class="form-group col-12 col-md-6">
                        <label>نام‌کاربری</label>
                        <input
                                type="text"
                                [(ngModel)]="username"
                                name="username"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <div class="form-group col-12 col-md-6">
                        <label>رمزعبور</label>
                        <input
                                type="text"
                                [(ngModel)]="customer.password"
                                name="password"
                                class="form-control form-control-lg"
                                required
                        />
                    </div>
                    <!-- <div class="form-group col-lg-4 col-md-6 col-12">
                       <label>آی‌دی تلگرام</label>
                       <input
                         type="text"
                         ngModel
                         name="telegram_id"
                         class="form-control form-control-lg"
                         required
                       />
                     </div>

                     <div class="form-group col-lg-4 col-md-6 col-12">
                       <label>آدرس اینستاگرام</label>
                       <input
                         type="text"
                         ngModel
                         name="instagram_id"
                         class="form-control form-control-lg"
                         required
                       />
                     </div>

                     <div class="form-group col-12">
                       <label>آدرس پستی</label>
                       <input
                         type="text"
                         value=""
                         ngModel
                         name="company_address"
                         class="form-control form-control-lg"
                         required
                       />
                     </div>-->
                </div>
            </app-panel-box>
        </div>
        <!--        <div class="row w-100">-->
        <!--            <button type="submit" class="btn mr-auto ml-3 w-25 btn-success">-->
        <!--                ثبت-->
        <!--            </button>-->
        <!--            <button-->
        <!--                    [routerLink]="['../']"-->
        <!--                    type="button"-->
        <!--                    class="btn ml-5 w-25 btn-secondary"-->
        <!--            >-->
        <!--                بازگشت-->
        <!--            </button>-->
        <!--        </div>-->
    </form>
</div>
