import {Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Config} from "../../../../app-config.service";
import * as Func from "../../../../../pack/js/func";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "../../../../auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingService} from "../../../../loading/loading.service";
import {Subject} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-warehouse-order-edit',
    templateUrl: './warehouse-order-edit.component.html',
    styleUrls: ['./warehouse-order-edit.component.scss']
})
export class WarehouseOrderEditComponent implements OnInit {

    isRepresentetiveOrder: boolean = false;
    cancellation = [];
    isWrong: boolean;
    errorMsg: string;
    success: boolean;
    msg: string;
    date = new Date();
    sendDate = new Date();
    reciveDate = new Date();
    conf = Config;
    warehouseList: any[] = [];
    currentWarehouseOrder = {
        id: 0,
        warehouse: 0,
        warehouseResponsible: '',
        code: '',
        label: '',
        state_name: '',
        state: 0,
        date:null,
        created_at: null,
        sending_date: null,
        receiving_date: null,
        created_name: '',
        detail: ''

    };


    control = new FormControl();
    controlorder = new FormControl();

    credit: number = 0;
    credit_map = new Map();
    order: any[] = [];

    status: boolean = true;
    types: any[] = [];
    type = "";

    transportations: any[] = [];
    transportation = "";

    methods: any[] = [];
    method = "";

    customers: any[] = [{id: null, label: "در حال بارگذاری"}];
    filteredCustomers;
    filteredProducts;
    customer = "";

    availabilities: any[] = [
        {id: true, label: "موجود"},
        {id: false, label: "ناموجود"},
    ];
    availability: string = "";

    products: any[] = [];
    orgProducts: any[] = [];
    selected: boolean[] = [];
    productsApi: string =
        Config.settings.api + Config.settings.product.product;
    usersApi: string = Config.settings.api + Config.settings.user.user;

    func = Func;
    math = Math;
    images: string[] = [];
    _is_sending = false;

    get disable_send() {
        return this._is_sending;
    }

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private router: Router,
        private loading: LoadingService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
    ) {
    }


    user;

    private _filter(value: string): string[] {
        const filterValue = value;

        return this.customers.filter((option) =>
            option.label.includes(filterValue)
        );
    }

    private _filterP(value: string): string[] {
        const filterValue = value;

        return this.products.filter((option) =>
            option.label.includes(filterValue)
        );
    }

    async ngOnInit(): Promise<void> {

        this.loading.add();
        //
        this.route.queryParams.subscribe((qp) => {
            if (qp["rep"]) this.isRepresentetiveOrder = true;
            else this.isRepresentetiveOrder = false;
        });
        //
        this.user = await this.auth.get_info();
        await this.load_preData();

        //
        //
        this.load();
        //
        this.loading.remove();



    }

   


    async load_preData() {
        await this.http
            .get(this.productsApi)
            .toPromise()
            .then((response: any) => {
                this.orgProducts = [];
                response.forEach((element) => {
                    this.orgProducts.push({
                        id: element.id,
                        name: element.name,
                        group: element.brand_name,
                        type: element.type_name,
                        status: element.is_active,
                        code: element.code,
                        unit: element.unit_name,
                        number_in_pallet: element.number_in_pallet,
                        weight: element.weight,
                    });
                    this.func.sort(this.orgProducts, 'name');
                });
                this.orgProducts.forEach((element, i) => {
                    this.products.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            });


        let warehouseApi: string =
            Config.settings.api + "warehouse/warehouse/";
        await this.http
            .get(warehouseApi)
            .toPromise()
            .then((response: any) => {
                response.forEach((element) => {
                    this.warehouseList.push({
                        id: element.id,
                        label: element.name,
                        responsible: `${element.responsible_company_name} | ${element.responsible_first_name} ${element.responsible_last_name}`

                    });
                });
            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            });
    }

    changeWarehouse($event) {
        let warehouse = this.warehouseList[this.warehouseList.findIndex(x => x.id === $event)];
        this.currentWarehouseOrder.warehouse = warehouse.id;
        this.currentWarehouseOrder.warehouseResponsible = warehouse.responsible;

    }

    changeProduct(el, i) {
        this.order[i].pindex = el;
        this.order[i].product_id = el;
    }

    customerRef: Subject<void> = new Subject<void>();

    async load() {


        this.customers = [];


        this.order = [];
        this.images = [];
        this.route.params.subscribe((params) => {
            if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
                // this.router.navigate(["/404"]);
            } else {
                this.currentWarehouseOrder.id = params.id;
                let warehouseApi: string =
                    Config.settings.api + "warehouse/warehouse-input/" +
                    `${this.currentWarehouseOrder.id}/`;
                // console.log(warehouseApi);
                this.http.get(warehouseApi).subscribe(
                    async (response: any) => {
                        console.log(response);

                        this.currentWarehouseOrder.code = response.code;
                        this.currentWarehouseOrder.detail = response.detail;
                        this.currentWarehouseOrder.warehouse = response.warehouse;
                        this.currentWarehouseOrder.warehouseResponsible = response.warehouse_responsible_company_name;
                        this.currentWarehouseOrder.state_name = response.state_name;
                        this.currentWarehouseOrder.state = response.state;
                        this.currentWarehouseOrder.date = response.date;
                        this.currentWarehouseOrder.created_at = response.created_at;
                        this.currentWarehouseOrder.sending_date = response.sending_date;
                        this.currentWarehouseOrder.receiving_date = response.receiving_date;
                        this.currentWarehouseOrder.created_name = `${response.creator_first_name} ${response.creator_last_name}`;

                        if(response.image_url_1 != null){
                            this.images.push(response.image_url_1);
                        }
                        if(response.image_url_2 != null){
                            this.images.push(response.image_url_2);
                        }
                        if(response.image_url_3 != null){
                            this.images.push(response.image_url_3);
                        }

                        response.warehouse_input_details.forEach((el) => {
                            this.orgProducts.forEach((p, i) => {
                                if (el.product === p.id) {
                                    this.insert_detail(i, el.product, el.sheet_number);
                                }
                            });
                        });


                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    insert_detail(pi = null, pro = null, c = 0) {
        this.order.push({
            pindex: pi,
            product_id: pro,
            count: c,
        });
    }

    changeCustomer(el) {
        this.customer = el.id;
        // this.credit = this.credit_map.get(el.id);
        this.http.get(this.usersApi + el.id).subscribe(
            (response: any) => {
                // console.log(response);
                this.credit = response.max_credit;
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    showC(el) {
        return el && el.label;
    }

    _pallet_sum: number;
    _weight_sum: number;

    get count_sum() {
        let sum: number = 0;
        this.order.forEach((el) => {
            if (parseInt(el.count)) sum += parseInt(el.count);
        });
        return sum;
    }

    get weight_sum() {
        let sum: number = 0;
        this.order.forEach((el) => {
            sum +=
                el.pindex != null && el.count !== null
                    ? this.math.ceil(el.count * this.orgProducts[el.pindex].weight)
                    : 0;
        });
        return sum;
    }

    get pallet_sum() {
        let sum: number = 0;
        this.order.forEach((el) => {
            sum +=
                el.pindex != null && el.count !== null
                    ? (1.0 * el.count) /
                    this.orgProducts[el.pindex].number_in_pallet
                    : 0;
        });
        return Math.floor(sum);
    }

    changeType($event) {
        this.type = $event;
    }


    async register(form, content = null, state = 1) {
        debugger;
        // console.log(content);
        if (content == null) {
            this._is_sending = true;
            let data = form.value;
            //data.date = this.date.toISOString();
            // if (state == 2) {
            //     data.sending_date =  new Date().toISOString();
            //
            // } else if (state == 3) {
            //     data.receiving_date = new Date().toISOString();
            // }
            data.state = state;
            if (this.images[0]) {
               data.image_url_1 = this.images[0]
            }
            else{data.image_url_1 = null;}
            if (this.images[1]) {
               data.image_url_2 = this.images[1]
            }   else{data.image_url_2 =null; }
            if (this.images[2]) {
                data.image_url_3 = this.images[2]
            }   else{data.image_url_3 =null; }
            data.warehouse_input_details = [];
            this.order.forEach((el) =>
                data.warehouse_input_details.push({
                    product: el.product_id,
                    sheet_number: el.count,
                })
            );


            let url = Config.settings.api + "warehouse/warehouse-input/" + `${this.currentWarehouseOrder.id}/`;
            await this.http
                .patch(url, data)
                .toPromise()
                .then((response: any) => {
                    this._is_sending = false;
                    this.success = true;
                    this.isWrong = false;
                    this.load();
                    this.msg = `عملیات با موفقیت انجام شد.`;
                })
                .catch((error: HttpErrorResponse) => {
                    console.log(error);
                    this._is_sending = false;
                    this.isWrong = true;
                    this.success = false;
                    this.errorMsg = "دوباره امتحان کنید.";
                    window.scroll(0, 0);
                    if (error.error.message) {
                        this.errorMsg = error.error.message;
                    }
                });
            return;
        }
        await this.modalService
            .open(content, {ariaLabelledBy: "modal-basic-title"})
            .result.then(async (result) => {
                this._is_sending = true;
                let data = form.value;
                // data.date = this.date.toISOString();
                if (state == 2) {
                    data.sending_date =this.sendDate.toISOString();

                } else if (state == 3) {
                    data.receiving_date =this.reciveDate.toISOString();
                }
                data.state = state;
                if (this.images[0]) {
                    data.image_url_1 = this.images[0]
                }
                else{data.image_url_1 = null;}
                if (this.images[1]) {
                    data.image_url_2 = this.images[1]
                }   else{data.image_url_2 =null; }
                if (this.images[2]) {
                    data.image_url_3 = this.images[2]
                }   else{data.image_url_3 =null; }
                data.warehouse_input_details = [];
                this.order.forEach((el) =>
                    data.warehouse_input_details.push({
                        product: el.product_id,
                        sheet_number: el.count,
                    })
                );


                let url = Config.settings.api + "warehouse/warehouse-input/" + `${this.currentWarehouseOrder.id}/`;
              await this.http
                  .patch(url, data)
                  .toPromise()
                  .then((response: any) => {
                    this._is_sending = false;
                    this.success = true;
                    this.isWrong = false;
                    this.load();
                      this.msg = `عملیات با موفقیت انجام شد.`;
                  })
                  .catch((error: HttpErrorResponse) => {
                    console.log(error);
                    this._is_sending = false;
                    this.isWrong = true;
                    this.success = false;
                    this.errorMsg = "دوباره امتحان کنید.";
                    window.scroll(0, 0);
                    if (error.error.message) {
                      this.errorMsg = error.error.message;
                    }
                  });
            });
    }

    get isCustomer(): boolean {
        return (
            (this.user?.role == 2 ||
                this.user?.role == 3 ||
                this.user?.role == 4) &&
            !(this.user?.role == 4 && this.isRepresentetiveOrder)
        );
    }

    add() {
        this.order.push({
            pindex: null,
            product_id: null,
            unit_of_measure: null,
            number_in_pallet: null,
            count: 0,
        });
    }

    remove(i) {
        this.order.splice(i, 1);
    }


    add_image(e) {
        // console.log(e);
        if (this.images.length < 3) {
            const url: string = Config.settings.api + Config.settings.info.file;
            const formData: FormData = new FormData();
            formData.append(
                "file",
                e.target.files.item(0),
                e.target.files.item(0).name
            );
            // console.log(formData);
            const headers = {dt: "yes"};
            this.http.post(url, formData, {headers}).subscribe(
                (res: any) => {
                    this.images.push(res.url.slice(1));
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    window.scroll(0, 0);
                }
            );
        } else {
            this.success = false;
            this.isWrong = true;
            this.errorMsg = "تعداد عکس ها بیشتر از 3 عدد نمی تواند باشد!";
            window.scroll(0, 0);
        }

    }

    removeImage(i) {
        this.images.splice(i, 1);
    }
    get can_edit() {
        return (
            (this.user?.permission_codes.includes(132) && this.currentWarehouseOrder.state !=1 ) ||
            this.user?.permission_codes.includes(133)
        );
    }

    get can_send() {
        return (
            // this.user.id === this.creator ||
            // this.user.id === this.buyer ||
            this.user.role == 1 ||
            this.user.role == 5 ||
            this.user.role == 10
        );
    }

    get can_change() {
        // return 1;
        // return this.state == 1 && this.can_send;
        return this.user?.permission_codes.includes(135);
    }

    edit(form, i) {
        this._is_sending = true;
        let data: any = {state: i, detail: this.currentWarehouseOrder.detail};
        if (this.images[0]) {
            data.image_url_1 = this.images[0]
        }
        else{data.image_url_1 = null;}
        if (this.images[1]) {
            data.image_url_2 = this.images[1]
        }   else{data.image_url_2 =null; }
        if (this.images[2]) {
            data.image_url_3 = this.images[2]
        }   else{data.image_url_3 =null; }
        data.warehouse_input_details = [];
        this.order.forEach((el) =>
            data.warehouse_input_details.push({
                product: el.product_id,
                sheet_number: el.count,
            })
        );


        let url = Config.settings.api + "warehouse/warehouse-input/" + `${this.currentWarehouseOrder.id}/`;
        this.http.patch(url, data).subscribe(
            (response: any) => {
                this.success = true;
                this._is_sending = false;
                this.isWrong = false;
                this.msg = `سفارش ذخیره شد.`;
                this.load();
            },
            (error: HttpErrorResponse) => {
                console.log(error);
                this.isWrong = true;
                this._is_sending = false;
                this.success = false;
                this.errorMsg = "دوباره امتحان کنید.";
                window.scroll(0, 0);
                if (error.error.message) {
                    this.errorMsg = error.error.message;
                }
            }
        );
    }
}
