import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Config} from 'src/app/app-config.service';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {untilDestroyed} from 'ngx-take-until-destroy';
import * as Func from 'src/pack/js/func';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-region-list',
    templateUrl: './region-list.component.html',
    styleUrls: ['./region-list.component.scss'],
})
export class RegionListComponent implements OnInit {
    func = Func;
    regions: any[] = [];
    orgRegions: any[] = [];
    searchControl = new FormControl();
    selected: boolean[] = [];
    regionsApi: string = Config.settings.api + Config.settings.info.region;

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private http: HttpClient
    ) {
    }

    regionSupervisors: any[] = [];
    saleExperts: any[] = [];
    marketingExperts: any[] = [];

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe((term) => this.search(term));
        this.load();

        let peopleApi: string =
            Config.settings.api + Config.settings.user.user + '?q=1';
        this.http.get(peopleApi).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    if (element.role == 5 || element.role == 10) {
                        this.regionSupervisors.push({
                            id: element.id,
                            label: element.first_name + ' ' + element.last_name,
                        });
                    }
                    if (element.role == 7) {
                        this.saleExperts.push({
                            id: element.id,
                            label: element.first_name + ' ' + element.last_name,
                        });
                    }
                    if (element.role == 6) {
                        this.marketingExperts.push({
                            id: element.id,
                            label: element.first_name + ' ' + element.last_name,
                        });
                    }
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    load() {
        this.orgRegions = [];
        this.http.get(this.regionsApi).subscribe(
            (response: any) => {
                console.log(response);
                let mx = 0;
                response.forEach((element) => {
                    this.orgRegions.push({
                        id: element.id,
                        marketing_expert_id: element.marketing_expert,
                        marketing_expert_2: element.marketing_expert_2,
                        sale_expert_id: element.sale_expert,
                        sale_expert_2: element.sale_expert_2,
                        supervisor_id: element.supervisor,
                        name: element.name,
                        supervisor:
                            element.supervisor_first_name +
                            ' ' +
                            element.supervisor_last_name,
                        sale_expert:
                            element.sale_expert_first_name +
                            ' ' +
                            element.sale_expert_last_name,
                        marketing_expert:
                            element.marketing_expert_first_name +
                            ' ' +
                            element.marketing_expert_last_name,
                        marketing_expert2:
                            element.marketing_expert_2_first_name +
                            ' ' +
                            element.marketing_expert_2_last_name,
                        sale_expert2:
                            element.sale_expert_2_first_name +
                            ' ' +
                            element.sale_expert_2_last_name,
                    });
                });
                this.selected = new Array(mx + 1).fill(false);
                this.regions = this.orgRegions;
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    search(value: string) {
        this.regions = this.orgRegions.filter((option) =>
            option['name'].includes(value)
        );
    }

    editing_region;

    edit(content, i) {
        let el = this.regions[i];
        this.editing_region = {
            id: el.id,
            marketing_expert: el.marketing_expert_id,
            marketing_expert_2: el.marketing_expert_2,
            sale_expert: el.sale_expert_id,
            sale_expert_2: el.sale_expert_2,
            supervisor: el.supervisor_id,
            name: el.name,
        };

        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http
                    .patch(
                        this.regionsApi + this.editing_region.id + '/',
                        this.editing_region
                    )
                    .subscribe(
                        (response: any) => {
                            this.load();
                        },
                        (error: HttpErrorResponse) => {
                            //TODO: server errors
                            console.log(error);
                        }
                    );
            }
        });
    }

    show = false;

    del(content, i) {
        if (!i) {
            return;
        }
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.regionsApi + i + '/').subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    statuses = [
        {
            id: true,
            label: 'فعال',
        },

        {
            id: false,
            label: 'غیر فعال',
        },
    ];

    region = {
        marketing_expert: null,
        sale_expert: null,
        sale_expert_2: null,
        marketing_expert_2: null,
        supervisor: null,
        name: '',
    };

    register(content) {
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.post(this.regionsApi, this.region).subscribe(
                    (response: any) => {
                        console.log('shod');
                        this.load();
                        this.region = {
                            marketing_expert: 1,
                            marketing_expert_2: 1,
                            sale_expert: 1,
                            sale_expert_2: 1,
                            supervisor: 1,
                            name: '',
                        };
                        this.show = false;
                        window.scroll(0, 0);
                    },
                    (error: HttpErrorResponse) => {
                        console.log(error);
                    }
                );
            }
        });
    }

    ngOnDestroy() {
    }
}
