import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
   selector: 'app-phone-signin',
   templateUrl: './phone-signin.component.html',
   styleUrls: ['./phone-signin.component.scss'],
   host: {
      class: 'w-100',
   },
})
export class PhoneSigninComponent implements OnInit {
   isValidating: boolean = false;
   isWrong: boolean;
   errorMsg: string;
   trackingCode: any;

   constructor(private auth: AuthService, private router: Router) {}

   ngOnInit(): void {}

   login(form) {
      this.isWrong = false;
      if (this.isValidating) {
         this.auth.phoneLogin(form.value).subscribe(
            (response: any) => {
               //TODO: navigate
               localStorage.setItem('token', response.key);
               this.router.navigate(['/userArea']);
            },
            (error: HttpErrorResponse) => {
               console.log(error);
               this.isWrong = true;
               this.errorMsg = 'دوباره امتحان کنید.';
               this.isValidating = false;
            }
         );
      } else {
         this.auth.loginPhoneValidation(form.value.phoneNumber).subscribe(
            (response: any) => {
               this.isValidating = true;
               this.trackingCode = response.tracking_code;
            },
            (error: HttpErrorResponse) => {
               console.log(error);
               this.isWrong = true;
               this.errorMsg = 'شماره تلفن معتبر نمی‌باشد.';
            }
         );
      }
   }
}
