import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Config } from "src/app/app-config.service";
import * as Func from "src/pack/js/func";
import { FormBuilder, FormGroup } from "@angular/forms";
import {Router} from "@angular/router";
import {ToolbarItemModel} from "../../../../models/toolbar.model";

@Component({
  selector: "app-personnels-new",
  templateUrl: "./personnels-new.component.html",
  styleUrls: ["./personnels-new.component.scss"],
})
export class PersonnelsNewComponent implements OnInit {
  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;

  _landing_number: string = null;

  get landing_number() {
    if (!parseInt(this._landing_number)) return null;
    if (this._landing_number.length > 3)
      return (
        this._landing_number.slice(0, 3) +
        "-" +
        this._landing_number.slice(3)
      );
    else return this._landing_number;
  }

  set landing_number(e) {
    var regex_en = new RegExp("-", "g");
    e = e.replace(regex_en, "");
    this._landing_number = e;
  }

  imgUrl = null;

  unit: number = null;
  role: number = null;
  degree: number = null;
  status: boolean = true;
  units: any[] = [];
  roles: any[] = [];
  degrees: any[] = [];
  date;
  statuses: any[] = [
    { id: true, label: "فعال" },
    { id: false, label: "غیر فعال" },
  ];

  name_titles: any[] = [
    { id: "سرکار خانم", label: "سرکار خانم" },
    { id: "جناب آقای", label: "جناب آقای" },
    { id: "جناب آقای مهندس", label: "جناب آقای مهندس" },
  ];
  name_title: string = "";

  func = Func;
  toolbarItemList: ToolbarItemModel[] = [
    {
      name: "save",
      icon: "fas fa-save",
      text: "ذخیره",
      type: "button",
      btn_type: "primary"
    },
    {
      name: "saveClose",
      icon: "fas fa-window-close",
      text: "ذخیره و بستن",
      type: "button",
      btn_type: "danger"
    },
    {
      name: "saveNew",
      icon: "fas fa-sync",
      text: "ذخیره و جدید",
      type: "button",
      btn_type: "success"
    },
    {
      name: "space",
      text: "space",
      type: "space",
    },
    {
      name: "cancel",
      text: "لغو",
      type: "icon-button",
      icon: "fal fa-times-circle fa-2x text-danger",
    },
    {
      name: "print",
      text: "چاپ",
      type: "icon-button",
      icon: "fal fa-print fa-2x text-info",
    },
    {
      name: "excel",
      text: "خروجی اکسل",
      type: "icon-button",
      icon: "fal fa-file-excel fa-2x text-success",
    },
    {
      name: "space",
      text: "space",
      type: "space",
    },
    {
      name: "operations",
      text: "عملیات",
      icon: "fas fa-cogs",
      type: "menu",
      btn_type: "primary",
      items: [
        {
          name: "cancel",
          text: "لغو",
          type: "icon-button",
          icon: "fal fa-times-circle",
        },
        {
          name: "print",
          text: "چاپ",
          type: "icon-button",
          icon: "fal fa-print ",
        },
        {
          name: "excel",
          text: "خروجی اکسل",
          type: "icon-button",
          icon: "fal fa-file-excel",
        }
      ]
    }
  ];
  @ViewChild("form")
  form:ElementRef;

  constructor(private http: HttpClient, private router: Router) {}

  getDegrees() {
    let degreesApi: string = Config.settings.api + "information/degree/";
    this.http.get(degreesApi).subscribe(
      (response: any) => {
        let tc = [{ id: null, label: "خالی" }];
        response.forEach((element) => {
          tc.push({
            id: element.id,
            label: element.name,
          });
        });
        this.degrees = tc;
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.getDegrees();

    let unitsApi: string =
      Config.settings.api + Config.settings.info.unit;
    this.http.get(unitsApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.units.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  changeUnit($event) {
    this.unit = $event;
    let roleApi: string =
      Config.settings.api + Config.settings.info.role + `?unit=${$event}`;
    this.roles = [];
    this.role = null;
    this.http.get(roleApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.roles.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }
  changeRole($event) {
    this.role = $event;
  }

  changeStatus($event) {
    this.status = $event;
  }

  changeNameTitle($event) {
    this.name_title = $event;
  }

  register(form) {
    debugger;
    console.log(form.value);
    form.value.landing_number = this._landing_number;
    let url = Config.settings.api + Config.settings.user.user;
    if (this.imgUrl) form.value.image_url = this.imgUrl;
    console.log(JSON.stringify(form.value));
    this.http.post(url, form.value).subscribe(
      (response: any) => {
        console.log("shod");
        this.success = true;
        this.isWrong = false;
        this.msg = `کاربر اضافه شد.`;
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }
  itmeClickHandler(event) {
    console.log(event);
    switch (event.name) {
      case 'save':
        this.register(this.form);
        break;
      case 'saveClose':
        this.register(this.form);
        this.router.navigate(['userArea/personnel']);
        break;
      case 'saveNew':
        this.register(this.form);
        this.router.navigate(['userArea/personnel/new']);
        break;
      case 'cancel':
        this.router.navigate(['userArea/personnel']);
        break;
    }
  }

  change_image(e) {
    const url: string = Config.settings.api + Config.settings.info.file;
    const formData: FormData = new FormData();
    formData.append(
      "file",
      e.target.files.item(0),
      e.target.files.item(0).name
    );
    console.log(formData);
    const headers = { dt: "yes" };
    this.http.post(url, formData, { headers }).subscribe(
      (res: any) => {
        this.imgUrl = res.url.slice(1);
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }
}
