<div class="m-3 ml-5 mt-4">
    <app-title title="ویرایش گزارش"></app-title>

    <div *ngIf="success" class="" role="">
        <div
                class="d-flex text-success justify-content-center align-items-center"
        >
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ msg }}
        </div>
    </div>
    <div *ngIf="isWrong" class="" role="">
        <div class="d-flex justify-content-center align-items-center">
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ errorMsg }}
        </div>
    </div>
    <app-toolbar [toolbarItems]="toolbarItemList" (clickHandler)="itmeClickHandler($event)"></app-toolbar>

    <div class="order-header">
        <div class="row">
            <div class="col item">شماره فعالیت:{{currentReport.id}}</div>
            <div class="col item">ایجاد کننده:{{currentReport.creatorFirstName}}</div>
            <div class="col item">مخاطب:{{currentReport.addresseeCompanyName}}</div>
            <div class="col item">تاریخ ایجاد:{{currentReport.created_at | jDate | toFa}}</div>
            <div class="col item">مهلت:{{currentReport.due_date | jDate | toFa}}</div>
            <div class="col item">ویرایش:{{currentReport.updated_at | jDate | toFa}}</div>
            <div class="col item">تکمیل:{{currentReport.completion_date | jDate | toFa}}</div>
            <div class="col item">وضعیت:{{currentReport.stateName}}</div>
        </div>
    </div>
    <form #form="ngForm" class="w-100">
        <div class="box">
            <div class="row">
                <div class="form-group col-lg-3 col-md-6 col-12">
                    <label class="mx-1 lead"> نوع </label>
                    <select
                            [(ngModel)]="currentReport.type"
                            (ngModelChange)="changeType($event)"
                            name="type"
                            class="custom-select custom-select-lg"
                    >
                        <option *ngFor="let el of types" [ngValue]="el.id">
                            {{ el.label }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12" *ngIf="showAddresseeType">
                    <label class="mx-1 lead"> دسته بندی مخاطب </label>
                    <select
                            [(ngModel)]="currentReport.addresseeType"
                            (ngModelChange)="changeAddressee($event)"
                            name="addressee_type"
                            class="custom-select custom-select-lg"
                    >
                        <option *ngFor="let el of addresseeTypes" [ngValue]="el.id">
                            {{ el.label }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12" *ngIf="showUser">
                    <label class="mx-1 lead">نام مخاطب </label>
                    <input
                            matInput
                            type="text"
                            class="form-control form-control-lg border-1"
                            [formControl]="control"
                            [matAutocomplete]="auto"
                    />
                    <mat-autocomplete
                        #auto="matAutocomplete"
                        (optionSelected)="addresseeChange($event.option.value)"
                        [displayWith]="showC"
                    >
                        <mat-option
                                *ngFor="let el of filteredUsers | async"
                                [value]="el"
                        >
                            {{ el.label }}
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12">
                    <label class="mx-1 lead"> تاریخ فعالیت </label>
                    <div class="input-group">
                        <input
                                matInput
                                [matDatepicker]="picker3"
                                [ngModel]="currentReport.activity_date"
                                name="activity_date"
                                class="form-control form-control-lg"
                        />

                        <span class="input-group-append" (click)="picker3.open()">
              <i class="fa fa-calculator input-group-text"></i>
            </span>
                        <mat-datepicker #picker3></mat-datepicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="row">
                <div class="form-group col-lg-3 col-md-6 col-12">
                    <label class="mx-1 lead"> نوع فعالیت </label>
                    <select
                            [(ngModel)]="currentReport.activityType"
                            name="activity_type"
                            class="custom-select custom-select-lg"
                    >
                        <option *ngFor="let el of activityTypes" [ngValue]="el.id">
                            {{ el.label }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12">
                    <label class="mx-1 lead"> نوع اقدام </label>
                    <select
                            [(ngModel)]="currentReport.actionType"
                            name="action_type"
                            class="custom-select custom-select-lg"
                    >
                        <option *ngFor="let el of actionTypes" [ngValue]="el.id">
                            {{ el.label }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12">
                    <label class="mx-1 lead">ساعت شروع </label>
                    <ejs-timepicker [step]="15" [enableMask]="true" [format]="'HH:mm'" [value]="dateValueStart"  (change)="changStartDate($event)"  ></ejs-timepicker>
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12">
                    <label class="mx-1 lead">ساعت پایان </label>
                    <ejs-timepicker [step]="15" [enableMask]="true" [format]="'HH:mm'" [value]="dateValueEnd" (change)="changEndDate($event)"></ejs-timepicker>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="row">
                <div class="form-group col-lg-12 col-md-24 col-48">
                    <label class="mx-1 lead"> موضوع فعالیت </label>
                    <input
                            type="text"
                            class="form-control form-control-lg my-1"
                            name="activity_title"
                            [(ngModel)]="currentReport.activityTitle"
                    />
                </div>
            </div>
            <div class="form-group col-lg-12 col-md-24 col-48">
                <label>شرح فعالیت</label>
                <textarea class="form-control"
                          style="height: 200px" ngModel name="activity_details"
                          [(ngModel)]="currentReport.activityDetails">
                </textarea>
            </div>
        </div>
        <div class="box">
            <div class="row" >
                <div class="form-group col-lg-3 col-md-6 col-12" >
                    <label class="mx-3 lead" ><p style="color: #6f42c1;text-align: center;">مشاهده مدیر بازاریابی</p></label>
                    <input
                            type="checkbox"
                            class="ml-sm-3"
                            (click)="changeMarketing()"
                            [(ngModel)]="seenByMarketing"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]=" !(user.role == 5 || user.role == 1)"
                    />
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12" >
                    <label class="mx-3 lead" ><p style="color: #6f42c1;text-align: center;">مشاهده‌ شده توسط معاونت بازرگانی</p></label>
                    <input
                            type="checkbox"
                            class="ml-sm-3"
                            [(ngModel)]="seenByVice"
                            (click)="changeByVice()"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]=" !(user.role == 16 || user.role == 1)"
                    />
                </div>
            </div>
            <div class="row" *ngIf="seenByMarketing ">
                <div class="form-group col-lg-12 col-md-24 col-48">
                    <label class="mx-1 lead"> توضیحات توسط مدیر بازاریابی </label>
                    <input
                            type="text"
                            class="form-control form-control-lg my-1"
                            name="details_by_marketing_manager"
                            [disabled]=" !(user.role == 5 || user.role == 1)"
                            [(ngModel)]="currentReport.details_by_marketing_manager"
                    />
                </div>
            </div>
            <div class="row" *ngIf="seenByVice ">
                <div class="form-group col-lg-12 col-md-24 col-48">
                    <label class="mx-1 lead"> توضیحات توسط معاونت بازرگانی </label>
                    <input
                            type="text"
                            class="form-control form-control-lg my-1"
                            name="details_by_vice_president"
                            [(ngModel)]="currentReport.details_by_vice_president"
                            [disabled]=" !(user.role == 16 || user.role == 1)"
                    />
                </div>
            </div>
        </div>
        <div class="box">
            <div *ngIf="images.length" class="row m-2 box w-95 d-flex" style="z-index: inherit">
                <div *ngFor="let image of images; let i = index"
                     class="d-block p-3 position-relative profile">
                    <div class="img">
                        <img
                                [src]="conf.settings.back + image"
                                style="width: 300px;height: 150px"
                        />
                    </div>
                    <div (click)="remove(i)"
                         class="cursor-pointer img-remove">
                        <i class="fa fa-times"></i>
                    </div>
                </div>
            </div>

            <div *ngIf="files.length" class="row m-2 box w-95 d-flex" style="z-index: inherit">
                <div *ngFor="let file of files; let i = index"
                     class="d-block p-3 position-relative profile">
                    <div class="pdf">
                        <div class="cursor-pointer"
                             (click)="goToLink(conf.settings.back , i)">
                            <i class="far fa-file-pdf" style="font-size:48px;color:red"></i>
                        </div>
                    </div>
                    <div (click)="remove_file(i)"
                         class="cursor-pointer img-remove">
                        <i class="fa fa-times"></i>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template #sendDueDatePicker let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                تاریخ مهلت اقدام را وارد کنید.
            </h4>
        </div>
        <div class="modal-body">
            <input
                    matInput
                    [matDatepicker]="dpicker"
                    (click)="dpicker.open()"
                    type="text"
                    [(ngModel)]="dueDate"
                    name="receiving_date"
                    class="input-text w-100 form-control"
                    required
            />
            <label placeholder="تاریخ سفارش" alt="تاریخ سفارش"></label>
            <mat-datepicker #dpicker></mat-datepicker>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(date)"
            >
                تایید
            </button>
        </div>
    </ng-template>
    <ng-template #sendCompletionDatePicker let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                تاریخ تکمیل فعالیت را وارد کنید.
            </h4>
        </div>
        <div class="modal-body">
            <input
                    matInput
                    [matDatepicker]="dpicker"
                    (click)="dpicker.open()"
                    type="text"
                    [(ngModel)]="completionDate"
                    name="receiving_date"
                    class="input-text w-100 form-control"
                    required
            />
            <label placeholder="تاریخ سفارش" alt="تاریخ سفارش"></label>
            <mat-datepicker #dpicker></mat-datepicker>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(date)"
            >
                تایید
            </button>
        </div>
    </ng-template>
    <div class="row w-100 justify-content-end pt-1">
<!--        <button *ngIf="user.role ==  1 || user.role ==  16 || user.role ==  5"-->
<!--                type="button"-->
<!--                style="height: 30px;"-->
<!--                (click)="register(form,null,5)"-->
<!--                class="btn btn-success  col-1 mx-1"-->
<!--        >-->
<!--            تایید مدریت-->
<!--        </button>-->
<!--        <button *ngIf="currentReport.state == 1"-->
<!--                type="button"-->
<!--                style="height: 30px;"-->
<!--                (click)="register(form,sendCompletionDatePicker,2)"-->
<!--                class="btn btn-primary  col-1 mx-1"-->
<!--        >-->
<!--            تکمیل فعالیت-->
<!--        </button>-->
<!--        <button *ngIf="currentReport.state == 1"-->
<!--                (click)="register(form,null,3)"-->
<!--                type="button"-->
<!--                class="btn btn-gray col-1 mx-1" style="height: 30px;"-->
<!--        >-->
<!--            لغو/کنسل-->
<!--        </button>-->
<!--        <button *ngIf="currentReport.state == 1"-->
<!--                type="button"-->
<!--                style="height: 30px;"-->
<!--                (click)="register(form,null,0)"-->
<!--                class="btn btn-primary  col-1 mx-1"-->
<!--        >-->
<!--            ویرایش/اصلاح-->
<!--        </button>-->

<!--        <button *ngIf="currentReport.state == 1"-->
<!--                type="button"-->
<!--                style="height: 30px;"-->
<!--                (click)="register(form,sendDueDatePicker,0)"-->
<!--                class="btn btn-warning  col-1 mx-1"-->
<!--        >-->
<!--            مهلت اقدم-->
<!--        </button>-->
        <label class="btn btn-success col-1 mx-1" style="height: 30px;" *ngIf="currentReport.state == 1">
            <input

                    type="file"
                    accept="pdf/*"
                    (change)="add_file($event)"
                    style="opacity: 0; display: none"/>
            ارسال فایل
        </label>
<!--        <button-->
<!--                [routerLink]="['/userArea/reportList']"-->
<!--                type="button"-->
<!--                class="btn  col-1 mx-1 btn-secondary" style="height: 30px;"-->
<!--        >-->
<!--            بازگشت-->
<!--        </button>-->

    </div>
</div>