import {Component, OnInit, OnDestroy} from "@angular/core";
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Config} from "src/app/app-config.service";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import * as Func from "src/pack/js/func";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-warehouse',
    templateUrl: './warehouse.component.html',
    styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {
    func = Func;
    warehouses: any[] = [];
    orgWarehouses: any[] = [];
    searchControl = new FormControl();
    selected: boolean[] = [];
    warehousesApi: string = Config.settings.api + "warehouse/warehouse/";

    provinces: any[] = [];
    province: number = null;

    cities: any[] = [];
    city: number = null;

    sendDate = new Date();
    sortcol: string = null;
    images: string[] = [];
    isWrong: boolean;
    errorMsg: string;
    success: boolean;
    msg: string;
    conf = Config;

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private http: HttpClient
    ) {
    }

    warehouseSupervisors: any[] = [];
    // saleExperts: any[] = [];
    // marketingExperts: any[] = [];


    async ngOnInit(): Promise<void> {
        this.searchControl.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe((term) => this.search(term));
        this.load();

        let provinceApi: string =
            Config.settings.api + Config.settings.add.province;
        await this.http
            .get(provinceApi)
            .toPromise()
            .then((response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.provinces.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            });


        let peopleApi: string =
            Config.settings.api + Config.settings.user.user + "?role=4";
        console.log(peopleApi);
        this.http.get(peopleApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    this.warehouseSupervisors.push({
                        id: element.id,
                        label: element.company_name,
                        //element.first_name + " " + element.last_name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }


    // ngOnInit(): void {
    //
    //   this.load();
    //
    //   let peopleApi: string =
    //       Config.settings.api + Config.settings.user.user + "?q=2";
    //   this.http.get(peopleApi).subscribe(
    //       (response: any) => {
    //         console.log(response);
    //         response.forEach((element) => {
    //           // if (element.role == 5 || element.role == 10) {
    //             this.warehouseSupervisors.push({
    //               id: element.id,
    //               label: element.first_name + " " + element.last_name,
    //             });
    //           // }
    //           // if (element.role == 7) {
    //           //   this.saleExperts.push({
    //           //     id: element.id,
    //           //     label: element.first_name + " " + element.last_name,
    //           //   });
    //           // }
    //           // if (element.role == 6) {
    //           //   this.marketingExperts.push({
    //           //     id: element.id,
    //           //     label: element.first_name + " " + element.last_name,
    //           //   });
    //           // }
    //         });
    //       },
    //       (error: HttpErrorResponse) => {
    //         //TODO: server errors
    //         console.log(error);
    //       }
    //   );
    // }

    changeProvince($event, city = null) {
        this.province = $event;
        let cityApi: string =
            Config.settings.api +
            Config.settings.add.citys +
            `?province=${$event}`;
        this.cities = [];
        this.http.get(cityApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    this.cities.push({
                        id: element.id,
                        label: element.name,
                    });
                    this.city = city;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    changeCity($event) {
        this.city = $event;
        this.warehouse.city = $event;
    }

    load() {
        this.orgWarehouses = [];
        this.http.get(this.warehousesApi + `?ordering=${this.sortcol}`).subscribe(
            (response: any) => {
                console.log(response);
                let mx = 0;
                response.forEach((element) => {
                    this.orgWarehouses.push({
                        id: element.id,
                        name: element.name,
                        city_name: element.city_name,
                        responsible_fullName: `${element.responsible_company_name} | ${element.responsible_first_name} ${element.responsible_last_name}`,
                        creation_date: element.creation_date
                        // sale_expert_id: element.sale_expert,
                        // supervisor_id: element.supervisor,
                        //
                        // supervisor:
                        //     element.supervisor_first_name +
                        //     " " +
                        //     element.supervisor_last_name,
                        // sale_expert:
                        //     element.sale_expert_first_name +
                        //     " " +
                        //     element.sale_expert_last_name,
                        // marketing_expert:
                        //     element.marketing_expert_first_name +
                        //     " " +
                        //     element.marketing_expert_last_name,
                    });
                });
                this.selected = new Array(mx + 1).fill(false);
                this.warehouses = this.orgWarehouses;
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    search(value: string) {
        this.warehouses = this.orgWarehouses.filter(
            (option) =>
                option["name"].includes(value) ||
                option["responsible_fullName"].includes(value)
        );
    }

    editing_warehouse;


    new(content) {

        this.modalService
            .open(content, {ariaLabelledBy: "modal-basic-title"})
            .result.then((result) => {
            if (result) {
                if (this.validator_save()) {
                    if (this.images[0]) {
                        this.warehouse.image_url_1 = this.images[0]
                    }
                    if (this.images[1]) {
                        this.warehouse.image_url_2 = this.images[1]
                    }
                    console.log(this.warehouse);
                    this.http.post(this.warehousesApi, this.warehouse).subscribe(
                        (response: any) => {
                            console.log("shod");
                            this.load();
                            this.warehouse = {
                                name: "",
                                creation_date: this.sendDate,
                                city: null,
                                responsible: null,
                                details: "",
                                image_url_1: "",
                                image_url_2: ""
                            };
                            this.images = [];
                            this.show = false;
                            window.scroll(0, 0);
                        },
                        (error: HttpErrorResponse) => {
                            console.log(error);
                        }
                    );
                }

            }
        });
        this.images = [];
    }


    validator_save() {
        // if(this.warehouse.name){this.errorMsg="نام انبار وارد نشده است"; return false;}
        // if(this.warehouse.city){this.errorMsg="نام شهر وارد نشده است"; return false;}

        return true;
    }

    edit(content, i) {
        let el = this.warehouses[i];
        this.http.get(this.warehousesApi + el.id).subscribe(
            (response: any) => {
                console.log(response);


                this.changeProvince(response.province);
                setTimeout(() => {
                    this.editing_warehouse = response;
                    if (this.editing_warehouse.image_url_1) {
                        this.images.push(this.editing_warehouse.image_url_1);
                    }
                    if (this.editing_warehouse.image_url_2) {
                        this.images.push(this.editing_warehouse.image_url_2);
                    }


                    this.modalService
                        .open(content, {ariaLabelledBy: "modal-basic-title"})
                        .result.then((result) => {
                        if (result) {

                            if (this.images[0]) {
                                this.editing_warehouse.image_url_1 = this.images[0]
                            } else {
                                this.editing_warehouse.image_url_1 = "";
                            }
                            if (this.images[1]) {
                                this.editing_warehouse.image_url_2 = this.images[1]
                            } else {
                                this.editing_warehouse.image_url_2 = "";
                            }
                            this.http
                                .patch(
                                    this.warehousesApi + this.editing_warehouse.id + "/",
                                    this.editing_warehouse
                                )
                                .subscribe(
                                    (response: any) => {
                                        this.load();
                                    },
                                    (error: HttpErrorResponse) => {
                                        //TODO: server errors
                                        console.log(error);
                                    }
                                );
                        }
                    });

                }, 1000);


            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
        this.images = [];
    }

    show = false;

    del(content, i) {
        if (!i) return;
        this.modalService
            .open(content, {ariaLabelledBy: "modal-basic-title"})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.warehousesApi + i + "/").subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    statuses = [
        {
            id: true,
            label: "فعال",
        },

        {
            id: false,
            label: "غیر فعال",
        },
    ];

    warehouse = {
        name: "",
        creation_date: this.sendDate,
        city: null,
        responsible: null,
        details: "",
        image_url_1: "",
        image_url_2: ""
    };


    add_image(e) {
        console.log(e);
        if (this.images.length < 2) {
            const url: string = Config.settings.api + Config.settings.info.file;
            const formData: FormData = new FormData();
            formData.append(
                "file",
                e.target.files.item(0),
                e.target.files.item(0).name
            );
            console.log(formData);
            const headers = {dt: "yes"};
            this.http.post(url, formData, {headers}).subscribe(
                (res: any) => {
                    this.images.push(res.url.slice(1));
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    window.scroll(0, 0);
                }
            );
        } else {
            this.success = false;
            this.isWrong = true;
            this.errorMsg = "تعداد عکس ها بیشتر از 2 عدد نمی تواند باشد!";
            window.scroll(0, 0);
        }

    }

    remove(i) {
        this.images.splice(i, 1);
    }

    sort(col: string) {
        if (this.sortcol == col) this.sortcol = "-" + col;
        else this.sortcol = col;
        this.load();
    }

    ngOnDestroy() {
    }
}


