import { Injectable } from '@angular/core';
import {
   CanActivate,
   CanLoad,
   ActivatedRouteSnapshot,
   RouterStateSnapshot,
   UrlTree,
   Route,
   Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { AuthService } from './auth.service';

@Injectable({
   providedIn: 'root',
})
export class LoadUserArea implements CanLoad {
   constructor(private router: Router) {}
   canLoad(route: Route): boolean {
      if (localStorage.getItem('token')) return true;

      this.router.navigate(['/auth']);

      return false;
      return true;
   }
}

@Injectable({
   providedIn: 'root',
})
export class UserAreaGuard implements CanActivate {
   constructor(private router: Router) {}
   canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): boolean {
      if (localStorage.getItem('token')) return true;

      this.router.navigate(['/auth']);

      return false;
      return true;
   }
}

@Injectable({
   providedIn: 'root',
})
export class AuthGuard implements CanActivate {
   constructor(private router: Router) {}

   canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): boolean {
      if (!localStorage.getItem('token')) return true;

      this.router.navigate(['/userArea']);

      return false;
   }
}

@Injectable({
   providedIn: 'root',
})
export class PageGuard implements CanActivate {
   constructor(private router: Router, private auth: AuthService) {}

   async canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Promise<boolean> {
      let user = await this.auth.get_info();
      console.log(user);
      if (
         state.url === '/userArea/personnel' &&
         !user.permission_codes.includes(110)
      )
         return this.home();
      if (
         (state.url === '/userArea/customer' ||
            state.url === '/userArea/customer/today') &&
         !user.permission_codes.includes(100) &&
         !user.permission_codes.includes(101) &&
         !user.permission_codes.includes('100') &&
         !user.permission_codes.includes('101')
      )
         return this.home();
      // if (state.url === '/userArea/personnel') return this.home();
      return true;
   }

   home(): boolean {
      this.router.navigate(['/userArea']);

      return false;
   }
}
