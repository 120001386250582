<div class="m-3 mt-4" xmlns="http://www.w3.org/1999/html">
    <app-title title="لیست ورق و پالت"></app-title>
    <div *ngIf="success" class="" role="">
        <div
                class="d-flex text-success justify-content-center align-items-center"
        >
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ message }}
        </div>
    </div>
    <div style="margin-top: 10px">
        <label class="btn btn-success col-2 mx-2" style="height: 30px;">
            <input
                    type="file"
                    accept="*/*"
                    (change)="upload($event)"
                    style="opacity: 0; display: none"/>
            بارگذاری فایل ورق
        </label>
    </div>
    <div class="list my-3">
        <div class="list-header bg-gray">
            <div class="list-header-item" (click)="new(newContent)">
                <i class="far fa-plus-square"></i>جدید
            </div>
            <div class="list-header-item">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div class="list-header-item">تعداد ردیف:
                {{ sheets.length }}
            </div>
            <div
                    class="list-header-item mr-auto cursor-pointer"
                    (click)="isCollapsed = !isCollapsed"
            >
                <i class="far fa-file-search"></i>فیلتر
            </div>
        </div>
        <app-filter
                [isCollapsed]="isCollapsed"
                [filter_items]="filter_items"
                (changeFilter)="search($event)"
        ></app-filter>
        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col" (click)="sort( 'creation_date')">
                    تاریخ پالت
                </th>
                <th scope="col" (click)="sort( 'pallet_code')">
                    شماره پالت
                </th>
                <th scope="col" (click)="sort( 'code')"
                >
                    شماره ورق
                </th>
                <th scope="col" (click)="sort( 'product_code')"
                >
                    کد کالا
                </th>
                <th scope="col" (click)="sort( 'product_name')"
                >
                    نام کالا
                </th>
                <th scope="col">
                    نوع محصول
                </th>
                <th scope="col">
                    درجه‌ی محصول
                </th>
                <th scope="col">
                    ابعاد محصول
                </th>
                <th scope="col">
                    ضخامت محصول
                </th>
                <th scope="col">ویرایش</th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of sheets; let i = index">
                <th scope="row">{{ i + 1 | toFa }}</th>
                <!-- <td>{{ i + 1 | toFa }}</td> -->
                <td>
                    {{ el.creationDate | jDate | toFa }}
                </td>
                <td>
                    {{el.palletCode}}
                </td>
                <td>
                    {{el.code}}
                </td>
                <td>
                    {{el.productCode}}
                </td>
                <td>
                    {{el.productName}}
                </td>
                <td>
                    {{el.productTypeName}}
                </td>
                <td>
                    {{el.productDegreeName}}
                </td>
                <td>
                    {{el.productDimensionName}}
                </td>
                <td>
                    {{el.productThicknessName}}
                </td>
                <td class="cursor-pointer" (click)="edit(editcontent, el.id)">
                    <i class="fal fa-edit"></i>
                </td>
                <td class="cursor-pointer" (click)="del(content, el.id)">
                    <i class="fal fa-trash-alt"></i>
                </td>
            </tr>
            </tbody>
        </table>
        <div *ngIf="sheets.length==0"
             style="width: 100%;
    height: 30px;
    text-align: center;padding-top: 7px;">
            <h5>
                اطلاعاتی یافت نشد
            </h5>

        </div>
        <div *ngIf="sheets.length!=0" class="d-flex justify-content-center">
            <app-pagination
                    [page_count]="page_count"
                    [page_number]="page_number"
                    (changePage)="load($event)"
            ></app-pagination>
        </div>

    </div>

    <ng-template #editcontent let-modal >

        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">ویرایش ورق</h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-md-4 col-12">
                    <label > تاریخ پالت </label>
                    <input
                            matInput
                            [matDatepicker]="picker"
                            (click)="picker.open()"
                            type="text"
                            [(ngModel)]="editing_sheet.creation_date"
                            name="creation_date"
                            class="form-control-lg form-control"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="form-group col-md-4 col-12">
                    <label>شماره پالت</label>
                    <input
                            type="text"
                            [(ngModel)]="editing_sheet.pallet_code"
                            ngModel required
                            name="pallet_code"
                            class="form-control form-control-lg"
                            required
                    />
                </div>
                <div class="form-group col-md-4 col-12">
                    <label>شماره ورق</label>
                    <input
                            type="text"
                            [(ngModel)]="editing_sheet.code"
                            ngModel required
                            name="code"
                            class="form-control form-control-lg"
                            required
                    />
                </div>
                <div class="form-group col-md-6 col-md-8">
                    <app-select
                            [options]="products"
                            placeholder="کالا"
                            (selectChange)="editing_sheet.product = $event"
                            [key]="editing_sheet.product"
                            req="true"
                    ></app-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-success mx-2 w-20"
                    (click)="modal.close(true)"
            >
                ثبت
            </button>

            <button
                    type="button"
                    class="btn btn-outline-dark mx-2 w-20"
                    (click)="modal.close(false)"
            >
                انصراف
            </button>
        </div>
    </ng-template>

    <ng-template #content let-modal >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                آیا از حذف این رکورد اطمینان دارید؟
            </h4>
        </div>
        <div class="modal-body">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(true)"
            >
                بله
            </button>
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(false)"
            >
                خیر
            </button>
        </div>
    </ng-template>

    <ng-template #newContent let-modal >
        <div class="modal-header" >
            <h4 class="modal-title" id="modal-basic-title">ایجاد ورق</h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-md-4 col-12">
                    <label > تاریخ پالت </label>
                    <input
                            matInput
                            [matDatepicker]="picker"
                            (click)="picker.open()"
                            type="text"
                            [(ngModel)]="sheet.creation_date"
                            name="creation_date"
                            class="form-control-lg form-control"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="form-group col-md-4 col-12">
                    <label>شماره پالت</label>
                    <input
                            type="text"
                            [(ngModel)]="sheet.pallet_code"
                            ngModel required
                            name="pallet_code"
                            class="form-control form-control-lg"
                            required
                    />
                </div>
                <div class="form-group col-md-4 col-12">
                    <label>شماره ورق</label>
                    <input
                            type="text"
                            [(ngModel)]="sheet.code"
                            ngModel required
                            name="code"
                            class="form-control form-control-lg"
                            required
                    />
                </div>
                <div class="form-group col-md-6 col-md-8" style="width: 70%">
                    <app-select
                            [options]="products"
                            placeholder="کالا"
                            (selectChange)="sheet.product = $event"
                            [key]="sheet.product"
                            req="true"
                    ></app-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-success mx-2 w-20"
                    (click)="modal.close(true)"
            >
                ثبت
            </button>

            <button
                    type="button"
                    class="btn btn-outline-dark mx-2 w-20"
                    (click)="modal.close(false)"
            >
                انصراف
            </button>
        </div>
    </ng-template>


</div>
