<div class="m-3 ml-5 mt-4">
  
  <app-title title="ثبت پروفایل پرسنلی"></app-title>
  <app-toolbar [toolbarItems]="toolbarItemList" (clickHandler)="itmeClickHandler($event)"></app-toolbar>
  <div *ngIf="success" class="" role="">
    <div class="d-flex text-success justify-content-center align-items-center">
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ msg }}
    </div>
  </div>
  <div *ngIf="isWrong" class="" role="">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ errorMsg }}
    </div>
  </div>

  <form
    #form="ngForm"
    (ngSubmit)="register(form)"
    class="d-flex flex-column align-items-center w-100"
  >
    <!-- <label
      ><input
        type="file"
        (change)="change_image($event)"
        style="opacity: 0; display: none" />
      <div
        class="d-flex justify-content-center align-items-center mb-3"
        style="
          width: 110px;
          height: 110px;
          border-radius: 50%;
          background-color: #ddd;
          overflow: hidden;
        "
      >
        <div *ngIf="!imgUrl">آپلود عکس</div>
        <img
          [src]="'http://185.97.119.59:8000/' + imgUrl"
          style="width: inherit"
          *ngIf="imgUrl"
        /></div
    ></label> -->
    <div class="box w-100">
      <app-panel-box [caption]="'اطلاعات عمومی'">
        <div class="row">
          <div class="form-group col-lg-4 col-md-6 col-12">
            <app-select
              [options]="statuses"
              placeholder="وضعیت کاربر"
              [key]="true"
              (selectChange)="changeStatus($event)"
            ></app-select>

            <input
              type="hidden"
              value=""
              [(ngModel)]="status"
              name="is_active"
              class="d-none"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>نام</label>
            <input
              type="text"
              [(ngModel)]="first_name"
              name="first_name"
              class="form-control form-control-lg"
              required
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>نام خانوادگی</label>
            <input
              type="text"
              [(ngModel)]="last_name"
              name="last_name"
              class="form-control form-control-lg"
              required
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-12">
            <app-select
              [options]="name_titles"
              [key]="name_title"
              placeholder="نام تشریفاتی"
              (selectChange)="changeNameTitle($event)"
              req="true"
            ></app-select>

            <input
              type="hidden"
              value=""
              [(ngModel)]="name_title"
              name="name_title"
              class="d-none"
              required
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-12">
            <app-select
              [options]="units"
              [key]="unit"
              placeholder="واحد فعالیت"
              (selectChange)="changeUnit($event)"
              req="true"
            ></app-select>
          </div>
          <div class="form-group col-lg-4 col-md-6 col-12">
            <app-select
              [options]="roles"
              placeholder="سمت"
              [key]="role"
              (selectChange)="changeRole($event)"
              req="true"
            ></app-select>

            <input
              type="hidden"
              value=""
              [(ngModel)]="role"
              name="role"
              class="d-none"
              required
            />
          </div>
          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>کد پرسنلی</label>
            <input
              type="text"
              [(ngModel)]="personnel_code"
              name="personnel_code"
              (keydown)="func.numControl($event)"
              class="form-control form-control-lg"
              required
            />
          </div>
        </div>
      </app-panel-box>
      <app-panel-box [caption]="'اطلاعات شخصی'">
        <div class="row">
          <div class="form-group col-lg-4 col-md-6 col-12">
            <label class="mx-1 lead"> تاریخ تولد </label>
            <div class="input-group">
              <input
                matInput
                [matDatepicker]="picker3"
                [(ngModel)]="date"
                name="date_of_birth"
                class="form-control form-control-lg"
              />

              <span class="input-group-append" (click)="picker3.open()">
                <i class="fa fa-calculator input-group-text"></i>
              </span>
              <mat-datepicker #picker3></mat-datepicker>
            </div>
          </div>

          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>محل تولد</label>
            <input
              type="text"
              [(ngModel)]="city_of_birth"
              name="city_of_birth"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>نام پدر</label>
            <input
              type="text"
              [(ngModel)]="father_name"
              name="father_name"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>کد ملی</label>
            <input
              type="text"
              [(ngModel)]="financial_code"
              name="financial_code"
              (keydown)="func.numControl($event)"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group col-lg-4 col-md-6 col-12">
            <app-select
              [options]="degrees"
              [key]="degree"
              placeholder="تحصیلات"
              (selectChange)="degree = $event"
              req="true"
            ></app-select>

            <input
              type="hidden"
              value=""
              [(ngModel)]="degree"
              name="degree"
              class="d-none"
              required
            />
          </div>

          <!-- <div class="form-group col-lg-4 col-md-6 col-12">
            <label>ای‌دی تلگرام</label>
            <input
              type="text"
              [(ngModel)]=""
              name="telegram_id"
              class="form-control form-control-lg"
              required
            />
          </div> -->

          
        </div>
      </app-panel-box>
      <app-panel-box [caption]="'اطلاعات تماس'">
        <div class="row w-100">
          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>شماره تلفن همراه</label>
            <input
              type="text"
              [(ngModel)]="phone_number"
              name="phone_number"
              (keydown)="func.numControl($event)"
              class="form-control form-control-lg"
              maxlength="11"
              required
            />
          </div>

          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>شماره تلفن ثابت</label>
            <input
              type="text"
              placeholder="××××××××-××۰"
              [(ngModel)]="landing_number"
              maxlength="12"
              name="landing_number"
              (keydown)="func.numControl($event)"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>شماره تلفن داخلی</label>
            <input
              type="text"
              [(ngModel)]="internal_number"
              name="internal_number"
              (keydown)="func.numControl($event)"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>نام کاربری</label>
            <input
              type="text"
              [(ngModel)]="username"
              name="username"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>رمز عبور</label>
            <input
              type="password"
              [(ngModel)]="password"
              name="password"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group col-lg-4 col-md-6 col-12">
            <label>ایمیل سازمانی</label>
            <input
              type="text"
              [(ngModel)]="email"
              name="email"
              class="form-control form-control-lg"
              required
            />
          </div>
        </div>
      </app-panel-box>
    </div>

<!--    <div class="row w-100">-->
<!--      <button type="submit" class="btn mr-auto ml-3 w-25 btn-success">-->
<!--        ثبت-->
<!--      </button>-->
<!--      <button-->
<!--        [routerLink]="['../']"-->
<!--        type="button"-->
<!--        class="btn ml-5 w-25 btn-secondary"-->
<!--      >-->
<!--        بازگشت-->
<!--      </button>-->
<!--    </div>-->
  </form>
</div>
