import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Config } from "src/app/app-config.service";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";
import * as Func from "src/pack/js/func";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
})
export class ProductListComponent implements OnInit {
  func = Func;
  products: any[] = [];
  orgProducts: any[] = [];
  searchControl = new FormControl();
  selected: boolean[] = [];
  productsApi: string =
    Config.settings.api + Config.settings.product.product;
  brands = [];
  types = [];
  degrees = [];
  dimensions = [];
  suppliers = [];
  thicknesses = [];
  unit_of_measures = [];
  valueSearch : string;
  page_number: number = 1;
  object_count: number = 0;
  page_count: number = 0;
  isCollapsed = true;
  filter_items = [
    {
      name: 'name',
      label: 'نام محصول',
      type: 'string'
    },
    {
      name: 'code',
      label: 'کد محصول',
      type: 'string'
    },
    {
      name: 'type_id',
      label: 'نوع محصول',
      options: [],
      type: 'list',
      res: null,
    },
    {
      name: 'brand_id',
      label: 'برند محصول',
      options: [],
      type: 'list',
      res: null,
    },
    {
      name: 'thickness_id',
      label: 'ضخامت محصول',
      options: [],
      type: 'list',
      res: null,
    },
    {
      name: 'dimension_id',
      label: 'ابعاد محصول',
      options: [],
      type: 'list',
      res: null,
    },
    {
      name: 'degree_id',
      label: 'درجه‌ی محصول',
      options: [],
      type: 'list',
      res: null,
    },
    {
      name: 'unit_id',
      label: 'واحد محصول',
      options: [],
      type: 'list',
      res: null,
    },
    {
      name: 'supplier_id',
      label: 'تامین‌کننده‌ی محصول',
      options: [],
      type: 'list',
      res: null,
    }
  ];
  searchTerm: string;


  activeValue : [{ is_active: true, label: "فعال" },{ is_active: false, label: "غیر فعال" }];
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((term) => this.search(term));
    this.load();

    let brandApi: string =
      Config.settings.api + Config.settings.product.brand;
    this.http.get(brandApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.brands.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );

    let typeApi: string =
      Config.settings.api + Config.settings.product.type;
    this.http.get(typeApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.types.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );

    let degreeApi: string =
      Config.settings.api + Config.settings.product.degree;
    this.http.get(degreeApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.degrees.push({
            id: element.id,
            label: element.degree,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );

    let dimensionApi: string =
      Config.settings.api + Config.settings.product.dimension;
    this.http.get(dimensionApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.dimensions.push({
            id: element.id,
            label: element.length + "×" + element.width,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );

    let supplierApi: string =
      Config.settings.api + Config.settings.product.supplier;
    this.http.get(supplierApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.suppliers.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );

    let thicknessApi: string =
      Config.settings.api + Config.settings.product.thickness;
    this.http.get(thicknessApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.thicknesses.push({
            id: element.id,
            label: element.thickness,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );

    let unitApi: string =
      Config.settings.api + Config.settings.product.unit;
    this.http.get(unitApi).subscribe(
      (response: any) => {
        console.log(response);
        response.forEach((element) => {
          this.unit_of_measures.push({
            id: element.id,
            label: element.unit,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );

    this.loadProductType();
    this.loadProductType();
    this.loadBrand();
    this.loadThickness();
    this.loadDimension();
    this.loadDegree();
    this.loadUnit();
    this.loadSupplier();
  }

  loadProductType() {
    // let tc = [{ id: null, label: 'همه' }];
    let tc = [{ id: null, label: "همه" }];

    this.http.get( Config.settings.api + "information/product-type/").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.name)
              tc.push({
                id: element.id,
                label: element.name,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name === "type_id")
              ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }
  loadUnit() {
    debugger;
    // let tc = [{ id: null, label: 'همه' }];
    let tc = [{ id: null, label: "همه" }];

    this.http.get( Config.settings.api + "information/product-unit/").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.name)
              tc.push({
                id: element.id,
                label: element.name,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name === "unit_id")
              ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }
  loadSupplier() {
    debugger;
    // let tc = [{ id: null, label: 'همه' }];
    let tc = [{ id: null, label: "همه" }];

    this.http.get( Config.settings.api + "information/product-supplier/").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.name)
              tc.push({
                id: element.id,
                label: element.name,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name === "supplier_id")
              ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }
  loadBrand() {
    // let tc = [{ id: null, label: 'همه' }];
    let tc = [{ id: null, label: "همه" }];

    this.http.get( Config.settings.api + "information/product-brand/").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.name)
              tc.push({
                id: element.id,
                label: element.name,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name === "brand_id")
              ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }
  loadThickness() {
    // let tc = [{ id: null, label: 'همه' }];
    let tc = [{ id: null, label: "همه" }];

    this.http.get( Config.settings.api + "information/product-thickness/").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.thickness)
              tc.push({
                id: element.id,
                label: element.thickness,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name === "thickness_id")
              ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }
  loadDimension() {
    // let tc = [{ id: null, label: 'همه' }];
    let tc = [{ id: null, label: "همه" }];

    this.http.get( Config.settings.api + "information/product-dimension/").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.width)
              tc.push({
                id: element.id,
                label: element.width,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name === "dimension_id")
              ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }
  loadDegree() {
    debugger;
    // let tc = [{ id: null, label: 'همه' }];
    let tc = [{ id: null, label: "همه" }];

    this.http.get( Config.settings.api + "information/product-degree/").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.degree)
              tc.push({
                id: element.id,
                label: element.degree,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name === "degree_id")
              ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }


  search(filter: any) {
    console.log(filter);
    this.searchTerm = '';
    Object.keys(filter).forEach((el) => {
      this.searchTerm += el + '=' + filter[el] + '&';
      // console.log(el);
    });

    if (this.searchTerm !== '') {
      this.searchTerm = this.searchTerm.slice(0, -1);
    }
    localStorage.setItem('searchReport',  this.searchTerm);
    const qp = filter;
    // qp['q'] = this.searchTerm;

    this.router.navigate([], {
      queryParams: qp,
      // queryParamsHandling: "merge",
    });
    this.load();
    this.isCollapsed = true;
  }

  // search(value: string) {
  //   this.valueSearch = value;
  //   value = Func.toEn(value);
  //   this.products = this.orgProducts.filter(
  //     (option) =>
  //       option["brand_name"].includes(value) ||
  //       option["degree_name"].includes(value) ||
  //       option["name"].includes(value) ||
  //       option["supplier_name"].includes(value) ||
  //       option["thickness_name"].includes(value) ||
  //       option["type_name"].includes(value) ||
  //       option["dimension_name"].includes(value)
  //   );
  // }

  load(page = 1) {
    debugger;
    this.orgProducts = [];
    this.http.get(this.productsApi + `?page=${page}&${this.searchTerm}`).subscribe(
      (response: any) => {
        console.log(response);
        this.page_count = response.page_count;
        this.object_count = response.object_count;
        this.page_number = response.page_number;
        response.results.forEach((element) => {
          this.orgProducts.push(element);
        });
        this.products = [];
        this.products = this.orgProducts;
        this.search(this.valueSearch);
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        this.products = [];
        this.products = [];
        this.page_count = 0;
        this.page_number = 0;
        console.log(error);
      }
    );
  }
  show = false;
  editing_product;
  edit(content, i) {
    let el = this.products[i];
    this.editing_product = {
      id: el.id,
      brand: el.brand,
      degree: el.degree,
      name: el.name,
      code: el.code,
      number_in_pallet: el.number_in_pallet,
      weight: el.weight,
      supplier: el.supplier,
      thickness: el.thickness,
      type: el.type,
      unit: el.unit,
      dimension: el.dimension,
      is_active: el.is_active,
    };

    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http
            .patch(
              this.productsApi + this.editing_product.id + "/",
              this.editing_product
            )
            .subscribe(
              (response: any) => {
                this.load();
              },
              (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
              }
            );
        }
      });
  }

  del(content, i) {
    if (!i) return;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http.delete(this.productsApi + i + "/").subscribe(
            (response: any) => {
              this.load();
            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
          );
        }
      });
  }
  product = {
    brand: 1,
    degree: 1,
    name: "",
    code: "",
    number_in_pallet: "",
    weight: 1,
    supplier: 1,
    thickness: 1,
    type: 1,
    unit: 1,
    dimension: 1,
  };

  register() {
    this.http.post(this.productsApi, this.product).subscribe(
      (response: any) => {
        console.log("shod");
        this.load();
        this.product = {
          brand: 1,
          degree: 1,
          name: "",
          code: "",
          number_in_pallet: "",
          weight: 1,
          supplier: 1,
          thickness: 1,
          type: 1,
          unit: 1,
          dimension: 1,
        };
        this.show = false;
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  ngOnDestroy() {}
}
