<div class="m-3 mt-4">
  <app-title title="لیست شهرستان‌ها"></app-title>
  <div class="list my-3">
    <div class="list-header bg-gray">
      <div class="list-header-item">
        <i class="far fa-plus-square cursor-pointer" (click)="register(registercontent)"></i>جدید
      </div>
      <div class="list-header-item">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
      <div class="list-header-item">تعداد ردیف: {{ cities.length }}</div>
      <div class="list-header-item mr-auto">
        <i class="far fa-file-search"></i
        ><input
          type="text"
          class="form-control"
          [formControl]="searchControl"
        />
      </div>
    </div>
    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col" (click)="func.sort(cities, 'name')">نام شهر</th>
          <th scope="col" (click)="func.sort(cities, 'province')">
            استان
          </th>
          <!-- <th scope="col" (click)="func.sort(cities, 'code')">کد شهر</th> -->
          <th scope="col" (click)="func.sort(cities, 'country')">
            نام کشور
          </th>
          <th scope="col" (click)="func.sort(cities, 'region')">
            منطقه کاری
          </th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of cities; let i = index">
          <th scope="row">{{ i + 1 | toFa }}</th>
          <!-- <td>{{ i + 1 | toFa }}</td> -->
          <td>{{ el.name }}</td>
          <td>{{ el.province }}</td>
          <!-- <td>{{ el.code | toFa }}</td> -->
          <td>{{ el.country }}</td>
          <td>{{ el.region }}</td>
          <td class="cursor-pointer" (click)="edit(editcontent, i)">
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
<!--  <app-title-->
<!--    (click)="show = !show"-->
<!--    [attr.aria-expanded]="show"-->
<!--    aria-controls="collapse"-->
<!--    [class.open]="show"-->
<!--    type="plus"-->
<!--    title="اضافه کردن"-->
<!--    class="cursor-pointer"-->
<!--  ></app-title>-->

<!--  <div id="collapse" [ngbCollapse]="!show" class="box">-->
<!--    <div class="row">-->
<!--      <div class="form-group col-md-4 col-12">-->
<!--        <label>نام شهر</label>-->
<!--        <input-->
<!--          type="text"-->
<!--          [(ngModel)]="city.name"-->
<!--          name="name"-->
<!--          class="form-control form-control-lg"-->
<!--          required-->
<!--        />-->
<!--      </div>-->

<!--      <div class="form-group col-md-4 col-12">-->
<!--        <app-select-->
<!--          [options]="provinces"-->
<!--          placeholder="استان"-->
<!--          (selectChange)="city.province = $event"-->
<!--          req="true"-->
<!--        ></app-select>-->
<!--      </div>-->
<!--      <div class="form-group col-md-4 col-12">-->
<!--        <app-select-->
<!--          [options]="regions"-->
<!--          placeholder="منطقه"-->
<!--          (selectChange)="city.region = $event"-->
<!--          req="true"-->
<!--        ></app-select>-->
<!--      </div>-->
<!--      <div class="row w-100 mb-3">-->
<!--        <button-->
<!--          (click)="register()"-->
<!--          class="btn mr-auto ml-3 w-25 btn-success"-->
<!--        >-->
<!--          ثبت-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      آیا از حذف این رکورد اطمینان دارید؟
    </h4>
  </div>
  <div class="modal-body">
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(true)"
    >
      بله
    </button>
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(false)"
    >
      خیر
    </button>
  </div>
</ng-template>

<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-4 col-12">
        <label>نام شهر</label>
        <input
          type="text"
          [(ngModel)]="editing_city.name"
          name="name"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="provinces"
          [key]="editing_city.province"
          placeholder="استان"
          (selectChange)="editing_city.province = $event"
          req="true"
        ></app-select>
      </div>
      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="regions"
          [key]="editing_city.region"
          placeholder="منطقه"
          (selectChange)="editing_city.region = $event"
          req="true"
        ></app-select>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(true)"
      >
        تایید
      </button>
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>
<ng-template #registercontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">افزودن شهر</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-4 col-12">
        <label>نام شهر</label>
        <input
                type="text"
                [(ngModel)]="city.name"
                name="name"
                class="form-control form-control-lg"
                required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <app-select
                [options]="provinces"
                placeholder="استان"
                (selectChange)="city.province = $event"
                req="true"
        ></app-select>
      </div>
      <div class="form-group col-md-4 col-12">
        <app-select
                [options]="regions"
                placeholder="منطقه"
                (selectChange)="city.region = $event"
                req="true"
        ></app-select>
      </div>
    </div>
    <div class="modal-footer">
      <button
              type="button"
              class="btn btn-outline-dark mx-2"
              (click)="modal.close(true)"
      >
        ثبت
      </button>
      <button
              type="button"
              class="btn btn-outline-dark mx-2"
              (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>
