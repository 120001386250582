<div class="m-3 mt-4">
  <!-- <app-title title="لیست پرسنل"></app-title> -->
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
    </div>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">نوع شبکه</th>
          <th scope="col">نام کاربری</th>
          <th scope="col">لینک</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of socialNetworks; let i = index">
          <th scope="row">
            {{ i + 1 | toFa }}
          </th>
          <td>{{ el.social_network_type_name }}</td>
          <td>{{ el.username }}</td>
          <td><a [href]="el.link">مشاهده</a></td>
          <td class="cursor-pointer" (click)="edit(editcontent, i)">
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-title
    (click)="show = !show"
    [attr.aria-expanded]="show"
    aria-controls="collapse"
    [class.open]="show"
    type="plus"
    title="اضافه کردن"
    class="cursor-pointer"
  ></app-title>

  <div id="collapse" [ngbCollapse]="!show" class="box">
    <div class="row">
      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="types"
          placeholder="نوع"
          (selectChange)="social_network.social_network_type = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-4 col-12">
        <label>نام کاربری</label>
        <input
          type="text"
          [(ngModel)]="social_network.username"
          name="username"
          class="form-control form-control-lg"
          maxlength="50"
          required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <label>لینک</label>
        <input
          type="text"
          [(ngModel)]="social_network.link"
          name="link"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="row w-100 mb-3">
        <button
          (click)="register()"
          class="btn mr-auto ml-3 w-25 btn-success"
        >
          ثبت
        </button>
      </div>
    </div>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          آیا از حذف این رکورد اطمینان دارید؟
        </h4>
      </div>
      <div class="modal-body">
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(true)"
        >
          بله
        </button>
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(false)"
        >
          خیر
        </button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="types"
          placeholder="نوع"
          [key]="editing_socialNetwork.social_network_type"
          (selectChange)="
            editing_socialNetwork.social_network_type = $event
          "
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-4 col-12">
        <label>نام کاربری</label>
        <input
          type="text"
          [(ngModel)]="editing_socialNetwork.username"
          name="username"
          class="form-control form-control-lg"
          maxlength="50"
          required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <label>لینک</label>
        <input
          type="text"
          [(ngModel)]="editing_socialNetwork.link"
          name="link"
          class="form-control form-control-lg"
          required
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(true)"
      >
        تایید
      </button>
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>
