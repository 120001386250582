import {Component, OnInit} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import * as Func from "src/pack/js/func";
import {Config} from "src/app/app-config.service";
import {AuthService} from "src/app/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ToRPipe} from "src/app/fa.pipe";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: "app-shipping-edit",
  templateUrl: "./shipping-edit.component.html",
  styleUrls: ["./shipping-edit.component.scss"],
})
export class ShippingEditComponent implements OnInit {
  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;
  sendDate = new Date();
  date = new Date();
  receiveDate = null;
  purchaseDate = null;
  name: string;
  credit: number = 0;
  sheet_sum: number = 0;
  remaining_sheet_number: number = 0;
  billImageUrl: string;
  productImageUrl: string;
  image_url_3: string;
  image_url_4: string;
  remittance_number: string;

  images: string[] = [];
  conf = Config;

  order: any[] = [];

  statuses: any[] = [
    { id: true, label: "فعال" },
    { id: false, label: "غیر فعال" },
  ];
  status: boolean = true;

  types: any[] = [];
  type: string = "";

  transportations: any[] = [];
  transportation: string = "";

  methods: any[] = [];
  method: number = null;

  products: any[] = [];
  orgProducts: any[] = [];
  selected: boolean[] = [];
  shippingApi: string =
    Config.settings.api + Config.settings.order.shipping;
  carApi: string = Config.settings.api + Config.settings.order.car_type;
  dropdownList = [];
  orgDropdownList: any[] = [];
  selectedItems = [];
  addPallets = [];
  selectedItem = [];
  orgSelectedItem: any[] = [];
  math = Math;
  func = Func;
  orderd: any;
  invoice_number: any;
  dropdownSettings: IDropdownSettings = {};
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public loc: Location
  ) {}

  id: number;

  toR: ToRPipe = new ToRPipe();

  _count: number = null;
  get count() {
    if (!this._count) return null;
    return this.toR.transform(this._count.toString());
  }
  set count(e: string) {
    this._count = Func.toNumber(e);
  }

  user;
  shipping;
  buyer: number;
  state: number = 0;
  async ngOnInit(): Promise<void> {
    this.user = await this.auth.get_info();
    // tslint:disable-next-line:no-unused-expression
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'code',
      enableCheckAll: false,
      selectAllText: 'انتخاب همه',
      unSelectAllText: 'عدم انتخاب همه',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    await this.load_cars();
    this.load();
    await this.http.get( Config.settings.api + "information/pallet/?available=true").subscribe(
        async (response: any) => {
          response.forEach( (e) => {
            this.orgDropdownList.push({
              id : e.id,
              code : e.code
            });
            this.dropdownList = this.orgDropdownList;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
    this.selectedItem = this.orgSelectedItem;
  }

  onItemSelect(item: any) {
    this.selectedItems.push(item.id);
    this.http.get( Config.settings.api + "information/pallet/" + item.id).subscribe(
        async (response1: any) => {
          debugger;
          this.addPallets.push(response1);
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );

  }
  onDeSelect(items: any) {
    debugger;
    console.log(items);
    this.selectedItems.forEach((value, index) => {
      if (value === items.id) {
        this.selectedItems.splice(index, 1);
      }
    });

    this.addPallets.forEach((value, index) => {
      if (value.id === items.id) {
        this.addPallets.splice(index, 1);
      }
    });
  }



  load() {
    this.order = [];
    this.route.params.subscribe((params) => {
      if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
        this.router.navigate(["/404"]);
      } else {
        this.id = params.id;
        let shippingApi: string =
          Config.settings.api +
          Config.settings.order.shipping +
          `${this.id}/`;
        this.http.get(shippingApi).subscribe(
          async (response: any) => {
            debugger;
            console.log(response);
            this.selectedItems = response.pallet_ids ;
            response.pallet_ids.forEach((e) => {
              debugger;
              this.http.get( Config.settings.api + "information/pallet/" + e).subscribe(
                  async (response1: any) => {
                    this.orgSelectedItem.push({
                      id: response1.id,
                      code: response1.code
                    });
                    this.addPallets.push(response1);
                    this.orgDropdownList.push({
                      id : response1.id,
                      code : response1.code
                    });
                  },
                  (error: HttpErrorResponse) => {
                    //TODO: server errors
                    console.log(error);
                  }
              );
            });
            this.date = new Date(response.order_date);
            this.purchaseDate = new Date(response.buying_date);
            this.receiveDate = new Date(response.receiving_date);
            this.sendDate = new Date(response.sending_date);
            if (response.image_url_1)
              this.images.push(response.image_url_1);
            if (response.image_url_2)
              this.images.push(response.image_url_2);
            if (response.image_url_3)
              this.images.push(response.image_url_3);
            if (response.image_url_4)
              this.images.push(response.image_url_4);

            this.shipping = response;
            this._count = response.sheet_number;
          },
          (error: HttpErrorResponse) => {
            //TODO: server errors
            console.log(error);
          }
        );
      }
    });
  }

  async load_cars() {
    this.types = [];
    await this.http
      .get(this.carApi)
      .toPromise()
      .then((response: any) => {
        console.log(response);
        response.forEach((element) => {
          this.types.push({
            id: element.id,
            label: element.type,
          });
        });
      })
      .catch((error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      });
  }

  changeTransportation($event) {
    this.transportation = $event;
  }

  changeStatus($event) {
    this.status = $event;
  }

  changeMethod($event) {
    this.method = $event;
  }

  changeProduct($event, i) {
    this.order[i].product_id = this.orgProducts[$event].id;
    this.order[i].pindex = $event;
  }

  _pallet_sum: number;
  _weight_sum: number;

  get count_sum() {
    let sum: number = 0;
    this.order.forEach((el) => {
      if (parseInt(el.count)) sum += parseInt(el.count);
    });
    return sum;
  }

  get weight_sum() {
    let sum: number = 0;
    this.order.forEach((el) => {
      sum +=
        el.pindex != null && el.count !== null
          ? this.math.ceil(el.count * this.orgProducts[el.pindex].weight)
          : 0;
    });
    return sum;
  }
  get pallet_sum() {
    let sum: number = 0;
    this.order.forEach((el) => {
      sum +=
        el.pindex != null && el.count !== null
          ? this.math.ceil(
              el.count / this.orgProducts[el.pindex].number_in_pallet
            )
          : 0;
    });
    return sum;
  }

  changeType($event) {
    this.type = $event;
  }

  register(form) {
    debugger;
    this._is_sending = true;

    let data = form.value;

    data.id = this.shipping.id;

    data.image_url_1 = this.images[0];
    data.image_url_2 = this.images[1];
    data.image_url_3 = this.images[2];
    data.image_url_4 = this.images[3];
    data.sheet_number = this._count;
    data.pallet_ids = this.selectedItems;
    console.log(data);

    // return;

    let url = this.shippingApi + `${this.shipping.id}/`;

    console.log(JSON.stringify(data));
    this.http.patch(url, data).subscribe(
      (response: any) => {
        console.log("shod");
        this._is_sending = false;
        this.success = true;
        this.isWrong = false;

        this.msg = `محموله ثبت شد.`;
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this._is_sending = false;
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }

  change_image(e, i) {
    const url: string = Config.settings.api + Config.settings.info.file;
    const formData: FormData = new FormData();
    formData.append(
      "file",
      e.target.files.item(0),
      e.target.files.item(0).name
    );
    console.log(formData);
    const headers = { dt: "yes" };
    this.http.post(url, formData, { headers }).subscribe(
      (res: any) => {
        this[i] = res.url.slice(1);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }

  add_image(e) {
    const url: string = Config.settings.api + Config.settings.info.file;
    const formData: FormData = new FormData();
    formData.append(
      "file",
      e.target.files.item(0),
      e.target.files.item(0).name
    );
    console.log(formData);
    const headers = { dt: "yes" };
    this.http.post(url, formData, { headers }).subscribe(
      (res: any) => {
        this.images.push(res.url.slice(1));
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }

  remove(i) {
    this.images.splice(i, 1);
  }

  orderList(order){
    this.router.navigate(["/userArea/order/" + order]);
  }

  _is_sending = false;
  get disable_send() {
    return (
      this._count == 0 ||
      this.remaining_sheet_number -
        this._count +
        this.shipping.sheet_number <
        0 ||
      this._is_sending
    );
  }
}
