import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpClient} from '@angular/common/http';
import * as Func from 'src/pack/js/func';
import '../models';
import {Config} from 'src/app/app-config.service';
import {AuthService} from 'src/app/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import {ToRPipe, ToFaPipe} from 'src/app/fa.pipe';
import {getAttrsForDirectiveMatching} from '@angular/compiler/src/render3/view/util';
import {Cash, Check} from '../models';

@Component({
    selector: 'app-checkout-edit',
    templateUrl: './checkout-edit.component.html',
    styleUrls: ['./checkout-edit.component.scss'],
})
export class CheckoutEditComponent implements OnInit {
    isWrong: boolean;
    errorMsg: string;
    success: boolean;
    msg: string;
    date = new Date();
    jdate = null;
    name: string;
    credit: number = 0;
    images: string[] = [];
    imagesCash: string[] = [];
    request_number;
    invoice_number;

    checks: Check[] = [];
    cashes: Cash[] = [];
    conf = Config;

    order: any;

    editing_check: Check = null;
    editing_check_idx: number = null;
    editing_cash: Cash = null;
    editing_cash_idx: number = null;

    productsApi: string =
        Config.settings.api + Config.settings.product.product;

    detail: string;

    func = Func;
    math = Math;
    sheet_sum: any;
    remaining_sheet_number: any;
    shippings: any[] = [];
    cancellation_reason: any;
    checkout: number = null;
    load_reports: any[] = [];
    bounced_checks: any[] = [];
    check_states: any[] = Array.from(Func.check_states, ([id, label]) => ({
        id,
        label,
    }));
    check_states_map = Func.check_states;
    bank_accounts: any[] = [];
    parseint = parseInt;

    deposit_types: any[] = [
        {id: 1, label: 'واریز به حساب'},
        {id: 2, label: 'واریز پایا'},
        {id: 3, label: 'واریز شبا'},
        {id: 4, label: 'کارت به کارت'},
    ];
    checkout_state: string;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        public loc: Location
    ) {
    }

    id: number;

    user;
    buyer: number;
    creator: number;
    creator_first_name: string;
    creator_last_name: string;
    state: number = 0;

    async ngOnInit(): Promise<void> {
        this.user = await this.auth.get_info();
        this.loadCheck();
        this.loadOrder();
    }

    loadCheck() {
        this.route.params.subscribe((params) => {
            if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
                this.router.navigate(['/404']);
            } else {
                let api = Config.settings.api + "order/checkout-detail/?cash=0&check_state=2&order_id=" + params.id;
                this.http
                    .get(
                        api
                    )
                    .subscribe(
                        (response: any) => {
                            debugger;
                            console.log(response);
                            let c = [];
                            c.push({id: null, label: 'خالی'});
                            response.forEach((el) => {
                                c.push({
                                    id: el.id,
                                    label: el.check_number
                                });
                            });
                            this.bounced_checks = c;
                        },
                        (error: HttpErrorResponse) => {
                            //TODO: server errors
                            if (error.status === 404) {
                                this.router.navigate(['/404']);
                            }
                            console.log(error);
                        }
                    );
            }
        });
    }

    optimum_transport: string = '';

    loadOrder() {
        this.checks = [];
        this.cashes = [];
        this.optimum_transport = '';
        this.shippings = [];
        this.route.params.subscribe((params) => {
            if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
                this.router.navigate(['/404']);
            } else {
                this.id = params.id;

                let orderApi: string =
                    Config.settings.api +
                    Config.settings.order.order +
                    `${this.id}/`;
                this.http.get(orderApi).subscribe(
                    (response: any) => {
                        console.log(response);
                        this.state = response.state;
                        this.buyer = response.buyer;
                        this.sheet_sum = response.sheet_sum;
                        this.remaining_sheet_number = response.remaining_sheet_number;
                        this.request_number = response.request_number;
                        this.invoice_number = response.invoice_number;
                        this.cancellation_reason = response.cancellation_reason;
                        this.creator_first_name = response.creator_first_name;
                        this.creator_last_name = response.creator_last_name;
                        this.creator = response.creator;
                        this.checkout_state = response.checkout_state;
                        this.date = new Date(response.date);

                        this.name = response.buyer_company_name;

                        this.order = response;

                        if (response.checkout) {
                            this.checkout = response.checkout;
                            this.http
                                .get(
                                    Config.settings.api +
                                    Config.settings.order.checkout +
                                    `${response.checkout}/`
                                )
                                .subscribe(
                                    (checkout: any) => {
                                        debugger;
                                        console.log(checkout);
                                        this.detail = checkout.detail;
                                        checkout.checks.forEach((el) =>
                                            this.checks.push(
                                                new Check(
                                                    el.id,
                                                    el.check_number,
                                                    el.date,
                                                    el.amount,
                                                    el.card_number,
                                                    el.name,
                                                    el.manager_confirmation,
                                                    el.expert_confirmation,
                                                    el.customer_confirmation,
                                                    el.load_report,
                                                    el.check_state,
                                                    el.check_state_date,
                                                    el.detail,
                                                    el.image_url_1,
                                                    el.image_url_2,
                                                    el.bounced_check
                                                )
                                            )
                                        );
                                        checkout.cashes.forEach((el) =>
                                            this.cashes.push(
                                                new Cash(
                                                    el.id,
                                                    el.date,
                                                    el.amount,
                                                    el.name,
                                                    el.manager_confirmation,
                                                    el.expert_confirmation,
                                                    el.customer_confirmation,
                                                    el.load_report,
                                                    el.bank_account,
                                                    el.deposit_type,
                                                    el.source_bank_account,
                                                    el.detail,
                                                    el.image_url_1,
                                                    el.image_url_2,
                                                    el.bounced_check
                                                )
                                            )
                                        );
                                        // this.checkout = checkout;
                                    },
                                    (error: HttpErrorResponse) => {
                                        //TODO: server errors
                                        if (error.status === 404) {
                                            this.router.navigate(['/404']);
                                        }
                                        console.log(error);
                                    }
                                );
                        }
                        let c = [];
                        c.push({id: null, label: 'خالی'});
                        this.order.load_reports.forEach((el) => {
                            if (el.remittance_number) {
                                c.push({
                                    id: el.id,
                                    label: el.remittance_number
                                });
                            }
                        });
                        this.load_reports = c;
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        if (error.status === 404) {
                            this.router.navigate(['/404']);
                        }
                        console.log(error);
                    }
                );

                let bankApi: string =
                    Config.settings.api + Config.settings.info.bank_account;

                this.http.get(bankApi).subscribe(
                    (res) => {
                        let c = [];
                        (res as Array<any>).forEach((el) =>
                            c.push({
                                id: el.id,
                                label: el.account_number + ')' + el.bank_name + ')',
                            })
                        );
                        this.bank_accounts = c;
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        if (error.status === 404) {
                            this.router.navigate(['/404']);
                        }
                        console.log(error);
                    }
                );
            }
        });
    }

    async register(form) {
        debugger;
        this._is_sending = true;
        let data = form.value;
        data.order = this.order.id;
        data.checkout_details = [...this.checks, ...this.cashes];

        console.log(data);

        let url = Config.settings.api + Config.settings.order.checkout;
        console.log(this.checkout);

        let req = this.checkout
            ? this.http.patch(url + `${this.checkout}/`, data)
            : this.http.post(url, data);

        await req
            .toPromise()
            .then((response: any) => {
                debugger;
                this._is_sending = false;
                this.success = true;
                this.isWrong = false;
                location.reload();
                this.msg = `فرم تسویه حساب ذخیره شد.`;
                this.imagesCash = [];
                this.images = [];
                window.scroll(0, 0);
            })
            .catch((error: HttpErrorResponse) => {
                debugger;
                console.log(error);
                this._is_sending = false;
                this.isWrong = true;
                this.success = false;
                this.imagesCash = [];
                this.images = [];
                // location.reload();
                this.errorMsg = 'دوباره امتحان کنید.';
                window.scroll(0, 0);
                if (error.error.message) {
                    this.errorMsg = error.error.message;
                }
            });
        return;
    }

    get_lr(i) {
        return this.load_reports.find((elm) => elm.id == i).label;
    }

    get can_edit() {
        // return 1;
        // return this.state == 1 && this.can_send;
        return (
            (this.user?.permission_codes.includes(132) && this.state < 2) ||
            this.user?.permission_codes.includes(133)
        );
    }

    get can_change() {
        // return 1;
        // return this.state == 1 && this.can_send;
        return this.user?.permission_codes.includes(135);
    }

    permissionControl(e): void {
        if (!this.can_edit) {
            e.preventDefault();
        }
    }

    _is_sending = false;

    get disable_send() {
        return this._is_sending;
    }

    can_del = true;

    addPayment(content) {
        // this.modalService
        //    .open(content, { ariaLabelledBy: 'modal-basic-title' })
        //    .result.then((result) => {
        //       if (result) {
        //          let data = result.value;
        //          data.amount = this._amount;
        //          data.confirmation = 0;
        //          if (data.type == 2) this.checks.push(result.value);
        //          if (data.type == 1) this.cashes.push(result.value);
        //          this._amount = 0;
        //          this.checkDate = null;
        //       }
        //    });
    }

    checkDate: any;

    toFa: ToFaPipe = new ToFaPipe();

    newCheck() {
        this.editing_check = new Check();
        this.editing_check_idx = 0;
    }

    newCash() {
        this.editing_cash = new Cash();
        this.editing_cash_idx = 0;
    }

    removeCheck(i, form) {
        this.checks.splice(i, 1);
        this.register(form);
    }

    removeCash(i, form) {
        this.cashes.splice(i, 1);
        this.register(form);
    }

    isCashComplete(el: Cash): boolean {
        if (el.amount && el.source_bank_account && el.date && el.name) {
            return true;
        }
        return false;
    }

    isCheckComplete(el: Check): boolean {
        if (el.amount && el.card_number && el.date && el.name) {
            return true;
        }

        return false;
    }

    get checks_sum(): number {
        let sum: number = 0;
        this.checks.forEach((el) => {
            if (el.amount && el.date) {
                sum += el.amount;
            }
        });
        return sum;
    }

    get done_checks_sum(): number {
        let sum: number = 0;
        this.checks.forEach((el) => {
            if (el.amount && el.date && el.check_state == 1) {
                sum += el.amount;
            }
        });
        return sum;
    }

    get failed_checks_sum(): number {
        let sum: number = 0;
        this.checks.forEach((el) => {
            if (el.amount && el.date && el.check_state == 2) {
                sum += el.amount;
            }
        });
        return sum;
    }

    get checks_head(): Date {
        let csum = this.checks_sum;
        if (csum == 0) {
            return null;
        }
        let sum: number = 0;
        let now: Date = new Date();
        this.checks.forEach((el) => {
            if (el.amount && el.date) {
                sum += el.amount * (now.getTime() - new Date(el.date).getTime());
            }
        });
        now.setTime(now.getTime() - sum / csum);
        return now;
    }

    get cashes_sum(): number {
        let sum: number = 0;
        this.cashes.forEach((el) => {
            if (el.amount && el.date) {
                sum += el.amount;
            }
        });
        return sum;
    }

    get cashes_head(): Date {
        let csum = this.cashes_sum;
        if (csum == 0) {
            return null;
        }
        let sum: number = 0;
        let now: Date = new Date();
        this.cashes.forEach((el) => {
            if (el.amount && el.date) {
                sum += el.amount * (now.getTime() - new Date(el.date).getTime());
            }
        });
        now.setTime(now.getTime() - sum / csum);
        return now;
    }

    get checkout_sum(): number {
        return this.cashes_sum + this.checks_sum;
    }

    edit_check(i) {
        this.images = [];
        this.editing_check = this.checks[i].clone();
        if (this.editing_check.image_url_1 != null) {
            this.images.push(this.editing_check.image_url_1);
        }
        if (this.editing_check.image_url_2 != null) {
            this.images.push(this.editing_check.image_url_2);
        }
        this.editing_check_idx = i;
    }

    edit_cash(i) {
        debugger;
        this.imagesCash = [];
        this.editing_cash = this.cashes[i].clone();
        if (this.editing_cash.image_url_1 != null) {
            this.imagesCash.push(this.editing_cash.image_url_1);
        }
        if (this.editing_cash.image_url_2 != null) {
            this.imagesCash.push(this.editing_cash.image_url_2);
        }
        this.editing_cash_idx = i;
    }

    add_image(e) {
        if (this.images.length < 2) {
            const url: string = Config.settings.api + Config.settings.info.file;
            const formData: FormData = new FormData();
            formData.append(
                'file',
                e.target.files.item(0),
                e.target.files.item(0).name
            );
            console.log(formData);
            const headers = {dt: 'yes'};
            this.http.post(url, formData, {headers}).subscribe(
                (res: any) => {
                    this.images.push(res.url.slice(1));
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    window.scroll(0, 0);
                }
            );
        } else {
            this.success = false;
            this.isWrong = true;
            this.errorMsg = 'تعداد عکس ها بیشتر از 2 عدد نمی تواند باشد!';
            window.scroll(0, 0);
        }
    }

    save_check(form) {
        if (!this.isCheckComplete(this.editing_check)) {
            return;
        }
        if (this.editing_check.id == null) {

            if (this.images[0] == null) {
                this.editing_check.image_url_1 = null;
            } else {
                this.editing_check.image_url_1 = this.images[0];
            }
            if (this.images[1] == null) {
                this.editing_check.image_url_2 = null;
            } else {
                this.editing_check.image_url_2 = this.images[1];
            }
            this.images = [];
            this.checks.push(this.editing_check.clone());

            if (this.editing_check_idx != 0) {
                this.checks.splice(this.editing_check_idx, 1);
            }
            this.editing_check = null;
        } else {
            this.editing_check = this.editing_check.clone();
            if (this.images[0] == null) {
                this.editing_check.image_url_1 = null;
            } else {
                this.editing_check.image_url_1 = this.images[0];
            }
            if (this.images[1] == null) {
                this.editing_check.image_url_2 = null;
            } else {
                this.editing_check.image_url_2 = this.images[1];
            }
            this.checks[this.editing_check_idx] = this.editing_check;
            this.editing_check = null;
            this.images = [];
        }
        this.register(form);
    }

    save_cash(form) {
        if (!this.isCashComplete(this.editing_cash)) {
            return;
        }
        if (this.editing_cash.id == null) {
            if (this.imagesCash[0] == null) {
                this.editing_cash.image_url_1 = null;
            } else {
                this.editing_cash.image_url_1 = this.imagesCash[0];
            }
            if (this.imagesCash[1] == null) {
                this.editing_cash.image_url_2 = null;
            } else {
                this.editing_cash.image_url_2 = this.imagesCash[1];
            }
            this.cashes.push(this.editing_cash.clone());
            if (this.editing_cash_idx != 0) {
                this.cashes.splice(this.editing_cash_idx, 1);
            }
            this.editing_cash = null;
            this.imagesCash = [];
        } else {
            this.editing_cash = this.editing_cash.clone();
            if (this.imagesCash[0] == null) {
                this.editing_cash.image_url_1 = null;
            } else {
                this.editing_cash.image_url_1 = this.imagesCash[0];
            }
            if (this.imagesCash[1] == null) {
                this.editing_cash.image_url_2 = null;
            } else {
                this.editing_cash.image_url_2 = this.imagesCash[1];
            }
            this.cashes[this.editing_cash_idx] = this.editing_cash;
            this.editing_cash = null;
            this.imagesCash = [];
        }
        this.register(form);
    }

    remove(i) {
        this.images.splice(i, 1);
    }

    remove_cash(i) {
        this.imagesCash.splice(i, 1);
    }

    add_image_cash(e) {
        if (this.imagesCash.length < 2) {
            const url: string = Config.settings.api + Config.settings.info.file;
            const formData: FormData = new FormData();
            formData.append(
                'file',
                e.target.files.item(0),
                e.target.files.item(0).name
            );
            console.log(formData);
            const headers = {dt: 'yes'};
            this.http.post(url, formData, {headers}).subscribe(
                (res: any) => {
                    this.imagesCash.push(res.url.slice(1));
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    window.scroll(0, 0);
                }
            );
        } else {
            this.success = false;
            this.isWrong = true;
            this.errorMsg = 'تعداد عکس ها بیشتر از 2 عدد نمی تواند باشد!';
            window.scroll(0, 0);
        }
    }
}
