<div class="m-3 mt-4">
  <app-title title="لیست بار‌های ارسالی"></app-title>
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item">
        <i class="far fa-plus-square"></i>جدید
      </div>
      <div class="list-header-item">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
      <div class="list-header-item">
        تعداد ردیف: {{ object_count.toString() | toFa }}
      </div>
      <div
        class="list-header-item mr-auto cursor-pointer"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="far fa-file-search"></i>فیلتر
      </div>
    </div>

    <app-filter
      [isCollapsed]="isCollapsed"
      [filter_items]="filter_items"
      (changeFilter)="search($event)"
    ></app-filter>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col" (click)="sort('company_name')">نام فروشگاه</th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('order_date')"
          >
            تاریخ سفارش
          </th>
          <th scope="col" (click)="sort('order_date')">تاریخ ارسال</th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('receiving_date')"
          >
            تاریخ دریافت
          </th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('order')"
          >
            سفارش
          </th>
          <th scope="col" (click)="sort('remittance_number')">فاکتور</th>
          <th scope="col" (click)="sort('sheet_number')">تعداد ورق</th>
          <th scope="col"  class="d-lg-table-cell d-none" (click)="sort('order_sheet_number')">تعداد ورق ارسال شده</th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('remaining_sheet_number')"
          >
            باقی مانده
          </th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('creator_last_name')"
          >
            نام ارسال کننده
          </th>
          <th scope="col" class="d-lg-table-cell d-none">وضعیت تایید سفارش</th>
          <th scope="col" (click)="sort('evaluation')">رضایت سنجی</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of shippings; let i = index">
          <th scope="row">
            {{ (page_number - 1) * page_size + i + 1 | toFa }}
          </th>
          <td>{{ el.company_name }}</td>
          <td class="d-lg-table-cell d-none">
            {{ el.order_date | jDate | toFa }}
          </td>
          <td>
            {{ el.sending_date | jDate | toFa }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ el.receiving_date | jDate | toFa }}
          </td>
          <td class="d-lg-table-cell d-none">{{ el.order | toFa }}</td>
          <td>{{ el.remittance_number }}</td>
          <td>{{ el.sheet_number | toFa }}</td>
          <td class="d-lg-table-cell d-none">{{ el.order_sheet_number | toFa }}</td>
          <td class="d-lg-table-cell d-none">
            {{ el.remaining_sheet_number | toFa }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ el.creator_first_name + " " + el.creator_last_name }}
          </td>
          <td class="d-lg-table-cell d-none">
            <a *ngIf="el.is_received">تایید دریافت</a>
            <a *ngIf="!el.is_received">عدم دریافت</a>
          </td>
          <td>
            <a
              *ngIf="!el.evaluation"
              [routerLink]="['../evaluate', el.id]"
              >انجام نشده</a
            >
            <a
              *ngIf="el.evaluation"
              [routerLink]="['../evaluation', el.evaluation]"
              >انجام شده</a
            >
          </td>

          <td class="cursor-pointer" [routerLink]="[el.id]">
            <i class="fal fa-edit"></i>
          </td>
          <td
            class="cursor-pointer"
            (click)="can_del && del(content, el.id)"
          >
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    <app-pagination
      [page_count]="page_count"
      [page_number]="page_number"
      (changePage)="load($event)"
    ></app-pagination>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      آیا از حذف این رکورد اطمینان دارید؟
    </h4>
  </div>
  <div class="modal-body">
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(true)"
    >
      بله
    </button>
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(false)"
    >
      خیر
    </button>
  </div>
</ng-template>
