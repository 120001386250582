import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class LoadingService {
   constructor() {}

   count: number = 0;
   add() {
      this.count++;
   }

   remove() {
      this.count--;
   }

   reset() {
      this.count = 0;
   }

   get show(): boolean {
      return this.count != 0;
   }
}
