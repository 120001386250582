<div class="m-3 mt-4">
  <app-title title="لیست مشتریان"></app-title>
  <app-toolbar [toolbarItems]="toolbarItemList" (clickHandler)="itmeClickHandler($event)"></app-toolbar>
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item">
        <i class="far fa-plus-square"></i>جدید
      </div>
      <div class="list-header-item">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
      <div class="list-header-item">
        تعداد ردیف: {{ object_count.toString() | toFa }}
      </div>
      <div
        class="list-header-item mr-auto cursor-pointer"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="far fa-file-search"></i>فیلتر
      </div>
    </div>

    <app-filter
      [isCollapsed]="isCollapsed"
      [filter_items]="filter_items"
      (changeFilter)="search($event)"
    ></app-filter>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col" (click)="sort('company_name')">فروشگاه</th>
          <th scope="col" class="d-lg-table-cell d-none">شخصیت</th>
          <th scope="col" (click)="sort('province_name')" class="d-lg-table-cell d-none">استان</th>
          <th scope="col" (click)="sort('city_name')">شهرستان</th>
          <th scope="col">تلفن همراه</th>
          <th scope="col" (click)="sort('created_at')" class="d-lg-table-cell d-none">
            تاریخ ثبت‌نام
          </th>
          <th scope="col" (click)="sort('marketing_expert_first_name')" class="d-lg-table-cell d-none">
            کارشناس بازاریابی
          </th>
          <th scope="col" (click)="sort('creator_first_name')" class="d-lg-table-cell d-none">ثبت کننده</th>
          <th scope="col" (click)="sort('familiarity_type_name')" class="d-lg-table-cell d-none">نحوه آشنایی</th>
          <th scope="col" (click)="sort('nature_name')" class="d-lg-table-cell d-none">ماهیت مشتری</th>
          <th scope="col"  class="d-lg-table-cell d-none">وضعیت مشتری</th>
          <th scope="col">نوع مخاطب</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let el of customers; let i = index"
          (dblclick)="edit(el.id)"
        >
          <th scope="row">
            {{ (page_number - 1) * page_size + i + 1 | toFa }}
          </th>
          <td>{{ el.company_name ? el.company_name : el.last_name }}</td>
          <td class="d-lg-table-cell d-none">{{ el.type }}</td>
          <td class="d-lg-table-cell d-none">{{ el.province_name }}</td>
          <td>{{ el.city_name }}</td>
          <td>{{ el.phone_number | toFa }}</td>
          <td class="d-lg-table-cell d-none">
            {{ el.created_at | jDate | toFa }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{
              el.marketing_expert_first_name +
                " " +
                el.marketing_expert_last_name
            }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ el.creator_first_name + " " + el.creator_last_name }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ el.familiarity_type_name  }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ el.nature_name }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ el.is_active == true  ? 'فعال' : 'غیر فعال'}}
          </td>
          <td>{{ el.role_name }}</td>
          <td class="cursor-pointer" [routerLink]="[el.id]">
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    <app-pagination
      [page_count]="page_count"
      [page_number]="page_number"
      (changePage)="load($event)"
    ></app-pagination>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      آیا از حذف این رکورد اطمینان دارید؟
    </h4>
  </div>
  <div class="modal-body">
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(true)"
    >
      بله
    </button>
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(false)"
    >
      خیر
    </button>
  </div>
</ng-template>
<ng-template #reportContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">لطفا فیلد های مورد نیاز در گزارش را انتخاب کنید</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="row box">
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            نام فروشگاه
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="company_name"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            شخصیت
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="type"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            استان
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="province_name"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            شهرستان
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="city_name"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            تلفن همراه
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="phone_number"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            تاریخ ثبت نام
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="created_at"
          />
        </div>
      </div>
      <div class="row box" style="margin-top: 0px">
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            کارشناس بازاریابی
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="marketing_expert_last_name"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            ثبت کننده
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="creator_last_name"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            نحوه‌ی آشنایی
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="familiarity_type_name"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            ماهیت
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="nature_name"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
          <label class="mx-3 lead" style="font-size: 1rem">
            نوع مخاطب
          </label>
          <input
                  type="checkbox"
                  class="ml-3"
                  [(ngModel)]="role_name"
          />
        </div>
        <div class="form-group col d-flex align-items-center justify-content-between m-3">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
              type="button"
              class="btn btn-outline-dark mx-2"
              (click)="modal.close(true)"
      >
        تایید
      </button>
      <button
              type="button"
              class="btn btn-outline-dark mx-2"
              (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>