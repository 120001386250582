<div class="m-3 mt-4">
  <app-title title="لیست‌ ارزیابی‌های انجام شده"></app-title>

  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item cursor-pointer" (click)="load()">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
      <div class="list-header-item">
        تعداد ردیف: {{ reports.length.toString() | toFa }}
      </div>
      <!-- <div class="list-header-item mr-auto">
        <i class="far fa-file-search"></i
        ><input
          type="text"
          class="form-control"
          [formControl]="searchControl"
        />
      </div> -->
    </div>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="func.sort(reports, 'order_id')"
          >
            شماره سفارش
          </th>
          <th scope="col" (click)="func.sort(reports, 'company_name')">
            نام فروشگاه
          </th>
          <th
            scope="col"
            (click)="func.sort(reports, 'remittance_number')"
          >
            شماره فاکتور
          </th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="func.sort(reports, 'load_report')"
          >
            کد بار
          </th>
          <th scope="col" (click)="func.sort(reports, 'order_date')">
            تاریخ سفارش
          </th>
          <th scope="col" (click)="func.sort(reports, 'date')">
            تاریخ ارزیابی
          </th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="func.sort(reports, 'total_score')"
          >
            امتیاز کامل
          </th>
          <th scope="col" (click)="func.sort(reports, 'total_score')">
            امتیاز کسب شده
          </th>
          <th scope="col">ویرایش</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let el of reports; let i = index"
          (dblclick)="edit(el.id)"
        >
          <th scope="row">
            {{ (page_number - 1) * page_size + i + 1 | toFa }}
          </th>
          <td class="d-lg-table-cell d-none">{{ el.order_id | toFa }}</td>
          <td>{{ el.company_name | toFa }}</td>
          <td>{{ el.remittance_number | toFa }}</td>
          <td class="d-lg-table-cell d-none">
            {{ el.load_report | toFa }}
          </td>
          <td>{{ el.order_date | jDate | toFa }}</td>
          <td>{{ el.date | jDate | toFa }}</td>
          <td class="d-lg-table-cell d-none">
            {{ el.total_score?.toString() | toFa }}
          </td>
          <td>{{ el.score?.toString() | toFa }}</td>
          <td class="cursor-pointer" [routerLink]="[el.id]">
            <i class="fal fa-edit"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex justify-content-center">
    <app-pagination
      [page_count]="page_count"
      [page_number]="page_number"
      (changePage)="load($event)"
    ></app-pagination>
  </div>
</div>
