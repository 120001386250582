import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Config } from "src/app/app-config.service";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";
import { AuthService } from "src/app/auth/auth.service";
import * as Func from "src/pack/js/func";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ThrowStmt } from "@angular/compiler";
import { Subject } from "rxjs";
import { LoadingService } from "src/app/loading/loading.service";

@Component({
  selector: "app-work-background-list",
  templateUrl: "./work-background-list.component.html",
  styleUrls: ["./work-background-list.component.scss"],
})
export class WorkBackgroundListComponent implements OnInit {
  func = Func;

  show: boolean = false;
  activity_types = [];
  industries = [];
  activity_products = [];
  activity_cooperations = [];
  brands = [];

  backgrounds: any[] = [];
  orgbackgrounds: any[] = [];
  searchControl = new FormControl();
  backgroundApi: string =
    Config.settings.api + "information/activity-background/";

  work_background = {
    user: null,
    activity_type: 1,
    activity_industry: 1,
    activity_product: 1,
    activity_cooperation: 1,
    activity_brand: 1,
    start_date: "",
    detail: "",
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalService: NgbModal,
    private loading: LoadingService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    let activity_typeApi: string =
      Config.settings.api + "information/activity-type/";
    this.http.get(activity_typeApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.activity_types.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    let activity_cooperationApi: string =
      Config.settings.api + "information/activity-cooperation/";
    this.http.get(activity_cooperationApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.activity_cooperations.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    let activity_productApi: string =
      Config.settings.api + "information/activity-product/";
    this.http.get(activity_productApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.activity_products.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    let brandApi: string =
      Config.settings.api + "information/activity-brand/";
    this.http.get(brandApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.brands.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    let industryApi: string =
      Config.settings.api + "information/activity-industry/";
    this.http.get(industryApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.industries.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    this.load();
  }

  disable_load = false;
  load(page = 1) {
    if (this.disable_load) return;
    this.disable_load = true;
    this.loading.add();

    this.orgbackgrounds = [];

    this.http.get(this.backgroundApi).subscribe(
      (response: any) => {
        console.log(response);

        response.forEach((element) => {
          this.orgbackgrounds.push(element);
        });
        this.backgrounds = this.orgbackgrounds;
      },
      (error: HttpErrorResponse) => {
        this.backgrounds = [];
        //TODO: server errors
        console.log(error);
        this.loading.remove();
        this.disable_load = false;
      },
      () => {
        this.loading.remove();
        this.disable_load = false;
      }
    );
  }
  editing_work_background;
  edit(content, i) {
    let el = this.backgrounds[i];
    this.editing_work_background = {
      id: el.id,
      activity_type: el.activity_type,
      activity_industry: el.activity_industry,
      activity_product: el.activity_product,
      activity_brand: el.activity_brand,
      activity_cooperation: el.activity_cooperation,
      start_date: el.start_date,
      detail: el.detail,
    };

    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http
            .patch(
              this.backgroundApi + this.editing_work_background.id + "/",
              this.editing_work_background
            )
            .subscribe(
              (response: any) => {
                this.load();
              },
              (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
              }
            );
        }
      });
  }

  del(content, i) {
    if (!i) return;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http.delete(this.backgroundApi + i + "/").subscribe(
            (response: any) => {
              this.load();
            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
          );
        }
      });
  }

  sortcol: string = "-created_at";

  sort(col: string) {
    if (this.sortcol == col) this.sortcol = "-" + col;
    else this.sortcol = col;
    this.load();
  }

  register() {
    console.log(this.work_background);
    this.work_background.user = parseInt(localStorage.getItem("user_id"));
    this.http.post(this.backgroundApi, this.work_background).subscribe(
      (response: any) => {
        console.log("shod");
        this.load();
        this.work_background = {
          user: null,
          activity_type: 1,
          activity_industry: 1,
          activity_cooperation: 1,
          activity_product: 1,
          activity_brand: 1,
          start_date: "",
          detail: "",
        };
        this.show = false;
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  ngOnDestroy() {}
}
