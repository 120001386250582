<div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordion"
     style="padding-top: 5px;" xmlns="http://www.w3.org/1999/html">
    <!--                class="collapse show"-->
    <div class="list-header">
        <div
                class="list-header-item mr-auto cursor-pointer"
                (click)="isCollapsed = !isCollapsed"
        >
            <i class="far fa-file-search"></i>فیلتر
        </div>
    </div>
    <div class="bg-gray-light" [ngbCollapse]="isCollapsed" style="background-color: #eaf1f0">
        <div class="form-container p-3">
            <form
                #form="ngForm"
                (ngSubmit)="initialDate(form)"
            >
                <div class="row mb-1">
                    <ng-container>
                        <div class="col-6 col-md-4 col-lg-3 form-group mb-2">
                            <label class="mx-1 lead"> از تاریخ </label>
                            <div class="input-group">
                                <input
                                        matInput
                                        [matDatepicker]="picker3"
                                        name="from_date"
                                        ngModel
                                        class="form-control form-control-lg"
                                />

                                <span class="input-group-append" (click)="picker3.open()">
              <i class="fa fa-calculator input-group-text"></i>
            </span>
                                <mat-datepicker #picker3></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 form-group mb-2">
                            <label class="mx-1 lead"> تا تاریخ </label>
                            <div class="input-group">
                                <input
                                        matInput
                                        [matDatepicker]="picker2"
                                        name="to_date"
                                        ngModel
                                        class="form-control form-control-lg"
                                />

                                <span class="input-group-append" (click)="picker2.open()">
              <i class="fa fa-calculator input-group-text"></i>
            </span>
                                <mat-datepicker #picker2></mat-datepicker>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="row justify-content-center">
                    <button class="btn btn-primary" type="submit">فیلتر</button>
                </div>
            </form>
        </div>
    </div>
    <div class="row " style="padding-top: 10px;">
        <div class="col-sm-6 col-md-6 col-lg-6">
            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                <h5
                        style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                    وضعیت ورودی انبارهای فروش استانی
                </h5>
                <table style="width: 100%;margin: 0px;">
                    <thead class="thead-light"
                           style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                    <tr>
                        <th scope="col" style="border-left: 1px solid gray;">
                            ردیف
                        </th>
                        <th scope="col" style="border-left: 1px solid gray;">
                            عنوان انبار
                        </th>
                        <th scope="col" style="border-left: 1px solid gray;">
                            تعداد سفارش
                        </th>
                        <th scope="col">تعداد ورق</th>
                        <th scope="col">مشاهده</th>
                    </tr>

                    </thead>
                    <tbody>


                    </tbody>
                    <tbody>

                    <tr *ngFor="let el of inputWarehouses; let i = index"
                        style="text-align: center;border-top: 1px solid gray;">
                        <td style="border-left: 1px solid gray;"> {{ i + 1 }}</td>
                        <td style="border-left: 1px solid gray;">
                            {{ el.warehouse_name }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ el.n_orders }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ el.sheet_sum }}
                        </td>
                        <td>
                            <div class="fa fa-eye" class="cursor-pointer"
                                 [routerLink]="['/userArea/warehouseOrderList']"
                                 [queryParams]="{ warehouse_id: el.warehouse_id ,state:3,start_date:showStartDate,end_date:showEndDate}"
                                 routerLinkActive="text-primary"
                                 [routerLinkActiveOptions]="{ exact: true }">
                                <i class="fa fa-eye"></i>
                            </div>

                        </td>
                    </tr>
                    </tbody>
                    <thead
                            style="text-align: center;background-color: #898993;width: 100%;margin: 0px;">
                    <tr
                            style="text-align: center">
                        <th class="border-left-0"></th>
                        <td>
                            جمع کل
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ nOrderSum }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ sheetSum }}
                        </td>
                        <th class="border-left-0"></th>
                    </tr>
                    </thead>

                </table>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                <h5
                        style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                    وضعیت خروجی انبارهای فروش استانی
                </h5>
                <table style="border-left: 1px ;width: 100%;margin: 0px;">
                    <thead class="thead-light"
                           style="text-align: center;
                       background-color: #b8bfc9;width: 100%;margin: 0px;">
                    <tr>
                        <th style="border-left: 1px solid #bdbbbb;">
                            ردیف
                        </th>
                        <th scope="col" style="border-left: 1px solid #111010;">
                            عنوان انبار
                        </th>
                        <th scope="col" style="border-left: 1px solid #111010;">
                            تعداد سفارش
                        </th>
                        <th scope="col" style="border-left: 1px solid #111010;">تعداد ورق</th>
                        <th scope="col" style="border-left: 1px solid #111010;">مبلغ سفارش</th>
                        <th scope="col" style="border-left: 1px solid #111010;">مبلغ پرداختی</th>
                        <th scope="col">مشاهده</th>
                    </tr>

                    </thead>
                    <tbody>


                    </tbody>
                    <tbody>

                    <tr *ngFor="let el of outPutWarehouses; let i = index"
                        style="text-align: center;border-top: 1px solid #4B4B4B;">
                        <td style="border-left: 1px solid gray;"> {{ i + 1 }}</td>
                        <td style="border-left: 1px solid gray;">
                            {{ el.warehouse_name }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ el.n_orders }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ el.sheet_sum }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ el.order_amount_sum - el.discount_amount_sum | number }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ el.paid_amount_sum   | number}}
                        </td>
                        <td>
                            <div class="fa fa-eye" class="cursor-pointer"
                                 [routerLink]="['/userArea/order']"
                                 [queryParams]="{source:2,warehouse_id: el.warehouse_id ,state:11,start_date:showStartDate,end_date:showEndDate}"
                                 routerLinkActive="text-primary"
                                 [routerLinkActiveOptions]="{ exact: true }">
                                <i class="fa fa-eye"></i>
                            </div>

                        </td>
                    </tr>
                    </tbody>
                    <thead
                            style="text-align: center;background-color: #898993;width: 100%;margin: 0px;">
                    <tr
                            style="text-align: center">
                        <th class="border-left-0"></th>
                        <td>
                            جمع کل
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ nOrderSumOutPut }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ sheetSumOutPut }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ amountSum | number }}
                        </td>
                        <td style="border-left: 1px solid gray;">
                            {{ paidAmountSum | number }}
                        </td>
                        <th class="border-left-0"></th>
                    </tr>
                    </thead>

                </table>

            </div>
        </div>
    </div>
    <div style="text-align: center;padding-top: 20px;">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
                <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                    <h5
                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                        وضعیت موجودی انبارهای فروش استانی
                    </h5>
                    <table style="width: 100%;margin: 0px;">
                        <thead class="thead-light"
                               style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                        <tr>
                            <th scope="col" style="border-left: 1px solid gray;">
                                محصولات
                            </th>
                            <th scope="col" style="border-left: 1px solid gray;"
                                *ngFor="let el of warehouseNames; let i = index">
                                {{el}}
                            </th>
                            <th scope="col" style="border-left: 1px solid gray;">
                                جمع کل
                            </th>
                            <th scope="col">مشاهده</th>
                        </tr>

                        </thead>
                        <tbody>


                        </tbody>
                        <tbody>

                        <tr style="text-align: center;border-top: 1px solid gray;"
                            *ngFor="let p of productStockList">
                            <td style="border-left: 1px solid gray;">
                                {{ p.name }}
                            </td>
                            <td style="border-left: 1px solid gray;" *ngFor="let el of p.stockList">
                                {{el}}
                            </td>
                            <td style="border-left: 1px solid gray;">
                                {{p.sumStockList}}
                            </td>
                            <td>
                                <div class="fa fa-eye" class="cursor-pointer"
                                     [routerLink]="['/userArea/WarehouseProductOrder']"
                                     [queryParams]="{ product_id: p.id}"
                                     routerLinkActive="text-primary"
                                     [routerLinkActiveOptions]="{ exact: true }">
                                    <i class="fa fa-eye"></i>
                                </div>

                            </td>
                        </tr>
                        </tbody>
                        <thead
                                style="text-align: center;background-color: #898993;width: 100%;margin: 0px;">
                        <tr
                                style="text-align: center">
                            <td>
                                جمع کل
                            </td>
                            <td style="border-left: 1px solid gray;"
                                *ngFor="let el of warehouseStockSum; let i = index">
                                {{ el }}
                            </td>
                            <td style="border-left: 1px solid gray;">
                                {{ productSum }}
                            </td>
                            <th class="border-left-0"></th>
                        </tr>
                        </thead>

                    </table>
                </div>

            </div>
        </div>
    </div>
</div>
