import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Config} from "../../../app-config.service";
import {Nature} from "./Nature";

@Component({
    selector: 'app-customer-dashboard',
    templateUrl: './customer-dashboard.component.html',
    styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit {

    customerApi: string = Config.settings.api + 'dashboard/user_nature';
    columns: any[] = [];
    data: any[] = [];
    natureValue;
    natureValueList: any[] = [];

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
        this.loadCustomer();
    }

    loadCustomer() {
        debugger;
        this.http.get(this.customerApi).subscribe(
            (response: any) => {
                debugger;
                console.log(response);
                this.columns = response.user_nature_table.columns;
                this.data  = response.user_nature_table.data;
                this.data.forEach((e) => {
                    this.natureValue  = new Nature(e.user_nature_id, e.user_nature_name , e.number_list);
                    this.natureValueList.push(this.natureValue);
                });
            },
            (error: HttpErrorResponse) => {
                debugger;
                console.log(error);
                window.scroll(0, 0);
            }
        );

    }
}
