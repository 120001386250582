<div class="m-3 ml-5 mt-4">
    <app-title title="ثبت سفارش جدید"></app-title>

    <div *ngIf="success" class="" role="">
        <div
                class="d-flex text-success justify-content-center align-items-center"
        >
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ msg }}
        </div>
    </div>
    <div *ngIf="isWrong" class="" role="">
        <div class="d-flex justify-content-center align-items-center">
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ errorMsg }}
        </div>
    </div>
    <app-toolbar [toolbarItems]="toolbarItemList" (clickHandler)="itmeClickHandler($event)"></app-toolbar>

    <div class="order-header">
        <div class="row">
            <div class="col item">سفارش:</div>
            <div class="col item">تاریخ سفارش:</div>
            <div class="col item">مخاطب:</div>
            <div class="col item">ایجاد کننده:</div>
            <div class="col item">وضعیت:</div>
        </div>
        <div class="row">
            <div class="col item">تعداد:</div>
            <div class="col item">تعداد ارسال شده:</div>
            <div class="col item">باقی مانده:</div>
            <div class="col item">مبلغ:</div>
            <div class="col item">وضعیت تسویه:</div>
        </div>
    </div>

    <form #form="ngForm" class="w-100">
        <div class="box">
            <div class="row">
                <div
                        dir="rtl"
                        *ngIf="!isCustomer"
                        class="form-group col-lg-3 col-md-6 col-12"
                >
                    <label class="mx-1 lead"> نام مشتری </label>
                    <input
                            type="text"
                            class="form-control form-control-lg"
                            matInput
                            [formControl]="control"
                            [matAutocomplete]="auto"
                    />
                    <mat-autocomplete
                        #auto="matAutocomplete"
                        (optionSelected)="changeCustomer($event.option.value)"
                        [displayWith]="showC"
                    >
                        <mat-option
                                *ngFor="let el of filteredCustomers | async"
                                [value]="el"
                        >
                            {{ el.label }}
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12">
                    <label class="mx-1 lead"> نحوه خرید </label>
                    <select
                            [ngModel]="null"
                            name="method"
                            class="custom-select custom-select-lg"
                    >
                        <!-- <option class="text-muted" selected [ngValue]="null">
                          نحوه خرید
                        </option> -->
                        <option *ngFor="let el of methods" [ngValue]="el.id">
                            {{ el.label }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12">
                    <label class="mx-1 lead"> نوع خرید </label>
                    <select
                            [ngModel]="null"
                            name="type"
                            class="custom-select custom-select-lg"
                    >
                        <!-- <option class="text-muted" selected [ngValue]="null">
                          نوع خرید
                        </option> -->
                        <option *ngFor="let el of types" [ngValue]="el.id">
                            {{ el.label }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-3 col-md-6 col-12">
                    <label class="mx-1 lead"> نحوه حمل </label>
                    <select
                            [ngModel]="null"
                            name="transportation"
                            class="custom-select custom-select-lg"
                    >
                        <!-- <option class="text-muted" selected [ngValue]="null">
                          نحوه حمل
                        </option> -->
                        <option *ngFor="let el of transportations" [ngValue]="el.id">
                            {{ el.label }}
                        </option>
                    </select>
                </div>
<!--                <div class="form-group col-lg-3 col-md-6 col-12">-->

<!--                    <label class="mx-1 lead">محل تامین سفارش</label>-->
<!--                    <select-->
<!--                            [ngModel]="source"-->
<!--                            name="source"-->
<!--                            class="custom-select custom-select-lg"-->
<!--                            (change)="changeSource($event.target.value)"-->
<!--                    >-->

<!--                        <option *ngFor="let el of sources" [ngValue]="el.id">-->
<!--                            {{ el.label }}-->
<!--                        </option>-->
<!--                    </select>-->
<!--                </div>-->

<!--                <div class="form-group col-lg-3 col-md-6 col-12"   >-->
<!--                    <fieldset  [disabled]="showWarehouse">-->
<!--                    <label class="mx-1 lead"> عنوان انبار فروش </label>-->
<!--                    <select-->
<!--                            [ngModel]="warehouse"-->
<!--                            name="warehouse"-->
<!--                            class="custom-select custom-select-lg"-->

<!--                    >-->
<!--                        &lt;!&ndash; <option class="text-muted" selected [ngValue]="null">-->
<!--                          نحوه حمل-->
<!--                        </option> &ndash;&gt;-->
<!--                        <option *ngFor="let el of warehouseList" [ngValue]="el.id">-->
<!--                            {{ el.label }}-->
<!--                        </option>-->
<!--                    </select>-->

<!--                    &lt;!&ndash;          <app-select&ndash;&gt;-->
<!--                    &lt;!&ndash;                  [options]="warehouseList"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  (selectChange)="changeWarehouse($event)"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  [key]="warehouse"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  req="true"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  placeholder=" "&ndash;&gt;-->
<!--                    &lt;!&ndash;          ></app-select>&ndash;&gt;-->
<!--                    &lt;!&ndash;          <input&ndash;&gt;-->
<!--                    &lt;!&ndash;                  type="hidden"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  value=""&ndash;&gt;-->
<!--                    &lt;!&ndash;                  [(ngModel)]="warehouse"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  name="warehouse"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  class="d-none"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  required&ndash;&gt;-->
<!--                    &lt;!&ndash;          />&ndash;&gt;-->
<!--                    </fieldset>-->
<!--                </div>-->
            </div>
        </div>
    </form>

    <div class="list mx-3">
        <table class="table table-bordered">
            <thead class="thead-light headerless">
            <tr>
                <th scope="col">#</th>
                <th scope="col">نام کالا</th>
                <th scope="col">تعداد ورق</th>
                <th scope="col" class="d-lg-table-cell d-none">تعداد پالت</th>
                <th scope="col" class="d-lg-table-cell d-none">وزن تقریبی</th>
                <th scope="col" class="d-lg-table-cell d-none">
                    واحد اندازه گیری
                </th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of order; let i = index">
                <th>{{ i + 1 }}</th>
                <td class="p-0" dir="rtl">
                    <!-- <select
                      [(ngModel)]="el.pindex"
                      (change)="
                        order[i].product_id = orgProducts[$event.target.value].id
                      "
                      class="border-0 custom-select custom-select-lg"
                    >
                      <option *ngFor="let p of products" [value]="p.id">
                        {{ p.label }}
                      </option>
                    </select> -->

                    <input
                            type="text"
                            class="form-control form-control-lg border-0"
                            matInput
                            [formControl]="controlorder"
                            [matAutocomplete]="autoo"
                    />

                    <mat-autocomplete
                        #autoo="matAutocomplete"
                        (optionSelected)="changeProduct($event.option.value, i)"
                        [displayWith]="showC"
                    >
                        <mat-option
                                *ngFor="let el of filteredProducts | async"
                                [value]="el"
                        >
                            {{ el.label }}
                        </mat-option>
                    </mat-autocomplete>
                </td>

                <td class="p-0">
                    <input
                            type="number"
                            class="border-0 form-control form-control-lg"
                            [(ngModel)]="order[i].count"
                            [ngModelOptions]="{ standalone: true }"
                    />
                </td>
                <td class="d-lg-table-cell d-none">
                    {{
                    el.pindex != null && order[i].count !== null
                        ? math.floor(
                        (1.0 * el.count) /
                        this.orgProducts[el.pindex].number_in_pallet
                        )
                        : null
                    }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{
                    el.pindex != null && order[i].count !== null
                        ? math.ceil(
                        order[i].count * orgProducts[el.pindex].weight
                        )
                        : null
                    }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ el.pindex != null ? orgProducts[el.pindex].unit : null }}
                </td>
                <td><i class="fal fa-trash-alt" (click)="remove(i)"></i></td>
            </tr>
            <tr>
                <th class="border-left-0"></th>
                <td (click)="add()" class="border-right-0">
                    <i class="fal fa-plus"></i>
                </td>
                <td>{{ count_sum }}</td>
                <td>{{ pallet_sum }}</td>
                <td class="d-lg-table-cell d-none">{{ weight_sum }}</td>
            </tr>
            </tbody>
        </table>
    </div>
<!--    <div class="row justify-content-end m-3">-->
<!--        <button-->
<!--                [routerLink]="['../']"-->
<!--                type="button"-->
<!--                class="btn col-2 btn-gray mx-3"-->
<!--        >-->
<!--            لغو-->
<!--        </button>-->
<!--        <button (click)="register(form)" class="btn col-2 btn-primary">-->
<!--            ذخیره-->
<!--        </button>-->
<!--    </div>-->
</div>
