import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import * as Func from "../../../../pack/js/func";
import {FormControl} from "@angular/forms";
import {Config} from "../../../app-config.service";


@Component({
    selector: 'app-sheet',
    templateUrl: './sheet.component.html',
    styleUrls: ['./sheet.component.scss']
})
export class SheetComponent implements OnInit {


    isCollapsed = true;
    page_number: number = 1;
    object_count: number = 0;
    page_count: number = 0;
    sortcol: string = null;
    func = Func;
    sheets: any[] = [];
    orgSheets: any[] = [];
    message ;
    success: boolean;
    searchControl = new FormControl();
    selected: boolean[] = [];
    sheetApi: string = Config.settings.api + "information/sheet/";
    editing_sheet;
    products: any[] = [];
    searchTerm: string;

    sheet = {
        creation_date: null,
        product: null,
        pallet_code: "",
        code: ""
    };
    filter_items = [
        {
            name: "code",
            label: "کد ورق",
            type: "string",
        },
        {
            name: "pallet_code",
            label: "کد پالت",
            type: "string",
        }
        ,
        {
            name: "product_id",
            label: 'کالا',
            options: this.products,
            type: 'list',
            res: null,
        },
        {
            name: "product_name",
            label: "نام کالا",
            type: "string",
        },
        {
            name: "product_code",
            label: "کد کالا",
            type: "string",
        },
        {
            name: "start_creation_date",
            label: "شروع تاریخ ساخت ورق",
            type: "date",
        },
        {
            name: "end_creation_date",
            label: "پایان تاریخ ساخت ورق",
            type: "date",
        }
    ];
    search(filter: any) {
        console.log(filter);

        this.searchTerm = "";
        Object.keys(filter).forEach((el) => {
            this.searchTerm += el + "=" + filter[el] + "&";
            // console.log(el);
        });

        if (this.searchTerm != "")
            this.searchTerm = this.searchTerm.slice(0, -1);

        let qp = filter;
        // qp['q'] = this.searchTerm;

        this.router.navigate([], {
            queryParams: qp,
            // queryParamsHandling: "merge",
        });
        this.load();
        this.isCollapsed = true;
    }

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {

        this.load();

        this.http.get(Config.settings.api + "information/product").subscribe(
            (response: any) => {
                response.forEach((element) => {
                    if (element.name)
                        this.products.push({
                            id: element.id,
                            label: element.name,
                        });
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    load(page = 1) {
        debugger;
        this.orgSheets = [];
        this.orgSheets = [];
        this.sheets = [];
        if (this.sortcol === null) {
            this.sortcol = "creation_date";
        }
        this.http.get(this.sheetApi + `?page=${page}&${this.searchTerm}&ordering=${this.sortcol}`).subscribe(
            (response: any) => {
                this.sheets = [];
                this.page_count = response.page_count;
                this.object_count = response.object_count;
                this.page_number = response.page_number;
                response.results.forEach((element) => {
                    this.orgSheets.push({
                        id: element.id,
                        creationDate : element.creation_date ,
                        code: element.code,
                        productCode: element.product_code,
                        palletCode: element.pallet_code,
                        productName: element.product_name,
                        productTypeName: element.product_type_name,
                        productDegreeName: element.product_degree_name,
                        productDimensionName: element.product_dimension_name,
                        productThicknessName: element.product_thickness_name
                    });
                });
                // this.selected = new Array(mx + 1).fill(false);
                this.sheets = this.orgSheets;

            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    upload(e) {
        this.success = false;
        console.log(e);
        const url: string = Config.settings.api + 'information/sheet/upload/';
        const formData: FormData = new FormData();
        formData.append(
            "file",
            e.target.files.item(0),
            e.target.files.item(0).name
        );
        console.log(formData);
        const headers = {dt: "yes"};
        this.http.post(url, formData, {headers}).subscribe(
            (res: any) => {
                debugger;
                this.success = true;
                window.scroll(0, 0);
                this.message = res.message;
                setTimeout(function(){
                    location.reload();
                },3000);
            },
            (error: HttpErrorResponse) => {
                debugger;
                console.log(error.error.message);
                this.success = true;
                this.message = error.error.message;
                window.scroll(0, 0);
                setTimeout(function(){
                    location.reload();
                },3000);
            }
        );
    }

    ngOnDestroy() {
    }

    sort(col: string) {
        if (this.sortcol === col) this.sortcol = "-" + col;
        else this.sortcol = col;
        this.load();
    }


    edit(content, id) {

        this.http.get(this.sheetApi + id).subscribe(
            (response: any) => {
                debugger;
                console.log(response);
                setTimeout(() => {
                    this.editing_sheet = response;
                    this.modalService
                        .open(content, {ariaLabelledBy: "modal-basic-title"})
                        .result.then((result) => {
                        if (result) {
                            this.http
                                .patch(
                                    this.sheetApi + this.editing_sheet.id + "/",
                                    this.editing_sheet
                                )
                                .subscribe(
                                    (response: any) => {
                                        this.load();
                                    },
                                    (error: HttpErrorResponse) => {
                                        //TODO: server errors
                                        console.log(error);
                                    }
                                );
                        }
                    });

                }, 1000);


            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    del(content, i) {
        if (!i) return;
        this.modalService
            .open(content, {ariaLabelledBy: "modal-basic-title"})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.sheetApi + i + "/").subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    new(content) {
        this.modalService
            .open(content, {ariaLabelledBy: "modal-basic-title"})
            .result.then((result) => {
            if (result) {
                debugger;
                    console.log(this.sheet);
                    this.http.post(this.sheetApi, this.sheet).subscribe(
                        (response: any) => {
                            console.log("shod");
                            this.load();
                            this.sheet = {
                                creation_date: null,
                                product: null,
                                pallet_code: "",
                                code: ""
                            };
                            window.scroll(0, 0);
                        },
                        (error: HttpErrorResponse) => {
                            console.log(error);
                        }
                    );
                }
        });
    }


}
