<div class="m-3 mt-4">
    <app-title title="لیست مناطق"></app-title>
    <div class="list my-3">
        <div class="list-header bg-gray">
            <div class="list-header-item">
                <i class="far fa-plus-square cursor-pointer" (click)="register(registercontent)"></i>جدید
            </div>
            <div class="list-header-item">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div class="list-header-item">تعداد ردیف: {{ regions.length }}</div>
            <div class="list-header-item mr-auto">
                <i class="far fa-file-search"></i
                ><input
                    type="text"
                    class="form-control"
                    [formControl]="searchControl"
            />
            </div>
        </div>
        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col" (click)="func.sort(regions, 'name')">
                    نام منطقه
                </th>
                <th scope="col" (click)="func.sort(regions, 'supervisor')">
                    سرپرست منطقه
                </th>
                <th scope="col" (click)="func.sort(regions, 'sale_expert')">
                    نام کارشناس فروش
                </th>
                <th scope="col" (click)="func.sort(regions, 'sale_expert2')">
                    نام کارشناس فروش دوم
                </th>
                <th
                        scope="col"
                        (click)="func.sort(regions, 'marketing_expert')"
                >
                    نام کارشناس بازاریابی
                </th>
                <th
                        scope="col"
                        (click)="func.sort(regions, 'marketing_expert2')"
                >
                    نام کارشناس بازاریابی دوم
                </th>
                <th scope="col">ویرایش</th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of regions; let i = index">
                <th scope="row">{{ i + 1 | toFa }}</th>
                <!-- <td>{{ i + 1 | toFa }}</td> -->
                <td>{{ el.name }}</td>
                <td>
                    {{ el.supervisor }}
                </td>
                <td>
                    {{ el.sale_expert }}
                </td>
                <td>
                    {{ el.sale_expert2 }}
                </td>
                <td>
                    {{ el.marketing_expert }}
                </td>
                <td>
                    {{ el.marketing_expert2 }}
                </td>
                <td class="cursor-pointer" (click)="edit(editcontent, i)">
                    <i class="fal fa-edit"></i>
                </td>
                <td class="cursor-pointer" (click)="del(content, el.id)">
                    <i class="fal fa-trash-alt"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>


    <!--  <app-title-->
    <!--    (click)="show = !show"-->
    <!--    [attr.aria-expanded]="show"-->
    <!--    aria-controls="collapse"-->
    <!--    [class.open]="show"-->
    <!--    type="plus"-->
    <!--    title="اضافه کردن"-->
    <!--    class="cursor-pointer"-->
    <!--  ></app-title>-->

    <!--  <div id="collapse" [ngbCollapse]="!show" class="box">-->
    <!--&lt;!&ndash;    <div class="row">&ndash;&gt;-->
    <!--&lt;!&ndash;      <div class="form-group col-md-6 col-12">&ndash;&gt;-->
    <!--&lt;!&ndash;        <label>عنوان منطقه</label>&ndash;&gt;-->
    <!--&lt;!&ndash;        <input&ndash;&gt;-->
    <!--&lt;!&ndash;          type="text"&ndash;&gt;-->
    <!--&lt;!&ndash;          [(ngModel)]="region.name"&ndash;&gt;-->
    <!--&lt;!&ndash;          name="name"&ndash;&gt;-->
    <!--&lt;!&ndash;          class="form-control form-control-lg"&ndash;&gt;-->
    <!--&lt;!&ndash;          required&ndash;&gt;-->
    <!--&lt;!&ndash;        />&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->
    <!--&lt;!&ndash;      <div class="form-group col-md-6 col-12">&ndash;&gt;-->
    <!--&lt;!&ndash;        <app-select&ndash;&gt;-->
    <!--&lt;!&ndash;          [options]="regionSupervisors"&ndash;&gt;-->
    <!--&lt;!&ndash;          placeholder="سرپرست منطقه"&ndash;&gt;-->
    <!--&lt;!&ndash;          (selectChange)="region.supervisor = $event"&ndash;&gt;-->
    <!--&lt;!&ndash;          req="true"&ndash;&gt;-->
    <!--&lt;!&ndash;        ></app-select>&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->
    <!--&lt;!&ndash;      <div class="form-group col-md-6 col-12">&ndash;&gt;-->
    <!--&lt;!&ndash;        <app-select&ndash;&gt;-->
    <!--&lt;!&ndash;          [options]="marketingExperts"&ndash;&gt;-->
    <!--&lt;!&ndash;          placeholder="کارشناس بازاریابی"&ndash;&gt;-->
    <!--&lt;!&ndash;          (selectChange)="region.marketing_expert = $event"&ndash;&gt;-->
    <!--&lt;!&ndash;          req="true"&ndash;&gt;-->
    <!--&lt;!&ndash;        ></app-select>&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->
    <!--&lt;!&ndash;      <div class="form-group col-md-6 col-12">&ndash;&gt;-->
    <!--&lt;!&ndash;        <app-select&ndash;&gt;-->
    <!--&lt;!&ndash;          [options]="saleExperts"&ndash;&gt;-->
    <!--&lt;!&ndash;          placeholder="کارشناس فروش"&ndash;&gt;-->
    <!--&lt;!&ndash;          (selectChange)="region.sale_expert = $event"&ndash;&gt;-->
    <!--&lt;!&ndash;          req="true"&ndash;&gt;-->
    <!--&lt;!&ndash;        ></app-select>&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->

    <!--&lt;!&ndash;      <div class="row w-100 my-3">&ndash;&gt;-->
    <!--&lt;!&ndash;        <button&ndash;&gt;-->
    <!--&lt;!&ndash;          (click)="register()"&ndash;&gt;-->
    <!--&lt;!&ndash;          class="btn mr-auto ml-3 w-25 btn-success"&ndash;&gt;-->
    <!--&lt;!&ndash;        >&ndash;&gt;-->
    <!--&lt;!&ndash;          ثبت&ndash;&gt;-->
    <!--&lt;!&ndash;        </button>&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->
    <!--&lt;!&ndash;    </div>&ndash;&gt;-->
    <!--&lt;!&ndash;  </div>&ndash;&gt;-->
    <!--</div>-->

    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                آیا از حذف این رکورد اطمینان دارید؟
            </h4>
        </div>
        <div class="modal-body">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(true)"
            >
                بله
            </button>
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(false)"
            >
                خیر
            </button>
        </div>
    </ng-template>

    <ng-template #editcontent let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-md-6 col-12">
                    <label>عنوان منطقه</label>
                    <input
                            type="text"
                            [(ngModel)]="editing_region.name"
                            name="name"
                            class="form-control form-control-lg"
                            required
                    />
                </div>

                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="regionSupervisors"
                            [key]="editing_region.supervisor"
                            placeholder="سرپرست منطقه"
                            (selectChange)="editing_region.supervisor = $event"
                            req="true"
                    ></app-select>
                </div>
                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="marketingExperts"
                            [key]="editing_region.marketing_expert"
                            placeholder="کارشناس بازاریابی"
                            (selectChange)="editing_region.marketing_expert = $event"
                            req="true"
                    ></app-select>
                </div>
                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="saleExperts"
                            [key]="editing_region.sale_expert"
                            placeholder="کارشناس فروش"
                            (selectChange)="editing_region.sale_expert = $event"
                            req="true"
                    ></app-select>
                </div>
                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="marketingExperts"
                            [key]="editing_region.marketing_expert_2"
                            placeholder="ارشناس بازاریابی دوم"
                            (selectChange)="editing_region.marketing_expert_2 = $event"
                            req="true"
                    ></app-select>
                </div>
                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="saleExperts"
                            [key]="editing_region.sale_expert_2"
                            placeholder="کارشناس فروش دوم"
                            (selectChange)="editing_region.sale_expert_2 = $event"
                            req="true"
                    ></app-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(true)"
            >
                تایید
            </button>
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(false)"
            >
                انصراف
            </button>
        </div>
    </ng-template>
    <ng-template #registercontent let-modal style="z-index: auto">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                افزودن منطقه
            </h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-md-6 col-12">
                    <label>عنوان منطقه</label>
                    <input
                            type="text"
                            [(ngModel)]="region.name"
                            name="name"
                            class="form-control form-control-lg"
                            required
                    />
                </div>
                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="regionSupervisors"
                            placeholder="سرپرست منطقه"
                            (selectChange)="region.supervisor = $event"
                            req="true"
                    ></app-select>
                </div>
                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="marketingExperts"
                            placeholder="کارشناس بازاریابی"
                            (selectChange)="region.marketing_expert = $event"
                            req="true"
                    ></app-select>
                </div>
                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="saleExperts"
                            placeholder="کارشناس فروش"
                            (selectChange)="region.sale_expert = $event"
                            req="true"
                    ></app-select>
                </div>
                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="marketingExperts"
                            placeholder="کارشناس بازاریابی دوم"
                            (selectChange)="region.marketing_expert_2 = $event"
                            req="true"
                    ></app-select>
                </div>
                <div class="form-group col-md-6 col-12">
                    <app-select
                            [options]="saleExperts"
                            placeholder="کارشناس فروش دوم"
                            (selectChange)="region.sale_expert_2 = $event"
                            req="true"
                    ></app-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(true)"
            >
                ثبت
            </button>
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(false)"
            >
                انصراف
            </button>
        </div>
    </ng-template>
</div>
