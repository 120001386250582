<div class="m-3 mt-4">
    <app-title title="لیست محصولات"></app-title>
    <div class="list my-3">
        <div class="list-header bg-gray">
            <div class="list-header-item" [routerLink]="['new']">
                <i class="far fa-plus-square cursor-pointer"></i>جدید
            </div>
            <div class="list-header-item">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div class="list-header-item">
                تعداد ردیف: {{ products.length }}
            </div>
            <div
                    class="list-header-item mr-auto cursor-pointer"
                    (click)="isCollapsed = !isCollapsed"
            >
                <i class="far fa-file-search"></i>فیلتر
            </div>
<!--            <div class="list-header-item mr-auto">-->
<!--                <i class="far fa-file-search"></i-->
<!--                ><input-->
<!--                    type="text"-->
<!--                    class="form-control"-->
<!--                    [formControl]="searchControl"-->
<!--            />-->
<!--            </div>-->
        </div>
        <app-filter
                [isCollapsed]="isCollapsed"
                [filter_items]="filter_items"
                (changeFilter)="search($event)"
        ></app-filter>

        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="func.sort(products, 'code')">
                    کد محصول
                </th>
                <th scope="col" (click)="func.sort(products, 'type_name')">
                    نوع محصول
                </th>
                <th scope="col" (click)="func.sort(products, 'brand_name')">
                    گروه‌بندی برندها
                </th>
                <th  scope="col" (click)="func.sort(products, 'name')">
                    نام محصول
                </th>
                <th class="d-lg-table-cell d-none" scope="col" (click)="func.sort(products, 'dimension_name')">
                    ابعاد محصول
                </th>
                <th scope="col" (click)="func.sort(products, 'thickness_name')">
                    ضخامت محصول
                </th>
                <th scope="col" (click)="func.sort(products, 'status')">
                    وضعیت
                </th>
                <th scope="col">ویرایش</th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of products; let i = index">
                <th scope="row">{{ i + 1 | toFa }}</th>
                <!-- <td>{{ i + 1 | toFa }}</td> -->
                <td class="d-lg-table-cell d-none">{{ el.code | toFa }}</td>
                <td>{{ el.type_name | toFa }}</td>
                <td>{{ el.brand_name }}</td>
                <td>{{ el.name | toFa }}</td>
                <td class="d-lg-table-cell d-none">{{ el.dimension_name | toFa }}</td>
                <td>{{ el.thickness_name | toFa }}</td>
                <td>
                    <i
                            class="fa fa-circle"
                            [class]="el.is_active ? 'text-success' : 'text-danger'"
                    ></i>
                </td>
                <td class="cursor-pointer" (click)="edit(editcontent, i)">
                    <i class="fal fa-edit"></i>
                </td>
                <td class="cursor-pointer" (click)="del(content, el.id)">
                    <i class="fal fa-trash-alt"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
      <div class="d-flex justify-content-center">
        <app-pagination
                [page_count]="page_count"
                [page_number]="page_number"
                (changePage)="load($event)"
        ></app-pagination>
      </div>
<!--    <app-title-->
<!--            (click)="show = !show"-->
<!--            [attr.aria-expanded]="show"-->
<!--            aria-controls="collapse"-->
<!--            [class.open]="show"-->
<!--            type="plus"-->
<!--            title="اضافه کردن"-->
<!--            class="cursor-pointer"-->
<!--    ></app-title>-->

<!--    <div id="collapse" [ngbCollapse]="!show" class="box">-->
<!--        <div class="row">-->
<!--            <div class="form-group col-md-4 col-12">-->
<!--                <label>نام محصول</label>-->
<!--                <input-->
<!--                        type="text"-->
<!--                        [(ngModel)]="product.name"-->
<!--                        name="name"-->
<!--                        class="form-control form-control-lg"-->
<!--                        required-->
<!--                />-->
<!--            </div>-->
<!--            <div class="form-group col-md-4 col-12">-->
<!--                <label>کد محصول</label>-->
<!--                <input-->
<!--                        type="text"-->
<!--                        [(ngModel)]="product.code"-->
<!--                        name="code"-->
<!--                        class="form-control form-control-lg"-->
<!--                        required-->
<!--                />-->
<!--            </div>-->

<!--            <div class="form-group col-md-4 col-12">-->
<!--                <label>تعداد در پالت</label>-->
<!--                <input-->
<!--                        type="text"-->
<!--                        [(ngModel)]="product.number_in_pallet"-->
<!--                        (keydown)="func.numControl($event)"-->
<!--                        name="number_in_pallet"-->
<!--                        class="form-control form-control-lg"-->
<!--                        required-->
<!--                />-->
<!--            </div>-->
<!--            <div class="form-group col-md-4 col-12">-->
<!--                <label>وزن</label>-->
<!--                <input-->
<!--                        type="text"-->
<!--                        [(ngModel)]="product.weight"-->
<!--                        name="weight"-->
<!--                        (keydown)="func.numControl($event)"-->
<!--                        class="form-control form-control-lg"-->
<!--                        required-->
<!--                />-->
<!--            </div>-->

<!--            <div class="form-group col-md-4 col-12">-->
<!--                <app-select-->
<!--                        [options]="unit_of_measures"-->
<!--                        [key]="product.unit"-->
<!--                        placeholder="واحد"-->
<!--                        (selectChange)="product.unit = $event"-->
<!--                        req="true"-->
<!--                ></app-select>-->
<!--            </div>-->

<!--            <div class="form-group col-md-4 col-12">-->
<!--                <app-select-->
<!--                        [options]="thicknesses"-->
<!--                        placeholder="ضخامت"-->
<!--                        (selectChange)="product.thickness = $event"-->
<!--                        req="true"-->
<!--                ></app-select>-->
<!--            </div>-->
<!--            <div class="form-group col-md-4 col-12">-->
<!--                <app-select-->
<!--                        [options]="types"-->
<!--                        placeholder="نوع"-->
<!--                        (selectChange)="product.type = $event"-->
<!--                        req="true"-->
<!--                ></app-select>-->
<!--            </div>-->

<!--            <div class="form-group col-md-4 col-12">-->
<!--                <app-select-->
<!--                        [options]="brands"-->
<!--                        placeholder="برند"-->
<!--                        (selectChange)="product.brand = $event"-->
<!--                        req="true"-->
<!--                ></app-select>-->
<!--            </div>-->
<!--            <div class="form-group col-md-4 col-12">-->
<!--                <app-select-->
<!--                        [options]="degrees"-->
<!--                        placeholder="درجه بندی"-->
<!--                        (selectChange)="product.degree = $event"-->
<!--                        req="true"-->
<!--                ></app-select>-->
<!--            </div>-->
<!--            <div class="form-group col-md-4 col-12">-->
<!--                <app-select-->
<!--                        [options]="suppliers"-->
<!--                        placeholder="تامین کننده"-->
<!--                        (selectChange)="product.supplier = $event"-->
<!--                        req="true"-->
<!--                ></app-select>-->
<!--            </div>-->
<!--            <div class="form-group col-md-4 col-12">-->
<!--                <app-select-->
<!--                        [options]="dimensions"-->
<!--                        placeholder="ابعاد"-->
<!--                        (selectChange)="product.dimension = $event"-->
<!--                        req="true"-->
<!--                ></app-select>-->
<!--            </div>-->

<!--            <div class="row w-100 mb-3">-->
<!--                <button-->
<!--                        (click)="register()"-->
<!--                        class="btn mr-auto ml-3 w-25 btn-success"-->
<!--                >-->
<!--                    ثبت-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            آیا از حذف این رکورد اطمینان دارید؟
        </h4>
    </div>
    <div class="modal-body">
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(true)"
        >
            بله
        </button>
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(false)"
        >
            خیر
        </button>
    </div>
</ng-template>

<ng-template #editcontent let-modal style="width: 50%">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">ویرایش محصولات</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-md-8 col-12">
                <label>نام محصول</label>
                <input
                        type="text"
                        [(ngModel)]="editing_product.name"
                        name="name"
                        class="form-control form-control-lg"
                        required
                />
            </div>
            <div class="form-group col-md-4 col-12">
                <label>کد محصول</label>
                <input
                        type="text"
                        (keydown)="func.numControl($event)"
                        [(ngModel)]="editing_product.code"
                        name="code"
                        class="form-control form-control-lg"
                        required
                />
            </div>
            <div class="form-group col-md-4 col-12">
                <label>تعداد در پالت</label>
                <input
                        type="text"
                        [(ngModel)]="editing_product.number_in_pallet"
                        (keydown)="func.numControl($event)"
                        name="number_in_pallet"
                        class="form-control form-control-lg"
                        required
                />
            </div>
            <div class="form-group col-md-4 col-12">
                <label>وزن</label>
                <input
                        type="text"
                        [(ngModel)]="editing_product.weight"
                        name="weight"
                        (keydown)="func.numControl($event)"
                        class="form-control form-control-lg"
                        required
                />
            </div>

            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="unit_of_measures"
                        [key]="editing_product.unit"
                        placeholder="واحد"
                        (selectChange)="editing_product.unit = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="thicknesses"
                        [key]="editing_product.thickness"
                        placeholder="ضخامت"
                        (selectChange)="editing_product.thickness = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="types"
                        [key]="editing_product.type"
                        placeholder="نوع"
                        (selectChange)="editing_product.type = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="brands"
                        [key]="editing_product.brand"
                        placeholder="برند"
                        (selectChange)="editing_product.brand = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="degrees"
                        [key]="editing_product.degree"
                        placeholder="درجه بندی"
                        (selectChange)="editing_product.degree = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="suppliers"
                        [key]="editing_product.supplier"
                        placeholder="تامین کننده"
                        (selectChange)="editing_product.supplier = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-4 col-12">
                <app-select
                        [options]="dimensions"
                        [key]="editing_product.dimension"
                        placeholder="ابعاد"
                        (selectChange)="editing_product.dimension = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="row box" style="width: 100%;">
                <div class="form-group col d-flex align-items-lg-center">
                    <div class="form-group col-md-8 col-12">
                        <label class="mx-3 lead">وضعیت</label>
                        <input
                                type="checkbox"
                                class="ml-3"
                                [(ngModel)]="editing_product.is_active"
                        />
                    </div>
                </div>
                <div class="form-group col d-flex align-items-lg-center">
                    <div class="form-group col-md-8 col-12">
                        <i
                                class="fa fa-circle"
                                [class]="editing_product.is_active ? 'text-success' : 'text-danger'"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(true)"
            >
                تایید
            </button>
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(false)"
            >
                انصراف
            </button>
        </div>
    </div>
</ng-template>
