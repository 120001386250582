import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import * as Func from "src/pack/js/func";
import { Config } from "src/app/app-config.service";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ToRPipe } from "src/app/fa.pipe";
import {Check} from '../models';

@Component({
  selector: 'app-checkout-check-edit',
  templateUrl: './checkout-check-edit.component.html',
  styleUrls: ['./checkout-check-edit.component.scss']
})
export class CheckoutCheckEditComponent implements OnInit {
    isWrong: boolean;
    errorMsg: string;
    success: boolean;
    msg: string;
    id: number;
    images: string[] = [];
    error : string = null;
    conf = Config;
    editing_check: Check = new Check();
    load_reports: any[] = [];
    check_states: any[] = [
        ...Array.from(Func.check_states, ([id, label]) => ({
            id,
            label,
        })),
    ];
  checksApi: string = Config.settings.api + 'order/checkout-detail/';

  constructor(
      private http: HttpClient,
      private auth: AuthService,
      private route: ActivatedRoute,
      private router: Router,
      public loc: Location
  ) {}
  user;
  async ngOnInit(): Promise<void> {
    this.user = await this.auth.get_info();
    // await this.load_cars();
    this.load();
  }

  async load() {
    // this.order = [];
    this.route.params.subscribe((params) => {
      if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
        this.router.navigate(["/404"]);
      } else {
        this.id = params.id;
        let checkApi: string =
            this.checksApi +
            `${this.id}/`;
        console.log(checkApi);
        this.http.get(checkApi).subscribe(
            async (response: any) => {
              console.log(response);
              let el = response;
              this.editing_check = new Check(
                  el.id,
                  el.check_number,
                  el.date,
                  el.amount,
                  el.card_number,
                  el.name,
                  el.manager_confirmation,
                  el.expert_confirmation,
                  el.customer_confirmation,
                  el.load_report,
                  el.check_state,
                  el.check_state_date,
                  el.detail,
                  el.image_url_1,
                  el.image_url_2,
              );
              console.log(el.check_state);
              if(el.image_url_1 != null){
                this.images.push(el.image_url_1);
              }
              if(el.image_url_2 != null){
                this.images.push(el.image_url_2);
              }
              await this.load_loadreports(el.order_id);

            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
        );
      }
    });
  }
  remove(i) {
    this.images.splice(i, 1);
  }

    async load_loadreports(order_id) {
        let orderApi: string =
            Config.settings.api + Config.settings.order.order + `${order_id}/`;

        await this.http
            .get(orderApi)
            .toPromise()
            .then((response: any) => {
                console.log(response);
                let c = [];
                c.push({id: null, label: 'خالی'});
                response.load_reports.forEach((el) => {
                    if (el.remittance_number) {
                        c.push({id: el.id, label: el.remittance_number});
                    }
                });
                this.load_reports = c;
            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                if (error.status === 404) {
                    this.router.navigate(['/404']);
                }
                console.log(error);
            });
    }

    add_image(e){
      if(this.images.length < 2){
          const url: string = Config.settings.api + Config.settings.info.file;
          const formData: FormData = new FormData();
          formData.append(
              "file",
              e.target.files.item(0),
              e.target.files.item(0).name
          );
          console.log(formData);
          const headers = { dt: "yes" };
          this.http.post(url, formData, { headers }).subscribe(
              (res: any) => {
                  this.images.push(res.url.slice(1));
              },
              (error: HttpErrorResponse) => {
                  console.log(error);
                  window.scroll(0, 0);
              }
          );
      }else {
          this.success = false;
          this.isWrong = true;
          this.errorMsg = "تعداد عکس ها بیشتر از 2 عدد نمی تواند باشد!";
          window.scroll(0, 0);
      }

    }
  register(form){
      let data = form.value;
      this.edit(data);
      let url = this.checksApi + this.editing_check.id + '/';
      this.http
          .patch(
              this.checksApi + this.editing_check.id + '/',
              this.editing_check
          )
          .subscribe(
              (response: any) => {
                  this.success = true;
                  this.isWrong = false;
                  location.reload();
                  this.msg = "عملیات با موفقیت انجام شد";
                  window.scroll(0, 0);
                  this.images = [];
              },
              (error: HttpErrorResponse) => {
                  this.success = false;
                  this.isWrong = true;
                  this.errorMsg = "عملیات با خطا مواجه شده است";
                  this.images = [];
                  window.scroll(0, 0);
                  this.load();
              }
          );
  }

  async edit(data){
      if(this.images[0] == null){
          this.editing_check.image_url_1 = null;
      }else {
          this.editing_check.image_url_1 = this.images[0];
      }
      if(this.images[1] == null){
          this.editing_check.image_url_2 = null;
      }else {
          this.editing_check.image_url_2 = this.images[1];
      }
      this.editing_check.check_number === data.check_number;
      this.editing_check.load_report === data.load_report;
      this.editing_check.card_number === data.card_number;
      this.editing_check.date === data.date;
      this.editing_check.amountt === data.amountt;
      this.editing_check.check_state === data.check_state;
      this.editing_check.name === data.name;
      this.editing_check.check_state_date === data.check_state_date;
      if(data.detail == null){
          this.editing_check.detail = null;
      }else {
          this.editing_check.detail = data.detail;
      }
      if((data.manager_confirmation === undefined || data.manager_confirmation === false)&& this.editing_check.manager_confirmation === false){
          this.editing_check.manager_confirmation === false;
      }else {
          this.editing_check.manager_confirmation === true;
      }
      if((data.customer_confirmation === undefined || data.customer_confirmation === false) && this.editing_check.customer_confirmation === false){
          this.editing_check.customer_confirmation === false;
      }else {
          this.editing_check.customer_confirmation === true;
      }
      if((data.expert_confirmation === undefined || data.expert_confirmation === false )&&  this.editing_check.expert_confirmation === false){
          this.editing_check.expert_confirmation === false;
      }else {
          this.editing_check.expert_confirmation === true;
      }
  }

}
