<aside class="border rounded">
    <div class="d-block p-3 position-relative profile">
        <!-- <span class="text-primary">پروفایل ادمین</span> -->
        <div class="row mt-3">
            <label
            ><input
                    type="file"
                    (change)="change_image($event)"
                    style="opacity: 0; display: none"/>

                <img
                        class="rounded-circle profile-image"
                        [src]="
            user?.image_url
              ? conf.settings.back + user.image_url
              : '../../../../assets/img/avatar.jpg'
          "
                /></label>
            <div class="col-7 profile-info">
                <h6></h6>
                <span class="d-block mb-2">{{ user?.role_name }}</span>
                <!-- <div class="d-flex">
                       {{ user?.phone_number?.slice(1) }}
                       <div *ngIf="user?.phone_number" class="text-primary mr-1">
                          98+
                       </div>
                    </div> -->
            </div>
        </div>

        <div class="row">
            <div class="col-12 profile-row py-3">
                <div>{{ user?.first_name + " " + user?.last_name }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 profile-row py-3 d-flex">
                <span>{{ user?.phone_number?.slice(1) }}</span>
                <div class="text-primary mr-1">98+</div>
            </div>
        </div>
        <div
                *ngIf="user && (user.role == 2 || user.role == 3 || user.role == 4)"
                class="row text-primary font-weight-bold"
        >
            <div class="col-6 profile-row py-3">
                <span>سقف اعتبار</span>
            </div>
            <div class="col-5 text-left">
        <span class="d-block w-100 py-3">{{
            user?.max_credit | toR
            }}</span>
            </div>
        </div>
        <div class="row text-primary font-weight-bold"
        >
            <div class="col-6 profile-row py-3">
                <span>کد مشتری</span>
            </div>
            <div class="col-5 text-left">
        <span class="d-block w-100 py-3">{{
            user?.code
            }}</span>
            </div>
        </div>

    </div>
    <div
            [routerLink]="['/userArea/dashboard']"
            class="navbar-item"
            routerLinkActive="text-primary"
            [routerLinkActiveOptions]="{ exact: true }"
    >
        داشبورد
    </div>

    <div
            *ngIf="user"
            class="navbar-item"
            [routerLink]="
      user.role == 2 || user.role == 3 || user.role == 4
        ? ['customer', id]
        : ['personnel', id]
    "
            routerLinkActive="text-primary"
            [routerLinkActiveOptions]="{ exact: true }"
    >
        حساب کاربری
    </div>

    <!-- Pessonels -->

    <div *ngIf="auth.permissions.personnel">
        <div
                class="navbar-item"
                (click)="open(0)"
                [attr.aria-expanded]="show[0]"
                aria-controls="collapse0"
                [class.open]="show[0]"
        >
            کاربران سازمان
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>
        <div class="menu" id="collapse0" [ngbCollapse]="!show[0]">
            <div
                    *ngIf="auth.permissions.personnel_list"
                    [routerLink]="['personnel']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                لیست کاربران
            </div>
            <div
                    *ngIf="auth.permissions.personnel_create"
                    [routerLink]="['personnel/new']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                تعریف کاربران جدید
            </div>
        </div>
    </div>

    <!-- Customers -->

    <div *ngIf="auth.permissions.customer">
        <div
                class="navbar-item"
                (click)="open(1)"
                [attr.aria-expanded]="show[1]"
                aria-controls="collapse1"
                [class.open]="show[1]"
        >
            مشتریان سازمان
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>
        <div class="menu" id="collapse1" [ngbCollapse]="!show[1]">
            <div
                    *ngIf="auth.permissions.customer_list"
                    [routerLink]="['customer']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                لیست مشتریان
            </div>
            <div
                    *ngIf="auth.permissions.customer_list"
                    [routerLink]="['customer', 'today']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                لیست مشتریان جدید
            </div>
            <div
                    *ngIf="auth.permissions.customer_create"
                    [routerLink]="['customer/new']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                تعریف کاربران جدید
            </div>
        </div>
    </div>

    <!-- Order -->

    <div *ngIf="auth.permissions.order">
        <div
                class="navbar-item"
                (click)="open(2)"
                [attr.aria-expanded]="show[2]"
                aria-controls="collapse2"
                [class.open]="show[2]"
        >
            سفارشات مشتریان
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>
        <div class="menu" id="collapse2" [ngbCollapse]="!show[2]">
            <div
                    *ngIf="user.role != 2 && user.role != 3"
                    class="navbar-submenu"
            >
                سفارشات شرکت
            </div>
            <div
                    *ngIf="auth.permissions.order_list"
                    [routerLink]="['order']"
                    class="navbar-item"
                    [queryParams]="{ in_company: 1 }"
                    [class.text-primary]="
          active(
            'order',
            { in_company: 1 },
            { start_date: [threeDaysAgo], state: ['4', '6'] }
          )
        "
            >
                لیست سفارشات
            </div>
            <div
                    *ngIf="auth.permissions.order_list"
                    [routerLink]="['order']"
                    [queryParams]="{ state: 4, in_company: 1 }"
                    class="navbar-item"
                    [class.text-primary]="
          active('order', { state: 4, in_company: 1 })
        "
            >
                لیست سفارشات آماده ارسال
            </div>
            <div
                    *ngIf="auth.permissions.order_list"
                    [routerLink]="['order']"
                    [queryParams]="{ start_date: threeDaysAgo, in_company: 1 }"
                    class="navbar-item"
                    [class.text-primary]="
          active('order', { start_date: threeDaysAgo, in_company: 1 })
        "
            >
                لیست سفارشات جدید
            </div>
            <div
                    *ngIf="auth.permissions.order_create"
                    [routerLink]="['order/new']"
                    class="navbar-item"
                    [class.text-primary]="active('order/new', {}, { rep: ['1'] })"
            >
                سفارش جدید
            </div>

            <div
                    *ngIf="user.role != 2 && user.role != 3"
                    class="navbar-submenu"
            >
                سفارشات زیرمجموعه
            </div>
            <div
                    *ngIf="
          auth.permissions.order_list && user.role != 2 && user.role != 3
        "
                    [routerLink]="['order']"
                    class="navbar-item"
                    [class.text-primary]="
          active(
            'order',
            { in_company: 0 },
            { start_date: [threeDaysAgo], state: ['4', '6'] }
          )
        "
                    [queryParams]="{ in_company: 0 }"
            >
                لیست سفارشات زیرمجموعه
            </div>
            <div
                    *ngIf="
          auth.permissions.order_list && user.role != 2 && user.role != 3
        "
                    class="navbar-item"
                    [routerLink]="['order']"
                    [queryParams]="{ state: 4, in_company: 0 }"
                    [class.text-primary]="
          active('order', { state: 4, in_company: 0 })
        "
            >
                لیست سفارشات آماده ارسال
            </div>
            <div
                    *ngIf="
          auth.permissions.order_list && user.role != 2 && user.role != 3
        "
                    class="navbar-item"
                    [routerLink]="['order']"
                    [queryParams]="{ start_date: threeDaysAgo, in_company: 0 }"
                    [class.text-primary]="
          active('order', { start_date: threeDaysAgo, in_company: 0 })
        "
            >
                لیست سفارشات جدید
            </div>
            <div
                    *ngIf="
          auth.permissions.order_create &&
          user.role != 2 &&
          user.role != 3
        "
                    [routerLink]="['order/new']"
                    [queryParams]="{ rep: 1 }"
                    class="navbar-item"
                    [class.text-primary]="active('order/new', { rep: 1 })"
            >
                سفارش جدید
            </div>
            <!-- <div
            *ngIf="auth.permissions.order_create"
            [routerLink]="['order/new']"
            class="navbar-item"
            [class.text-primary]="active('order/new', {})"
            >
              سفارش جدید
            </div> -->
        </div>
    </div>

    <!-- Shipping -->

    <div *ngIf="auth.permissions.shipping">
        <div
                class="navbar-item"
                (click)="open(3)"
                [attr.aria-expanded]="show[3]"
                aria-controls="collapse3"
                [class.open]="show[3]"
        >
            سفارشات ارسال شده
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>
        <div class="menu" id="collapse3" [ngbCollapse]="!show[3]">
            <div
                    *ngIf="user.role != 2 && user.role != 3"
                    class="navbar-submenu"
            >
                سفارشات نماینده
            </div>
            <div
                    *ngIf="auth.permissions.shipping_list"
                    [routerLink]="['shipping']"
                    class="navbar-item"
                    [queryParams]="{ in_company: 1 }"
                    [class.text-primary]="
          active(
            'shipping',
            { in_company: 1 },
            {
              sending_start_date: [threeDaysAgo],
              is_received: ['0', '1']
            }
          )
        "
            >
                لیست سفارشات ارسال شده
            </div>
            <div
                    *ngIf="auth.permissions.order_list"
                    [routerLink]="['order']"
                    [queryParams]="{ state: 6, in_company: 1 }"
                    class="navbar-item"
                    [class.text-primary]="
          active('order', { state: 6, in_company: 1 })
        "
            >
                لیست سفارشات تکمیل نشده
            </div>
            <div
                    *ngIf="auth.permissions.shipping_list"
                    [routerLink]="['shipping']"
                    class="navbar-item"
                    [queryParams]="{ is_received: 0, in_company: 1 }"
                    [class.text-primary]="
          active('shipping', { is_received: 0, in_company: 1 })
        "
            >
                لیست سفارشات ارسالی فاقد تایید دریافت
            </div>
            <div
                    *ngIf="auth.permissions.shipping_list"
                    [routerLink]="['shipping']"
                    class="navbar-item"
                    [queryParams]="{
          sending_start_date: threeDaysAgo,
          in_company: 1
        }"
                    [class.text-primary]="
          active('shipping', {
            sending_start_date: threeDaysAgo,
            in_company: 1
          })
        "
            >
                لیست سفارشات ارسالی جدید
            </div>
            <div
                    *ngIf="user.role != 2 && user.role != 3"
                    class="navbar-submenu"
            >
                سفارشات زیر‌مجموعه
            </div>
            <div
                    *ngIf="
          auth.permissions.shipping_list &&
          user.role != 2 &&
          user.role != 3
        "
                    [routerLink]="['shipping']"
                    class="navbar-item"
                    [queryParams]="{ in_company: 0 }"
                    [class.text-primary]="
          active(
            'shipping',
            { in_company: 0 },
            {
              sending_start_date: [threeDaysAgo],
              is_received: ['0', '1']
            }
          )
        "
            >
                لیست سفارشات ارسال شده
            </div>
            <!-- <div
              *ngIf="auth.permissions.shipping_list"
              [routerLink]="['shipping']"
              class="navbar-item"
              [routerLink]="['order']"
              [queryParams]="{ in_company: 0 }"
              [class.text-primary]="active('order', { in_company: 0 })"
            >
              لیست سفارشات ناقص ارسال شده
            </div> -->
            <div
                    *ngIf="
          auth.permissions.order_list && user.role != 2 && user.role != 3
        "
                    [routerLink]="['order']"
                    [queryParams]="{ state: 6, in_company: 0 }"
                    class="navbar-item"
                    [class.text-primary]="
          active('order', { state: 6, in_company: 0 })
        "
            >
                لیست سفارشات تکمیل نشده
            </div>
            <div
                    *ngIf="
          auth.permissions.shipping_list &&
          user.role != 2 &&
          user.role != 3
        "
                    [routerLink]="['shipping']"
                    class="navbar-item"
                    [queryParams]="{
          sending_start_date: threeDaysAgo,
          in_company: 0
        }"
                    [class.text-primary]="
          active('shipping', {
            sending_start_date: threeDaysAgo,
            in_company: 0
          })
        "
            >
                لیست سفارشات ارسالی جدید
            </div>
        </div>
    </div>

    <!-- Checkouts -->

    <div>
        <div
                class="navbar-item"
                (click)="open(4)"
                [attr.aria-expanded]="show[4]"
                aria-controls="collapse4"
                [class.open]="show[4]"
        >
            تسویه حساب
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>
        <div class="menu" id="collapse9" [ngbCollapse]="!show[4]">
            <div
                    *ngIf="auth.permissions.order_list"
                    [routerLink]="['order/checkout']"
                    [queryParams]="{
          start_date: startCurrentYear,
          state:'11,6'
        }"
                    class="navbar-item"
                    [class.text-primary]="active('order/checkout', {})"
            >
                وضعیت تسویه حساب سفارشات
            </div>
            <div
                    [routerLink]="['checkout', 'cashes']"
                    [queryParams]="{
          start_created_at: startCurrentYear
        }"
                    class="navbar-item"
                    [class.text-primary]="
          active(
            'checkout/cashes',
            {},
            {
              start_created_at: [threeDaysAgo]
            }
          )
        "
            >
                لیست پرداخت‌های نقدی
            </div>
            <div
                    [routerLink]="['checkout', 'cashes']"
                    [queryParams]="{
          start_created_at: threeDaysAgo
        }"
                    class="navbar-item"
                    [class.text-primary]="
          active('checkout/cashes', { start_created_at: threeDaysAgo })
        "
            >
                لیست پرداخت‌های سه روز اخیر
            </div>
            <div
                    [routerLink]="['checkout', 'checks']"
                    [queryParams]="{
          start_created_at: startCurrentYear
        }"
                    class="navbar-item"
                    [class.text-primary]="
          active(
            'checkout/checks',
            {},
            {
              start_created_at: [threeDaysAgo],
              check_state: ['2', '3']
            }
          )
        "
            >
                لیست چک‌های ارسالی
            </div>
            <div
                    [routerLink]="['checkout', 'checks']"
                    [queryParams]="{
          start_created_at: threeDaysAgo
        }"
                    class="navbar-item"
                    [class.text-primary]="
          active(
            'checkout/checks',
            { start_created_at: threeDaysAgo },
            { check_state: ['2', '3'] }
          )
        "
            >
                لیست چک‌های سه روز اخیر
            </div>
            <div
                    [routerLink]="['checkout', 'checks']"
                    [queryParams]="{
          check_state: 3
        }"
                    class="navbar-item"
                    [class.text-primary]="
          active(
            'checkout/checks',
            {
              check_state: '3'
            },
            { start_created_at: [threeDaysAgo] }
          )
        "
            >
                لیست چک‌های تعویض شده
            </div>
            <div
                    [routerLink]="['checkout', 'checks']"
                    [queryParams]="{
          check_state: 2
        }"
                    class="navbar-item"
                    [class.text-primary]="
          active(
            'checkout/checks',
            {
              check_state: '2'
            },
            {
              start_created_at: [threeDaysAgo],
              start_check_state_date: [threeDaysAgo]
            }
          )
        "
            >
                لیست چک‌های برگشت شده
            </div>
            <div
                    [routerLink]="['checkout', 'checks']"
                    [queryParams]="{
          start_check_state_date: threeDaysAgo,
          check_state: 5
        }"
                    class="navbar-item"
                    [class.text-primary]="
          active('checkout/checks', {
            start_check_state_date: threeDaysAgo,
            check_state: '5'
          })
        "
            >
                لیست چک های عودت شده
            </div>
            <div
                    [routerLink]="['checkout', 'checks']"
                    [queryParams]="{
          start_check_state_date: threeDaysAgo,
          check_state: 2
        }"
                    class="navbar-item"
                    [class.text-primary]="
          active('checkout/checks', {
            start_check_state_date: threeDaysAgo,
            check_state: '2'
          })
        "
            >
                لیست چک‌های برگشت شده سه روز اخیر
            </div>
        </div>
    </div>
    <div>
        <div
                *ngIf="auth.permissions.order_list"
                [routerLink]="['order/product']"
                [queryParams]="{

        }"
                class="navbar-item"
                [class.text-primary]="active('order/product', {})"
        >
            محصولات
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>

    </div>
    <!-- Evaluatoin -->

    <div *ngIf="auth.permissions.evaluation || auth.permissions.survey">
        <div
                class="navbar-item"
                (click)="open(5)"
                [attr.aria-expanded]="show[5]"
                aria-controls="collapse5"
                [class.open]="show[5]"
        >
            رضایت سنجی
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>
        <div class="menu" id="collapse5" [ngbCollapse]="!show[5]">
            <div
                    *ngIf="auth.permissions.evaluation_list"
                    [routerLink]="['evaluation']"
                    class="navbar-item"
                    [class.text-primary]="active('evaluation', {})"
            >
                لیست رضایت سنجی
            </div>
            <div
                    *ngIf="auth.permissions.survey_list"
                    [routerLink]="['survey']"
                    class="navbar-item"
                    [class.text-primary]="active('survey', {})"
            >
                لیست فرم‌های ارزیابی
            </div>
            <div
                    *ngIf="auth.permissions.survey_create"
                    [routerLink]="['survey/new']"
                    class="navbar-item"
                    [class.text-primary]="active('survey/new', {})"
            >
                تعری فرم ارزیابی رضایت
            </div>
        </div>
    </div>
    <div *ngIf="auth.permissions.showWarehouse">
        <div
                class="navbar-item"
                (click)="open(71)"
                [attr.aria-expanded]="show[71]"
                aria-controls="collapse7"
                [class.open]="show[71]"
        >
            مدیریت انبار فروش استانی
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>
        <div class="menu" id="collapse71" [ngbCollapse]="!show[71]">
            <div
                    [routerLink]="['warehouseOrderList']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                لیست سفارشات انبار
            </div>
            <div
                    [routerLink]="['warehouseOrderList']"
                    [queryParams]="{ start_date: sevenDaysAgo }"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                سفارشات جدید انبار
            </div>
            <div style="padding-bottom: 0px;margin--bottom: 0px;"
                 [routerLink]="['warehouseOrderNew']"
                 class="navbar-item"
                 routerLinkActive="text-primary"
                 [routerLinkActiveOptions]="{ exact: true }"
            >
                ثبت سفارش جدید
            </div>
            <div style="padding: 0px;margin: 0px;">
                <hr>
            </div>
            <div style="padding-top: 0px;margin-top: 0px;"
                 [routerLink]="['WarehouseProductOrder']"
                 class="navbar-item"
                 routerLinkActive="text-primary"
                 [routerLinkActiveOptions]="{ exact: true }"
            >
                وضعیت موجودی انبار
            </div>
            <div
                    [routerLink]="['order']"
                    [queryParams]="{  source: 2 ,state: '11'}"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                لیست خروجی های انبار
            </div>
            <div
                    [routerLink]="['order']"
                    [queryParams]="{  source: 2 ,state: '11',start_date: sevenDaysAgo }"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                خروجی های جدید انبار
            </div>
        </div>
    </div>
    <div *ngIf="auth.permissions.reporter">
        <div
                class="navbar-item"
                (click)="open(72)"
                [attr.aria-expanded]="show[72]"
                aria-controls="collapse72"
                [class.open]="show[72]"
        >
            گزارشات
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>
        <div class="menu" id="collapse72" [ngbCollapse]="!show[72]">
            <div
                    [routerLink]="['reportList']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
            >
                لیست گزارشات
            </div>
            <div *ngIf="auth.permissions.reporterNew"
                 [routerLink]="['reportNew']"
                 class="navbar-item"
                 routerLinkActive="text-primary"
                 [routerLinkActiveOptions]="{exact: true}"
            >
                ثبت گزارش جدید
            </div>
            <div style="padding: 0px;margin: 0px;">
                <hr>
            </div>
            <div
                    [routerLink]="['reportList']"
                    class="navbar-item"
                    [queryParams]="{start_created_at: threeDaysAgo}"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
            >
                لیست گزارشات اخیر
            </div>
            <div
                    [routerLink]="['reportList']"
                    class="navbar-item"
                    [queryParams]="{start_due_or_completion_date: oneStartDate , end_due_or_completion_date:onnEndDate}"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{exact: true}"
            >
                کارتابل فعالیت‌های روزانه
            </div>
        </div>
    </div>
    <div *ngIf="auth.permissions.information">
        <div
                class="navbar-item"
                (click)="open(7)"
                [attr.aria-expanded]="show[7]"
                aria-controls="collapse7"
                [class.open]="show[7]"
        >
            اطلاعات پایه
            <i class="far fa-chevron-left float-left align-middle ml-2"></i>
        </div>
        <div class="menu" id="collapse7" [ngbCollapse]="!show[7]">
            <div
                    [routerLink]="['product']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                لیست محصولات
            </div>
            <div
                    [routerLink]="['region']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                مناطق کاری
            </div>
            <div
                    [routerLink]="['province']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                استان‌ها
            </div>
            <div
                    [routerLink]="['city']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                شهرستان‌ها
            </div>
            <div
                    [routerLink]="['unit']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                واحدها
            </div>
            <div
                    [routerLink]="['role']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                نقش‌ها
            </div>
            <div
                    [routerLink]="['bankAccount']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                حساب‌‌های بانکی
            </div>
            <div
                    [routerLink]="['product/information']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                اطلاعات مربوط به محصولات
            </div>
            <div
                    [routerLink]="['warehouse']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                انبار های استانی
            </div>
            <div
                    [routerLink]="['customer_information_list']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                اطلاعات مربوط به حساب کاربری مشتریان
            </div>
            <div
                    [routerLink]="['familiarity_type']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                نحوه آشنایی
            </div>
            <div
                    [routerLink]="['report-information-list']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                گزارشات
            </div>
<!--            <div-->
<!--                    [routerLink]="['pallet-list']"-->
<!--                    class="navbar-item"-->
<!--                    routerLinkActive="text-primary"-->
<!--                    [routerLinkActiveOptions]="{ exact: true }"-->
<!--            >-->
<!--               پالت-->
<!--            </div>-->
            <div
                    [routerLink]="['sheet-list']"
                    class="navbar-item"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: true }"
            >
                ورق و پالت
            </div>

        </div>
    </div>
</aside>
