<div class="sh-collapse">
    <div (click)="toggleCollpse($event)" class="sh-collapse-header {{collapsed ? 'sh-state-collapsed' : ''}}">
        <div class="sh-collapse-button-div">
            <div class="sh-collapse-button" ><i class="fal fa-angle-down"></i></div>
        </div>
        <div class="sh-collapse-title-div">
            <span class="sh-collapse-title">{{caption}}</span>
        </div>
        
        
    </div>
    <div class="sh-collapse-body" *ngIf="!collapsed">
        <ng-content></ng-content>
    </div>
</div>
