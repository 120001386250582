import {Component, Input, OnInit} from '@angular/core';
import {Config} from "../../../app-config.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../auth/auth.service";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoadingService} from "../../../loading/loading.service";
import {FormControl} from "@angular/forms";
import * as Func from "../../../../pack/js/func";
import {debounceTime} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {Subject} from "rxjs";

@Component({
    selector: 'app-product-order',
    templateUrl: './product-order.component.html',
    styleUrls: ['./product-order.component.scss']
})
export class ProductOrderComponent implements OnInit {

    @Input()
    customer_id?: number;

    showTitle = true;
    page_number: number = 1;
    object_count: number = 0;
    page_count: number = 0;

    usersApi: string = Config.settings.api + Config.settings.user.user;

    isCollapsed = true;

    orders: any[] = [];
    orgOrders: any[] = [];
    searchControl = new FormControl();
    selected: boolean[] = [];
    ordersApi: string = Config.settings.api + "order/order-detail";
    province_change: Subject<void> = new Subject<void>();
    func = Func;
    searchTerm: string;
    sheet_sum: number = 0;
    page_sheet_sum: number = 0;
    page_size: any;
    is_today: boolean;

    filter_items = [
        {
            name: "product_id",
            label: "نام محصول",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "product_type_id",
            label: "نوع محصول",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "product_brand_id",
            label: "برند محصول",
            type: "list",
            options: [],
            res: null,
        },

        {
            name: "product_degree_id",
            label: "درجه‌ی محصول",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "product_thickness_id",
            label: "ضخامت محصول",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "product_dimension_id",
            label: "ابعاد محصول",
            type: "list",
            options: [],
            res: null,
        },

        {
            name: "order_id",
            label: "شماره سفارش",
            type: "string",
        },
        {
            name: "order_invoice_number",
            label: "شماره پیش فاکتور",
            type: "string",
        },
        {
            name: "start_order_date",
            label: "تاریخ سفارش از",
            type: "date",
        },
        {
            name: "end_order_date",
            label: "تاریخ سفارش تا",
            type: "date",
        },
        {
            name: "company_name",
            label: "شرکت خریدار",
            type: "string",
        },
        {
            name: "province_id",
            label: "استان خریدار",
            type: "list",
            options: [],
            res: null,
            onChange: this.province_change,
        },
        {
            name: "city_id",
            label: "شهر خریدار",
            type: "list",
            options: [],
            res: null,
        },
        {
            name: "region_id",
            label: "منطقه خریدار",
            type: "list",
            options: [],
            res: null,
        }
    ];

    constructor(
        private auth: AuthService,
        private router: Router,
        private http: HttpClient,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private loading: LoadingService
    ) {
    }

    can_del = true;
    user;

    async ngOnInit(): Promise<void> {
        if (this.customer_id != null){
            this.showTitle = false;
        }
        this.user = await this.auth.get_info();

        this.route.queryParamMap.subscribe((f) => {
            let filter = {};
            f.keys.forEach((k) => (filter[k] = f.get(k)));

            this.filter_items.forEach(
                (el) => (el.res = filter[el.name] ? filter[el.name] : null)
            );
            this.search(filter);
        });

        this.searchControl.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe((term) => this.search(term));



        this.loadProduct();
        this.loadProductType();
        this.loadProductBrand();
        this.loadProductDegree();
        this.loadProductThickness();
        this.loadProductDimension();

        this.load_pro();
        this.load_reg();

        this.changeProvince();
        this.province_change.subscribe((pro) => this.changeProvince(pro));

    }

    disable_load = false;

    load(page = 1) {
        if (this.disable_load) return;
        this.disable_load = true;
        this.loading.add();

        console.log(this.ordersApi);
        if (this.customer_id !== null && this.customer_id !== undefined){
            this.searchTerm =  this.searchTerm + "&buyer_id=" + this.customer_id ;
        }
        this.orgOrders = [];
        this.http
            .get(
                this.ordersApi +
                `?page=${page}&${this.searchTerm}&ordering=${this.sortcol}`
            )
            .subscribe(
                (response: any) => {
                    console.log(response);
                    this.page_count = response.page_count;
                    this.object_count = response.object_count;
                    this.page_number = response.page_number;
                    this.page_size = response.page_size;
                    this.sheet_sum = response.total_sheet_sum;
                    this.page_sheet_sum = response.page_sheet_sum;

                    response.results.forEach((element) => {
                        this.orgOrders.push(element);
                    });
                    this.orders = this.orgOrders;

                    this.loading.remove();
                    this.disable_load = false;
                },
                (error: HttpErrorResponse) => {
                    //TODO: server errors
                    console.log(error);
                    this.orgOrders = [];
                    this.orders = [];
                    this.page_count = 0;
                    this.page_number = 0;
                    this.object_count = 0;
                    this.sheet_sum = 0;
                    this.page_sheet_sum = 0;
                    this.loading.remove();

                    this.disable_load = false;
                }
            );
    }

    loadProduct() {
        let tc = [{id: null, label: "همه"}];

        this.http.get(Config.settings.api + "information/product").subscribe(
            (response: any) => {
                response.forEach((element) => {
                    if (element.name)
                        tc.push({
                            id: element.id,
                            label: element.name,
                        });
                });
                // this.filter_items[4].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "product_id") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    loadProductType() {
        let tc = [{id: null, label: "همه"}];

        this.http.get(Config.settings.api + "information/product-type").subscribe(
            (response: any) => {
                response.forEach((element) => {
                    if (element.name)
                        tc.push({
                            id: element.id,
                            label: element.name,
                        });
                });
                // this.filter_items[4].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "product_type_id") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    loadProductBrand() {
        let tc = [{id: null, label: "همه"}];

        this.http.get(Config.settings.api + "information/product-brand").subscribe(
            (response: any) => {
                response.forEach((element) => {
                    if (element.name)
                        tc.push({
                            id: element.id,
                            label: element.name,
                        });
                });
                // this.filter_items[4].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "product_brand_id") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    loadProductDegree() {
        let tc = [{id: null, label: "همه"}];

        this.http.get(Config.settings.api + "information/product-degree").subscribe(
            (response: any) => {
                response.forEach((element) => {
                    if (element.degree)
                        tc.push({
                            id: element.id,
                            label: element.degree,
                        });
                });
                // this.filter_items[4].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "product_degree_id") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    loadProductThickness() {
        let tc = [{id: null, label: "همه"}];

        this.http.get(Config.settings.api + "information/product-thickness").subscribe(
            (response: any) => {
                response.forEach((element) => {
                    if (element.thickness)
                        tc.push({
                            id: element.id,
                            label: element.thickness,
                        });
                });
                // this.filter_items[4].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "product_thickness_id") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    loadProductDimension() {
        let tc = [{id: null, label: "همه"}];

        this.http.get(Config.settings.api + "information/product-dimension").subscribe(
            (response: any) => {
                response.forEach((element) => {
                    if (element.length )
                        tc.push({
                            id: element.id,
                            label: element.length + ' * '  + element.width
                        });
                });

                // this.filter_items[4].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "product_dimension_id") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }


    async load_pro() {
        let tc = [{id: null, label: "همه"}];
        // let tc = [{ id: null, label: 'همه' }];

        let provinceApi: string =
            Config.settings.api + Config.settings.info.province;
        this.http.get(provinceApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.name,
                    });
                });
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "province_id") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    async load_reg() {
        let tc = [{id: null, label: "همه"}];
        // let tc = [{ id: null, label: 'همه' }];

        let regionApi: string =
            Config.settings.api + Config.settings.add.region;
        this.http.get(regionApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.name,
                    });
                });
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "region_id") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    province_id = null;

    changeProvince($event = null) {
        this.province_id = $event;

        let tc = [{ id: null, label: "همه" }];
        // let tc = [{ id: null, label: 'همه' }];
        if (this.province_id == null) {
            this.filter_items.map((el) => {
                let ret = el;
                if (el.name == "city_id") ret.options = tc;
                if (el.name == "city_id") ret.res = null;
                return ret;
            });
            return;
        }

        let cityApi: string =
            Config.settings.api +
            Config.settings.add.citys +
            `?province=${$event}`;
        this.http.get(cityApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.name,
                    });
                });
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == "city") ret.options = tc;
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
        console.log("salam");
    }


    search(filter: any) {
        console.log(filter);

        this.searchTerm = "";
        Object.keys(filter).forEach((el) => {
            this.searchTerm += el + "=" + filter[el] + "&";
            // console.log(el);
        });

        if (this.searchTerm != "")
            this.searchTerm = this.searchTerm.slice(0, -1);

        let qp = filter;
        // qp['q'] = this.searchTerm;

        this.router.navigate([], {
            queryParams: qp,
            // queryParamsHandling: "merge",
        });
        this.load();
        this.isCollapsed = true;
    }



    sortcol: string = null;

    sort(col: string) {
        if (this.sortcol == col) this.sortcol = "-" + col;
        else this.sortcol = col;
        this.load();
    }

    ngOnDestroy() {
    }
}

