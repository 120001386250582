<div class="m-3 mt-4">
    <app-title  title="لیست گزارشات" *ngIf="showTitle"></app-title>
    <div class="list my-3 mx-auto">
        <div class="list-header">
            <div class="list-header-item cursor-pointer" [routerLink]="['/userArea/reportNew' ]"
                 *ngIf="auth.permissions.reporterNew">
                <i class="far fa-plus-square"></i>جدید
            </div>
            <div class="list-header-item cursor-pointer" (click)="load()">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div
                    class="list-header-item mr-auto cursor-pointer"
                    (click)="isCollapsed = !isCollapsed"
            >
                <i class="far fa-file-search"></i>فیلتر
            </div>
        </div>
                <app-filter
                        [isCollapsed]="isCollapsed"
                        [filter_items]="filter_items"
                        (changeFilter)="search($event)"
                ></app-filter>

        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th>#</th>
                <th scope="col" (click)="sort('id')">
                    شماره فعالیت
                </th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('activity_title')">نوع  فعالیت</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('type_name')">موضوع فعالیت</th>
                <th scope="col" (click)="sort('addressee_type_name')">دسته بندی</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('addressee_first_name')">نام مخاطب</th>
                <th scope="col" (click)="sort('activity_type_name')">نوع فعالیت</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('action_type_name')">نوع اقدام</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('creator_first_name')">ایجاد کننده</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('created_at')">تاریخ ایجاد</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('due_date')">مهلت اقدام</th>
                <th scope="col" (click)="sort('completion_date')">تاریخ تکمیل</th>
                <th scope="col" (click)="sort('state')">وضعیت</th>
                <th scope="col" *ngIf="auth.permissions.reporterEdit">ویرایش</th>
                <th scope="col" *ngIf="auth.permissions.reporterDelete">x</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of reports; let i = index" >
                <th scope="row"  [ngStyle]="{background : el.color}">{{i + 1}}</th>
                <th scope="col" [ngStyle]="{background : el.color}">
                    {{ el.id }}
                </th>
                <th class="d-lg-table-cell d-none" [ngStyle]="{background : el.color}">{{el.type_name}}</th>
                <td class="d-lg-table-cell d-none" [ngStyle]="{background : el.color}">
                    {{ el.activity_title }}
                </td>
                <td [ngStyle]="{background : el.color}">
                    {{ el.addressee_type_name }}
                </td>
                <td class="d-lg-table-cell d-none" [ngStyle]="{background : el.color}">
                    {{ el.addressee_company_name === null || el.addressee_company_name === '' ? el.addressee_first_name + ' ' + el.addressee_last_name : el.addressee_company_name }}
                </td>
                <td [ngStyle]="{background : el.color}">
                    {{ el.activity_type_name  }}
                </td>
                <td class="d-lg-table-cell d-none" [ngStyle]="{background : el.color}">
                    {{ el.action_type_name }}
                </td>
                <td class="d-lg-table-cell d-none" [ngStyle]="{background : el.color}">
                    {{ el.creator_last_name + ' ' + el.creator_first_name }}
                </td>
                <td class="d-lg-table-cell d-none" [ngStyle]="{background : el.color}">{{ el.created_at | jDate | toFa }}</td>
                <td class="d-lg-table-cell d-none" [ngStyle]="{background : el.color}">{{ el.due_date | jDate | toFa }}</td>
                <td [ngStyle]="{background : el.color}">{{ el.completion_date | jDate | toFa }}</td>
                <td [ngStyle]="{background : el.color}">
                    {{ el.state_name  }}
                </td>
                <td class="cursor-pointer" [routerLink]="['/userArea/reportEdit/' + el.id]"
                    *ngIf="auth.permissions.reporterEdit" [ngStyle]="{background : el.color}">
                    <i class="fal fa-edit"></i>
                </td>
                <td class="cursor-pointer " *ngIf="auth.permissions.reporterDelete" [ngStyle]="{background : el.color}">
                    <i class="fal fa-trash-alt" (click)="del(content, el.id)"></i>
                </td>
            </tr>
            </tbody>
        </table>
        <div *ngIf="reports.length==0"
             style="width: 100%;
    height: 30px;
    text-align: center;padding-top: 7px;">
            <h5>
                اطلاعاتی یافت نشد
            </h5>

        </div>
    </div>
    <div *ngIf="reports.length!=0" class="d-flex justify-content-center">
        <app-pagination
                [page_count]="page_count"
                [page_number]="page_number"
                (changePage)="load($event)"
        ></app-pagination>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            آیا از حذف این رکورد اطمینان دارید؟
        </h4>
    </div>
    <div class="modal-body">
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(true)"
        >
            بله
        </button>
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(false)"
        >
            خیر
        </button>
    </div>
</ng-template>
