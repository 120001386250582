<div class="m-3 mt-4">
  <ngb-tabset [destroyOnHide]="false">
    <ngb-tab title="برندها">
      <ng-template ngbTabContent>
        <app-product-brand-list></app-product-brand-list>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="درجه بندی محصولات">
      <ng-template ngbTabContent>
        <app-product-degree-list></app-product-degree-list>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="ابعاد">
      <ng-template ngbTabContent>
        <app-product-dimension-list></app-product-dimension-list>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="تامین کننده‌ ها">
      <ng-template ngbTabContent>
        <app-product-supplier-list></app-product-supplier-list>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="ضخامت">
      <ng-template ngbTabContent>
        <app-product-thickness-list></app-product-thickness-list>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="نوع محصول">
      <ng-template ngbTabContent>
        <app-product-type-list></app-product-type-list>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="واحد اندازه گیری">
      <ng-template ngbTabContent>
        <app-product-unit-list></app-product-unit-list>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
