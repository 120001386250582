import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Config } from "../app-config.service";

import { timeout } from "rxjs/operators";
import { LoadingService } from "../loading/loading.service";
import { UserPermissions } from "./user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // apiUrl = 'http://127.0.0.1:8000/api/';

  id: number = null;
  user = null;
  permissions = new UserPermissions();

  constructor(
    private router: Router,
    private http: HttpClient,
    private loading: LoadingService
  ) {}

  async get_info() {
    if (localStorage.getItem("token")) {
      if (this.user) return this.user;

      if (!this.id) {
        this.loading.add();
        await this.http
          .get(Config.settings.api + Config.settings.user.my_id)
          .pipe(timeout(20000))
          .toPromise()
          .then((res: any) => {
            this.id = res.id;
          })
          .catch((err) => {
            // this.router.navigate(['/300']);
            console.log("here:");
            console.log(err);
          });
        this.loading.remove();
      }
      if (!this.id) return null;

      this.loading.add();
      this.user = await this.http
        .get(
          Config.settings.api + Config.settings.user.user + `${this.id}/`
        )
        .pipe(timeout(10000))
        .toPromise()
        .catch((err) => {
          // this.router.navigate(['/300']);
        });
      this.user.permission_codes.map((x) => parseInt(x));
      this.permissions = this.create_premissions(
        this.user.permission_codes
      );
      this.loading.remove();
      return this.user;
    } else {
      return null;
    }
  }

  login(model: any) {
    return this.http.post(
      Config.settings.api + Config.settings.add.usernameLogin,
      JSON.stringify(model)
    );
  }

  phoneLogin(model: any) {
    let data: any = {
      tracking_code: model.trackingCode,
      code: model.validationCode,
    };
    console.log(data);
    return this.http.post(
      Config.settings.api + Config.settings.auth.code_validation,
      JSON.stringify(data)
    );
  }

  phone_validate(model: any) {
    let data: any = {
      tracking_code: model.trackingCode,
      code: model.validationCode,
    };
    console.log(data);
    return this.http.post(
      Config.settings.api + Config.settings.info.phone.validate,
      JSON.stringify(data)
    );
  }

  phone_register(phone) {
    let data = {
      phone_number: phone,
    };
    console.log(JSON.stringify(data));
    return this.http.post(
      Config.settings.api + Config.settings.info.phone.register,
      JSON.stringify(data)
    );
  }

  loginPhoneValidation(phone) {
    let data = {
      phone_number: phone,
    };
    console.log(JSON.stringify(data));
    return this.http.post(
      Config.settings.api + Config.settings.auth.phone_login,
      JSON.stringify(data)
    );
  }

  register(model: any) {
    let data: any = {
      city: model.state,
      first_name: model.firstname,
      last_name: model.lastname,
      company_name: model.companyName,
      phone_number: model.phone,
      type: model.lstate,
    };
    console.log(data);
    return this.http.post(
      Config.settings.api + Config.settings.user.user,
      JSON.stringify(data)
    );
  }

  logout() {
    this.http
      .post(Config.settings.api + Config.settings.add.signout, "")
      .subscribe(
        (response: any) => {
          localStorage.removeItem("token");
          this.user = null;
          this.id = null;
          this.router.navigate(["/auth"]);
        },
        (error: HttpErrorResponse) => {}
      );
  }

  create_premissions(permission_codes: number[]): UserPermissions {
    let res = new UserPermissions();
    res.showWarehouse = permission_codes.includes(190) || permission_codes.includes(192) || permission_codes.includes(193) || permission_codes.includes(194);
    res.showDashboardWarehouse = permission_codes.includes(210) || permission_codes.includes(211) ;
    res.personnel_list = permission_codes.includes(110);
    res.personnel_create = permission_codes.includes(111);
    res.personnel_edit = permission_codes.includes(112);
    res.personnel_delete = permission_codes.includes(113);
    res.personnel = res.personnel_list || res.personnel_create;

    res.customer_list =
      permission_codes.includes(100) || permission_codes.includes(101);
    res.customer_create = permission_codes.includes(102);
    res.customer_edit = permission_codes.includes(103);
    res.customer_delete = permission_codes.includes(104);
    res.customer = res.customer_list || res.customer_create;

    res.order_list =
      permission_codes.includes(130) || permission_codes.includes(131);
    res.order_create = permission_codes.includes(132);
    res.order_edit = permission_codes.includes(133);
    res.order_delete = permission_codes.includes(134);
    res.order_edit_status = permission_codes.includes(135);
    res.order = res.order_list || res.order_create;

    res.shipping_list =
      permission_codes.includes(140) || permission_codes.includes(141);
    res.shipping_create = permission_codes.includes(142);
    res.shipping_edit = permission_codes.includes(143);
    res.shipping_delete = permission_codes.includes(144);
    res.shipping = res.shipping_list || res.shipping_create;

    res.evaluation_list =
      permission_codes.includes(160) || permission_codes.includes(161);
    res.evaluation_create = permission_codes.includes(162);
    res.evaluation_edit = permission_codes.includes(163);
    res.evaluation_delete = permission_codes.includes(164);
    res.evaluation = res.evaluation_list || res.evaluation_create;

    res.survey_list = permission_codes.includes(150);
    res.survey_create = permission_codes.includes(151);
    res.survey_edit = permission_codes.includes(151);
    res.survey_delete = permission_codes.includes(151);
    res.survey = res.survey_list || res.survey_create;

    res.information = permission_codes.includes(121);
    res.reporter = permission_codes.includes(220);
    res.reporterNew = permission_codes.includes(221);
    res.reporterEdit = permission_codes.includes(222);
    res.reporterDelete = permission_codes.includes(223);

    return res;
  }
}
