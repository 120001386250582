<div class="m-3 mt-4">
    <div class="list my-3">
        <div class="list-header bg-gray">
            <div class="list-header-item" (click)="show = !show">
                <i class="far fa-plus-square cursor-pointer" (click)="register(registercontent)"></i>جدید
            </div>
            <div class="list-header-item">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div class="list-header-item">تعداد ردیف: {{ familiarityList.length }}</div>
            <div class="list-header-item mr-auto">
                <i class="far fa-file-search"></i
                ><input
                    type="text"
                    class="form-control"
                    [formControl]="searchControl"
            />
            </div>
        </div>
        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col" (click)="func.sort(familiarityList, 'name')">نام</th>
                <th scope="col">ویرایش</th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of familiarityList; let i = index">
                <th scope="row">{{ i + 1 | toFa }}</th>
                <!-- <td>{{ i + 1 | toFa }}</td> -->
                <td>{{ el.name }}</td>

                <td class="cursor-pointer" (click)="edit(editcontent, i)">
                    <i class="fal fa-edit"></i>
                </td>
                <td class="cursor-pointer" (click)="del(content, el.id)">
                    <i class="fal fa-trash-alt"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            آیا از حذف این رکورد اطمینان دارید؟
        </h4>
    </div>
    <div class="modal-body">
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(true)"
        >
            بله
        </button>
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(false)"
        >
            خیر
        </button>
    </div>
</ng-template>

<ng-template #editcontent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-md-12 col-12">
                <label>نام</label>
                <input
                        type="text"
                        [(ngModel)]="editing_brand.name"
                        name="name"
                        class="form-control form-control-lg"
                        required
                />
            </div>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(true)"
            >
                تایید
            </button>
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(false)"
            >
                انصراف
            </button>
        </div>
    </div>
</ng-template>

<ng-template #registercontent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">افزودن</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-md-12 col-12">
                <label>نام</label>
                <input
                        type="text"
                        [(ngModel)]="familiarity.name"
                        name="name"
                        class="form-control form-control-lg"
                        required
                />
            </div>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(true)"
            >
                تایید
            </button>
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(false)"
            >
                انصراف
            </button>
        </div>
    </div>
</ng-template>
