<div class="dropdown">
  <!-- <button type="button" class="" (click)="open(dropdown, origin)" #origin>
      <ng-template [ngIf]="!isOpen" [ngIfElse]="searchTpl">
         {{ label }}
      </ng-template>

      <ng-template #searchTpl>
         <input
            [formControl]="searchControl"
            placeholder="Search.."
            autofocus
            (click)="$event.stopPropagation()"
         />
      </ng-template>
   </button> -->

  <div *ngIf="simple" class="d-flex">
    <input
      type="text"
      class="simple"
      [class.border-success]="green"
      [attr.valid]="label !== '' ? '' : null"
      (focus)="can_edit && open(dropdown, origin)"
      (focus)="can_edit && $event.stopPropagation()"
      (blur)="can_edit && c($event)"
      (ngModelChange)="search($event)"
      #origin
      (keypress)="permissionControl($event)"
      [formControl]="searchControl"
      [placeholder]="label"
    />
    <span
      class="c my-auto fas fa-chevron-down"
      style="color: #4a4a4a; font-size: 0.75rem"
      *ngIf="!isOpen"
    ></span>

    <!-- <span class="textLabels p-0" *ngIf="!isOpen">{{ label }}</span> -->
  </div>
  <div *ngIf="!simple">
    <label>{{ placeholder }}</label>
    <input
      type="text"
      class="form-control form-control-lg"
      [attr.value]="label"
      [attr.valid]="label !== '' ? '' : null"
      (focus)="can_edit && open(dropdown, origin)"
      (focus)="can_edit && $event.stopPropagation()"
      (blur)="can_edit && c($event)"
      (ngModelChange)="search($event)"
      (keypress)="permissionControl($event)"
      #origin
      required
      [formControl]="searchControl"
    />
    <span
      class="textLabel p-0"
      [class.textLabelWithNoph]="!placeholder"
      [class.textLabel]="placeholder"
      *ngIf="!isOpen"
      >{{ label }}</span
    >
    <span
      class="chevron mr-auto fas fa-chevron-down"
      *ngIf="!isOpen && placeholder"
    ></span>
  </div>
  <ng-template #dropdown>
    <div class="select-menu">
      <cdk-virtual-scroll-viewport
        itemSize="2rem"
        class="select-menu-inner"
        [style.height.rem]="
          math.max(1, math.min(visibleOptions, options.length)) * 2
        "
      >
        <div *ngIf="!options.length" class="no-results-item">
          <div class="select-item" *ngIf="originalOptions.length">
            چیزی یافت نشد...
          </div>
          <div class="select-item" *ngIf="!originalOptions.length">
            در حال بارگزاری...
          </div>
        </div>

        <div
          *cdkVirtualFor="let option of options"
          class="select-item"
          [class.active]="isActive(option)"
          (click)="select(option)"
          tabindex="0"
        >
          <ng-template [ngIf]="!optionTpl">{{
            option.label
          }}</ng-template>
          <ng-template
            *ngTemplateOutlet="optionTpl; context: { $implicit: option }"
          ></ng-template>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-template>
</div>
