import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthComponent } from "./auth/auth.component";
import {
  AuthGuard,
  LoadUserArea,
  PageGuard,
  UserAreaGuard,
} from "./auth/auth.guard";
import { PhoneSigninComponent } from "./components/auth/signin/phone-signin/phone-signin.component";
import { SigninComponent } from "./components/auth/signin/signin.component";
import { UsernameSigninComponent } from "./components/auth/signin/username-signin/username-signin.component";
import { SignupComponent } from "./components/auth/signup/signup.component";
import { InternalServerErrorComponent } from "./components/misc/errorPages/internal-server-error/internal-server-error.component";
import { PageNotFoundComponent } from "./components/misc/errorPages/page-not-found/page-not-found.component";
import { DashboardComponent } from "./components/user-area/dashboard/dashboard.component";
import { UserAreaComponent } from "./components/user-area/user-area.component";
import { PersonnelsNewComponent } from "./components/users/personnels/personnels-new/personnels-new.component";
import { PersonnelsListComponent } from "./components/users/personnels/personnels-list/personnels-list.component";
import { CustomerListComponent } from "./components/users/Customers/customer-list/customer-list.component";
import { OrderListComponent } from "./components/orders/orders/order-list/order-list.component";
import { ShippingListComponent } from "./components/orders/shipping/shipping-list/shipping-list.component";
import { OrderNewComponent } from "./components/orders/orders/order-new/order-new.component";
import { OrderEditComponent } from "./components/orders/orders/order-edit/order-edit.component";
import { ShippingNewComponent } from "./components/orders/shipping/shipping-new/shipping-new.component";
import { CheckoutEditComponent } from "./components/orders/checkout/checkout-edit/checkout-edit.component";
import { SurveyNewComponent } from "./components/orders/satisfaction/survey-new/survey-new.component";
import { SurveyWatchComponent } from "./components/orders/satisfaction/survey-watch/survey-watch.component";
import { SurveyListComponent } from "./components/orders/satisfaction/survey-list/survey-list.component";
import { SurveyFormListComponent } from "./components/orders/satisfaction/survey-form-list/survey-form-list.component";
import { SurveyFormNewComponent } from "./components/orders/satisfaction/survey-form-new/survey-form-new.component";
import { SurveyFormEditComponent } from "./components/orders/satisfaction/survey-form-edit/survey-form-edit.component";
import { PersonnelsEditComponent } from "./components/users/personnels/personnels-edit/personnels-edit.component";
import { CustomerNewComponent } from "./components/users/Customers/customer-new/customer-new.component";
import { CheckoutCheckListComponent } from "./components/orders/checkout/checkout-check-list/checkout-check-list.component";
import { CheckoutCheckEditComponent } from "./components/orders/checkout/checkout-check-edit/checkout-check-edit.component";
import { CheckOutCashEditComponent } from "./components/orders/checkout/chekout-chash-edit/check-out-cash-edit.component";
import { CheckoutCashListComponent } from "./components/orders/checkout/checkout-cash-list/checkout-cash-list.component";
import { ContactListComponent } from "./components/users/Customers/contact-list/contact-list.component";
import { PostalAddressListComponent } from "./components/users/Customers/postal-address-list/postal-address-list.component";
import { CPersonnelListComponent } from "./components/users/Customers/cpersonnel-list/cpersonnel-list.component";
import { WorkBackgroundListComponent } from "./components/users/Customers/work-background-list/work-background-list.component";
import { CustomerEditComponent } from "./components/users/Customers/customer-edit/customer-edit.component";
import { CustomerBankAccountListComponent } from "./components/users/Customers/customer-bank-account-list/customer-bank-account-list.component";
import { SocialNetworkListComponent } from "./components/users/Customers/social-network-list/social-network-list.component";
import { OrderCheckoutListComponent } from "./components/orders/orders/order-checkout-list/order-checkout-list.component";
import { CityListComponent } from "./components/information/cities/city-list/city-list.component";
import { ProvinceListComponent } from "./components/information/provinces/province-list/province-list.component";
import { UnitListComponent } from "./components/information/units/unit-list/unit-list.component";
import { RoleListComponent } from "./components/information/roles/role-list/role-list.component";
import { RegionListComponent } from "./components/information/regions/region-list/region-list.component";
import { BankAccountListComponent } from "./components/information/BackAcounts/bank-account-list/bank-account-list.component";
import { ProductListComponent } from "./components/information/Product/product-list/product-list.component";
import { ProductNewComponent } from "./components/information/Product/product-new/product-new.component";
import { ProductInformationListsComponent } from "./components/information/Product/product-information-lists/product-information-lists.component";
import { CustomerProfileComponent } from "./components/users/Customers/customer-profile/customer-profile.component";
import { CustomerInformationListsComponent } from "./components/information/CustomerInformation/customer-information-lists/customer-information-lists.component";
import { ShippingEditComponent } from "./components/orders/shipping/shipping-edit/shipping-edit.component";
import { ProductOrderComponent } from "./components/orders/product-order/product-order.component";
import {WarehouseOrderNewComponent} from "./components/orders/warehouse-orders/warehouse-order-new/warehouse-order-new.component";
import {WarehouseOrderEditComponent} from "./components/orders/warehouse-orders/warehouse-order-edit/warehouse-order-edit.component";
import {WarehouseOrdersListComponent} from "./components/orders/warehouse-orders/warehouse-orders-list/warehouse-orders-list.component";
import {WarehouseProductOrderComponent} from "./components/orders/warehouse-orders/warehouse-product-order/warehouse-product-order.component";
import {WarehouseComponent} from  "./components/information/warehouse/warehouse/warehouse.component";
import {FamiliarityTypeComponent} from './components/information/familiarity-type/familiarity-type.component';
import {ReportInformationListComponent} from "./components/information/report/report-information-list/report-information-list.component";
import {ReportNewComponent} from "./components/reports/report-new/report-new.component";
import {ReportListComponent} from "./components/reports/report-list/report-list.component";
import {ReportEditComponent} from "./components/reports/report-edit/report-edit.component";
import {PalletComponent} from "./components/information/pallet/pallet.component";
import {SheetComponent} from "./components/information/sheet/sheet.component";
import { CustomerTabsComponent } from "./components/users/Customers/customer-tabs/customer-tabs.component";

const routes: Routes = [
  { path: "", redirectTo: "/auth/login/username", pathMatch: "full" },
  {
    path: "auth",
    component: AuthComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "register", component: SignupComponent },
      {
        path: "login",
        component: SigninComponent,
        children: [
          { path: "", redirectTo: "username", pathMatch: "full" },
          { path: "phone", component: PhoneSigninComponent },
          { path: "username", component: UsernameSigninComponent },
        ],
      },
    ],
  },
  {
    path: "userArea",
    component: UserAreaComponent,
    canLoad: [LoadUserArea],
    canActivate: [UserAreaGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },

      // { path: 'province/new', component: NewProvinceComponent },
      { path: "province", component: ProvinceListComponent },
      // { path: 'province/:id', component: ProvinceComponent },

      // { path: 'bankAcount/new', component: NewBankAccountComponent },
      { path: "bankAccount", component: BankAccountListComponent },
      // // { path: 'bankAcount/:id', component: ProvinceComponent },

      { path: "personnel/new", component: PersonnelsNewComponent },
      {
        path: "personnel",
        component: PersonnelsListComponent,
        canActivate: [PageGuard],
      },
      { path: "personnel/:id", component: PersonnelsEditComponent },

      // { path: 'unit/new', component: NewUnitComponent },
      { path: "unit", component: UnitListComponent },
      // { path: 'unit/:id', component: EditUnitComponent },

      // { path: 'role/new', component: NewRoleComponent },
      { path: "role", component: RoleListComponent },
      // { path: 'role/:id', component: EditRoleComponent },

      { path: "customer/new", component: CustomerNewComponent },
      {
        path: "customer",
        component: CustomerListComponent,
        canActivate: [PageGuard],
      },
      {
        path: "customer/today",
        component: CustomerListComponent,
        canActivate: [PageGuard],
      },
      {
        path: "customer/:id",
        component: CustomerTabsComponent,
        canActivate: [PageGuard],
      },
      {
        path: "pallet-list" ,
        component: PalletComponent,
      },
      {
        path: "sheet-list" ,
        component: SheetComponent,
      },

      // {
      //   path: "contact",
      //   component: ContactListComponent,
      // },
      // {
      //   path: "address",
      //   component: PostalAddressListComponent,
      // },
      // {
      //   path: "customer_personnel",
      //   component: CPersonnelListComponent,
      // },
      // {
      //   path: "work_background",
      //   component: WorkBackgroundListComponent,
      // },
      // {
      //   path: "customerbankaccount",
      //   component: CustomerBankAccountListComponent,
      // },
      // {
      //   path: "social_network",
      //   component: SocialNetworkListComponent,
      // },
      {
        path: "customer_information_list",
        component: CustomerInformationListsComponent,
      },
      {
        path: "report-information-list",
        component: ReportInformationListComponent,
      },
      {
        path: "familiarity_type",
        component: FamiliarityTypeComponent,
      },

      // { path: 'region/new', component: NewRegionComponent },
      { path: "region", component: RegionListComponent },
      // { path: 'region/:id', component: EditRegionComponent },

      // { path: "city/new", component: NewCityComponent },
      { path: "city", component: CityListComponent },
      // { path: "city/:id", component: CityComponent },

      // { path: 'product/new', component: ProductNewComponent },
      { path: "product", component: ProductListComponent },
      { path: "product/new", component: ProductNewComponent },
      {
        path: "product/information",
        component: ProductInformationListsComponent,
      },
      {
        path: "warehouse",
        component: WarehouseComponent,
      },
      {
        path: "warehouseOrderList",
        component: WarehouseOrdersListComponent,
      },
      {
        path: "reportNew",
        component: ReportNewComponent,
      },
      {
        path: "reportList",
        component: ReportListComponent,
      },
      {
        path: "reportEdit/:id",
        component: ReportEditComponent,
      },
      {
        path: "warehouseOrder/:id",
        component: WarehouseOrderEditComponent,
      },

      {
        path: "warehouseOrderNew",
        component: WarehouseOrderNewComponent,
      },
      {
        path: "WarehouseProductOrder",
        component: WarehouseProductOrderComponent,
      },


      // { path: 'product/:id', component: EditProductComponent },

      // { path: 'order/new', component: NewCustomerOrderComponent },
      // {
      //    path: 'order/new/customer',
      //    component: NewRepresentetiveOrderComponent,
      // },
      { path: "shipping", component: ShippingListComponent },
      { path: "shipping/new/:id", component: ShippingNewComponent },
      { path: "shipping/:id", component: ShippingEditComponent },
      { path: "order", component: OrderListComponent },
      { path: "order/new", component: OrderNewComponent },
      { path: "order/product", component: ProductOrderComponent },
      // { path: 'order/my_customers', component: OrderListComponent },
      // {
      //    path: 'order/my_customers/new',
      //    component: NewRepresentetiveOrderComponent,
      // },
      {
        path: "evaluate/:id",
        component: SurveyNewComponent,
      },
      { path: "order/checkout", component: OrderCheckoutListComponent },
      { path: "order/:id", component: OrderEditComponent },
      // { path: 'order/:id/redirect', component: RedirectComponent },
      { path: "checkout/checks", component: CheckoutCheckListComponent },
      { path: "checkout/checks/:id", component: CheckoutCheckEditComponent },
      { path: "checkout/cashes/:id", component: CheckOutCashEditComponent },
      { path: "checkout/cashes", component: CheckoutCashListComponent },
      { path: "checkout/:id", component: CheckoutEditComponent },

      { path: "survey", component: SurveyFormListComponent },
      { path: "survey/new", component: SurveyFormNewComponent },
      { path: "survey/:id", component: SurveyFormEditComponent },

      { path: "evaluation", component: SurveyListComponent },
      {
        path: "evaluation/:id",
        component: SurveyWatchComponent,
      },
    ],
  },
  // { path: '500', component: InternalServerErrorComponent },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
