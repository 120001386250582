<div *ngIf="type == 'circle'" class="text-primary display-6">
  <i class="fa fa-circle"></i>
  {{ title }}
</div>
<div *ngIf="type == 'caret'" class="text-primary display-6">
  <i class="fa fa-caret-down"></i>
  {{ title }}
</div>
<div *ngIf="type == 'plus'" class="text-primary display-6">
  <i class="fa fa-plus"></i>
  {{ title }}
</div>
