import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Config } from "src/app/app-config.service";
import * as Func from "src/pack/js/func";
import { ToRPipe } from "src/app/fa.pipe";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-customer-new",
  templateUrl: "./customer-new.component.html",
  styleUrls: ["./customer-new.component.scss"],
})
export class CustomerNewComponent implements OnInit {
  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;
  conf = Config;

  date;
  imgUrl: string = null;
  user_types: any[] = [
    { id: 2, label: "مشتری" },
    { id: 3, label: "عامل فروش" },
    { id: 4, label: "نمایندگی" },
  ];

  user_type: number = null;

  nameTitles: any[] = [
    { id: "آقای", label: "آقا" },
    { id: "خانم", label: "خانم" },
  ];

  nameTitle: number = null;

  _landing_number: string = null;

  get landing_number() {
    if (!parseInt(this._landing_number)) return null;
    if (this._landing_number.length > 3)
      return (
        this._landing_number.slice(0, 3) +
        "-" +
        this._landing_number.slice(3)
      );
    else return this._landing_number;
  }

  set landing_number(e) {
    var regex_en = new RegExp("-", "g");
    e = e.replace(regex_en, "");
    this._landing_number = e;
  }

  statuses: any[] = [
    { id: true, label: "فعال" },
    { id: false, label: "غیر فعال" },
  ];
  status: boolean = true;

  references: any[] = [];
  reference: number = null;

  credits: any[] = [
    { id: true, label: "دارد" },
    { id: false, label: "ندارد" },
  ];
  credit: boolean = false;

  provinces: any[] = [];
  province: number = null;

  states: any[] = [
    { id: "natural", label: "حقیقی" },
    { id: "juridical", label: "حقوقی" },
  ];
  state: string = null;

  genders: any[] = [
    { id: "male", label: "آقا" },
    { id: "female", label: "خانم" },
  ];
  gender: string = null;

  degrees: any[] = [];
  degree: number = null;

  cities: any[] = [];
  city: number = null;

  fields: any[] = [];
  field: number = null;

  jobs: any[] = [];
  job: number = null;

  _max_credit: number = null;
  get max_credit() {
    if (!this._max_credit) return null;
    return this.toR.transform(this._max_credit.toString());
  }
  set max_credit(e: string) {
    this._max_credit = Func.toNumber(e);
  }

  func = Func;
  toR: ToRPipe = new ToRPipe();

  constructor(private http: HttpClient, private auth: AuthService) {}

  user = null;

  async ngOnInit(): Promise<void> {
    this.user = await this.auth.get_info();

    let provinceApi: string =
      Config.settings.api + Config.settings.add.province;
    await this.http
      .get(provinceApi)
      .toPromise()
      .then((response: any) => {
        response.forEach((element) => {
          this.provinces.push({
            id: element.id,
            label: element.name,
          });
        });
      })
      .catch((error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      });

    let jobApi: string = Config.settings.api + "information/job/";
    this.http.get(jobApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.jobs.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    let degreeApi: string = Config.settings.api + "information/degree/";
    this.http.get(degreeApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.degrees.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    let fieldApi: string = Config.settings.api + "information/field/";
    this.http.get(fieldApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.fields.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );

    let referenceApi: string =
      Config.settings.api + Config.settings.user.user + "?q=3";
    this.http.get(referenceApi).subscribe(
      (response: any) => {
        console.log(response);
        response.forEach((element) => {
          if (element.role !== 2)
            this.references.push({
              id: element.id,
              label: element.company_name,
            });
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }
  changeUserType($event) {
    console.log($event);

    this.user_type = $event;
  }

  chaneNameTitle($event) {
    this.nameTitle = $event;
  }

  changeStatus($event) {
    this.status = $event;
  }

  changeReference($event) {
    this.reference = $event;
  }

  changeProvince($event, city = null) {
    this.province = $event;
    let cityApi: string =
      Config.settings.api +
      Config.settings.add.citys +
      `?province=${$event}`;
    this.cities = [];
    this.http.get(cityApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.cities.push({
            id: element.id,
            label: element.name,
          });
          this.city = city;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  changeState($event) {
    this.state = $event;
  }

  changeCity($event) {
    this.city = $event;
  }
  changeCredit($event) {
    this.credit = $event;
  }

  register(form) {
    let data = form.value;
    data.landing_number = this._landing_number;

    if (this.user.role == 2 || this.user.role == 3 || this.user.role == 4)
      data.reference = this.user.id;

    data.max_credit = this._max_credit;

    data.image_url = this.imgUrl;
    console.log(data);
    let url = Config.settings.api + Config.settings.user.user;
    this.http.post(url, data).subscribe(
      (response: any) => {
        console.log("shod");
        this.success = true;
        this.isWrong = false;
        this.msg = `اضافه شد.`;
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }

  change_image(e) {
    const url: string = Config.settings.api + Config.settings.info.file;
    const formData: FormData = new FormData();
    formData.append(
      "file",
      e.target.files.item(0),
      e.target.files.item(0).name
    );
    console.log(formData);
    const headers = { dt: "yes" };
    this.http.post(url, formData, { headers }).subscribe(
      (res: any) => {
        this.imgUrl = res.url.slice(1);
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }
}
