<div class="m-3 ml-5 mt-4">
    <app-title title="پرداخت مشتریان"></app-title>

    <div *ngIf="success" class="" role="">
        <div
                class="d-flex text-success justify-content-center align-items-center"
        >
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ msg }}
        </div>
    </div>
    <div *ngIf="isWrong" class="" role="">
        <div class="d-flex justify-content-center align-items-center">
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ errorMsg }}
        </div>
    </div>

  <div class="order-header mt-4">
    <div class="row justify-content-between">
      <div class="item">
        شماره سفارش:{{ id ? (id.toString() | toFa) : null }}
      </div>
      <div class="item">تاریخ سفارش:{{ date.toISOString() | jDate }}</div>
      <div class="item">مخاطب:{{ name }}</div>
      <div class="item">
        ایجاد کننده:{{ creator_first_name + " " + creator_last_name }}
      </div>
      <div class="item">
        وضعیت:{{ func.order_states[state]
        }}{{
          order?.state == 7 || order?.state > 11
            ? " (" + order.representative_company_name + ")"
            : null
        }}
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="item">
        تعداد:{{ sheet_sum ? (sheet_sum.toString() | toFa) : null }}
      </div>
      <div class="item">
        تعداد ارسال شده:{{
          (sheet_sum - remaining_sheet_number)?.toString() | toFa
        }}
      </div>
      <div class="item">
        تعداد باقی مانده:{{ remaining_sheet_number?.toString() | toFa }}
      </div>
      <div class="item">وضعیت تسویه: {{ order?.checkout_state }}</div>
    </div>
    <div class="row justify-content-between">
      <div class="item">مبلغ خالص سفارش:{{ (order?.debt_amount -  order?.discount_amount)?.toString()| toR }}</div>
      <div class="item">
        مبلغ واریزی:{{ checkout_sum?.toString() | toFa }}
      </div>
      <div class="item">
        مبلغ باقی مانده:
        {{ ((order?.debt_amount - order?.discount_amount) - checkout_sum)?.toString() | toR }}
      </div>
    </div>
  </div>

    <app-title title="لیست چک‌ها" type="caret"></app-title>

    <div class="list m-3">
        <table class="table table-bordered">
            <thead class="thead-light headerless">
            <tr>
                <th scope="col">#</th>
                <th scope="col" class="d-lg-table-cell d-none">شماره فاکتور</th>
                <th scope="col" class="d-lg-table-cell d-none">شماره چک</th>
                <th scope="col">تاریخ چک</th>
                <th scope="col">مبلغ چک (ریال)</th>
                <th scope="col">شماره حساب</th>
                <th scope="col" class="d-lg-table-cell d-none">تایید کارشناس</th>
                <th scope="col" class="d-lg-table-cell d-none">تایید حسابداری</th>
                <th scope="col" class="d-lg-table-cell d-none">وضعیت چک</th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr
                    *ngFor="let el of checks; let i = index"
                    (click)="edit_check(i)"
            >
                <th class="d-lg-table-cell d-none">{{ i + 1 | toFa }}</th>
                <td class="d-lg-table-cell d-none">
                    {{ get_lr(el.load_report) | toFa }}
                </td>

                <td class="p-0">
                    {{ el.check_number | toFa }}
                </td>
                <td>
                    {{ el.date | jDate | toFa }}
                </td>
                <td>
                    {{ el.amount | toR }}
                </td>
                <td>
                    {{ el.card_number | toFa }}
                </td>
                <td class="d-lg-table-cell d-none">
                    <i
                            class="fa fa-circle"
                            [class]="
                el.expert_confirmation ? 'text-success' : 'text-danger'
              "
                    ></i>
                </td>
                <td class="d-lg-table-cell d-none">
                    <i
                            class="fa fa-circle"
                            [class]="
                el.manager_confirmation ? 'text-success' : 'text-danger'
              "
                    ></i>
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ check_states_map.get(parseint(el.check_state)) }}
                </td>

                <td>
                    <i class="fal fa-trash-alt" (click)="removeCheck(i,form)"></i>
                </td>
            </tr>
            </tbody>
        </table>
        <div class="list-footer">
            <div class="list-footer-item">
                تعداد چک
                <div class="text-primary">
                    {{ checks.length.toString() | toFa }}
                </div>
            </div>
            <div class="list-footer-item">
                جمع مبالغ
                <div class="text-primary">{{ checks_sum.toString() | toR }}</div>
            </div>
            <div class="list-footer-item">
                مبالغ وصول شده
                <div class="text-primary">
                    {{ done_checks_sum.toString() | toR }}
                </div>
            </div>
            <div class="list-footer-item">
                مبالغ برگشت شده
                <div class="text-primary">
                    {{ failed_checks_sum.toString() | toR }}
                </div>
            </div>
            <div class="list-footer-item">
                راس چک‌ها
                <div class="text-primary">
                    {{ checks_head?.toISOString() | jDate }}
                </div>
            </div>
        </div>
    </div>

    <div class="row m-3 d-flex justify-content-end">
        <button class="btn btn-primary" (click)="newCheck()">
            اضافه کردن
        </button>
    </div>

    <div *ngIf="editing_check" class="box">
        <div class="row">
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> شماره فاکتور </label>
                <select
                        [ngModel]="editing_check.load_report"
                        class="custom-select custom-select-lg"
                        (ngModelChange)="editing_check.load_report = $event"
                >
                    <option *ngFor="let el of load_reports" [ngValue]="el.id">
                        {{ el.label }}
                    </option>
                </select>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> شماره چک </label>
                <input
                        type="text"
                        [(ngModel)]="editing_check.check_number"
                        class="form-control form-control-lg"
                />
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> شماره حساب </label>
                <input
                        type="text"
                        [(ngModel)]="editing_check.card_number"
                        class="form-control form-control-lg"
                />
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> تاریخ چک </label>
                <div class="input-group">
                    <input
                            matInput
                            [matDatepicker]="picker3"
                            [(ngModel)]="editing_check.date"
                            class="form-control form-control-lg"
                    />

                    <span class="input-group-append" (click)="picker3.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
                    <mat-datepicker #picker3></mat-datepicker>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> تاریخ تغییر وضعیت </label>
                <div class="input-group">
                    <input
                            matInput
                            [matDatepicker]="picker4"
                            [(ngModel)]="editing_check.check_state_date"
                            class="form-control form-control-lg"
                    />

                    <span class="input-group-append" (click)="picker4.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
                    <mat-datepicker #picker4></mat-datepicker>
                </div>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> مبلغ </label>
                <input
                        type="text"
                        [(ngModel)]="editing_check.amountt"
                        class="form-control form-control-lg"
                />
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> نام صاحب حساب </label>
                <input
                        type="text"
                        [(ngModel)]="editing_check.name"
                        class="form-control form-control-lg"
                />
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> وضعیت چک </label>
                <select
                        [ngModel]="editing_check.check_state"
                        class="custom-select custom-select-lg"
                        (ngModelChange)="editing_check.check_state = $event"
                >
                    <option *ngFor="let el of check_states" [ngValue]="el.id">
                        {{ el.label }}
                    </option>
                </select>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> اتصال به چک برگشتی </label>
                <select
                        [ngModel]="editing_check.bounced_check"
                        class="custom-select custom-select-lg"
                        (ngModelChange)="editing_check.bounced_check = $event"
                >
                    <option *ngFor="let el of bounced_checks" [ngValue]="el.id">
                        {{ el.label }}
                    </option>
                </select>
            </div>
        </div>

        <div class="row m-3">
            <label class="mx-1 lead">توضیحات</label>
            <textarea
                    class="w-100 form-control"
                    [(ngModel)]="editing_check.detail"
                    name="detail"
                    (keydown)="(1 == 1)"
            ></textarea>
        </div>

        <div class="row box m-3">
            <div
                    class="form-group col d-flex align-items-center justify-content-between m-3"
            >
                <label class="mx-3 lead" style="font-size: 1.4rem">
                    تایید مشتری
                </label>
                <input
                        type="checkbox"
                        class="ml-3"
                        [(ngModel)]="editing_check.customer_confirmation"
                        [disabled]="
            !(
              user.role == 1 ||
              user.role == 2 ||
              user.role == 3 ||
              user.role == 4 ||
              user.role == 5 ||
              user.role == 10
            )
          "
                />
            </div>
            <div
                    class="form-group col d-flex align-items-center justify-content-between m-3"
            >
                <label class="mx-3 lead" style="font-size: 1.4rem">
                    تایید کارشناس
                </label>
                <input
                        type="checkbox"
                        class="ml-3"
                        [(ngModel)]="editing_check.expert_confirmation"
                        [disabled]="
            !editing_check.customer_confirmation ||
            ((user.role < 5 || user.role > 10) && user.id != 1)
          "
                />
            </div>
            <div
                    class="form-group col d-flex align-items-center justify-content-between m-3"
            >
                <label class="mx-3 lead" style="font-size: 1.4rem">
                    تایید حسابداری
                </label>
                <input
                        type="checkbox"
                        class="ml-3"
                        [(ngModel)]="editing_check.manager_confirmation"
                        [disabled]="
            !editing_check.expert_confirmation ||
            (user.role != 14 && user.role != 13 && user.id != 1)
          "
                />
            </div>
        </div>
        <div *ngIf="images.length" class="row box m-2" >
            <div *ngFor="let image of images; let i = index"
                 class="d-block p-3 position-relative profile">
                <div class="img">
                    <img
                            [src]="conf.settings.back + image"
                            style="width: 300px;height: 150px"
                    />
                </div>
                <div (click)="remove(i)"
                     class="cursor-pointer img-remove">
                    <i class="fa fa-times"></i>
                </div>
            </div>
        </div>

        <div class="row justify-content-end">
            <div
                    class="form-group col-2 d-flex align-items-center justify-content-between">
                <button class="btn btn-primary w-100" (click)="save_check(form)">
                    ثبت
                </button>
            </div>
            <div class="img">
                <label class="btn btn-primary w-100">
                    <input
                            type="file"
                            accept="image/*"
                            (change)="add_image($event)"
                            style="opacity: 0; display: none"/>
                    ارسال تصویر
                </label>
            </div>
            <div
                    class="form-group col-2 d-flex align-items-center justify-content-between">
                <button class="btn btn-gray w-100" (click)="editing_check = null">
                    لغو
                </button>
            </div>
        </div>
    </div>

    <app-title title="لیست پرداخت‌های نقدی" type="caret"></app-title>

    <div class="list m-3">
        <table class="table table-bordered">
            <thead class="thead-light headerless">
            <tr>
                <th scope="col">#</th>
                <th scope="col" class="d-lg-table-cell d-none">شماره فاکتور</th>
                <th scope="col">تاریخ واریز</th>
                <th scope="col">مبلغ واریز (ریال)</th>
                <th scope="col">نام واریز کننده</th>
                <th scope="col">شماره حساب مبدا</th>
                <th scope="col" class="d-lg-table-cell d-none">تایید مشتری</th>
                <th scope="col" class="d-lg-table-cell d-none">تایید کارشناس</th>
                <th scope="col" class="d-lg-table-cell d-none">تایید حسابداری</th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr
                    *ngFor="let el of cashes; let i = index"
                    (click)="edit_cash(i)"
            >
                <th>{{ i + 1 | toFa }}</th>
                <td class="d-lg-table-cell d-none">
                    {{ get_lr(el.load_report) | toFa }}
                </td>
                <td>
                    {{ el.date | jDate | toFa }}
                </td>
                <td>
                    {{ el.amount | toR }}
                </td>
                <td>
                    {{ el.name }}
                </td>
                <td>
                    {{ el.source_bank_account | toFa }}
                </td>
                <td class="d-lg-table-cell d-none">
                    <i
                            class="fa fa-circle"
                            [class]="
                el.customer_confirmation ? 'text-success' : 'text-danger'
              "
                    ></i>
                </td>
                <td class="d-lg-table-cell d-none">
                    <i
                            class="fa fa-circle"
                            [class]="
                el.expert_confirmation ? 'text-success' : 'text-danger'
              "
                    ></i>
                </td>
                <td class="d-lg-table-cell d-none">
                    <i
                            class="fa fa-circle"
                            [class]="
                el.manager_confirmation ? 'text-success' : 'text-danger'
              "
                    ></i>
                </td>
                <td>
                    <i class="fal fa-trash-alt" (click)="removeCash(i,form)"></i>
                </td>
            </tr>
            </tbody>
        </table>
        <div class="list-footer">
            <div class="list-footer-item">
                تعداد
                <div class="text-primary">
                    {{ cashes.length.toString() | toFa }}
                </div>
            </div>
            <div class="list-footer-item">
                جمع مبالغ
                <div class="text-primary">{{ cashes_sum.toString() | toR }}</div>
            </div>
            <div class="list-footer-item">
                راس واریزها
                <div class="text-primary">
                    {{ cashes_head?.toISOString() | jDate }}
                </div>
            </div>
        </div>
    </div>

    <div class="row m-3 d-flex justify-content-end">
        <button class="btn btn-primary" (click)="newCash()">
            اضافه کردن
        </button>
    </div>

    <div *ngIf="editing_cash" class="box">
        <div class="row">
            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> شماره فاکتور </label>
                <select
                        [ngModel]="editing_cash.load_report"
                        class="custom-select custom-select-lg"
                        (ngModelChange)="editing_cash.load_report = $event"
                >
                    <option *ngFor="let el of load_reports" [ngValue]="el.id">
                        {{ el.label }}
                    </option>
                </select>
            </div>

            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> مبلغ </label>
                <input
                        type="text"
                        [(ngModel)]="editing_cash.amountt"
                        class="form-control form-control-lg"
                />
            </div>

            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> تاریخ واریز </label>
                <div class="input-group">
                    <input
                            matInput
                            [matDatepicker]="picker3"
                            [(ngModel)]="editing_cash.date"
                            class="form-control form-control-lg"
                    />

                    <span class="input-group-append" (click)="picker3.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
                    <mat-datepicker #picker3></mat-datepicker>
                </div>
            </div>

            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> نوع پرداخت </label>
                <select
                        [ngModel]="editing_cash.deposit_type"
                        class="custom-select custom-select-lg"
                        (ngModelChange)="editing_cash.deposit_type = $event"
                >
                    <option *ngFor="let el of deposit_types" [ngValue]="el.id">
                        {{ el.label }}
                    </option>
                </select>
            </div>

            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> شماره حساب مقصد </label>
                <select
                        [ngModel]="editing_cash.bank_account"
                        class="custom-select custom-select-lg"
                        (ngModelChange)="editing_cash.bank_account = $event"
                >
                    <option *ngFor="let el of bank_accounts" [ngValue]="el.id">
                        {{ el.label }}
                    </option>
                </select>
            </div>

            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> شماره حساب مبدا </label>
                <input
                        type="text"
                        [(ngModel)]="editing_cash.source_bank_account"
                        class="form-control form-control-lg"
                />
            </div>

            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> نام واریز کننده </label>
                <input
                        type="text"
                        [(ngModel)]="editing_cash.name"
                        class="form-control form-control-lg"
                />
            </div>

            <div class="form-group col-lg-4 col-md-6 col-12">
                <label class="mx-1 lead"> اتصال به چک برگشتی </label>
                <select
                        [ngModel]="editing_cash.bounced_check"
                        class="custom-select custom-select-lg"
                        (ngModelChange)="editing_cash.bounced_check = $event"
                >
                    <option *ngFor="let el of bounced_checks" [ngValue]="el.id">
                        {{ el.label }}
                    </option>
                </select>
            </div>

        </div>

        <div class="row m-3">
            <label class="mx-1 lead">توضیحات</label>
            <textarea
                    class="w-100 form-control"
                    [(ngModel)]="editing_cash.detail"
                    name="detail"
                    (keydown)="(1 == 1)"
            ></textarea>
        </div>

        <div class="row box m-3">
            <div
                    class="form-group col d-flex align-items-center justify-content-between m-3"
            >
                <label class="mx-3 lead" style="font-size: 1.4rem">
                    تایید مشتری
                </label>
                <input
                        type="checkbox"
                        class="ml-3"
                        [(ngModel)]="editing_cash.customer_confirmation"
                        [disabled]="
            !(
              user.role == 1 ||
              user.role == 2 ||
              user.role == 3 ||
              user.role == 4 ||
              user.role == 5 ||
              user.role == 10
            )
          "
                />
            </div>
            <div
                    class="form-group col d-flex align-items-center justify-content-between m-3"
            >
                <label class="mx-3 lead" style="font-size: 1.4rem">
                    تایید کارشناس
                </label>
                <input
                        type="checkbox"
                        class="ml-3"
                        [(ngModel)]="editing_cash.expert_confirmation"
                        [disabled]="
            !editing_cash.customer_confirmation ||
            ((user.role < 5 || user.role > 10) && user.id != 1)
          "
                />
            </div>
            <div
                    class="form-group col d-flex align-items-center justify-content-between m-3"
            >
                <label class="mx-3 lead" style="font-size: 1.4rem">
                    تایید حسابداری
                </label>
                <input
                        type="checkbox"
                        class="ml-3"
                        [(ngModel)]="editing_cash.manager_confirmation"
                        [disabled]="
            !editing_cash.expert_confirmation ||
            (user.role != 14 && user.role != 13 && user.id != 1)
          "
                />
            </div>
        </div>
        <div *ngIf="imagesCash.length" class="row box m-2" >
            <div *ngFor="let image of imagesCash; let i = index"
                 class="d-block p-3 position-relative profile">
                <div class="img">
                    <img
                            [src]="conf.settings.back + image"
                            style="width: 300px;height: 150px"
                    />
                </div>
                <div (click)="remove_cash(i)"
                     class="cursor-pointer img-remove">
                    <i class="fa fa-times"></i>
                </div>
            </div>
        </div>

        <div class="row justify-content-end">
            <div
                    class="form-group col-2 d-flex align-items-center justify-content-between"
            >
                <button class="btn btn-primary w-100" (click)="save_cash(form)">
                    ثبت
                </button>
            </div>
            <div class="img">
                <label class="btn btn-primary w-100">
                    <input
                            type="file"
                            accept="image/*"
                            (change)="add_image_cash($event)"
                            style="opacity: 0; display: none"/>
                    ارسال تصویر
                </label>
            </div>
            <div
                    class="form-group col-2 d-flex align-items-center justify-content-between"
            >
                <button class="btn btn-gray w-100" (click)="editing_cash = null">
                    لغو
                </button>
            </div>
        </div>
    </div>

    <form
        #form="ngForm"
        class="d-flex flex-column align-items-center w-100"
    >
        <div class="box w-100">
            <div class="form-input m-3">
                <label class="mx-1 lead"> توضیحات </label>
                <textarea
                        class="form-control-lg form-control w-100"
                        ngModel
                        name="detail"
                ></textarea>
            </div>
        </div>

        <div class="row justify-content-end w-100 px-3">
            <button
                    [disabled]="disable_send"
                    (click)="register(form)"
                    class="btn mx-3 w-25 btn-primary"
            >
                ذخیره تغییرات
            </button>
            <button
                    (click)="loc.back()"
                    type="button"
                    class="btn mx-3 w-25 btn-gray"
            >
                بازگشت
            </button>
        </div>
    </form>
</div>
