<div class="m-3 mt-4">
  <app-title title="وضعیت تسویه حساب سفارشات"></app-title>
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <!-- <div class="list-header-item cursor-pointer" [routerLink]="['new']">
        <i class="far fa-plus-square"></i>جدید
      </div> -->
      <!-- <div class="list-header-item cursor-pointer" (click)="load()">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div> -->
      <div class="list-header-item">
        ردیف:&nbsp;
        <div
          class="text-success"
          style="font-size: 1rem; font-weight: 600"
        >
          {{ object_count.toString() | toFa }}
        </div>
      </div>
      <div class="list-header-item">
        ورق صفحه:&nbsp;
        <div
          class="text-success"
          style="font-size: 1rem; font-weight: 600"
        >
          {{ page_sheet_sum.toString() | toFa }}
        </div>
      </div>
      <div class="list-header-item">
        ورق کل:&nbsp;
        <div
          class="text-success"
          style="font-size: 1rem; font-weight: 600"
        >
          {{ sheet_sum.toString() | toFa }}
        </div>
      </div>
      <div class="list-header-item">
        خریدها:&nbsp;
        <div
          class="text-success"
          style="font-size: 1rem; font-weight: 600"
        >
          {{ (all_order_amounts-all_discount_amounts).toString() | toR }}
        </div>
      </div>
      <div class="list-header-item">
        واریزی‌ها:&nbsp;
        <div
          class="text-success"
          style="font-size: 1rem; font-weight: 600"
        >
          {{ all_paid_amounts.toString() | toR }}
        </div>
      </div>
      <div class="list-header-item">
        بدهی‌ها:&nbsp;
        <div
          class="text-success"
          style="font-size: 1rem; font-weight: 600"
        >
          {{ (all_order_amounts - all_paid_amounts).toString() | toR }}
        </div>
      </div>
      <div class="list-header-item">
        مبلغ تخفیفات :
        <div
                class="text-success"
                style="font-size: 1rem; font-weight: 400"
        >
          {{ all_discount_amounts.toString() | toR }}
        </div>
      </div>
      <div
        class="list-header-item mr-auto cursor-pointer"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="far fa-file-search"></i>فیلتر
      </div>
    </div>

    <app-filter
      [isCollapsed]="isCollapsed"
      [filter_items]="filter_items"
      (changeFilter)="search($event)"
    ></app-filter>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col" (click)="sort('company_name')">نام فروشگاه</th>
          <th scope="col" (click)="sort('order_date')">تاریخ سفارش</th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('order')"
          >
            سفارش
          </th>
          <th scope="col" (click)="sort('invoice_number')">پیش‌فاکتور</th>
          <th scope="col" class="d-lg-table-cell d-none">تعداد ورق</th>
          <!-- <th scope="col" (click)="sort('remittance_number')">
            شماره فاکتور
          </th> -->
          <th scope="col">مبلغ کل</th>
          <th scope="col" class="d-lg-table-cell d-none">تخفیفات</th>
          <th scope="col" class="d-lg-table-cell d-none">مبلغ خالص</th>
          <th scope="col" class="d-lg-table-cell d-none">
            مبلغ پرداخت شده
          </th>
          <th scope="col">مبلغ باقی‌مانده</th>
          <th
            scope="col"
            class="d-lg-table-cell d-none"
            (click)="sort('state')"
          >
            وضعیت سفارش
          </th>
          <th scope="col">وضعیت تسویه</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let el of orders; let i = index"
          (dblclick)="edit(el.id)"
        >
          <th scope="row">
            {{ (page_number - 1) * page_size + i + 1 | toFa }}
          </th>
          <td>{{ el.buyer_company_name }}</td>
          <td>{{ el.date | jDate | toFa }}</td>
          <td class="d-lg-table-cell d-none">{{ el.id | toFa }}</td>
          <td>{{ el.invoice_number | toFa }}</td>
          <!-- <td>{{ el.remittance_number | toFa }}</td> -->
          <td class="d-lg-table-cell d-none">{{ el.sum | toFa }}</td>
          <td>
            {{ el.debt_amount.toString() | toR }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ el.discount_amount | toR }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ (el.debt_amount - el.discount_amount)?.toString() | toR }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ el.paid_amount.toString() | toR }}
          </td>
          <td>
            {{ (el.debt_amount - el.paid_amount - el.discount_amount ).toString() | toR }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{
              el.state !== 7 && el.state <= 11
                ? func.order_states[el.state]
                : "به " + el.representative + " ارجاع داده شد"
            }}
          </td>
          <td>{{ el.checkout_state }}</td>
          <td
            class="cursor-pointer"
            [routerLink]="['/userArea/order/', el.id]"
          >
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center">
    <app-pagination
      [page_count]="page_count"
      [page_number]="page_number"
      (changePage)="load($event)"
    ></app-pagination>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      آیا از حذف این رکورد اطمینان دارید؟
    </h4>
  </div>
  <div class="modal-body">
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(true)"
    >
      بله
    </button>
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(false)"
    >
      خیر
    </button>
  </div>
</ng-template>
