<div class="m-3 ml-5 mt-4" xmlns="http://www.w3.org/1999/html">
    <app-title title="ویرایش پرداخت"></app-title>

        <div *ngIf="success" class="" role="">
            <div
                    class="d-flex text-success justify-content-center align-items-center"
            >
                <i class="fas fa-exclamation-circle ml-1"></i>
                {{ msg }}
            </div>
        </div>
        <div *ngIf="isWrong" class="" role="">
            <div class="d-flex justify-content-center align-items-center">
                <i class="fas fa-exclamation-circle ml-1"></i>
                {{ errorMsg }}
            </div>
        </div>


    <form
        #form="ngForm"
        (ngSubmit)="register(form)"
            class="d-flex flex-column align-items-center"
    >
        <div *ngIf="true">
            <div class="box">
                <div class="row">
                    <div class="form-input col-lg-3 col-md-6 col-12">
                        <label class="mx-1 lead"> شماره فاکتور </label>
                        <select
                                name="load_report"
                                [ngModel]="editing_check.load_report"
                                (ngModelChange)="editing_check.load_report = $event"
                                class="custom-select custom-select-lg">
                            <option *ngFor="let el of load_reports" [ngValue]="el.id">
                                {{ el.label }}
                            </option>
                        </select>
                    </div>

                    <div class="form-input col-lg-3 col-md-6 col-12">
                        <label class="mx-1 lead"> شماره چک </label>
                        <input
                                type="text"
                                [(ngModel)]="editing_check.check_number"
                                name="check_number"
                                class="form-control form-control-lg"
                        />
                    </div>
                    <div class="form-input col-lg-3 col-md-6 col-12">
                        <label class="mx-1 lead"> شماره حساب </label>
                        <input
                                type="text"
                                [(ngModel)]="editing_check.card_number"
                                name="card_number"
                                class="form-control form-control-lg"
                        />
                    </div>


                    <div class="form-input col-lg-3 col-md-6 col-12">
                        <label class="mx-1 lead"> تاریخ چک </label>
                        <input
                                matInput
                                [matDatepicker]="picker3"
                                (focus)="picker3.open()"
                                type="text"
                                [(ngModel)]="editing_check.date"
                                name="date"
                                class="form-control-lg form-control"
                        />
                        <mat-datepicker #picker3></mat-datepicker>
                    </div>

                    <div class="form-input col-lg-3 col-md-6 col-12">
                        <label class="mx-1 lead"> مبلغ </label>
                        <input
                                type="text"
                                [(ngModel)]="editing_check.amountt"
                                name="amountt"
                                class="form-control form-control-lg"
                        />
                    </div>
                    <div class="form-input col-lg-3 col-md-6 col-12">
                        <label class="mx-1 lead"> نام صاحب حساب </label>
                        <input
                                type="text"
                                [(ngModel)]="editing_check.name"
                                name="name"
                                class="form-control-lg form-control"
                        />
                    </div>

                    <div class="form-input col-lg-3 col-md-6 col-12">
                        <label class="mx-1 lead"> وضعیت چک </label>
                        <select
                                [ngModel]="editing_check.check_state"
                                name="check_state"
                                class="custom-select custom-select-lg"
                                (ngModelChange)="editing_check.check_state = $event"
                        >
                            <option *ngFor="let el of check_states" [ngValue]="el.id">
                                {{ el.label }}
                            </option>
                        </select>
                    </div>
                    <div class="form-input col-lg-3 col-md-6 col-12">
                        <label class="mx-1 lead"> تاریخ تغییر آخرین وضعیت </label>
                        <input
                                matInput
                                [matDatepicker]="picker3"
                                (focus)="picker3.open()"
                                type="text"
                                [(ngModel)]="editing_check.check_state_date"
                                name="check_state_date"
                                class="form-control-lg form-control"
                        />
                        <mat-datepicker #picker3></mat-datepicker>
                    </div>

                    <div class="form-input col-12">
                        <label class="mx-1 lead"> توضیحات </label>
                        <textarea
                                [(ngModel)]="editing_check.detail"
                                class="form-control-lg form-control"
                                (keydown)="(1 == 1)"
                                name="detail"
                        ></textarea>
                    </div>

                    <div class="box w-100 row box m-3">
                        <div
                                class="form-group col d-flex align-items-center justify-content-between m-3"
                        >
                            <label class="mx-3 lead" style="font-size: 1.4rem">
                                تایید مشتری
                            </label>
                            <input
                                    type="checkbox"
                                    [(ngModel)]="editing_check.customer_confirmation"
                                    name="customer_confirmation"
                                    class="ml-3"
                                    [disabled]="!(user.role == 1 ||user.role == 2 ||user.role == 3 ||user.role == 4 ||user.role == 5 || user.role == 10)"/>
                        </div>
                        <div
                                class="form-group col d-flex align-items-center justify-content-between m-3"
                        >
                            <label class="mx-3 lead" style="font-size: 1.4rem">
                                تایید کارشناس
                            </label>
                            <input
                                    type="checkbox"
                                    [(ngModel)]="editing_check.expert_confirmation"
                                    name="expert_confirmation"
                                    class="ml-3"
                                    [disabled]="
              !editing_check.customer_confirmation ||
            ((user.role < 5 || user.role > 10) && user.id != 1)
          "
                            />
                        </div>
                        <div
                                class="form-group col d-flex align-items-center justify-content-between m-3"
                        >
                            <label class="mx-3 lead" style="font-size: 1.4rem">
                                تایید حسابداری
                            </label>
                            <input
                                    type="checkbox"
                                    class="ml-3"
                                    name="manager_confirmation"
                                    [(ngModel)]="editing_check.manager_confirmation"
                                    [disabled]="
            !editing_check.expert_confirmation ||
            (user.role != 14 && user.role != 13 && user.id != 1)
          "
                            />
                        </div>
                    </div>
                    <div *ngIf="images.length" class="row m-2 box w-100 d-flex" style="z-index: inherit">
                        <div *ngFor="let image of images; let i = index"
                                class="d-block p-3 position-relative profile">
                            <div class="img">
                                <img
                                        [src]="conf.settings.back + image"
                                        style="width: 300px;height: 150px"
                                />
                            </div>
                            <div (click)="remove(i)"
                                    class="cursor-pointer img-remove">
                                <i class="fa fa-times"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end w-100 px-3">
                        <div
                                class="form-group col-2 d-flex align-items-center justify-content-between">
                            <button class="btn btn-primary w-100" (click)="register(form)">
                                ثبت
                            </button>
                        </div>
                        <div class="img">
                            <label class="btn btn-primary w-100">
                                <input
                                        type="file"
                                        accept="image/*"
                                        (change)="add_image($event)"
                                        style="opacity: 0; display: none"/>
                                ارسال تصویر
                            </label>
                        </div>
                        <div
                                class="form-group col-2 d-flex align-items-center justify-content-between">
                            <button class="btn btn-secondary w-100 " (click)="loc.back()">
                                بازگشت
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
