import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import * as Func from "src/pack/js/func";
import { Config } from "src/app/app-config.service";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToRPipe } from "src/app/fa.pipe";
import { dematerialize } from "rxjs/operators";
import { Form } from "@angular/forms";

@Component({
  selector: "app-survey-form-edit",
  templateUrl: "./survey-form-edit.component.html",
  styleUrls: ["./survey-form-edit.component.scss"],
})
export class SurveyFormEditComponent implements OnInit {
  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;
  sendDate = new Date();
  date = new Date();
  survayDate = new Date();
  purchaseDate = null;
  name: string;
  credit: number = 0;
  imgUrl: string;
  conf = Config;

  questions: any[] = [];

  statuses: any[] = [
    { id: true, label: "فعال" },
    { id: false, label: "غیر فعال" },
  ];
  status: boolean = true;

  func = Func;
  math = Math;
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  responses: { question: number; answer: number; detail: string }[] = [];

  id: number;

  toR: ToRPipe = new ToRPipe();

  user;
  buyer: number;
  state: number = 0;
  async ngOnInit(): Promise<void> {
    this.user = await this.auth.get_info();
    this.load();
  }

  load() {
    this.route.params.subscribe((params) => {
      if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
        this.router.navigate(["/404"]);
      } else {
        this.id = params.id;
        this.responses = [];
        let evaluationApi: string =
          Config.settings.api +
          Config.settings.evaluation.evaluation +
          `${this.id}/`;
        this.http.get(evaluationApi).subscribe(
          (response: any) => {
            console.log(response);
            this.questions = response.questions;
            this.date = new Date(response.date);
            this.status = response.is_active;
          },
          (error: HttpErrorResponse) => {
            //TODO: server errors
            console.log(error);
          }
        );
      }
    });
  }

  changeStatus($event) {
    this.status = $event;
  }

  get score_sum() {
    let sum: number = 0;
    this.questions.forEach((el) => {
      sum += 10 * el.value;
    });
    return sum;
  }

  register(form) {
    let data = form.value;
    data.is_active = this.status;
    data.questions = this.questions;
    data.date = this.date;
    console.log(JSON.stringify(data));
    let url =
      Config.settings.api +
      Config.settings.evaluation.evaluation +
      `${this.id}/`;
    this.http.patch(url, data).subscribe(
      (response: any) => {
        console.log("shod");
        this.success = true;
        this.isWrong = false;
        window.scroll(0, 0);
        this.msg = `فرم ارزیابی ویرایش شد.`;
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }

  add(body = null, value = 0, detail = null) {
    this.questions.push({
      body: body,
      value: value,
      detail: detail,
    });
  }

  remove(i) {
    this.questions.splice(i, 1);
  }
}
