import {Component, Input, OnInit} from '@angular/core';
import {Config} from '../../../app-config.service';
import {FormControl} from '@angular/forms';
import * as Func from '../../../../pack/js/func';
import {AuthService} from '../../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from '../../../loading/loading.service';


@Component({
    selector: 'app-report-list',
    templateUrl: './report-list.component.html',
    styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

    @Input()
    addressee_id?: number;

    showTitle = true;
    constructor(
        public auth: AuthService,
        private router: Router,
        private http: HttpClient,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private loading: LoadingService
    ) {
    }

    // tslint:disable-next-line:variable-name
    public page_number = 1;
    // tslint:disable-next-line:variable-name
    public object_count = 0;
    // tslint:disable-next-line:variable-name
    public page_count = 0;
    typeItem: any[] = [];
    isCollapsed = true;
    // tslint:disable-next-line:variable-name
    filter_items = [
        {
            name: 'type_id',
            label: 'نوع یادداشت',
            options: [],
            type: 'list',
            res: null,
        },
        {
            name: 'action_type_id',
            label: 'نوع اقدام',
            options: [],
            type: 'list',
            res: null,
        },
        {
            name: 'creator_id',
            label: 'ایجاد کننده',
            options: [],
            type: 'list',
            res: null,
        },
        {
            name: 'activity_type_id',
            label: 'نوع فعالیت',
            options: [],
            type: 'list',
            res: null,
        },
        {
            name: 'start_created_at',
            label: 'تاریخ ثبت از',
            type: 'date',
        },
        {
            name: 'end_created_at',
            label: 'تاریخ ثبت تا',
            type: 'date',
        },
        {
            name: 'start_activity_date',
            label: 'تاریخ یادداشت از',
            type: 'date',
        },
        {
            name: 'end_activity_date',
            label: 'تاریخ یادداشت تا',
            type: 'date',
        },
        {
            name: 'start_due_date',
            label: 'مهلت اقدام از',
            type: 'date',
        },
        {
            name: 'end_due_date',
            label: 'مهلت اقدام تا',
            type: 'date',
        },
        {
            name: 'start_completion_date',
            label: 'تاریخ تکمیل از',
            type: 'date',
        },
        {
            name: 'end_completion_date',
            label: 'تاریخ تکمیل تا',
            type: 'date',
        },
        {
            name: 'state_id',
            label: 'وضعیت',
            options: [],
            type: 'list',
            res: null,
        },
        {
            name: 'seen_by_marketing_manager',
            label: 'مشاهده توسط مدیر بازاریابی',
            options: [],
            type: 'list',
            res: null,
        },
        {
            name: 'seen_by_vice_president',
            label: 'مشاهده شده توسط معاونت بازرگانی',
            options: [],
            type: 'list',
            res: null,
        },
        {
            name: 'addressee_type_id',
            label: 'دسته بندی مخاطب',
            options: [],
            type: 'list',
            res: null,
        },
        {
            name: 'addressee_id',
            label: 'نام مخاطب',
            options: [],
            res: null,
            isUser: true
        },

    ];
    report = {
        id: null,
        type_name: '',
        activity_title: '',
        addressee_type_name: '',
        addressee_company_name: '',
        addressee_first_name: '',
        addressee_last_name: '',
        activity_type_name: '',
        action_type_name: '',
        creator_last_name: '',
        creator_first_name: '',
        created_at: null,
        due_date: null,
        completion_date: null,
        state_name: null,
        color: 'red'
    };
    reports: any[] = [];
    orgReports: any[] = [];
    // searchControl = new FormControl();
    // selected: boolean[] = [];
    reportApi: string = Config.settings.api + 'report/report/';
    func = Func;
    searchTerm: string;
    disable_load = false;
    sortcol = '-created_at';


    async ngOnInit(): Promise<void> {
        if (this.addressee_id !== undefined){
            this.showTitle = false;
            localStorage.setItem('addressee_id', this.addressee_id.toString());
        } else {
            this.showTitle = true;
            localStorage.removeItem('addressee_id');
        }
        this.load();
        // this.route.queryParams.subscribe((f) => {
        this.route.queryParamMap.subscribe((f) => {
          const filter = {};
          f.keys.forEach((k) => (filter[k] = f.get(k)));
          this.filter_items.forEach(
              (el) => (el.res = filter[el.name] ? filter[el.name] : null)
          );
          this.search(filter);
        });
        this.loadReportType();
        this.loadCreator();
        this.loadAddresseeType();
        this.loadActiontype();
        this.loadActivity();
        this.loadState();
        this.loadSeenMarketing();
    }

    load(page = 1) {
        debugger;
        // @ts-ignore
        this.report = {};
        if (this.disable_load) {
            return;
        }
        this.disable_load = true;
        this.loading.add();
        // this.loading.add();
        console.log(this.reportApi);
        this.orgReports = [];
        this.reports = [];
        this.searchTerm = localStorage.getItem('searchReport');

        if (this.addressee_id !== null && this.addressee_id !== undefined){
            // tslint:disable-next-line:max-line-length
            this.searchTerm =  this.searchTerm + "&addressee_id=" + this.addressee_id ;
        }
        this.http.get(this.reportApi + `?page=${page}&${this.searchTerm}&ordering=${this.sortcol}`).subscribe(
            (response: any) => {
                debugger;
                this.page_count = response.page_count;
                this.object_count = response.object_count;
                this.page_number = response.page_number;
                response.results.forEach((element) => {
                    this.report.id = element.id;
                    this.report.action_type_name = element.action_type_name;
                    this.report.addressee_type_name = element.addressee_type_name;
                    this.report.activity_type_name = element.activity_type_name;
                    this.report.type_name = element.type_name;
                    this.report.activity_title = element.activity_title;
                    this.report.completion_date = element.completion_date;
                    this.report.state_name = element.state_name;
                    this.report.created_at = element.created_at;
                    this.report.due_date = element.due_date;
                    this.report.creator_last_name = element.creator_last_name;
                    this.report.creator_first_name = element.creator_first_name;
                    this.report.addressee_last_name = element.addressee_last_name;
                    this.report.addressee_first_name = element.addressee_first_name;
                    this.report.addressee_company_name = element.addressee_company_name;
                    if (element.seen) {
                        this.report.color = '#eaeaea';
                    }
                    this.orgReports.push(this.report);
                    // @ts-ignore
                    this.report = {};
                });
                this.reports = this.orgReports;
                this.loading.remove();
                this.disable_load = false;
            },
            (error: HttpErrorResponse) => {
                // TODO: server errors
                console.log(error);
                this.loading.remove();
                this.disable_load = false;
            }
        );
    }

    loadReportType() {
      const tc = [{id: null, label: 'همه'}];

      this.http.get(Config.settings.api + 'report/report-type/').subscribe(
          (response: any) => {
            response.forEach((element) => {
              if (element.name) {
                tc.push({
                  id: element.id,
                  label: element.name,
                });
              }
            });
            this.filter_items[4].options = tc;
            this.filter_items.map((el) => {
              const ret = el;
              if (el.name === 'type_id') {
                ret.options = tc;
              }
              return ret;
            });
            // this.typeItem = tc;
          },
          (error: HttpErrorResponse) => {
            console.log(error);
          }
      );
    }

    loadCreator() {
      const tc = [{id: null, label: 'همه'}];
      const api: string =
          Config.settings.api + 'user/user/?q=2';
      this.http.get(api).subscribe(
          (response: any) => {
            console.log(response);
            response.forEach((element) => {
              tc.push({
                id: element.id,
                label: `${element.first_name} ${element.last_name}`,
              });
            });
            this.filter_items.map((el) => {
              const ret = el;
              if (el.name === 'creator_id') {
                ret.options = tc;
              }
              return ret;
            });
          },
          (error: HttpErrorResponse) => {
            console.log(error);
          }
      );
    }

    loadActivity() {
        const tc = [{id: null, label: 'همه'}];
        const activityTypeUrl =
            Config.settings.api + 'report/report-activity-type/';
        this.http.get(activityTypeUrl).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.name,
                    });
                });
                this.filter_items.map((el) => {
                    const ret = el;
                    if (el.name === 'activity_type_id') {
                        ret.options = tc;
                    }
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }
    loadAddresseeType() {
      const tc = [{id: null, label: 'همه'}];
      const api: string =
          Config.settings.api + 'report/report-addressee-type/';
      this.http.get(api).subscribe(
          (response: any) => {
            response.forEach((element) => {
              tc.push({
                id: element.id,
                label: element.name,
              });
            });
            this.filter_items.map((el) => {
              const ret = el;
              if (el.name === 'addressee_type_id') {
                ret.options = tc;
              }
              return ret;
            });
          },
          (error: HttpErrorResponse) => {
            console.log(error);
          }
      );
    }
    loadActiontype() {
      const tc = [{id: null, label: 'همه'}];
      this.http.get(Config.settings.api + 'report/report-action-type/').subscribe(
          (response: any) => {
            response.forEach((element) => {
              tc.push({
                id: element.id,
                label: element.name,
              });
            });
            this.filter_items.map((el) => {
              const ret = el;
              if (el.name === 'action_type_id') {
                ret.options = tc;
              }
              return ret;
            });
          },
          (error: HttpErrorResponse) => {
            console.log(error);
          }
      );
    }
    loadState() {
      const tc = [{id: null, label: 'همه'}];
      this.http.get(Config.settings.api + 'report/report-state/').subscribe(
          (response: any) => {
            response.forEach((element) => {
              tc.push({
                id: element.id,
                label: element.name,
              });
            });
            this.filter_items.map((el) => {
              const ret = el;
              if (el.name === 'state_id') {
                ret.options = tc;
              }
              return ret;
            });
          },
          (error: HttpErrorResponse) => {
            console.log(error);
          }
      );
    }

    loadSeenMarketing(){
        const tc = [{id: null, label: 'همه'}];
        tc.push({
            id: true,
            label: 'مشاهده شده',
        });
        tc.push({
            id: false,
            label: 'مشاهده نشده',
        });
        this.filter_items.map((el) => {
            const ret = el;
            if (el.name === 'seen_by_marketing_manager' || el.name === 'seen_by_vice_president') {
                ret.options = tc;
            }
            return ret;
        });
    }

    search(filter: any) {
        console.log(filter);
        this.searchTerm = '';
        Object.keys(filter).forEach((el) => {
          this.searchTerm += el + '=' + filter[el] + '&';
          // console.log(el);
        });

        if (this.searchTerm !== '') {
          this.searchTerm = this.searchTerm.slice(0, -1);
        }
        localStorage.setItem('searchReport',  this.searchTerm);
        const qp = filter;
        // qp['q'] = this.searchTerm;

        this.router.navigate([], {
          queryParams: qp,
          // queryParamsHandling: "merge",
        });
        this.load();
        this.isCollapsed = true;
    }

    // searchFilter(form){
    //   debugger;
    //   const filter = {
    //       type_id: undefined
    //   };
    //   let tc = {} ;
    //   const data =   form.value;
    //   if (data.type_id !== '' && data.type_id !== null) {
    //       filter.type_id = data.type_id;
    //   }
    //   if (filter.type_id !== undefined ) {
    //       // @ts-ignore
    //       tc = filter;
    //   }
    //   console.log(tc);
    //   this.search(tc);
    // }
    del(content, i) {
        if (!i) {
            return;
        }
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.reportApi + i + '/').subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        // TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    sort(col: string) {
        debugger;
        if (this.sortcol === col) {
            this.sortcol = '-' + col;
        } else {
            this.sortcol = col;
        }
        this.load();
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnDestroy() {
    }

}
