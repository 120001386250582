<div class="m-3 ml-5 mt-4">
  <app-title title="فرم تعریف سوالات ارزیابی رضایت مشتریان"></app-title>

  <div *ngIf="success" class="" role="">
    <div
      class="d-flex text-success justify-content-center align-items-center"
    >
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ msg }}
    </div>
  </div>
  <div *ngIf="isWrong" class="" role="">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ errorMsg }}
    </div>
  </div>

  <form
    #form="ngForm"
    (ngSubmit)="register(form)"
    class="d-flex flex-column align-items-center"
  >
    <div class="order-header w-100 mt-3">
      <div class="row justify-content-between">
        <div class="item">
          شماره فرم ارزیابی:
          {{ id?.toString() | toFa }}
        </div>
        <div class="item">
          تاریخ ایجاد فرم:
          {{ date.toISOString() | jDate | toFa }}
        </div>
        <div class="item">
          وضعیت:
          <div class="mx-1 d-flex align-items-center">
            فعال<input
              class="mx-1"
              type="radio"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="status"
              [value]="true"
            />
          </div>
          <div class="mx-1 d-flex align-items-center">
            غیر فعال<input
              class="mx-1"
              type="radio"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="status"
              [value]="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="list mx-3 w-100">
      <table class="table table-bordered">
        <thead class="thead-light headerless">
          <tr>
            <th scope="col">#</th>
            <th scope="col">عنوان سوال</th>
            <th scope="col">ارزش سوال</th>
            <th scope="col">امتیاز کامل سول</th>
            <th scope="col">توضیحات</th>
            <th scope="col">X</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let el of questions; let i = index">
            <th>{{ i + 1 }}</th>
            <td class="p-0">
              <textarea
                class="border-0 form-control"
                [(ngModel)]="questions[i].body"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
            </td>
            <td class="p-0">
              <input
                type="number"
                class="border-0 form-control form-control-lg"
                [(ngModel)]="questions[i].value"
                [ngModelOptions]="{ standalone: true }"
              />
            </td>
            <td class="p-0">
              {{ el.value * 10 }}
            </td>
            <td class="p-0">
              <textarea
                class="border-0 form-control"
                [(ngModel)]="questions[i].detail"
                [ngModelOptions]="{ standalone: true }"
              ></textarea>
            </td>
            <td class="cursor-pointer" (click)="remove(i)">
              <i class="fal fa-trash-alt"></i>
            </td>
          </tr>
          <tr>
            <th class="border-left-0"></th>
            <td (click)="add()" class="border-right-0">
              <i class="fal fa-plus"></i>
            </td>
            <td></td>
            <td>{{ score_sum }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row w-100 mt-3">
      <button type="submit" class="btn mr-auto ml-3 w-25 btn-success">
        ذخیره
      </button>
      <button
        [routerLink]="['../']"
        type="button"
        class="btn ml-2 w-25 btn-secondary"
      >
        بازگشت
      </button>
    </div>
  </form>
</div>
