<div class="m-3 mt-4">
    <app-title title="{{title}}" *ngIf="isTitle"></app-title>
    <app-toolbar [toolbarItems]="toolbarItemList" (clickHandler)="itmeClickHandler($event)"></app-toolbar>
    <div *ngIf="success" class="" role="">
        <div
                class="d-flex text-success justify-content-center align-items-center"
        >
            <i class="fas fa-exclamation-circle ml-1"></i>
            {{ msg }}
        </div>
    </div>
    <div class="list my-3 mx-auto">
        <div class="list-header">
            <div class="list-header-item">
                ردیف:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.0rem; font-weight: 400"
                >
                    {{ object_count.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item">
                تعدادصفحه:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.0rem; font-weight: 400"
                >
                    {{ page_sheet_sum.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item d-lg-table-cell d-none">
                تعدادکل:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.0rem; font-weight: 400"
                >
                    {{ sheet_sum.toString() | toFa }}
                </div>
            </div>


            <div class="list-header-item d-lg-table-cell d-none">
                تخفیفات :
                <div
                        class="text-success"
                        style="font-size: 1.0rem; font-weight: 400"
                >
                    {{ all_discount_amounts.toString() | toR }}
                </div>
            </div>
            <div class="list-header-item d-lg-table-cell d-none">
                مبلغ خالص:
                <div
                        class="text-success"
                        style="font-size: 1.0rem; font-weight: 400"
                >
                    {{ (all_order_amounts - all_discount_amounts).toString() | toR }}
                </div>
            </div>

            <div
                    class="list-header-item mr-auto cursor-pointer"
                    (click)="isCollapsed = !isCollapsed"
            >
                <i class="far fa-file-search"></i>فیلتر
            </div>
        </div>
        <app-filter
                [isCollapsed]="isCollapsed"
                [filter_items]="filter_items"
                (changeFilter)="search($event)"
        ></app-filter>
        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col" (click)="sort('buyer_company_name')">
                    نام فروشگاه
                </th>
                <th scope="col" *ngIf="show_warehouse">
                    نام انبار استانی
                </th>
                <th class="d-lg-table-cell d-none" scope="col" (click)="sort('date')">تاریخ</th>
                <th
                        scope="col"
                        class="d-lg-table-cell d-none"
                        (click)="sort('id')"
                >
                    سفارش
                </th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('invoice_number')">پیش‌فاکتور</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('pallet_sum')">تعداد</th>
                <th scope="col" class="d-lg-table-cell d-none">مبلغ سفارش</th>
                <th scope="col" class="d-lg-table-cell d-none">تخفیفات</th>
                <th scope="col" class="d-lg-table-cell d-none">مبلغ خالص</th>
                <th
                        scope="col"
                        class="d-lg-table-cell d-none"
                        (click)="sort('creator_first_name')"
                >
                    ثبت کننده
                </th>
                <th
                        scope="col"
                        class="d-lg-table-cell d-none"
                        (click)="sort('sale_expert_last_name')"
                >
                    کارشناس
                </th>
                <th scope="col" (click)="sort('state')">وضعیت سفارش</th>
                <th scope="col">وضعیت تسویه</th>

                <th scope="col">ویرایش</th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr
                    *ngFor="let el of orders; let i = index"
                    (dblclick)="edit(el.id)"
            >
                <th scope="row">
                    {{ (page_number - 1) * page_size + i + 1 | toFa }}
                </th>
                <td>{{ el.buyer_company_name }}</td>
                <td *ngIf="show_warehouse">{{ el.warehouse_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.date | jDate | toFa }}</td>
                <td class="d-lg-table-cell d-none">{{ el.id | toFa }}</td>
                <td class="d-lg-table-cell d-none">{{ el.invoice_number | toFa }}</td>
                <td class="d-lg-table-cell d-none">{{ el.sum | toFa }}</td>
                <td class="d-lg-table-cell d-none">
                    {{ el.debt_amount | toR }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ el.discount_amount | toR }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ (el.debt_amount - el.discount_amount)?.toString() | toR }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ el.creator_first_name + " " + el.creator_last_name }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{
                    el.buyer_marketing_expert_first_name +
                    " " +
                    el.buyer_marketing_expert_last_name
                    }}
                </td>

                <td>
                    {{
                    el.state !== 7 && el.state <= 11
                        ? func.order_states[el.state]
                        : "به " +
                        el.representative_company_name +
                        " ارجاع داده شد"
                    }}
                </td>
                <td>{{ el.checkout_state }}</td>
                <td class="cursor-pointer" (click)="edit(el.id)" >
                    <i class="fal fa-edit"></i>
                </td>
                <td class="cursor-pointer" (click)="del(content, el.id)">
                    <i class="fal fa-trash-alt"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center">
        <app-pagination
                [page_count]="page_count"
                [page_number]="page_number"
                (changePage)="load($event)"
        ></app-pagination>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            آیا از حذف این رکورد اطمینان دارید؟
        </h4>
    </div>
    <div class="modal-body">
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(true)"
        >
            بله
        </button>
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(false)"
        >
            خیر
        </button>
    </div>
</ng-template>
<ng-template #reportContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">لطفا فیلد های مورد نیاز در گزارش را انتخاب کنید</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="row box">
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        نام فروشگاه
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="buyer_company_name"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        تاریخ
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="date"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        شماره سفارش
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="id"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        پیش فاکتور
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="invoice_number"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        تعداد ورق
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="sum"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        مبلغ سفارش
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="debt_amount"
                    />
                </div>
            </div>
            <div class="row box" style="margin-top: 0px">
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        تخفیفات
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="discount_amoun"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        ثبت کننده
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="creator_last_name"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        کارشناس بازاریابی
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="buyer_marketing_expert_last_name"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        وضعیت سفارش
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="state_name"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                    <label class="mx-3 lead" style="font-size: 1rem">
                        وضعیت تسویه
                    </label>
                    <input
                            type="checkbox"
                            class="ml-3"
                            [(ngModel)]="checkout_state"
                    />
                </div>
                <div class="form-group col d-flex align-items-center justify-content-between m-3">
                </div>
                </div>
        </div>
        <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(true)"
            >
                تایید
            </button>
            <button
                    type="button"
                    class="btn btn-outline-dark mx-2"
                    (click)="modal.close(false)"
            >
                انصراف
            </button>
        </div>
    </div>
</ng-template>