<header>
   <section class="bg-gray-dark py-3">
      <div class="px-4 w-100">
         <div class="row align-itmes-center">
            <div class="ml-auto">
               <img class="navbar-logo" src="../../assets/img/logo.svg" />
            </div>
            <div class="mr-auto d-flex align-items-center">
               <div class="px-2 h-75 d-flex align-items-center border-left">
                  <span class="text-white">
                     <i
                        class="fa fa-user text-primary mr-0 ml-2 align-middle"
                        aria-hidden="true"
                     ></i
                     >سلام {{ user?.first_name + " " + user?.last_name }}
                  </span>
               </div>
               <div class="px-2 h-75 d-flex align-items-center border-left">
                  <a href="#">
                     <span class="text-white">
                        <i
                           class="fa fa-home text-primary mr-0 ml-2 align-middle"
                           aria-hidden="true"
                        ></i
                        >بازگشت به سایت
                     </span>
                  </a>
               </div>
               <div class="px-2 h-75 d-flex align-items-center">
                  <span
                     style="cursor: pointer"
                     (click)="auth.logout()"
                     class="text-white"
                  >
                     <i
                        class="fa fa-power-off text-primary mr-0 ml-2 align-middle"
                        aria-hidden="true"
                     ></i>
                  </span>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="bg-gray py-2">
      <div
         class="text-white px-5 w-100 d-flex justify-content-end secondary-header"
      >
         <i
            class="d-sm-none fa fa-bars ml-auto te"
            (click)="toggleSidebar.emit()"
         ></i>
         <div class="item">
            <small class=""
               >امروز ،{{ today.toISOString() | jDate | toFa }}</small
            >
         </div>
         <div class="item">
            <small>تاریخ ایجـاد: {{ user?.created_at | jDate | toFa }}</small>
         </div>
         <div class="item">
            <small>تاریخ ویرایش : {{ user?.updated_at | jDate | toFa }}</small>
         </div>
      </div>
   </section>
</header>
