<div class="row">
    <div class="col-sm-6 col-md-6 col-lg-6">
        <div *ngIf="user && !(user.role ==2 || user.role ==3 )"
             style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
            <h5
                    style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                سفارشات ارجاعی
            </h5>
            <table style="width: 100%;margin: 0px;">
                <thead class="thead-light"
                       style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                <tr>
                    <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                    <th scope="col" style="border-left: 1px solid gray;">
                        عنوان
                    </th>
                    <th scope="col" style="border-left: 1px solid gray;">
                        تعداد سفارشات
                    </th>
                    <th scope="col" style="border-left: 1px solid gray;">
                        تعداد ورق
                    </th>

                    <th scope="col">مشاهده</th>

                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let el of referredOrder; let i = index"
                    style="text-align: center;border-top: 1px solid gray;">
                    <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>

                    <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;width: 120px;">{{ el.name }}</td>
                    <td style="border-left: 1px solid gray;">
                        {{ el.n_orders  | number}}
                    </td>
                    <td style="border-left: 1px solid gray;">
                        {{ el.n_sheets  | number}}
                    </td>

                    <td>
                        <div class="fa fa-eye" class="cursor-pointer" [routerLink]="['/userArea/order']"
                             [queryParams]=el.parm
                             routerLinkActive="text-primary"
                             [routerLinkActiveOptions]="{ exact: true }">

                            <i class="fa fa-eye"></i>

                        </div>

                    </td>
                </tr>
                </tbody>
            </table>
            <div *ngIf="referredOrder.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
            </div>
        </div>
        <div style="margin-top: 5px;padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
            <h5
                    style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                تسویه حساب
            </h5>
            <table style="width: 100%;margin: 0px;">
                <thead class="thead-light"
                       style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                <tr>
                    <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                    <th scope="col" style="border-left: 1px solid gray;">
                        عنوان
                    </th>
                    <th scope="col" style="border-left: 1px solid gray;">
                        تعداد سفارشات
                    </th>
                    <th scope="col" style="border-left: 1px solid gray;">
                        مبلغ سفارش
                    </th>
                    <th  class="d-lg-table-cell d-none"
                            scope="col" style="border-left: 1px solid gray;"
                    >
                        مبلغ واریز شده
                    </th>
                    <th  class="d-lg-table-cell d-none" scope="col" style="border-left: 1px solid gray;">مبلغ باقی مانده</th>
                    <th scope="col">مشاهده</th>

                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let el of checkOut; let i = index"
                    style="text-align: center;border-top: 1px solid gray;">
                    <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                    <!-- <td>{{ i + 1 | toFa }}</td> -->
                    <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;width: 80px;">{{ el.name }}</td>
                    <td style="border-left: 1px solid gray;">
                        {{ el.n_orders  | number}}
                    </td>
                    <td style="border-left: 1px solid gray;">
                        {{ el.order_amount  | number}}
                    </td>
                    <td  class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                        {{ el.paid_amount  | number}}
                    </td>
                    <td  class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                        {{ el.remaining_amount  | number}}
                    </td>
                    <td>
                        <div class="fa fa-eye" class="cursor-pointer" [routerLink]="['/userArea/order/checkout']"
                             [queryParams]=el.parm
                             routerLinkActive="text-primary"
                             [routerLinkActiveOptions]="{ exact: true }">

                            <i class="fa fa-eye"></i>

                        </div>

                    </td>
                </tr>
                </tbody>
            </table>
            <div *ngIf="checkOut.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6">
        <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
            <h5
                    style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                سفارشات من

            </h5>
            <table style="width: 100%;margin: 0px;">
                <thead class="thead-light"
                       style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                <tr>
                    <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                    <th scope="col" style="border-left: 1px solid gray;">
                        عنوان
                    </th>
                    <th scope="col" style="border-left: 1px solid gray;">
                        تعداد سفارشات
                    </th>
                    <th scope="col" style="border-left: 1px solid gray;">
                        تعداد ورق
                    </th>

                    <th scope="col">مشاهده</th>

                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let el of myOrder; let i = index"
                    style="text-align: center;border-top: 1px solid gray;">
                    <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                    <!-- <td>{{ i + 1 | toFa }}</td> -->
                    <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;width: 150px;">{{ el.name }}</td>
                    <td style="border-left: 1px solid gray;">
                        {{ el.n_orders  | number}}
                    </td>
                    <td style="border-left: 1px solid gray;">
                        {{ el.n_sheets | number}}
                    </td>
                    <td>
                        <div class="fa fa-eye" class="cursor-pointer"
                             [routerLink]=el.router
                             [queryParams]=el.parm
                             routerLinkActive="text-primary"
                             [routerLinkActiveOptions]="{ exact: true }">

                            <i class="fa fa-eye"></i>

                        </div>

                    </td>
                    <!--                    <td class="cursor-pointer" [ngSwitch]="user.role">-->
                    <!--                        <i class="fa fa-eye" *ngSwitchCase="1" [routerLink]="['/userArea/order']" [queryParams]="{ in_company:0 ,role: 2}"-->
                    <!--                           routerLinkActive="text-primary"-->
                    <!--                           [routerLinkActiveOptions]="{ exact: true }"></i>-->
                    <!--                        <i class="fa fa-eye" *ngSwitchDefault></i>-->
                    <!--                    </td>-->
                </tr>
                </tbody>
            </table>
            <div *ngIf="myOrder.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
            </div>
        </div>
    </div>
</div>

<!--<jqxTabs #jqxTabs-->
<!--         [width]="getWidth()" [height]="400" [position]="'top'" [rtl]="true">-->
<!--    <ul style="margin-left: 10px;">-->
<!--        <li>Avatar</li>-->
<!--        <li>End Game</li>-->
<!--        <li>Twilight</li>-->
<!--        <li>Unstoppable</li>-->
<!--        <li>Priest</li>-->
<!--    </ul>-->
<!--    <div style="text-align: right;  direction: rtl;">-->
<!--        <jqxChart style="float: left"-->
<!--                  [width]="400" [height]="250"-->
<!--                  [title]="''"-->
<!--                  [description]="charts[0].title"-->
<!--                  [showLegend]="true" [enableAnimations]="false" [padding]="padding"-->
<!--                  [titlePadding]="titlePadding" [source]="charts[0].dataSource" [showBorderLine]="true"-->
<!--                  [seriesGroups]="seriesGroups" [colorScheme]="'scheme03'">-->
<!--        </jqxChart>-->

<!--    </div>-->
<!--    <div style="text-align: right;  direction: rtl;">-->
<!--        <img src="./../../../images/endgame.png" style="float: right; margin: 10px;" alt="" />-->
<!--        End Game is a 2006 action/thriller film, written and directed by Andy Cheng. The-->
<!--        film stars Cuba Gooding, Jr. as Secret Service agent Alex Thomas, who is shot in-->
<!--        the hand, while unsuccessfully trying to protect the President (played by Jack Scalia)-->
<!--        from an assassin's bullet. Later, with the help of a persistent newspaper reporter-->
<!--        named Kate Crawford (played by Angie Harmon), he uncovers a vast conspiracy behind-->
<!--        what initially appeared to be a lone gunman. James Woods, Burt Reynolds, and Anne-->
<!--        Archer co–star in this film that was originally set to be shown in cinemas by MGM-->
<!--        in 2005, but was delayed by the takeover from Sony and eventually sent direct to-->
<!--        DVD.-->
<!--    </div>-->
<!--    <div style="text-align: right;  direction: rtl;">-->
<!--        <img src="./../../../images/twilight.png" style="float: right; margin: 10px;" alt="" />-->
<!--        The project was in development for approximately three years at Paramount Pictures,-->
<!--        during which time a screen adaptation that differed significantly from the novel-->
<!--        was written. Summit Entertainment acquired the rights to the novel after three years-->
<!--        of the project's stagnant development. Melissa Rosenberg wrote a new adaptation-->
<!--        of the novel shortly before the 2007–2008 Writers Guild of America strike and sought-->
<!--        to be faithful to the novel's storyline. Principal photography took 44 days, and-->
<!--        completed on May 2, 2008; the film was primarily shot in Oregon-->
<!--    </div>-->
<!--    <div style="text-align: right;  direction: rtl;">-->
<!--        <img src="./../../../images/unstoppable.png" style="float: right; margin: 10px;" alt="" />-->
<!--        Meanwhile, in a rail yard within the northern town of Fuller, two AWVR hostlers,-->
<!--        Dewey (Ethan Suplee) and Gilleece (T.J. Miller), are ordered by Fuller operations-->
<!--        dispatcher Bunny (Kevin Chapman) to move a freight train led by locomotive #777-->
<!--        (nicknamed "Triple Seven") off its current track to clear the track for an excursion-->
<!--        train carrying schoolchildren. Dewey attempts to take shortcuts, instructing Gilleece-->
<!--        to leave the hoses for the air brakes disconnected for the short trip. Dewey later-->
<!--        leaves the moving cab to throw a misaligned rail switch along the train's path,-->
<!--        but is unable to climb back on, as the train's throttle jumps from idle, to full-->
<!--        power. He is forced to report the train as a "coaster" to Fuller yardmaster Connie-->
<!--        Hooper (Rosario Dawson)...-->
<!--    </div>-->
<!--    <div style="text-align: right; direction: rtl;">-->
<!--        <img src="./../../../images/priest.png" style="float: right; margin: 10px;" alt="" />-->
<!--        Priest is a 2011 American post-apocalyptic sci-fi western and supernatural action-->
<!--        film starring Paul Bettany as the title character. The film, directed by Scott Stewart,-->
<!--        is based on the Korean comic of the same name. In an alternate world, humanity and-->
<!--        vampires have warred for centuries. After the last Vampire War, the veteran Warrior-->
<!--        Priest (Bettany) lives in obscurity with other humans inside one of the Church's-->
<!--        walled cities. When the Priest's niece (Lily Collins) is kidnapped by vampires,-->
<!--        the Priest breaks his vows to hunt them down. He is accompanied by the niece's boyfriend-->
<!--        (Cam Gigandet), who is a wasteland sheriff, and a former Warrior Priestess (Maggie-->
<!--        Q).-->
<!--    </div>-->
<!--</jqxTabs>-->

<!--<div style="font-size: 13px; font-family: Verdana; float: left">-->
<!--    <style>-->
<!--        #toolbar {-->
<!--            display: flex;-->
<!--            height: 100%;-->
<!--            padding: 0 1rem;-->
<!--        }-->
<!--        #toolbar.warning:after {-->
<!--            content: 'Selected data cannot be used to create a chart.';-->
<!--            position: absolute;-->
<!--            top: 50%;-->
<!--            right: 10px;-->
<!--            transform: translateY(-50%);-->
<!--            color: var(&#45;&#45;smart-error);-->
<!--        }-->
<!--        #toolbar > div {-->
<!--            display: flex;-->
<!--            align-items: flex-end;-->
<!--            justify-content: center;-->
<!--            margin-right: 15px;-->
<!--            width: 35px;-->
<!--            height: 30px;-->
<!--            height: 100%;-->
<!--            font-size: 12px;-->
<!--            background-size: contain;-->
<!--            background-repeat: no-repeat;-->
<!--            user-select: none;-->
<!--            cursor: pointer;-->
<!--        }-->
<!--        #toolbar > div:hover {-->
<!--            background-color: var(&#45;&#45;smart-grid-column-header-background-hover);-->
<!--        }-->
<!--        #toolbar > div:active {-->
<!--            background-color: var(&#45;&#45;smart-grid-column-header-background-active);-->
<!--        }-->
<!--        #toolbar > div.warning {-->
<!--            outline: 2px solid var(&#45;&#45;smart-error);-->
<!--        }-->
<!--        #column {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-column.png");-->
<!--        }-->
<!--        #line {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-line.png");-->
<!--        }-->
<!--        #pie {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-pie.png");-->
<!--        }-->
<!--        #bar {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-bar.png");-->
<!--        }-->
<!--        #area {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-area.png");-->
<!--        }-->
<!--        #scatter {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-scatter.png");-->
<!--        }-->
<!--        [theme="dark"] #column {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-column-dark.png");-->
<!--        }-->
<!--        [theme="dark"] #line {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-line-dark.png");-->
<!--        }-->
<!--        [theme="dark"] #pie {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-pie-dark.png");-->
<!--        }-->
<!--        [theme="dark"] #bar {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-bar-dark.png");-->
<!--        }-->
<!--        [theme="dark"] #area {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-area-dark.png");-->
<!--        }-->
<!--        [theme="dark"] #scatter {-->
<!--            background-image: url("../../jqwidgets/styles/images/create-scatter-dark.png");-->
<!--        }-->
<!--    </style>-->
<!--    <jqxGrid #jqxgrid [rendertoolbar]="myGridRenderToolbar"-->
<!--             [width]="getWidth()" [source]="dataAdapter"-->
<!--             [autoheight]=true-->
<!--             [showtoolbar]=true-->
<!--             [toolbarheight]=50-->
<!--             [columns]="columns" [selectionmode]="'multiplecellsadvanced'">-->
<!--    </jqxGrid>-->
<!--</div>-->



