import {Component, OnInit, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpClient} from '@angular/common/http';
import {Router, ActivatedRoute, UrlSegment} from '@angular/router';
import {Config} from 'src/app/app-config.service';
import {FormControl} from '@angular/forms';
import {debounceTime, throttleTime} from 'rxjs/operators';
import * as Func from 'src/pack/js/func';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from 'src/app/loading/loading.service';
import {AuthService} from 'src/app/auth/auth.service';

@Component({
    selector: 'app-warehouse-orders-list',
    templateUrl: './warehouse-orders-list.component.html',
    styleUrls: ['./warehouse-orders-list.component.scss'],
    host: {class: 'w-100'},
})
export class WarehouseOrdersListComponent implements OnInit {
    page_number: number = 1;
    object_count: number = 0;
    page_count: number = 0;

    usersApi: string = Config.settings.api + Config.settings.user.user;

    isCollapsed = true;
    filter_items = [
        {
            name: 'id',
            label: 'شماره سفارش',
            type: 'string',
        },
        {
            name: 'warehouse_name',
            label: 'عنوان انبار استانی',
            type: 'string',
        },
        {
            name: 'warehouse_id',
            label: 'انبار استانی',
            type: 'list',
            options: [],
            res: null,
        },
        {
            name: 'code',
            label: 'کد راهکاران',
            type: 'string',
        },


        {
            name: 'state',
            label: 'وضعیت سفارش',
            type: 'list',
            options: [],
            res: null,
        },
        {
            name: 'state_name',
            label: 'نام وضعیت سفارش',
            type: 'string',
        },
        {
            name: 'warehouse_responsible_id',
            label: 'مسئول انبار',
            type: 'list',
            options: [],
            res: null,
        },
        {
            name: 'warehouse_responsible_company_name',
            label: 'نام شرکت مسئول انبار',
            type: 'string',
        },

        {
            name: 'creator_id',
            label: 'ایجاد کننده',
            type: 'list',
            options: [],
            res: null,
        },

        {
            name: 'creator_name',
            label: 'نام ایجادکننده‌ی سفارش انبار',
            type: 'string',
        },

        {
            name: 'start_created_at',
            label: 'تاریخ ثبت از',
            type: 'date',
        },
        {
            name: 'end_created_at',
            label: 'تاریخ ثبت تا',
            type: 'date',
        },


        {
            name: 'start_date',
            label: 'تاریخ سفارش از',
            type: 'date',
        },
        {
            name: 'end_date',
            label: 'تاریخ سفارش تا',
            type: 'date',
        },


        {
            name: 'start_sending_date',
            label: 'تاریخ ارسال سفارش از',
            type: 'date',
        },
        {
            name: 'end_sending_date',
            label: 'تاریخ ارسال سفارش تا',
            type: 'date',
        },
        {
            name: 'start_receinving_date',
            label: 'تاریخ دریافت سفارش از',
            type: 'date',
        },
        {
            name: 'end_receinving_date',
            label: 'تاریخ دریافت سفارش تا',
            type: 'date',
        },

    ];

    warehouseOrders: any[] = [];
    orgWarehouseOrders: any[] = [];
    searchControl = new FormControl();
    selected: boolean[] = [];
    warehouseOrdersApi: string = Config.settings.api + 'warehouse/warehouse-input/';
    func = Func;
    searchTerm: string;
    total_sheet_sum: number = 0;
    page_sheet_sum: number = 0;
    all_discount_amounts: number = 0;
    all_order_amounts: number = 0;
    page_size: any;
    is_today: boolean;

    constructor(
        private auth: AuthService,
        private router: Router,
        private http: HttpClient,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private loading: LoadingService
    ) {
    }

    can_del = true;
    user;

    async ngOnInit(): Promise<void> {
        this.user = await this.auth.get_info();
        // this.route.queryParams.subscribe((f) => {
        this.route.queryParamMap.subscribe((f) => {
            let filter = {};
            f.keys.forEach((k) => (filter[k] = f.get(k)));
            console.log(filter);
            console.log(filter['in_company']);

            if (this.user.role == 4 && filter['in_company'] == '0') {
                this.warehouseOrdersApi += 'customer_orders/';
                delete filter['in_company'];
            } else if (
                this.user.role <= 4 &&
                this.user.role >= 2 &&
                filter['in_company'] == '1'
            ) {
                delete filter['in_company'];
            }

            this.filter_items.forEach(
                (el) => (el.res = filter[el.name] ? filter[el.name] : null)
            );
            this.search(filter);
        });

        this.searchControl.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe((term) => this.search(term));


        // this.loadExperts();
        this.loadCreator();
        this.loadWarehouse_responsible();
        this.loadWarehouse();
        this.loadStates();
    }

    disable_load = false;

    load(page = 1) {
        if (this.disable_load) {
            return;
        }
        this.disable_load = true;
        this.loading.add();

        console.log(this.warehouseOrdersApi);

        this.orgWarehouseOrders = [];
        this.http
            .get(
                this.warehouseOrdersApi +
                `?page=${page}&${this.searchTerm}&ordering=${this.sortcol}`
            )
            .subscribe(
                (response: any) => {

                    this.page_count = response.page_count;
                    this.object_count = response.object_count;
                    this.page_number = response.page_number;
                    this.page_size = response.page_size;
                    this.total_sheet_sum = response.total_sheet_sum;
                    this.page_sheet_sum = response.page_sheet_sum;
                    //
                    response.results.forEach((element) => {
                        element.sum = 0;
                        element.warehouse_input_details.forEach((element_1) => {
                            console.log(element_1.sheet_number);
                            element.sum += element_1.sheet_number;
                        });
                        this.orgWarehouseOrders.push(element);
                        console.log(element);
                    });
                    this.warehouseOrders = this.orgWarehouseOrders;

                    // //
                    this.loading.remove();
                    this.disable_load = false;
                },
                (error: HttpErrorResponse) => {
                    //TODO: server errors
                    console.log(error);
                    this.orgWarehouseOrders = [];
                    this.warehouseOrders = [];
                    this.page_count = 0;
                    this.page_number = 0;
                    this.object_count = 0;
                    this.total_sheet_sum = 0;
                    this.all_discount_amounts = 0;
                    this.all_order_amounts = 0;
                    this.page_sheet_sum = 0;
                    this.loading.remove();

                    this.disable_load = false;
                }
            );
    }

    loadExperts() {
        let tc = [{id: null, label: 'همه'}];

        this.http.get(this.usersApi + '?q=5').subscribe(
            (response: any) => {
                response.forEach((element) => {
                    if (element.name) {
                        tc.push({
                            id: element.id,
                            label: element.name,
                        });
                    }
                });
                // this.filter_items[4].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == 'expert_name') {
                        ret.options = tc;
                    }
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    loadCreator() {
        let tc = [{id: null, label: 'همه'}];
        let api: string =
            Config.settings.api + 'user/user/?q=1';
        this.http.get(api).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: `${element.first_name} ${element.last_name}`,
                    });
                });
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == 'creator_id') {
                        ret.options = tc;
                    }
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    loadWarehouse_responsible() {
        let tc = [{id: null, label: 'همه'}];
        let api: string =
            Config.settings.api +"user/user/?role=4";
        this.http.get(api).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: `${element.first_name} ${element.last_name}`,
                    });
                });
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == 'warehouse_responsible_id') {
                        ret.options = tc;
                    }
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    loadStates() {
        let tc = [{id: null, label: 'همه'}];
        let stateApi: string =
            Config.settings.api + 'warehouse/warehouse-input-state/';
        this.http.get(stateApi).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.name,
                    });
                });
                // this.filter_items[7].options = tc;
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == 'state') {
                        ret.options = tc;
                    }
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    loadWarehouse() {
        //
        let tc = [{id: null, label: 'همه'}];
        let warehouseApi: string =
            Config.settings.api + 'warehouse/warehouse/';
        this.http.get(warehouseApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    tc.push({
                        id: element.id,
                        label: element.name,
                    });
                });
                this.filter_items.map((el) => {
                    let ret = el;
                    if (el.name == 'warehouse_id') {
                        ret.options = tc;
                    }
                    return ret;
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    search(filter: any) {
        console.log(filter);

        this.searchTerm = '';
        Object.keys(filter).forEach((el) => {
            this.searchTerm += el + '=' + filter[el] + '&';
            // console.log(el);
        });

        if (this.searchTerm != '') {
            this.searchTerm = this.searchTerm.slice(0, -1);
        }

        let qp = filter;
        // qp['q'] = this.searchTerm;

        this.router.navigate([], {
            queryParams: qp,
            // queryParamsHandling: "merge",
        });
        this.load();
        this.isCollapsed = true;
    }

    edit(i = null) {
        if (i) {
            this.router.navigate(['/userArea/order/', i]);
        }
        this.selected.forEach((el, i) => {
            if (el) {
                this.router.navigate(['/userArea/order/', i]);
            }
        });
    }

    del(content, i) {
        if (!i) {
            return;
        }
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.warehouseOrdersApi + i + '/').subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    sortcol: string = 'date';

    sort(col: string) {
        if (this.sortcol == col) {
            this.sortcol = '-' + col;
        } else {
            this.sortcol = col;
        }
        this.load();
    }

    ngOnDestroy() {
    }
}
