import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Config } from "src/app/app-config.service";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";
import { AuthService } from "src/app/auth/auth.service";
import * as Func from "src/pack/js/func";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ThrowStmt } from "@angular/compiler";
import { Subject } from "rxjs";
import { LoadingService } from "src/app/loading/loading.service";
import {ToolbarItemModel} from "../../../../models/toolbar.model";

@Component({
  selector: "app-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.scss"],
  host: { class: "w-100" },
})
export class CustomerListComponent implements OnInit {
  func = Func;
  page_number: number = 1;
  object_count: number = 0;
  page_size: number = 0;
  page_count: number = 0;
  activity_types = [];
  nature_ids = [];
  province_change: Subject<void> = new Subject<void>();
  isCollapsed = true;
  filter_items = [
    {
      name: "searching_term",
      label: "عبارت جست‌وجو",
      type: "string",
    },
    {
      name: "name",
      label: "نام",
      type: "string",
    },
    {
      name: "company_name",
      label: "نام فروشگاه",
      type: "string",
    },
    {
      name: "reference",
      label: "نام فروشنده مادر",
      type: "string",
    },
    {
      name: "start_created_at",
      label: "از تاریخ",
      type: "date",
    },
    {
      name: "end_created_at",
      label: "تا تاریخ",
      type: "date",
      res: null,
    },
    {
      name: "type",
      label: "حقیقی/حقوقی",
      type: "list",
      options: [
        // { id: null, label: 'همه' },
        {
          id: "natural",
          label: "حقیقی",
        },
        {
          id: "juridical",
          label: "حقوقی",
        },
      ],
      res: null,
    },
    {
      name: "role",
      label: "نوع مخاطب",
      type: "list",
      options: [
        // { id: null, label: 'همه' },
        {
          id: 2,
          label: "مشتری",
        },
        {
          id: 3,
          label: "عامل فروش",
        },
        {
          id: 4,
          label: "نماینده",
        },
      ],
      res: null,
    },
    {
      name: "province",
      label: "استان",
      type: "list",
      options: [],
      res: null,
      onChange: this.province_change,
    },
    {
      name: "city",
      label: "شهرستان",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "region",
      label: "منطقه",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "marketing_expert",
      label: "نام کارشناس بازاریابی",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "sale_expert",
      label: "نام کارشناس فروش",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "is_active",
      label: "وضعیت مشتری",
      type: "list",
      options: [
        {id: null, label: "همه" },
        { id: true, label: "فعال" },
        { id: false, label: "غیر فعال" },
      ],
      res: null,
    },
    {
      name: "activity_type",
      label: "زمینه کاری",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "activity_industry",
      label: "صنعت مورد فعالیت",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "activity_product",
      label: "محصولات/خدمات",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "nature_id",
      label: "ماهیت مشتری",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "familiarity_type_id",
      label: "نحوه آشنایی",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "code",
      label: "کد مشتری",
      type: "string",
    },
    {
      name: "phone_number",
      label: "شماره تلفن",
      type: "string",
    }, {
      name: "postal_address",
      label: "آدرس پستی",
      type: "string",
    },
  ];
  field = {
    fields : null
  } ;
  @ViewChild("reportContent")
  reportContent:ElementRef;
  customers: any[] = [];
  orgCustomers: any[] = [];
  searchControl = new FormControl();
  selected: boolean[] = [];
  searchTerm;
  usersApi: string = Config.settings.api + Config.settings.user.user;
  is_today = false;
  status = 1;
  success: boolean;
  msg: string;
  fields: any [] = [];
  company_name: boolean ;
  type: boolean ;
  province_name: boolean ;
  city_name: boolean ;
  phone_number: boolean ;
  created_at: boolean ;
  marketing_expert_last_name: boolean ;
  creator_last_name: boolean ;
  familiarity_type_name: boolean ;
  nature_name: boolean ;
  role_name: boolean ;
  toolbarItemList: ToolbarItemModel[] = [
    {
      name: "new",
      icon: "fas fa-plus",
      text: "جدید",
      type: "button",
      btn_type: "info"
    },
    {
      name: "refresh",
      icon: "fas fa-sync",
      text: "به‌روزرسانی",
      type: "button",
      btn_type: "primary"
    },
    {
      name: "multiple",
      icon: "fal fa-ballot-check",
      text: "انتخاب چند گانه",
      type: "button",
      btn_type: "primary"
    },
    {
      name: "space",
      text: "space",
      type: "space",
    },
    {
      name:"cancel",
      text: "لغو",
      type:"icon-button",
      icon: "fal fa-times-circle fa-2x text-danger",
    },
    {
      name:"print",
      text: "چاپ",
      type:"icon-button",
      icon: "fal fa-print fa-2x text-info",
    },
    {
      name:"excel",
      text: "خروجی اکسل",
      type:"icon-button",
      icon: "fal fa-file-excel fa-2x text-success",
    },
    {
      name: "space",
      text: "space",
      type: "space",
    },
    {
      name:"operations",
      text:"عملیات",
      icon: "fas fa-cogs",
      type:"menu",
      btn_type:"primary",
      items: [
        {
          name:"cancel",
          text: "لغو",
          type:"icon-button",
          icon: "fal fa-times-circle",
        },
        {
          name:"print",
          text: "چاپ",
          type:"icon-button",
          icon: "fal fa-print ",
        },
        {
          name:"excel",
          text: "خروجی اکسل",
          type:"icon-button",
          icon: "fal fa-file-excel",
        }
      ]
    }
  ]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalService: NgbModal,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    if (this.router.url == "/userArea/customer/today")
      this.is_today = true;
    let filter = {};

    const queryParamMap = this.route.snapshot["queryParamMap"];
    const queryParamEntries = queryParamMap.keys.forEach((key) => {
      filter[key] = queryParamMap.get(key);
    });
    this.search(filter);

    this.filter_items.forEach(
      (el) => (el.res = filter[el.name] ? filter[el.name] : null)
    );
    this.loadProductApi();
    this.loadIndustry();
    this.loadActivity();
    this.loadExperts();
    this.load_pro();
    this.load_reg();
    this.changeProvince();
    this.natureLists();
    this.familiarityLists();
    this.province_change.subscribe((pro) => this.changeProvince(pro));
    // this.loadCustomers();
  }

  disable_load = false;
  load(page = 1) {
    debugger;
    if (this.disable_load) return;
    this.disable_load = true;
    this.loading.add();

    this.orgCustomers = [];

    this.http
      .get(
        this.usersApi +
          `?q=3&page=${page}&${this.searchTerm}&ordering=${this.sortcol}${
            this.is_today
              ? "&start_created_at=" +
                new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3)
                  .toJSON()
                  .slice(0, 11) +
                "00:00:00.000Z"
              : ""
          }`
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.page_count = response.page_count;
          this.object_count = response.object_count;
          this.page_number = response.page_number;
          this.page_size = response.page_size;

          response.results.forEach((element) => {
            element.type = element.type == "natural" ? "حقیقی" : "حقوقی";
            if (element.role >= 2 && element.role <= 4)
              this.orgCustomers.push(element);
          });
          this.customers = this.orgCustomers;
        },
        (error: HttpErrorResponse) => {
          this.customers = [];
          //TODO: server errors
          console.log(error);
          this.loading.remove();
          this.disable_load = false;
        },
        () => {
          this.loading.remove();
          this.disable_load = false;
        }
      );
  }

  loadExperts() {
    // let tc = [{ id: null, label: 'همه' }];
    let tc = [{ id: null, label: "همه" }];

    this.http.get(this.usersApi + "?q=5").subscribe(
      (response: any) => {
        response.forEach((element) => {
          if (element.name)
            tc.push({
              id: element.id,
              label: element.name,
            });
        });
        // this.filter_items[4].options = tc;
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "marketing_expert" || el.name == "sale_expert")
            ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  async load_pro() {
    let tc = [{ id: null, label: "همه" }];
    // let tc = [{ id: null, label: 'همه' }];

    let provinceApi: string =
      Config.settings.api + Config.settings.info.province;
    this.http.get(provinceApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          tc.push({
            id: element.id,
            label: element.name,
          });
        });
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "province") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  async load_reg() {
    let tc = [{ id: null, label: "همه" }];
    // let tc = [{ id: null, label: 'همه' }];

    let regionApi: string =
      Config.settings.api + Config.settings.add.region;
    this.http.get(regionApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          tc.push({
            id: element.id,
            label: element.name,
          });
        });
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "region") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  province = null;

  changeProvince($event = null) {
    this.province = $event;

    let tc = [{ id: null, label: "همه" }];
    // let tc = [{ id: null, label: 'همه' }];
    if (this.province == null) {
      this.filter_items.map((el) => {
        let ret = el;
        if (el.name == "city") ret.options = tc;
        if (el.name == "city") ret.res = null;
        return ret;
      });
      return;
    }

    let cityApi: string =
      Config.settings.api +
      Config.settings.add.citys +
      `?province=${$event}`;
    this.http.get(cityApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          tc.push({
            id: element.id,
            label: element.name,
          });
        });
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "city") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  loadCustomers() {
    let tc = [{ id: null, label: "همه" }];
    // let tc = [{ id: null, label: 'همه' }];

    this.http.get(this.usersApi + "?q=4").subscribe(
      (response: any) => {
        console.log(response);
        response.forEach((element) => {
          if (element.company_name)
            tc.push({
              id: element.id,
              label: element.company_name,
            });
        });
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "company_name") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }
  natureLists() {
    debugger;
    let tc = [{ id: null, label: "همه" }];

    this.http.get(Config.settings.api + "information/user-nature/").subscribe(
      (response: any) => {
        console.log(response);
        response.forEach((element) => {
            tc.push({
              id: element.id,
              label: element.name,
            });
        });
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "nature_id") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }
  familiarityLists() {
    debugger;
    let tc = [{ id: null, label: "همه" }];

    this.http.get(Config.settings.api + "information/familiarity-type/").subscribe(
      (response: any) => {
        console.log(response);
        response.forEach((element) => {
            tc.push({
              id: element.id,
              label: element.name,
            });
        });
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "familiarity_type_id") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  search(filter: any) {
    console.log(filter);

    this.searchTerm = "";
    Object.keys(filter).forEach((el) => {
      this.searchTerm += el + "=" + filter[el] + "&";
      // console.log(el);
    });

    if (this.searchTerm != "")
      this.searchTerm = this.searchTerm.slice(0, -1);

    console.log(this.searchTerm);
    let qp = filter;
    // qp['q'] = this.searchTerm;
    console.log(qp);

    this.router.navigate([], {
      queryParams: qp,
    });
    this.load();
    this.isCollapsed = true;
  }

  edit(i = null) {
    if (i) {
      this.router.navigate(["/userArea/customer/", i]);
    }
    this.selected.forEach((el, i) => {
      if (el) {
        this.router.navigate(["/userArea/customer/", i]);
      }
    });
  }

  del(content, i) {
    if (!i) return;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http
            .delete(
              Config.settings.api + Config.settings.user.user + i + "/"
            )
            .subscribe(
              (response: any) => {
                this.load();
              },
              (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
              }
            );
        }
      });
  }

  sortcol: string = "-created_at";

  sort(col: string) {
    debugger;
    if (this.sortcol == col) this.sortcol = "-" + col;
    else this.sortcol = col;
    this.load();
  }

  loadActivity(){
    let activity_typeApi: string =
        Config.settings.api + "information/activity-type/";
    let tc = [{ id: null, label: "همه" }];
    this.http.get(activity_typeApi).subscribe(
        (response: any) => {
          response.forEach((element) => {
            tc.push({
              id: element.id,
              label: element.name,
            });
          });
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "activity_type") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  loadIndustry(){
    let industryApi: string =
        Config.settings.api + "information/activity-industry/";
    let tc = [{ id: null, label: "همه" }];
    this.http.get(industryApi).subscribe(
        (response: any) => {
          response.forEach((element) => {
            tc.push({
              id: element.id,
              label: element.name,
            });
          });
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "activity_industry") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  loadProductApi(){
    let activity_productApi: string =
        Config.settings.api + "information/activity-product/";
    let tc = [{ id: null, label: "همه" }];

    this.http.get(activity_productApi).subscribe(
        (response: any) => {
          response.forEach((element) => {
            tc.push({
              id: element.id,
              label: element.name,
            });
          });
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "activity_product") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  itmeClickHandler(event) {
    console.log(event);
    switch (event.name) {
      case 'new':
        this.router.navigate(['userArea/customer/new']);
        break;
      case 'refresh':
        this.load();
        break;
      case 'excel':
        this.downloadFile(this.reportContent);
    }
  }

  downloadFile(content){
    if (this.status === 1) {
      this.status = 0 ;
      debugger;
      this.modalService
          .open(content, { ariaLabelledBy: "modal-basic-title" })
          .result.then((result) => {
        if (result) {
          this.setFields();
          this.field = {
            fields: this.fields
          };
          this.http.post(Config.settings.api + `user/user/download/?q=3&${this.searchTerm}`, this.field).subscribe(
              (response: any) => {
                this.status = 1 ;
                this.success = false;
                this.msg = " ";
                this.removeFields();
                this.fields = [];
                this.field = {
                  fields : null
                };
                window.open(Config.settings.download + response.link, "_blank");
              },
              (error: HttpErrorResponse) => {
                console.log(error);
                this.status = 1 ;
                this.fields = [];
                this.field = {
                  fields : null
                };
                this.removeFields();
              }
          );
        } else {
          this.fields = [];
          this.field = {
            fields : null
          };
          this.removeFields();
          this.status = 1 ;
        }
      });
    } else {
      this.success = true;
      this.msg = "فایل در حال دانلود می باشد...";
    }

  }

  removeFields(){
    this.company_name = false;
    this.type = false;
    this.province_name = false;
    this.city_name = false;
    this.phone_number = false;
    this.created_at = false;
    this.marketing_expert_last_name = false;
    this.creator_last_name = false;
    this.familiarity_type_name = false;
    this.nature_name = false;
    this.role_name = false;
  }

  setFields() {
    if (this.company_name){
      this.fields.push("company_name");
    }
    if (this.type){
      this.fields.push("type");
    }
    if (this.province_name){
      this.fields.push("province_name");
    }
    if (this.city_name){
      this.fields.push("city_name");
    }
    if (this.phone_number){
      this.fields.push("phone_number");
    }
    if (this.created_at){
      this.fields.push("created_at");
    }
    if (this.marketing_expert_last_name){
      this.fields.push("marketing_expert_last_name");
    }
    if (this.creator_last_name){
      this.fields.push("creator_last_name");
    }

    if (this.familiarity_type_name){
      this.fields.push("familiarity_type_name");
    }
    if (this.nature_name){
      this.fields.push("nature_name");
    }
    if (this.role_name){
      this.fields.push("role_name");
    }
  }


  ngOnDestroy() {}
}
