<div class="m-3 mt-4">
  <!-- <app-title title="لیست پرسنل"></app-title> -->
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
    </div>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">شماره حساب</th>
          <th scope="col">نام صاحب حساب</th>
          <th scope="col">نام بانک</th>
          <th scope="col">شعبه</th>
          <th scope="col">وضعیت استعلام</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of bankAccounts; let i = index">
          <th scope="row">
            {{ i + 1 | toFa }}
          </th>
          <td>{{ el.account_number }}</td>
          <td>{{ el.account_owner_name }}</td>
          <td>{{ el.bank_name }}</td>
          <td>{{ el.branch_name }}</td>
          <td>{{ el.inquiry_status_name }}</td>
          <td class="cursor-pointer" (click)="edit(editcontent, i)">
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-title
    (click)="show = !show"
    [attr.aria-expanded]="show"
    aria-controls="collapse"
    [class.open]="show"
    type="plus"
    title="اضافه کردن"
    class="cursor-pointer"
  ></app-title>

  <div id="collapse" [ngbCollapse]="!show" class="box">
    <div class="row">
      <div class="form-group col-md-6 col-12">
        <label>شماره حساب</label>
        <input
          type="text"
          [(ngModel)]="bankaccount.account_number"
          name="account_number"
          (keydown)="func.numControl($event)"
          class="form-control form-control-lg"
          maxlength="11"
          required
        />
      </div>

      <div class="form-group col-md-6 col-12">
        <label>نام صاحب حساب</label>
        <input
          type="text"
          [(ngModel)]="bankaccount.account_owner_name"
          name="account_owner_name"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="banks"
          placeholder="بانک"
          (selectChange)="bankaccount.bank = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-4 col-12">
        <label>شعبه</label>
        <input
          type="text"
          [(ngModel)]="bankaccount.branch_name"
          name="branch_name"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="inquiry_statuses"
          placeholder="وضعیت استعلام"
          (selectChange)="bankaccount.inquiry_status = $event"
          req="true"
        ></app-select>
      </div>

      <div class="row w-100 mb-3">
        <button
          (click)="register()"
          class="btn mr-auto ml-3 w-25 btn-success"
        >
          ثبت
        </button>
      </div>
    </div>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          آیا از حذف این رکورد اطمینان دارید؟
        </h4>
      </div>
      <div class="modal-body">
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(true)"
        >
          بله
        </button>
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(false)"
        >
          خیر
        </button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-6 col-12">
        <label>شماره حساب</label>
        <input
          type="text"
          [(ngModel)]="editing_bankAccount.account_number"
          name="account_number"
          (keydown)="func.numControl($event)"
          class="form-control form-control-lg"
          maxlength="11"
          required
        />
      </div>

      <div class="form-group col-md-6 col-12">
        <label>نام صاحب حساب</label>
        <input
          type="text"
          [(ngModel)]="editing_bankAccount.account_owner_name"
          name="account_owner_name"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="banks"
          [key]="editing_bankAccount.bank"
          placeholder="بانک"
          (selectChange)="editing_bankAccount.bank = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-4 col-12">
        <label>شعبه</label>
        <input
          type="text"
          [(ngModel)]="editing_bankAccount.branch_name"
          name="branch_name"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="inquiry_statuses"
          [key]="editing_bankAccount.inquiry_status"
          placeholder="وضعیت استعلام"
          (selectChange)="editing_bankAccount.inquiry_status = $event"
          req="true"
        ></app-select>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(true)"
      >
        تایید
      </button>
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>
