import {
   ChangeDetectionStrategy,
   ChangeDetectorRef,
   Component,
   EmbeddedViewRef,
   EventEmitter,
   Input,
   OnDestroy,
   OnInit,
   Output,
   TemplateRef,
   ViewContainerRef,
   NgZone,
   OnChanges,
   ViewChild,
   ElementRef,
   AfterViewInit,
} from '@angular/core';

import Popper from 'popper.js';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { fromEvent, Observable } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
   selector: 'app-select',
   templateUrl: './select.component.html',
   styleUrls: ['./select.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent
   implements OnInit, OnDestroy, OnChanges, AfterViewInit {
   @Input() model;
   @Input() key;
   @Input() can_edit: boolean = true;
   @Input() simple = false;
   @Input() green = false;
   @Input() labelKey = 'label';
   @Input() idKey = 'id';
   @Input() placeholder = 'انتخاب کنید...';
   @Input() options = [];
   @Input() optionTpl: TemplateRef<any>;
   @Output() selectChange = new EventEmitter();
   @Output() closed = new EventEmitter();
   @Input() req = false;
   @Input() refresh: Observable<void> = null;
   @ViewChild('dropdown') dropdown: TemplateRef<any>;
   @ViewChild('origin') origin: HTMLElement;

   math = Math;
   visibleOptions = 4;
   searchControl = new FormControl();

   private view: EmbeddedViewRef<any>;
   private popperRef: Popper;
   originalOptions = [];

   constructor(
      private vcr: ViewContainerRef,
      private zone: NgZone,
      private cdr: ChangeDetectorRef
   ) {}

   get isOpen() {
      return !!this.popperRef;
   }
   cnt = 0;
   ngOnChanges(changes) {
      this.originalOptions = this.options;
      if (this.key !== undefined) {
         this.model = this.options.find(
            (currentOption) => currentOption[this.idKey] === this.key
         );
         if (this.model) this.req = false;
      }
   }

   ngAfterViewInit() {
      if (this.refresh)
         this.refresh.subscribe(() => {
            let o = this.isOpen;
            if (o) this.close();
            this.originalOptions = this.options;
            if (this.key !== undefined) {
               this.model = this.options.find(
                  (currentOption) => currentOption[this.idKey] === this.key
               );
               if (this.model) this.req = false;
            }
            if (o) this.open(this.dropdown, this.origin);
         });
   }

   ngOnInit() {
      if (this.key !== undefined) {
         this.model = this.options.find(
            (currentOption) => currentOption[this.idKey] === this.key
         );
      }

      this.searchControl.valueChanges
         .pipe(debounceTime(300), untilDestroyed(this))
         .subscribe((term) => this.search(term));
   }

   get label() {
      return this.model ? this.model[this.labelKey] : '';
   }

   open(dropdownTpl: TemplateRef<any>, origin: HTMLElement) {
      if (!this.can_edit) return;
      this.view = this.vcr.createEmbeddedView(dropdownTpl);
      const dropdown = this.view.rootNodes[0];

      document.body.appendChild(dropdown);
      dropdown.style.width = `${Math.max(origin.offsetWidth, 200)}px`;
      // dropdown.style.height = `${32 * this.options.length}px`;

      this.zone.runOutsideAngular(() => {
         this.popperRef = new Popper(origin, dropdown, {
            removeOnDestroy: true,
            placement: 'bottom-start',
            positionFixed: false,
            modifiers: {
               preventOverflow: { enabled: false },
            },
         });
      });

      this.handleClickOutside();
   }

   close() {
      this.closed.emit();
      this.popperRef.destroy();
      this.view.destroy();
      this.searchControl.patchValue('');
      this.view = null;
      this.popperRef = null;
   }

   select(option) {
      this.req = false;
      this.model = option;
      this.selectChange.emit(option[this.idKey]);
      // the handleClickOutside function will close the dropdown
   }

   isActive(option) {
      if (!this.model) {
         return false;
      }
      return option[this.idKey] === this.model[this.idKey];
   }

   search(value: string) {
      this.options = this.originalOptions.filter((option) =>
         option[this.labelKey].toLowerCase().includes(value.toLowerCase())
      );
      requestAnimationFrame(
         () => (this.visibleOptions = this.options.length || 1)
      );
   }

   private handleClickOutside() {
      fromEvent(document, 'click')
         .pipe(
            filter(({ target }) => {
               const origin = this.popperRef.reference as HTMLElement;
               return origin.contains(target as HTMLElement) === false;
            }),
            takeUntil(this.closed)
         )
         .subscribe(() => {
            this.close();
            this.cdr.detectChanges();
         });
   }

   c(event) {
      // console.log(event);
      if (
         event.relatedTarget === null ||
         !event.relatedTarget.className.includes('select-item')
      )
         this.close();
   }

   permissionControl(e): void {
      if (!this.can_edit) e.preventDefault();
   }

   ngOnDestroy() {}
}
