import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Config} from '../../../app-config.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {L10n, setCulture} from '@syncfusion/ej2-base';
import * as EJ2_LOCALE from '@syncfusion/ej2-locale/src/fa.json';
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {AuthService} from "../../../auth/auth.service";
import {ToolbarItemModel} from "../../../models/toolbar.model";

@Component({
    selector: 'app-report-edit',
    templateUrl: './report-edit.component.html',
    styleUrls: ['./report-edit.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReportEditComponent implements OnInit {

    public month: number = new Date().getMonth();
    public fullYear: number = new Date().getFullYear();
    public dateValueStart: Date;
    public dateValueEnd: Date;
    @ViewChild("form")
    form: ElementRef;

    @ViewChild("sendDueDatePicker")
    sendDueDatePicker: ElementRef;

    @ViewChild("sendCompletionDatePicker")
    sendCompletionDatePicker: ElementRef;

    isWrong: boolean;
    errorMsg: string;
    success: boolean;
    msg: string;
    showAddresseeType: boolean;
    showUser: boolean;
    seenByMarketing = false;
    seenByVice = false;
    conf = Config;
    type: number;
    types: any[] = [];
    users: any[] = [];
    activityTypes: any[] = [];
    actionTypes: any[] = [];
    addresseeTypes: any[] = [];
    images: any[] = [];
    files: any[] = [];
    allFile: any[] = [];
    dueDate = new Date();
    completionDate = new Date();
    date = new Date();
    control = new FormControl();
    userList: any[] = [];
    filteredUsers;
    reportApi: string = Config.settings.api + 'report/report/';
    addressee_id;

    currentReport = {
        id: 0,
        creatorFirstName: '',
        addresseeCompanyName: '',
        stateName: '',
        created_at: null,
        activity_date: null,
        due_date: null,
        completion_date: null,
        updated_at: null,
        type: 0,
        addresseeType: 0,
        address: 0,
        activityType: 0,
        actionType: 0,
        activityTitle: '',
        details_by_marketing_manager: '',
        details_by_vice_president: '',
        activityDetails: '',
        state: 0
    };
    user;

    toolbarItemList: ToolbarItemModel[] = [];


    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private auth: AuthService,
        private router: Router,
        private modalService: NgbModal
    ) {
    }


    async ngOnInit(): Promise<void> {
        this.user = await this.auth.get_info();
        this.addressee_id = localStorage.getItem('addressee_id');
        this.load();
        const typeApi =
            Config.settings.api + 'report/report-type/';
        this.http.get(typeApi).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.types.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );

        const addresseeTypeUrl =
            Config.settings.api + 'report/report-addressee-type/';
        this.http.get(addresseeTypeUrl).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.addresseeTypes.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
        const activityTypeUrl =
            Config.settings.api + 'report/report-activity-type/';
        this.http.get(activityTypeUrl).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.activityTypes.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
        const actionTypeUrl =
            Config.settings.api + 'report/report-action-type/';
        this.http.get(actionTypeUrl).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.actionTypes.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );

        L10n.load(EJ2_LOCALE.fa);
        setCulture('fa');

    }

    changeType($event) {
        this.type = $event;
        // @ts-ignore
        this.showAddresseeType = this.type === 2;
        this.showUser = this.type === 2;
    }

    changStartDate(e) {
        this.dateValueStart = e.value;
    }

    changEndDate(e) {
        this.dateValueEnd = e.value;
    }

    changeAddressee($event) {
        if ($event == null) {
            return;
        }
        let addresseeUrl = Config.settings.api;
        const addresseeId = $event;
        switch (addresseeId) {
            case 1:
                addresseeUrl = addresseeUrl + 'user/user/?q=2';
                break;
            case 2:
                addresseeUrl = addresseeUrl + 'user/user/?role=2';
                break;
            case 3:
                addresseeUrl = addresseeUrl + 'user/user/?role=3';
                break;
            case 4:
                addresseeUrl = addresseeUrl + 'user/user/?role=4';
                break;
        }
        this.showUser = true;
        this.http.get(addresseeUrl).subscribe(
            (response: any) => {
                let tc = [];
                response.forEach((element) => {
                    if (addresseeId === 1) {
                        tc.push({
                            id: element.id,
                            // tslint:disable-next-line:max-line-length
                            label: element.first_name + ' ' + element.last_name,
                        });
                    } else if (addresseeId === 2) {
                        if (element.company_name !== '') {
                            tc.push({
                                id: element.id,
                                label: element.company_name,
                            });
                        } else {
                            tc.push({
                                id: element.id,
                                label: element.first_name + ' ' + element.last_name,
                            });
                        }
                    } else {
                        tc.push({
                            id: element.id,
                            label: element.company_name,
                        });
                    }
                });
                this.userList = [];
                this.userList = tc;
                this.filteredUsers = this.control.valueChanges.pipe(
                    startWith(""),
                    map((value) => this._filterP(value))
                );
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    private _filterP(value: string): string[] {
        const filterValue = value;
        return this.userList.filter((option) =>
            option.label.includes(filterValue)
        );
    }

    async register(form, content = null, state = 0) {
        this.allFile = [];
        this.files.forEach(value => {
            this.allFile.push(value);
        });
        this.images.forEach(value => {
            this.allFile.push(value);
        });
        // tslint:disable-next-line:max-line-length
        const url = Config.settings.api + 'report/report/' + this.currentReport.id + '/';
        if (content == null) {
            const data = form.value;
            if (this.currentReport.address !== 0) {
                data.addressee = this.currentReport.address;
            }
            debugger;
            data.activity_start_time = this.dateValueStart;
            data.activity_end_time = this.dateValueEnd;
            data.seen_by_marketing_manager = this.seenByMarketing;
            data.seen_by_vice_president = this.seenByVice;
            if (state === 3) {
                data.state = 3;
            } else if (state !== 5) {
                data.state = 1;
            }
            this.setImage(data);
            // tslint:disable-next-line:max-line-length

            console.log(JSON.stringify(data));
            await this.http
                .patch(url, data)
                .toPromise()
                .then((response: any) => {
                    this.success = true;
                    this.isWrong = false;
                    this.msg = `گزارش ویرایش شد.`;
                    this.load();
                })
                .catch((error: HttpErrorResponse) => {
                    console.log(error);
                    this.isWrong = true;
                    this.success = false;
                    this.errorMsg = 'دوباره امتحان کنید.';
                    window.scroll(0, 0);
                    if (error.error.message) {
                        this.errorMsg = error.error.message;
                    }
                });
        } else {
            await this.modalService
                .open(content, {ariaLabelledBy: 'modal-basic-title'})
                .result.then(async (result) => {
                    let data = form.value;
                    if (this.currentReport.address !== 0) {
                        data.addressee = this.currentReport.address;
                    }
                    this.setImage(data);
                    data.due_date = this.dueDate;
                    if (state !== 0) {
                        data.state = state;
                        data.completion_date = this.completionDate;
                        // tslint:disable-next-line:max-line-length
                        if (this.dateValueStart === null || this.dateValueStart === undefined) {
                            this.success = true;
                            this.isWrong = false;
                            this.load();
                            this.msg = `لطفا ساعت شروع تکمیل شود`;
                            return;
                        }
                        // tslint:disable-next-line:max-line-length
                        if (this.dateValueEnd === null || this.dateValueEnd === undefined) {
                            this.success = true;
                            this.isWrong = false;
                            this.load();
                            this.msg = `لطفا ساعت پایان تکمیل شود`;
                            return;
                        }
                        data.activity_start_time = this.dateValueStart;
                        data.activity_end_time = this.dateValueEnd;
                    } else {
                        data.state = 1;
                    }
                    await this.http
                        .patch(url, data)
                        .toPromise()
                        .then((response: any) => {
                            this.success = true;
                            this.isWrong = false;
                            this.load();
                            this.msg = `عملیات با موفقیت انجام شد.`;
                        })
                        .catch((error: HttpErrorResponse) => {
                            console.log(error);
                            this.isWrong = true;
                            this.success = false;
                            this.errorMsg = 'دوباره امتحان کنید.';
                            window.scroll(0, 0);
                            if (error.error.message) {
                                this.errorMsg = error.error.message;
                            }
                        });
                });
        }
    }

    setImage(data) {
        if (this.allFile.length) {
            if (this.allFile[0] == null) {
                data.image_url_1 = null;
            } else {
                data.image_url_1 = this.allFile[0];
            }
            if (this.allFile[1] == null) {
                data.image_url_2 = null;
            } else {
                data.image_url_2 = this.allFile[1];
            }
            if (this.allFile[2] == null) {
                data.image_url_3 = null;
            } else {
                data.image_url_3 = this.allFile[2];
            }
            if (this.allFile[3] == null) {
                data.image_url_4 = null;
            } else {
                data.image_url_4 = this.allFile[3];
            }
            if (this.allFile[4] == null) {
                data.image_url_5 = null;
            } else {
                data.image_url_5 = this.allFile[4];
            }
            if (this.allFile[5] == null) {
                data.image_url_6 = null;
            } else {
                data.image_url_6 = this.allFile[5];
            }
        }
    }

    add_image(e) {
        console.log(e);
        if (this.images.length < 6) {
            // tslint:disable-next-line:max-line-length
            const url: string = Config.settings.api + Config.settings.info.file;
            const formData: FormData = new FormData();
            formData.append(
                'file',
                e.target.files.item(0),
                e.target.files.item(0).name
            );
            console.log(formData);
            const headers = {dt: 'yes'};
            this.http.post(url, formData, {headers}).subscribe(
                (res: any) => {
                    this.images.push(res.url.slice(1));
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    window.scroll(0, 0);
                }
            );
        } else {
            this.success = false;
            this.isWrong = true;
            this.errorMsg = 'تعداد عکس ها بیشتر از 6 عدد نمی تواند باشد!';
            window.scroll(0, 0);
        }

    }

    add_file(e) {
        if ((this.images.length + this.files.length) < 6) {
            // tslint:disable-next-line:max-line-length
            const url: string = Config.settings.api + Config.settings.info.file;
            const formData: FormData = new FormData();
            formData.append(
                'file',
                e.target.files.item(0),
                e.target.files.item(0).name
            );
            console.log(formData);
            const headers = {dt: 'yes'};
            this.http.post(url, formData, {headers}).subscribe(
                (res: any) => {
                    if (res.url.search('.pdf') === -1) {
                        this.images.push(res.url.slice(1));
                    } else {
                        this.files.push(res.url.slice(1));
                    }
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    window.scroll(0, 0);
                }
            );
        } else {
            this.success = false;
            this.isWrong = true;
            this.errorMsg = 'تعداد عکس ها بیشتر از 6 عدد نمی تواند باشد!';
            window.scroll(0, 0);
        }
    }

    remove(i) {
        this.images.splice(i, 1);
    }

    remove_file(i) {
        this.files.splice(i, 1);
    }

    goToLink(url: string, i) {
        const fileUrl = this.files[i];
        window.open(url + fileUrl, '_blank');
    }

    load() {
        this.toolbarItemList = [];
        this.route.params.subscribe((params) => {
            if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
                this.router.navigate(['/404']).then(r => '/404');
            } else {
                this.currentReport.id = params.id;
            }
            const url = this.reportApi + `${this.currentReport.id}/`;
            this.http.get(url).subscribe(
                (response: any) => {
                    if (this.user.role ===  1 || this.user.role ===  16 || this.user.role ===  5) {
                        this.toolbarItemList.push(
                            {
                                name: "confirmManagement",
                                icon: "far fa-edit",
                                text: "تایید مدریت",
                                type: "button",
                                btn_type: "btn btn-success"
                            }
                        );
                        if (response.state === 2) {
                            this.toolbarItemList.push(
                                {
                                    name: "confirmEdit",
                                    icon: "far fa-edit",
                                    text: "ویرایش مجدد",
                                    type: "button",
                                    btn_type: "btn btn-primary"
                                }
                            );
                        }
                    }
                    if (response.state === 1) {
                        this.toolbarItemList.push(
                            {
                                name: "save",
                                icon: "fas fa-save",
                                text: "تکمیل فعالیت",
                                type: "button",
                                btn_type: "primary"
                            },
                            {
                                name: "cancel",
                                icon: "fas fa-times-circle",
                                text: "لغو/کنسل",
                                type: "button",
                                btn_type: "btn btn-gray"
                            },
                            {
                                name: "saveEdit",
                                icon: "far fa-edit",
                                text: "ویرایش/اصلاح",
                                type: "button",
                                btn_type: "btn btn-primary"
                            },
                            {
                                name: "saveDate",
                                icon: "far fa-clock",
                                text: "مهلت اقدم",
                                type: "button",
                                btn_type: "btn btn-warning"
                            }
                        );
                        this.setItemToolbar();
                    } else {
                        this.setItemToolbar();
                    }
                    let imageIndex = 0;
                    let fileIndex = 0;
                    if (response.image_url_1 !== null) {
                        if (response.image_url_1.search('.pdf') === -1) {
                            // tslint:disable-next-line:max-line-length
                            this.images[imageIndex] = response.image_url_1;
                            imageIndex++;
                        } else {
                            this.files[fileIndex] = response.image_url_1;
                            fileIndex++;
                        }
                    }
                    if (response.image_url_2 !== null) {
                        if (response.image_url_2.search('.pdf') === -1) {
                            this.images[imageIndex] = response.image_url_2;
                            imageIndex++;
                        } else {
                            this.files[fileIndex] = response.image_url_2;
                            fileIndex++;
                        }
                    }
                    if (response.image_url_3 !== null) {
                        if (response.image_url_3.search('.pdf') === -1) {
                            this.images[imageIndex] = response.image_url_3;
                            imageIndex++;
                        } else {
                            this.files[fileIndex] = response.image_url_3;
                            fileIndex++;
                        }
                    }
                    if (response.image_url_4 !== null) {
                        if (response.image_url_4.search('.pdf') === -1) {
                            // tslint:disable-next-line:max-line-length
                            this.images[imageIndex] = response.image_url_4;
                            imageIndex++;
                        } else {
                            this.files[fileIndex] = response.image_url_4;
                            fileIndex++;
                        }
                    }
                    if (response.image_url_5 !== null) {
                        if (response.image_url_5.search('.pdf') === -1) {
                            // tslint:disable-next-line:max-line-length
                            this.images[imageIndex] = response.image_url_5;
                            imageIndex++;
                        } else {
                            this.files[fileIndex] = response.image_url_5;
                            fileIndex++;
                        }
                    }
                    if (response.image_url_6 !== null) {
                        if (response.image_url_6.search('.pdf') === -1) {
                            // tslint:disable-next-line:max-line-length
                            this.images[imageIndex] = response.image_url_6;
                            imageIndex++;
                        } else {
                            this.files[fileIndex] = response.image_url_6;
                            fileIndex++;
                        }
                    }
                    // tslint:disable-next-line:max-line-length
                    this.currentReport.addresseeType = response.addressee_type;
                    this.changeAddressee(response.addressee_type);
                    // tslint:disable-next-line:max-line-length
                    this.currentReport.creatorFirstName = response.creator_first_name;
                    this.showAddresseeType = response.type === 2;
                    this.showUser = response.type === 2;
                    // tslint:disable-next-line:max-line-length
                    this.currentReport.activityType = response.activity_type;
                    // tslint:disable-next-line:max-line-length
                    this.currentReport.activity_date = response.activity_date;
                    // tslint:disable-next-line:max-line-length
                    this.currentReport.completion_date = response.completion_date;
                    this.currentReport.due_date = response.due_date;
                    this.currentReport.created_at = response.created_at;
                    this.currentReport.updated_at = response.updated_at;
                    this.currentReport.type = response.type;
                    this.currentReport.address = response.addressee;
                    debugger;
                    this.currentReport.details_by_marketing_manager = response.details_by_marketing_manager;
                    this.currentReport.details_by_vice_president = response.details_by_vice_president;
                    this.seenByMarketing = response.seen_by_marketing_manager;
                    this.seenByVice = response.seen_by_vice_president;
                    if (response.addressee_company_name !== '') {
                        this.control.setValue({
                            id: response.addressee,
                            label: response.addressee_company_name
                        });
                    } else {
                        this.control.setValue({
                            id: response.addressee,
                            // tslint:disable-next-line:max-line-length
                            label: response.addressee_first_name + ' ' + response.addressee_last_name
                        });
                    }
                    this.currentReport.actionType = response.action_type;
                    // tslint:disable-next-line:max-line-length
                    this.currentReport.activityTitle = response.activity_title;
                    // tslint:disable-next-line:max-line-length
                    this.currentReport.activityDetails = response.activity_details;
                    // tslint:disable-next-line:max-line-length
                    this.currentReport.addresseeCompanyName = response.addressee_company_name;
                    this.currentReport.stateName = response.state_name;
                    this.currentReport.state = response.state;

                    if (response.activity_start_time != null) {
                        // tslint:disable-next-line:max-line-length
                        this.dateValueStart = new Date(new Date(response.activity_start_time).getFullYear(), new Date(response.activity_start_time).getMonth()
                            // tslint:disable-next-line:max-line-length
                            , new Date(response.activity_start_time).getDate(), new Date(response.activity_start_time).getUTCHours(), new Date(response.activity_start_time).getUTCMinutes(), 0);
                    }
                    if (response.activity_end_time != null) {
                        // tslint:disable-next-line:max-line-length
                        this.dateValueEnd = new Date(new Date(response.activity_end_time).getFullYear(), new Date(response.activity_end_time).getMonth()
                            // tslint:disable-next-line:max-line-length
                            , new Date(response.activity_end_time).getDate(), new Date(response.activity_end_time).getUTCHours(), new Date(response.activity_end_time).getUTCMinutes(), 0);
                    }
                },
                (error: HttpErrorResponse) => {
                    // TODO: server errors
                    console.log(error);
                }
            );
        });
    }

    showC(el) {
        return el && el.label;
    }

    addresseeChange(el) {
        this.currentReport.address = el.id;
    }

    selectChange = (event: any) => {
        // const key: string = event.key;
        // this.currentReport[key] = [ ...event.data ];
        this.currentReport.address = event.id;
    };

    changeMarketing() {
        this.seenByMarketing = !this.seenByMarketing;
        console.log(this.seenByMarketing);
    }

    changeByVice() {
        this.seenByVice = !this.seenByVice;
        console.log(this.seenByVice);
    }

    itmeClickHandler(event) {
        console.log(event);
        switch (event.name) {
            case 'close':
            case 'closeBack':
                if (this.addressee_id !== undefined && this.addressee_id != null) {
                    this.router.navigate(['userArea/customer/' + this.addressee_id]).then(r => '/404');
                } else {
                    this.router.navigate(['/userArea/reportList']).then(r => '/404');
                }
                break;
            case 'save':
                this.register(this.form, this.sendCompletionDatePicker, 2).then(r => '/404');
                break;
            case 'cancel':
                this.register(this.form, null, 3).then(r  => '/404');
                break;
            case 'saveEdit':
                this.register(this.form, null, 0).then(r  => '/404');
                break;
            case 'saveDate':
                this.register(this.form, this.sendDueDatePicker, 0).then(r  => '/404');
                break;
            case 'confirmManagement':
                this.register(this.form, null, 5).then(r  => '/404');
                break;
            case 'confirmEdit':
                this.register(this.form, null, 1).then(r  => '/404');
        }
    }

    setItemToolbar(){
        this.toolbarItemList.push(
            {
                name: "space",
                text: "space",
                type: "space",
            },
            {
                name: "closeBack",
                text: "لغو",
                type: "icon-button",
                icon: "fal fa-times-circle fa-2x text-danger",
            },
            {
                name: "print",
                text: "چاپ",
                type: "icon-button",
                icon: "fal fa-print fa-2x text-info",
            },
            {
                name: "excel",
                text: "خروجی اکسل",
                type: "icon-button",
                icon: "fal fa-file-excel fa-2x text-success",
            },
            {
                name: "space",
                text: "space",
                type: "space",
            },
            {
                name: "operations",
                text: "عملیات",
                icon: "fas fa-cogs",
                type: "menu",
                btn_type: "primary",
                items: [
                    {
                        name: "close",
                        text: "لغو",
                        type: "icon-button",
                        icon: "fal fa-times-circle",
                    },
                    {
                        name: "print",
                        text: "چاپ",
                        type: "icon-button",
                        icon: "fal fa-print ",
                    },
                    {
                        name: "excel",
                        text: "خروجی اکسل",
                        type: "icon-button",
                        icon: "fal fa-file-excel",
                    }
                ]
            }
        );
    }

}
