<div class="m-3 mt-4">
    <app-title title="لیست محصولات" *ngIf="showTitle"></app-title>
    <div class="list my-3 mx-auto">
        <div class="list-header">
            <div class="list-header-item cursor-pointer">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div class="list-header-item">
                تعداد ردیف:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 600"
                >
                    {{ object_count.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item">
                تعداد ورق صفحه:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 600"
                >
                    {{ page_sheet_sum.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item">
                تعداد ورق کل:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 600"
                >
                    {{ sheet_sum.toString() | toFa }}
                </div>
            </div>
            <div
                    class="list-header-item mr-auto cursor-pointer"
                    (click)="isCollapsed = !isCollapsed"
            >
                <i class="far fa-file-search"></i>فیلتر
            </div>
        </div>
        <app-filter
                [isCollapsed]="isCollapsed"
                [filter_items]="filter_items"
                (changeFilter)="search($event)"
        ></app-filter>

        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th  class="d-lg-table-cell d-none" scope="col" (click)="sort('product_code')">
                    کد محصول
                </th>
                <th scope="col"  (click)="sort('product_type_name')">نوع محصول</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('product_name')">
                    نام محصول
                </th>
                <th scope="col" (click)="sort('product_brand_name')">برند</th>
                <th class="d-lg-table-cell d-none" scope="col" (click)="sort('product_degree_name')">درجه بندی</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('product_thickness_name')">ضخامت</th>
                <th
                        scope="col"
                        class="d-lg-table-cell d-none"
                        (click)="sort('product_dimension_name')"
                >
                    ابعاد
                </th>
                <th
                        scope="col"
                        class="d-lg-table-cell d-none"
                        (click)="sort('order_invoice_number')"
                >
                    شماره پیش فاکتور
                </th>
                <th
                        scope="col"
                        class="d-lg-table-cell d-none"
                        (click)="sort('order_id')"
                >
شماره سفارش
                </th>
                <th scope="col" (click)="sort('order_date')" >تاریخ سفارش سفارش</th>
                <th scope="col" (click)="sort('company_name')">نام فروشگاه</th>
                <th class="d-lg-table-cell d-none" scope="col" (click)="sort('sheet_number')">تعداد خرید</th>
                <th class="d-lg-table-cell d-none" scope="col" (click)="sort('province_name')">استان</th>
                <th class="d-lg-table-cell d-none" scope="col" (click)="sort('city_name')">شهرستان</th>
                <th scope="col" (click)="sort('region_name')">منطقه</th>
            </tr>
            </thead>
            <tbody>
            <tr
                    *ngFor="let el of orders; let i = index">
               <th scope="row">
                    {{ (page_number - 1) * page_size + i + 1 | toFa }}
                </th>
                <td class="d-lg-table-cell d-none">{{ el.product_code }}</td>
                <td>{{ el.product_type_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.product_name }}</td>
                <td>{{ el.product_brand_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.product_degree_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.product_thickness_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.product_dimension_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.order_invoice_number }}</td>
                <td class="d-lg-table-cell d-none">{{ el.order_id }}</td>
                <td>{{ el.order_date | jDate | toFa}}</td>
                <td>{{ el.company_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.sheet_number }}</td>
                <td class="d-lg-table-cell d-none">{{ el.province_name }}</td>
                <td class="d-lg-table-cell d-none">{{ el.city_name }}</td>
                <td>{{ el.region_name }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center">
        <app-pagination
                [page_count]="page_count"
                [page_number]="page_number"
                (changePage)="load($event)"
        ></app-pagination>
    </div>
</div>
