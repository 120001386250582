<div class="m-3 mt-4">
  <!-- <app-title title="لیست زمینه‌های فعالیت"></app-title> -->
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
    </div>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">زمینه کاری</th>
          <th scope="col">صنعت مورد فعالیت</th>
          <th scope="col">محصولات/خدمات</th>
          <th scope="col">برند‌ها</th>
          <th scope="col">تاریخ شروع</th>
          <th scope="col">توضیحات</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of backgrounds; let i = index">
          <th scope="row">
            {{ i + 1 | toFa }}
          </th>
          <td>{{ el.activity_type_name }}</td>
          <td>{{ el.activity_industry_name }}</td>
          <td>{{ el.activity_product_name }}</td>
          <td>{{ el.activity_brand_name }}</td>
          <td>{{ el.start_date | jDate | toFa }}</td>
          <td>{{ el.detail }}</td>
          <td class="cursor-pointer" (click)="edit(editcontent, i)">
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-title
    (click)="show = !show"
    [attr.aria-expanded]="show"
    aria-controls="collapse"
    [class.open]="show"
    type="plus"
    title="اضافه کردن"
    class="cursor-pointer"
  ></app-title>

  <div id="collapse" [ngbCollapse]="!show" class="box">
    <div class="row">
      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="activity_types"
          placeholder="زمینه کاری"
          (selectChange)="work_background.activity_type = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="industries"
          placeholder="صنعت مورد فعالیت"
          (selectChange)="work_background.activity_industry = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="activity_products"
          placeholder="محصولات/خدمات"
          (selectChange)="work_background.activity_product = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="activity_cooperations"
          placeholder="نحوه همکاری"
          (selectChange)="work_background.activity_cooperation = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="brands"
          placeholder="برند‌ها"
          (selectChange)="work_background.activity_brand = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <label class="mx-1 lead"> تاریخ شروع </label>
        <div class="input-group">
          <input
            matInput
            [matDatepicker]="picker3"
            [(ngModel)]="work_background.start_date"
            name="start_date"
            class="form-control form-control-lg"
          />

          <span class="input-group-append" (click)="picker3.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
          <mat-datepicker #picker3></mat-datepicker>
        </div>
      </div>

      <div class="row w-100 mb-3">
          <div class="form-group col-lg-12 col-md-24 col-48">
                <label>توضیحات</label>
                <input
                        type="text"
                        [(ngModel)]="work_background.detail"
                        name="detail"
                        class="form-control form-control-lg"
                        required
                />
            </div>
      </div>

      <div class="row w-100 mb-3">
        <button
          (click)="register()"
          class="btn mr-auto ml-3 w-25 btn-success"
        >
          ثبت
        </button>
      </div>
    </div>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          آیا از حذف این رکورد اطمینان دارید؟
        </h4>
      </div>
      <div class="modal-body">
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(true)"
        >
          بله
        </button>
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(false)"
        >
          خیر
        </button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="activity_types"
          placeholder="زمینه کاری"
          [key]="editing_work_background.activity_type"
          (selectChange)="editing_work_background.activity_type = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="industries"
          placeholder="صنعت مورد فعالیت"
          [key]="editing_work_background.activity_industry"
          (selectChange)="
            editing_work_background.activity_industry = $event
          "
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="activity_products"
          placeholder="محصولات/خدمات"
          [key]="editing_work_background.activity_product"
          (selectChange)="
            editing_work_background.activity_product = $event
          "
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="activity_cooperations"
          placeholder="نحوه همکاری"
          [key]="editing_work_background.activity_cooperation"
          (selectChange)="
            editing_work_background.activity_cooperation = $event
          "
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="brands"
          placeholder="برند‌ها"
          [key]="editing_work_background.activity_brand"
          (selectChange)="editing_work_background.activity_brand = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <label class="mx-1 lead"> تاریخ شروع </label>
        <div class="input-group">
          <input
            matInput
            [matDatepicker]="picker3"
            [(ngModel)]="editing_work_background.start_date"
            name="start_date"
            class="form-control form-control-lg"
          />

          <span class="input-group-append" (click)="picker3.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
          <mat-datepicker #picker3></mat-datepicker>
        </div>
      </div>

      <div class="form-group col-md-6 col-12">
        <label>توضیحات</label>
        <input
          type="text"
          [(ngModel)]="editing_work_background.detail"
          name="detail"
          class="form-control form-control-lg"
          required
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(true)"
      >
        تایید
      </button>
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>
