<div class="w-100">
   <!-- Login Select -->
   <div class="m-0 mt-3 mb-2 d-flex justify-content-around w-100">
      <div
         [routerLink]="['phone']"
         class="w-50 p-1 ml-1 text-center text-white cursor-pointer login-bg-gray-light"
         routerLinkActive="login-bg-gray"
      >
         ورود با تلفن همراه
      </div>
      <div
         [routerLink]="['username']"
         class="w-50 p-1 mr-1 text-center text-white cursor-pointer login-bg-gray-light"
         routerLinkActive="login-bg-gray "
      >
         ورود با نام کاربری
      </div>
   </div>
</div>

<router-outlet></router-outlet>
