import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-customer-tabs',
  templateUrl: './customer-tabs.component.html',
  styleUrls: ['./customer-tabs.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CustomerTabsComponent implements OnInit , OnDestroy {
  user_id = 0;
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    localStorage.removeItem("user_id");
  }
  async ngOnInit(): Promise<void> {
    // let user = await this.auth.get_info();
    // this.user_id = user.id;
    debugger;
    this.route.params.subscribe((params) => {
      debugger;
      if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
        console.log(this.user_id);

        this.router.navigate(["/404"]);
      } else {
        this.user_id = params.id;
        localStorage.setItem("user_id", this.user_id.toString());
      }
    });
  }

}
