import { Component, OnInit } from '@angular/core';
import {Config} from '../../../../app-config.service';
import {Cash, Check} from '../models';
import * as Func from '../../../../../pack/js/func';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '../../../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-chekout-chash-edit',
  templateUrl: './check-out-cash-edit.component.html',
  styleUrls: ['./check-out-cash-edit.component.scss']
})
export class CheckOutCashEditComponent implements OnInit {

  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;
  id: number;
  images: string[] = [];
  error : string = null;
  conf = Config;
  editing_cash: Cash = new Cash();
  load_reports: any[] = [];
  deposit_types: any[] = [
        { id: null, label: "همه" },
        { id: 1, label: "واریز به حساب" },
        { id: 2, label: "واریز پایا" },
        { id: 3, label: "واریز شبا" },
        { id: 4, label: "کارت به کارت" },
    ];
    bank_accounts: any[] = [];
    checksApi: string = Config.settings.api + 'order/checkout-detail/';

  constructor(
      private http: HttpClient,
      private auth: AuthService,
      private route: ActivatedRoute,
      private router: Router,
      public loc: Location
  ) {}
  user;
  async ngOnInit(): Promise<void> {
    this.user = await this.auth.get_info();
    // await this.load_cars();
    this.load();

      let bankApi: string =
          Config.settings.api + Config.settings.info.bank_account;

      this.http.get(bankApi).subscribe(
          (res) => {
              let c = [];
              (res as Array<any>).forEach((el) =>
                  c.push({
                      id: el.id,
                      label: el.account_number + ")" + el.bank_name + ")",
                  })
              );
              this.bank_accounts = c;
          },
          (error: HttpErrorResponse) => {
              //TODO: server errors
              if (error.status === 404) this.router.navigate(["/404"]);
              console.log(error);
          }
      );
  }

  async load() {
    // this.order = [];
    this.route.params.subscribe((params) => {
      if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
        this.router.navigate(["/404"]);
      } else {
        this.id = params.id;
        let checkApi: string =
            this.checksApi +
            `${this.id}/`;
        this.http.get(checkApi).subscribe(
            async (response: any) => {
              console.log(response);
              let el = response;
                this.editing_cash = new Cash(
                    el.id,
                    el.date,
                    el.amount,
                    el.name,
                    el.manager_confirmation,
                    el.expert_confirmation,
                    el.customer_confirmation,
                    el.load_report,
                    el.bank_account,
                    el.deposit_type,
                    el.source_bank_account,
                    el.detail,
                    el.image_url_1,
                    el.image_url_2
                );
              if(el.image_url_1 != null){
                this.images.push(el.image_url_1);
              }
              if(el.image_url_2 != null){
                this.images.push(el.image_url_2);
              }
                this.load_reports = [];
                await this.load_loadreports(el.order_id);

            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
        );
      }
    });
  }
  remove(i) {
    this.images.splice(i, 1);
  }

    async load_loadreports(order_id) {
        let orderApi: string =
            Config.settings.api + Config.settings.order.order + `${order_id}/`;

        await this.http
            .get(orderApi)
            .toPromise()
            .then((response: any) => {
                console.log(response);
                let c = [];
                c.push({ id: null, label: "خالی" });
                response.load_reports.forEach((el) => {
                    if (el.remittance_number)
                        c.push({ id: el.id, label: el.remittance_number });
                });
                this.load_reports = c;
            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                if (error.status === 404) this.router.navigate(["/404"]);
                console.log(error);
            });
    }

  add_image(e){
    if(this.images.length < 2){
      const url: string = Config.settings.api + Config.settings.info.file;
      const formData: FormData = new FormData();
      formData.append(
          "file",
          e.target.files.item(0),
          e.target.files.item(0).name
      );
      console.log(formData);
      const headers = { dt: "yes" };
      this.http.post(url, formData, { headers }).subscribe(
          (res: any) => {
            this.images.push(res.url.slice(1));
          },
          (error: HttpErrorResponse) => {
            console.log(error);
            window.scroll(0, 0);
          }
      );
    }else {
      this.success = false;
      this.isWrong = true;
      this.errorMsg = "تعداد عکس ها بیشتر از 2 عدد نمی تواند باشد!";
      window.scroll(0, 0);
    }

  }
  async register(form){
    let data = form.value;
    this.edit(data);
    let url = this.checksApi + this.editing_cash.id + '/';
    this.http
        .patch(
            this.checksApi + this.editing_cash.id + '/',
            this.editing_cash
        )
        .subscribe(
            (response: any) => {
              this.success = true;
              this.isWrong = false;
              location.reload();
              this.msg = "عملیات با موفقیت انجام شد";
              window.scroll(0, 0);
              this.images = null;
            },
            (error: HttpErrorResponse) => {
              this.success = false;
              this.isWrong = true;
              this.errorMsg = "عملیات با خطا مواجه شده است";
              this.images = null;
              window.scroll(0, 0);
              this.load();
            }
        );
  }

  async edit(data){
    if(this.images[0] == null){
      this.editing_cash.image_url_1 = null;
    }else {
      this.editing_cash.image_url_1 = this.images[0];
    }
    if(this.images[1] == null){
      this.editing_cash.image_url_2 = null;
    }else {
      this.editing_cash.image_url_2 = this.images[1];
    }
    this.editing_cash.load_report === data.load_report;
    this.editing_cash.date === data.date;
    this.editing_cash.deposit_type === data.deposit_type;
    this.editing_cash.bank_account === data.bank_account;
    this.editing_cash.source_bank_account === data.source_bank_account;
    this.editing_cash.amountt === data.amountt;
    this.editing_cash.name === data.name;
    if(data.detail === null){
      this.editing_cash.detail === null;
    }else {
      this.editing_cash.detail === data.detail;
    }
    if(data.manager_confirmation === undefined){
      this.editing_cash.manager_confirmation = false;
    }else {
      this.editing_cash.manager_confirmation === data.manager_confirmation;
    }
    if(data.customer_confirmation === undefined){
      this.editing_cash.customer_confirmation === false;
    }else {
      this.editing_cash.customer_confirmation === data.customer_confirmation;
    }
    if(data.expert_confirmation === undefined){
      this.editing_cash.expert_confirmation === false;
    }else {
      this.editing_cash.expert_confirmation === data.expert_confirmation;
    }
  }


}
