<div v-else class="w-100">
   <form
      class="d-flex flex-column justify-content-center"
      #form="ngForm"
      (ngSubmit)="login(form)"
   >
      <input
         type="text"
         class="form-control form-control-lg my-2"
         placeholder="نام کاربری"
         ngModel
         name="username"
      />
      <div class="input-group align-middle">
         <input
            [type]="!showPass ? 'password' : 'text'"
            class="form-control form-control-lg my-2"
            placeholder="رمز عبور"
            ngModel
            name="password"
         />
         <div class="input-group-append my-2">
            <div
               class="input-group-text align-middle"
               (click)="showPass = !showPass"
               style="background: white"
            >
               <i class="fa fa-eye"></i>
            </div>
         </div>
      </div>
      <button type="submit" class="btn btn-lg mx-auto btn-success m-2 w-50">
         ورود
      </button>
   </form>
</div>
