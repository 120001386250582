import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Config} from "src/app/app-config.service";
import {AuthService} from "src/app/auth/auth.service";
import * as jalaliMoment from "jalali-moment";

@Component({
    selector: "app-the-sidebar",
    templateUrl: "./the-sidebar.component.html",
    styleUrls: ["./the-sidebar.component.scss"],
})
export class TheSidebarComponent implements OnInit {
    show: boolean[] = [];
    curr: number = null;
    id: number = 0;
    threeDaysAgo =
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3)
            .toJSON()
            .slice(0, 11) + "00:00:00.000Z";

    sevenDaysAgo =
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7)
            .toJSON()
            .slice(0, 11) + "00:00:00.000Z";
    startCurrentYear = jalaliMoment.from(jalaliMoment(new Date, 'YYYY').locale('fa').format('YYYY') + "/01/01", 'fa', 'YYYY/MM/DD')
        .locale('en').toJSON()
                        .slice(0, 11) + "00:00:00.000Z";

    oneStartDate = new Date().toJSON().slice(0, 11) + "00:00:00.000Z";
    onnEndDate = new Date(new Date().getTime() + (1000 * 60 * 60 * 24) ).toJSON().slice(0, 11) + "00:00:00.000Z";

    user;
    constructor(
        public auth: AuthService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.user = await this.auth.get_info();
        this.id = this.user.id;
    }

    open(i) {
        console.log(i);

        if (i == this.curr) {
            this.show[this.curr] = false;
            this.curr = null;
        } else {
            this.show[this.curr] = false;
            this.show[i] = true;
            this.curr = i;
        }
    }

    active(url, params, notParams = {}) {
        const queryParamsIndex = this.router.url.indexOf("?");
        const baseUrl =
            queryParamsIndex === -1
                ? this.router.url.slice(10)
                : this.router.url.slice(10, queryParamsIndex);

        if (baseUrl !== url) return false;

        const queryParamMap = this.route.snapshot["queryParamMap"];
        let flag = true;
        Object.keys(params).forEach((key) => {
            if (queryParamMap.get(key) != params[key]) flag = false;
        });
        Object.keys(notParams).forEach((key) => {
            if (notParams[key].includes(queryParamMap.get(key))) flag = false;
        });

        return flag;
    }

    conf = Config;

    change_image(e) {
        const url: string = Config.settings.api + Config.settings.info.file;
        const formData: FormData = new FormData();
        formData.append(
            "file",
            e.target.files.item(0),
            e.target.files.item(0).name
        );
        console.log(formData);
        const headers = {dt: "yes"};
        this.http.post(url, formData, {headers}).subscribe(
            (res: any) => {
                let url =
                    Config.settings.api + Config.settings.user.user + `${this.id}/`;
                let data = {image_url: res.url.slice(1)};
                this.http.patch(url, data).subscribe((response: any) => {
                    this.user.image_url = res.url.slice(1);
                });
            },
            (error: HttpErrorResponse) => {
            }
        );
    }
}
