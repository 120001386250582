import { Component, OnInit } from '@angular/core';
import {
   Location,
   LocationStrategy,
   PathLocationStrategy,
} from '@angular/common';
import * as $ from 'jquery';
import { AuthService } from 'src/app/auth/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Config } from 'src/app/app-config.service';
import { Router } from '@angular/router';

@Component({
   selector: 'app-user-area',
   templateUrl: './user-area.component.html',
   styleUrls: ['./user-area.component.scss'],
})
export class UserAreaComponent implements OnInit {
   sidebarCollapsed = false;

   constructor(
      public auth: AuthService,
      private http: HttpClient,
      private router: Router,
      public loc: Location
   ) {}
   user = null;
   expert = null;
   permissions: any[] = [];
   conf = Config;
   async ngOnInit(): Promise<void> {}

   toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
   }
}
