import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../../auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Config } from 'src/app/app-config.service';

@Component({
   selector: 'app-username-signin',
   templateUrl: './username-signin.component.html',
   styleUrls: ['./username-signin.component.scss'],
})
export class UsernameSigninComponent implements OnInit {
   isWrong = false;
   errorMsg: string = '';
   showPass = false;

   constructor(private auth: AuthService, private router: Router) {}

   ngOnInit(): void {
      console.log(Config.settings);
   }

   login(form) {
      this.isWrong = false;
      this.auth.login(form.value).subscribe(
         (response: any) => {
            localStorage.setItem('token', response.key);
            // this.auth.getUserInfo().subscribe(
            //    (response: any) => {
            //       this.auth.username = response.username;
            //       this.auth.role = response.role;
            //       this.auth.firstname = response.attributes.first_name;
            //       this.auth.lastname = response.attributes.last_name;
            //       this.auth.fullname =
            //          this.auth.firstname + ' ' + this.auth.lastname;
            //       this.router.navigate(['/home']);
            //    },
            //    (error: HttpErrorResponse) => {
            //       this.isNotWrong = false;
            //    }
            // );
            this.router.navigate(['/userArea']);
         },
         (error: HttpErrorResponse) => {
            this.isWrong = true;
            this.errorMsg = 'دوباره امتحان کنید.';
         }
      );
   }
}
