<!-- <img src="../../assets/img/background.jpg" /> -->

<div class="vw-100 vh-100 login-and-register">
   <div
      class="d-flex flex-column align-items-center content-center p-4 login-and-register-inner"
   >
      <div
         class="d-flex flex-column justify-content-between align-items-center"
      >
         <img
            src="../../assets/img/logo.png"
            alt="logo"
            class="login-and-register-logo"
         />
         <span class="mb-1 h5">پورتال مشتریان</span>
         <span class="mt-2 h5">گـروه صنعـتی و بـازرگانی شفـیق</span>
      </div>

      <!-- Login/Register Select -->
      <div
         class="d-flex justify-content-around bg-white w-100 py-2 display-6 row my-3"
         style="border-radius: 0.4rem"
      >
         <a
            [routerLink]="['login']"
            class="col-5 text-left align-middle"
            routerLinkActive="text-primary"
            >ورود اعضـا</a
         >
         <div class="col-2 d-none d-sm-block text-center align-middle">|</div>
         <a
            [routerLink]="['register']"
            class="col-5 text-right align-middle"
            routerLinkActive="text-primary"
            >ثبت نـام</a
         >
      </div>
      <router-outlet></router-outlet>
   </div>
</div>
