<ngb-tabset>
        <ngb-tab title="اطلاعات کاربری">
            <ng-template ngbTabContent>
                <app-customer-edit [user_id]="user_id"></app-customer-edit>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="زمینه‌های کاری">
            <ng-template ngbTabContent>
                <app-work-background-list></app-work-background-list>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="آدرس‌های پستی">
            <ng-template ngbTabContent>
                <app-postal-address-list></app-postal-address-list>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="لیست پرسنل">
            <ng-template ngbTabContent>
                <app-cpersonnel-list></app-cpersonnel-list>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="اطلاعات تماس">
            <ng-template ngbTabContent>
                <app-contact-list></app-contact-list>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="حساب‌های بانکی">
            <ng-template ngbTabContent>
                <app-customer-bank-account-list></app-customer-bank-account-list>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="شبکه‌های اجتماعی">
            <ng-template ngbTabContent>
                <app-social-network-list></app-social-network-list>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
