import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/auth/auth.service';
import {Config} from 'src/app/app-config.service';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import * as Func from '../../../../pack/js/func';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {debounceTime} from 'rxjs/operators';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {RouterModule, Routes} from '@angular/router';
import {ProvinceListComponent} from '../../information/provinces/province-list/province-list.component';
import * as jalaliMoment from "jalali-moment";


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

    chart: am4charts.PieChart;

    user = null;
    conf = Config;
    func = Func;
    headerNumber: any;


    btnNextReadOnlyFlag: boolean = false;
    btnPrevReadOnlyFlag: boolean = false;
    showYearInformationFlag: boolean = false;

    cashPayment: any[] = [];
    orgCashPayments: any[] = [];
    years: any[] = [
        {id: 1401 , name: '1401'},
        {id: 1400 , name: '1400'},
        {id: 1399 , name: '1399'},
        {id: 1398 , name: '1398'}

    ];


    month: any[] = [
        {id: 1 , label: 'فروردین'},
        {id: 2 , label: 'اردیبهشت'},
        {id: 3 , label: 'خرداد'},
        {id: 4 , label: 'تیر'},
        {id: 5 , label: 'مرداد'},
        {id: 6 , label: 'شهریور'},
        {id: 7 , label: 'مهر'},
        {id: 8 , label: 'آبان'},
        {id: 9 , label: 'آذر'},
        {id: 10 , label: 'دی'},
        {id: 11 , label: 'بهمن'},
        {id: 12 , label: 'اسفند'},
    ];

    checkPayment: any[] = [];
    orgCheckPayments: any[] = [];

    dimension: any[] = [];
    orgDimensions: any[] = [];

    type: any[] = [];
    orgTypes: any[] = [];
    //
    degree: any[] = [];
    orgDegres: any[] = [];

    thickness: any[] = [];
    orgThicknes: any[] = [];

    currentMonthSale: any[] = [];
    orgCurrentMonthSales: any[] = [];

    currentYearSale: any[] = [];
    orgCurrentYearSales: any[] = [];

    currentMonthBestSellerCustomer: any[] = [];
    orgCurrentMonthBestSellerCustomers: any[] = [];

    currentMonthBestSellerProvince: any[] = [];
    orgCurrentMonthBestSellerProvinces: any[] = [];

    showCurrentMonthSaleChart: boolean = false;
    showCurrentYearSaleChart: boolean = false;
    showCurrentMonthBestSellerCustomerChart: boolean = false;
    showCurrentMonthBestSellerProvinceChart: boolean = false;
    showCurrentProductDimensionsChar: boolean = false;
    showCurrentProductTypeChar: boolean = false;
    showCurrentProductDegreeChar: boolean = false;
    showCurrentProductThicknessChar: boolean = false;

    currentMonthBestSellerProvinceCaption;
    currentMonthSaleCaption = "وضعیت فروش ماه ";
    currentMonthBestSellerCustomerCaption = "پرفروش ترین مشتریان در ماه ";
    currentDimensionCaption;
    currentTypeCaption;
    currentDegreeCaption;
    currentThicknessCaption;

    curentYear = jalaliMoment(new Date, 'YYYY').locale('fa').format('YYYY');
    fixStartYearDate = jalaliMoment.from(this.curentYear + "/01/01", 'fa', 'YYYY/MM/DD')
        .locale('en').format('YYYY-MM-DD').slice(0, 11) + "T00:00:00.000Z";
    fixEndYearDate = jalaliMoment.from(this.curentYear + "/12/29", 'fa', 'YYYY/MM/DD')
        .locale('en').format('YYYY-MM-DD').slice(0, 11) + "T00:00:00.000Z";
    currentMountNum = Number(jalaliMoment(new Date, 'MM').locale('fa')
        .format('MM'));

    startDate;
    endDate;

    mountNum = Number(jalaliMoment(new Date, 'MM').locale('fa').format('MM'));
    mountName;
    //=****************************
    dashboardHeaderApi: string = Config.settings.api + 'dashboard/user';
    dashboardPaymentApi: string = Config.settings.api + 'dashboard/payment';
    dashboardSaleApi: string = Config.settings.api + 'dashboard/sale';
    dashboardProductOrderApi: string = Config.settings.api + 'dashboard/product';

    constructor(public auth: AuthService, private router: Router,
                private modalService: NgbModal,
                private http: HttpClient) {

    }

    async ngOnInit(): Promise<void> {
        this.user = await this.auth.get_info();
        if (this.user && !(this.user.role == 2 || this.user.role == 3 || this.user.role == 4)) {
            this.load_header();
        }
        this.initiallData()
        // this.load_sale();
        this.load_cash();
        // this.loadProductOrder();

    }


    initiallData() {

        debugger;
        this.clearData();

        this.mountName = jalaliMoment.from(this.curentYear + "/" + this.mountNum + "/01",
            'fa', 'YYYY/MM/DD').locale('fa').format('jMMMM');


        this.startDate = jalaliMoment.from(this.curentYear + "/" + this.mountNum + "/01",
            'fa', 'YYYY/MM/DD').locale('en').format('YYYY-MM-DD').slice(0, 11) + "T00:00:00.000Z";


        if (this.mountNum >= 1 && this.mountNum <= 6) {
            this.endDate = jalaliMoment.from(this.curentYear + "/" + this.mountNum + "/31",
                'fa', 'YYYY/MM/DD').locale('en').format('YYYY-MM-DD').slice(0, 11) + "T23:59:00.000Z";
        } else if (this.mountNum == 12) {
            this.endDate = jalaliMoment.from(this.curentYear + "/" + this.mountNum + "/29",
                'fa', 'YYYY/MM/DD').locale('en').format('YYYY-MM-DD').slice(0, 11) + "T23:59:00.000Z";
        } else {
            this.endDate = jalaliMoment.from(this.curentYear + "/" + this.mountNum + "/30",
                'fa', 'YYYY/MM/DD').locale('en').format('YYYY-MM-DD').slice(0, 11) + "T23:59:00.000Z";
        }


        if (this.mountNum != this.currentMountNum) {
            this.currentMonthSaleCaption = "وضعیت فروش ماه " + this.mountName;
            this.currentMonthBestSellerCustomerCaption = "پرفروش ترین مشتریان در ماه " + this.mountName;
            if (this.user.role == 4) {
                this.currentMonthBestSellerProvinceCaption = "پر فروش ترین شهرستان ها در ماه " + this.mountName;
            } else {
                this.currentMonthBestSellerProvinceCaption = "پر فروش ترین استان ها در ماه  " + this.mountName;
            }


            this.currentDimensionCaption = "ابعاد محصولات " + this.mountName;
            this.currentTypeCaption = "نوع محصولات " + this.mountName;
            this.currentDegreeCaption = "درجه‌بندی محصولات " + this.mountName;
            this.currentThicknessCaption = "ضخامت محصولات " + this.mountName;


        } else {
            this.currentMonthSaleCaption = "وضعیت فروش ماه  | " + this.mountName;
            this.currentMonthBestSellerCustomerCaption = "پرفروش ترین مشتریان در ماه  | " + this.mountName;
            if (this.user.role == 4) {
                this.currentMonthBestSellerProvinceCaption = "پر فروش ترین شهرستان ها در ماه  | " + this.mountName;
            } else {
                this.currentMonthBestSellerProvinceCaption = "پر فروش ترین استان ها در ماه  | " + this.mountName;
            }


            this.currentDimensionCaption = "ابعاد محصولات در ماه  | " + this.mountName + " سال | " + this.curentYear;
            this.currentTypeCaption = "نوع محصولات در ماه  | " + this.mountName + " سال | " + this.curentYear;
            this.currentDegreeCaption = "درجه‌بندی محصولات در ماه  | " + this.mountName + " سال  | " + this.curentYear;
            this.currentThicknessCaption = "ضخامت محصولات درماه  | " + this.mountName + " سال | " + this.curentYear;

        }

        if (this.showYearInformationFlag) {
            this.currentDimensionCaption = "ابعاد محصولات در سال  | " + this.curentYear;
            this.currentTypeCaption = "نوع محصولات در سال  | " + this.curentYear;
            this.currentDegreeCaption = "درجه‌بندی محصولات در سال  | " + this.curentYear;
            this.currentThicknessCaption = "ضخامت محصولات در سال  | " + this.curentYear;
        }
        console.log(this.startDate);
        console.log(this.endDate);
        console.log(this.mountNum);

        this.dashboardSaleApi = Config.settings.api + 'dashboard/sale?start_order_date=' + this.startDate + '&end_order_date=' + this.endDate ;

        if (!this.showYearInformationFlag) {
            this.dashboardProductOrderApi = Config.settings.api  + 'dashboard/product?start_order_date=' + this.startDate + '&end_order_date=' + this.endDate;
        } else {
            this.dashboardProductOrderApi = Config.settings.api  + 'dashboard/product?start_order_date=' + this.fixStartYearDate + '&end_order_date=' + this.fixEndYearDate;
        }

        setTimeout(() => {
            this.load_sale();
        }, 100);

        setTimeout(() => {
            this.loadProductOrder();
        }, 100);

        if (this.mountNum >= this.currentMountNum) {
            this.btnNextReadOnlyFlag = true;
        } else {
            setTimeout(() => {
                this.btnNextReadOnlyFlag = false;
            }, 2000);
        }

        if (this.mountNum <= this.currentMountNum && this.mountNum > 1) {
            setTimeout(() => {
                this.btnPrevReadOnlyFlag = false;

            }, 2000);
        } else {
            this.btnPrevReadOnlyFlag = true;

        }
    }

    clearData() {
        this.orgCurrentMonthSales = [];
        this.currentMonthSale = [];
        this.orgCurrentYearSales = [];
        this.currentYearSale = [];
        this.orgCurrentMonthBestSellerCustomers = [];
        this.currentMonthBestSellerCustomer = [];
        this.orgCurrentMonthBestSellerProvinces = [];
        this.currentMonthBestSellerProvince = [];

        this.orgDimensions = [];
        this.orgTypes = [];
        this.orgDegres = [];
        this.orgThicknes = [];
    }

//region Load Data _Request

    load_header() {
        this.http.get(this.dashboardHeaderApi).subscribe(
            (response: any) => {
                this.headerNumber = {
                    n_new_customers: response.n_new_customers,
                    n_representatives: response.n_representatives,
                    n_agents: response.n_agents,
                    n_customers: response.n_customers,
                    n_referred_customers: response.n_referred_customers
                };


            },
            (error: HttpErrorResponse) => {

                // TODO: server errors
                console.log(error);
            }
        );
    }

    load_sale() {
        debugger;
        this.clearData();
        this.btnNextReadOnlyFlag = true;
        this.btnPrevReadOnlyFlag = true;

        this.http.get(this.dashboardSaleApi).subscribe(
            (response: any) => {
                console.log(response);
                response.current_month_sales.forEach((element) => {
                    this.orgCurrentMonthSales.push(element);
                });

                this.currentMonthSale = this.orgCurrentMonthSales;
                setTimeout(() => {
                    this.loadCurrentMonthSaleChart();
                }, 2000);
                //endregion

                response.current_year_sales.forEach((e) => {
                    this.orgCurrentYearSales.push(e);
                });
                this.currentYearSale = this.orgCurrentYearSales;
                setTimeout(() => {
                    this.loadCurrentYearSaleChart();
                }, 2000);
                //endregion
                //-------------
                if (!(this.user.role == 3 || this.user.role == 2)) {
                //     //#region current_month_best_seller_customers
                    response.current_month_best_seller_customers.forEach((element) => {
                        this.orgCurrentMonthBestSellerCustomers.push({
                            id: 1,
                            company_name: element.company_name,
                            n_orders: element.n_orders,
                            n_sheets: element.n_sheets,
                            order_amount: element.order_amount,
                            paid_amount: element.paid_amount,
                            parm: this.setRequestBestSellerCustomersMonthParam(element.buyer_id)
                        });

                    });
                    this.currentMonthBestSellerCustomer = this.orgCurrentMonthBestSellerCustomers;

                    setTimeout(() => {
                        this.loadCurrentMonthBestSellerCustomerChart();
                    }, 2000);

                    //endregion

                    //#region CurrentMonthBestSellerProvinces
                    if (!(this.user.role == 3 || this.user.role == 2)) {
                        if (this.user.role == 4) {

                            response.current_month_best_seller_cities.forEach((element) => {
                                this.orgCurrentMonthBestSellerProvinces.push({
                                    id: 1,
                                    province_name: element.city_name,
                                    n_orders: element.n_orders,
                                    n_sheets: element.n_sheets,
                                    order_amount: element.order_amount,
                                    paid_amount: element.paid_amount,
                                    parm: {
                                        start_date: this.startDate,
                                        end_date: this.endDate,
                                        state: '6,11',
                                        in_company: 0,
                                        city: element.city_id
                                    }
                                });
                            });

                        } else {

                            response.current_month_best_seller_provinces.forEach((element) => {
                                this.orgCurrentMonthBestSellerProvinces.push({
                                    id: 1,
                                    province_name: element.province_name,
                                    n_orders: element.n_orders,
                                    n_sheets: element.n_sheets,
                                    order_amount: element.order_amount,
                                    paid_amount: element.paid_amount,
                                    parm: {
                                        start_date: this.startDate,
                                        end_date: this.endDate,
                                        state: '6,11',
                                        province: element.province_id
                                    }
                                });
                            });
                        }
                        this.currentMonthBestSellerProvince = this.orgCurrentMonthBestSellerProvinces;
                        setTimeout(() => {
                            this.loadCurrentMonthBestSellerProvinceChart();
                        }, 2000);
                    }
                    //endregion
                }
            },
            (error: HttpErrorResponse) => {
                // TODO: server errors
                console.log(error);
            }
        );


    }

    loadProductOrder() {
        debugger;
        this.btnNextReadOnlyFlag = true;
        this.btnPrevReadOnlyFlag = true;

        this.http.get(this.dashboardProductOrderApi).subscribe(
            (response: any) => {

                // region referredOrders
                //  if (this.user && !(this.user.role == 2 || this.user.role == 3)) {

                response.dimension.forEach((element) => {
                    this.orgDimensions.push({
                        id: element.id,
                        name: element.name,
                        all: element.all,
                        percentage: element.percentage,
                        region_1: element.region_1,
                        region_2: element.region_2,
                        parm: this.setRequestDimensionParam(element.id)
                    })

                });

                this.dimension = this.orgDimensions;

                //       //endregion
                //
                //       //#region checkout
                response.type.forEach((element) => {
                    this.orgTypes.push({
                        id: element.id,
                        name: element.name,
                        all: element.all,
                        percentage: element.percentage,
                        region_1: element.region_1,
                        region_2: element.region_2,
                        parm: this.setRequestTypeParam(element.id)
                    })

                });

                this.type = this.orgTypes;
                //
                //       //endregion
                //


                response.degree.forEach((element) => {
                    this.orgDegres.push({
                        id: element.id,
                        name: element.name,
                        all: element.all,
                        percentage: element.percentage,
                        region_1: element.region_1,
                        region_2: element.region_2,
                        parm: this.setRequestDegreeParam(element.id)
                    })

                });

                this.degree = this.orgDegres;


                response.thickness.forEach((element) => {
                    this.orgThicknes.push({
                        id: element.id,
                        name: element.name,
                        all: element.all,
                        percentage: element.percentage,
                        region_1: element.region_1,
                        region_2: element.region_2,
                        parm: this.setRequestThicknessParam(element.id)
                    })

                });

                this.thickness = this.orgThicknes;


            },
            (error: HttpErrorResponse) => {
                // TODO: server errors
                console.log(error);
            }
        );

        setTimeout(() => {
            this.loadCurrentProductDimensionsChar();
        }, 2000);

        setTimeout(() => {
            this.loadCurrentProductTypeChar();
        }, 2000);

        setTimeout(() => {
            this.loadCurrentProductDegreeChar();
        }, 2000);

        setTimeout(() => {
            this.loadCurrentProductThicknessChar();
        }, 2000);

        // if (this.mountNum >= this.currentMountNum) {
        //     this.btnNextReadOnlyFlag = true;
        // } else {
        //     setTimeout(() => {
        //         this.btnNextReadOnlyFlag = false;
        //     }, 2000);
        // }

        // if (this.mountNum <= this.currentMountNum && this.mountNum > 1) {
        //     setTimeout(() => {
        //         this.btnPrevReadOnlyFlag = false;
        //
        //     }, 2000);
        // } else {
        //     this.btnPrevReadOnlyFlag = true;
        //
        // }
    }

    load_cash() {

        this.http.get(this.dashboardPaymentApi).subscribe(
            (response: any) => {
                //region cashPayment
                this.orgCashPayments.push({
                    id: 1, name: 'تعداد پرداخت',
                    number: response.cash.all.number,
                    amount: response.cash.all.amount,
                    parm: this.setRequestCashPaymentParm(undefined, this.fixStartYearDate
                    )
                });
                this.orgCashPayments.push({
                    id: 2, name: 'پرداخت های جدید',
                    number: response.cash.new.number,
                    amount: response.cash.new.amount,
                    parm: this.setRequestCashPaymentParm(undefined, new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3)
                        .toJSON()
                        .slice(0, 11) + "00:00:00.000Z")
                });
                this.orgCashPayments.push({
                    id: 3, name: 'نیاز به تایید مشتری',
                    number: response.cash.not_confirmed.number,
                    amount: response.cash.not_confirmed.amount,
                    parm: this.setRequestCashPaymentParm(0, this.fixStartYearDate
                    )
                });
                this.orgCashPayments.push({
                    id: 4, name: 'نیاز به تایید کارشناس',
                    number: response.cash.customer_confirmation.number,
                    amount: response.cash.customer_confirmation.amount,
                    parm: this.setRequestCashPaymentParm(1, this.fixStartYearDate
                    )
                });
                this.orgCashPayments.push({
                    id: 5, name: 'نیاز به تایید حسابداری',
                    number: response.cash.expert_confirmation.number,
                    amount: response.cash.expert_confirmation.amount,
                    parm: this.setRequestCashPaymentParm(2, this.fixStartYearDate
                    )
                });
                this.orgCashPayments.push({
                    id: 6, name: 'تایید حسابداری',
                    number: response.cash.financial_confirmation.number,
                    amount: response.cash.financial_confirmation.amount,
                    parm: this.setRequestCashPaymentParm(3, this.fixStartYearDate
                    )
                });

                this.cashPayment = this.orgCashPayments;
                //endregion
                //region checkPayment
                this.orgCheckPayments.push({
                    id: 1, name: 'تعداد چک ها',
                    number: response.check.all.number,
                    amount: response.check.all.amount,
                    parm: this.setRequestCheckoutParm(undefined, this.fixStartYearDate
                        , undefined, undefined)
                });
                this.orgCheckPayments.push({
                    id: 2, name: 'چک های جدید',
                    number: response.check.new.number,
                    amount: response.check.new.amount,
                    parm: this.setRequestCheckoutParm(undefined,
                        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3).toJSON()
                            .slice(0, 11) + "00:00:00.000Z"
                        , undefined, undefined)
                });
                this.orgCheckPayments.push({
                    id: 3, name: 'نیاز به تایید مشتری',
                    number: response.check.not_confirmed.number,
                    amount: response.check.not_confirmed.amount,
                    parm: this.setRequestCheckoutParm(0, this.fixStartYearDate
                        , undefined, undefined)
                });
                this.orgCheckPayments.push({
                    id: 4, name: 'نیاز به تایید کارشناس',
                    number: response.check.customer_confirmation.number,
                    amount: response.check.customer_confirmation.amount,
                    parm: this.setRequestCheckoutParm(1, this.fixStartYearDate
                        , undefined, undefined)
                });
                this.orgCheckPayments.push({
                    id: 5, name: 'نیاز به تایید حسابداری',
                    number: response.check.expert_confirmation.number,
                    amount: response.check.expert_confirmation.amount,
                    parm: this.setRequestCheckoutParm(2, this.fixStartYearDate
                        , undefined, undefined)
                });
                this.orgCheckPayments.push({
                    id: 1, name: 'تایید حسابداری',
                    number: response.check.financial_confirmation.number,
                    amount: response.check.financial_confirmation.amount,
                    parm: this.setRequestCheckoutParm(3, this.fixStartYearDate
                        , undefined, undefined)
                });
                this.orgCheckPayments.push({
                    id: 1, name: 'در حال پیگیری',
                    number: response.check.in_progress.number,
                    amount: response.check.in_progress.amount,
                    parm: this.setRequestCheckoutParm(undefined, this.fixStartYearDate
                        , undefined, 4)
                });
                this.orgCheckPayments.push({
                    id: 1, name: 'در حال وصول',
                    number: response.check.receiving.number,
                    amount: response.check.receiving.amount,
                    parm: this.setRequestCheckoutParm(undefined,
                        this.fixStartYearDate
                        , new Date(new Date().getTime()).toJSON()
                        .slice(0, 11) + "00:00:00.000Z"
                        , 4)
                });
                this.orgCheckPayments.push({
                    id: 9, name: 'برگشت شده',
                    number: response.check.bounced.number,
                    amount: response.check.bounced.amount,
                    parm: this.setRequestCheckoutParm(undefined, this.fixStartYearDate
                        , undefined, 2)
                });
                this.orgCheckPayments.push({
                    id: 10, name: 'تعویض شده',
                    number: response.check.replaced.number,
                    amount: response.check.replaced.amount,
                    parm: this.setRequestCheckoutParm(undefined, this.fixStartYearDate
                        , undefined, 3)
                });


                this.checkPayment = this.orgCheckPayments;
                //endregion
            },
            (error: HttpErrorResponse) => {
                // TODO: server errors
                console.log(error);
            }
        );
    }

//endregion

    setRequestBestSellerCustomersMonthParam(buyer_id: number) {

        let parm = {};
        parm = {start_date: this.startDate, end_date: this.endDate};

        if (!(this.user.role == 3 || this.user.role == 2) && this.user.role == 4) {
            parm = Object.assign(parm, {"state": "6,11,19"});
            parm = Object.assign(parm, {"in_company": 0});

        } else {
            parm = Object.assign(parm, {"state": "6,11"});
        }
        parm = Object.assign(parm, {"buyer": buyer_id});
        return parm;
    }

    setRequestCashPaymentParm(statement: any, department: any) {
        let parm = {};
        parm = Object.assign(parm, {start_created_at: department});
        if (statement != undefined) {
            parm = Object.assign(parm, {confirmation_state: statement});
        }
        return parm;
    }

    setRequestCheckoutParm(confirmation_state: any, startDate: any,
                           endDate: any, check_state: any) {
        let parm = {};
        parm = Object.assign(parm, {start_created_at: startDate});
        if (confirmation_state != undefined) {
            parm = Object.assign(parm, {confirmation_state: confirmation_state});
        }

        if (check_state != undefined) {
            parm = Object.assign(parm, {check_state: check_state});
        }
        if (endDate != undefined) {
            parm = Object.assign(parm, {end_date: endDate});
        }
        return parm;
    }

    setRequestDimensionParam(productDimensionId: number) {

        let parm = {};
        parm = {
            start_order_date: this.startDate,
            end_order_date: this.endDate,
            "product_dimension_id": productDimensionId
        };

        return parm;
    }

    setRequestTypeParam(productTypeId: number) {

        let parm = {};
        parm = {
            start_order_date: this.startDate,
            end_order_date: this.endDate,
            "product_type_id": productTypeId
        };

        return parm;
    }

    setRequestDegreeParam(degreeId: number) {

        let parm = {};
        parm = {
            start_order_date: this.startDate,
            end_order_date: this.endDate,
            "product_degree_id": degreeId
        };

        return parm;
    }

    setRequestThicknessParam(thicknessId: number) {

        let parm = {};
        parm = {
            start_order_date: this.startDate,
            end_order_date: this.endDate,
            "product_thickness_id": thicknessId
        };

        return parm;
    }

    isCurrentMonthSaleChart() {
        this.showCurrentMonthSaleChart = !this.showCurrentMonthSaleChart;
        console.log(this.showCurrentMonthSaleChart);

        setTimeout(() => {
            this.loadCurrentMonthSaleChart();
        }, 2000);

    }

    loadCurrentMonthSaleChart() {
        debugger;
        if (this.showCurrentMonthSaleChart) {

            am4core.useTheme(am4themes_animated);

            let chart = am4core.create("currentMonthSaleChartDiv", am4charts.PieChart);


            let currentMonthSaleChartData = JSON.parse(JSON.stringify(this.currentMonthSale));

            currentMonthSaleChartData.shift();

            chart.data = currentMonthSaleChartData;


            let pieSeries = chart.series.push(new am4charts.PieSeries());
            chart.rtl = true;
            pieSeries.dataFields.value = "n_sheets";
            pieSeries.dataFields.category = "name";

            pieSeries.slices.template.stroke = am4core.color("#fff");
            pieSeries.ticks.template.disabled = true;
            pieSeries.alignLabels = false;


            pieSeries.labels.template.text = "[bold]{value}[/bold]";
            pieSeries.labels.template.maxWidth = 50;
            pieSeries.labels.template.wrap = true;
            pieSeries.labels.template.fontSize = 12;


            // pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
            pieSeries.labels.template.radius = am4core.percent(-50);
            pieSeries.labels.template.fill = am4core.color("white");

            chart.legend = new am4charts.Legend();

            chart.legend.position = "right";
            chart.legend.minWidth = 80;
            chart.legend.scrollable = true;

            chart.legend.itemContainers.template.reverseOrder = true;
            pieSeries.legendSettings.labelText = "[bold {color}]{name}[/]";
            pieSeries.legendSettings.valueText = "{value} {valueY.close}";
            this.chart = chart;
        }
    }

    isCurrentYearSaleChart() {
        this.showCurrentYearSaleChart = !this.showCurrentYearSaleChart;


        setTimeout(() => {
            this.loadCurrentYearSaleChart();
        }, 2000);

    }

    loadCurrentYearSaleChart() {
        if (this.showCurrentYearSaleChart) {
            am4core.useTheme(am4themes_animated);

            let chart = am4core.create("currentYearSaleChartDiv", am4charts.PieChart);


            let currentYearSaleChartData = JSON.parse(JSON.stringify(this.currentYearSale));

            currentYearSaleChartData.shift();

            chart.data = currentYearSaleChartData;

            let pieSeries = chart.series.push(new am4charts.PieSeries());
            chart.rtl = true;
            pieSeries.dataFields.value = "n_sheets";
            pieSeries.dataFields.category = "name";

            pieSeries.slices.template.stroke = am4core.color("#fff");
            pieSeries.ticks.template.disabled = true;
            pieSeries.alignLabels = false;


            pieSeries.labels.template.text = "[bold]{value}[/bold]";
            pieSeries.labels.template.maxWidth = 50;
            pieSeries.labels.template.wrap = true;
            pieSeries.labels.template.fontSize = 12;


            // pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
            pieSeries.labels.template.radius = am4core.percent(-50);
            pieSeries.labels.template.fill = am4core.color("white");

            chart.legend = new am4charts.Legend();

            chart.legend.position = "right";
            chart.legend.minWidth = 80;
            chart.legend.scrollable = true;

            chart.legend.itemContainers.template.reverseOrder = true;
            pieSeries.legendSettings.labelText = "[bold {color}]{name}[/]";
            pieSeries.legendSettings.valueText = "{value} {valueY.close}";
            this.chart = chart;
        }
    }

    isCurrentMonthBestSellerCustomerChart() {
        this.showCurrentMonthBestSellerCustomerChart = !this.showCurrentMonthBestSellerCustomerChart;


        setTimeout(() => {
            this.loadCurrentMonthBestSellerCustomerChart();
        }, 2000);

    }

    loadCurrentMonthBestSellerCustomerChart() {
        if (this.showCurrentMonthBestSellerCustomerChart) {


            am4core.useTheme(am4themes_animated);

            let chart = am4core.create("currentMonthBestSellerCustomerChartDiv", am4charts.XYChart);
            chart.rtl = true;
            chart.data = this.currentMonthBestSellerCustomer;

            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "company_name";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.verticalCenter = "middle";
            categoryAxis.renderer.labels.template.rotation = 270;
            categoryAxis.tooltip.disabled = true;
            categoryAxis.renderer.minHeight = 110;

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.minWidth = 50;

            let series = chart.series.push(new am4charts.ColumnSeries());
            series.sequencedInterpolation = true;
            series.dataFields.valueY = "n_sheets";
            series.dataFields.categoryX = "company_name";
            series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
            series.columns.template.strokeWidth = 0;

            series.tooltip.pointerOrientation = "vertical";

            series.columns.template.column.cornerRadiusTopLeft = 10;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.fillOpacity = 0.8;

            let hoverState = series.columns.template.column.states.create("hover");
            hoverState.properties.cornerRadiusTopLeft = 0;
            hoverState.properties.cornerRadiusTopRight = 0;
            hoverState.properties.fillOpacity = 1;

            series.columns.template.adapter.add("fill", function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });

            chart.cursor = new am4charts.XYCursor();
        }
    }

    isCurrentMonthBestSellerProvinceChart() {
        this.showCurrentMonthBestSellerProvinceChart = !this.showCurrentMonthBestSellerProvinceChart;


        setTimeout(() => {
            this.loadCurrentMonthBestSellerProvinceChart();
        }, 2000);

    }

    loadCurrentMonthBestSellerProvinceChart() {
        if (this.showCurrentMonthBestSellerProvinceChart) {


            am4core.useTheme(am4themes_animated);

            let chart = am4core.create("currentMonthBestSellerProvinceChartDiv", am4charts.XYChart);
            chart.rtl = true;
            chart.data = this.currentMonthBestSellerProvince;

            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "province_name";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.verticalCenter = "middle";
            categoryAxis.renderer.labels.template.rotation = 270;
            categoryAxis.tooltip.disabled = true;
            categoryAxis.renderer.minHeight = 110;

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.minWidth = 50;

            let series = chart.series.push(new am4charts.ColumnSeries());
            series.sequencedInterpolation = true;
            series.dataFields.valueY = "n_sheets";
            series.dataFields.categoryX = "province_name";
            series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
            series.columns.template.strokeWidth = 0;

            series.tooltip.pointerOrientation = "vertical";

            series.columns.template.column.cornerRadiusTopLeft = 10;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.fillOpacity = 0.8;

            let hoverState = series.columns.template.column.states.create("hover");
            hoverState.properties.cornerRadiusTopLeft = 0;
            hoverState.properties.cornerRadiusTopRight = 0;
            hoverState.properties.fillOpacity = 1;

            series.columns.template.adapter.add("fill", function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });

            chart.cursor = new am4charts.XYCursor()

        }

    }

    isCurrentProductDimensionsChar() {
        this.showCurrentProductDimensionsChar = !this.showCurrentProductDimensionsChar;


        setTimeout(() => {
            this.loadCurrentProductDimensionsChar();
        }, 2000);

    }

    loadCurrentProductDimensionsChar() {
        if (this.showCurrentProductDimensionsChar) {

            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("productDimensionsChartDiv", am4charts.XYChart);
            chart.rtl = true;
            chart.data = this.dimension;
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "name";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 40;
            categoryAxis.fontSize = 11;
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.max = 15000;
            valueAxis.strictMinMax = true;
            valueAxis.renderer.minGridDistance = 30;
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryX = "name";
            series.dataFields.valueY = "all";
            series.columns.template.tooltipText = "{valueY.value}";
            series.columns.template.tooltipY = 0;
            series.columns.template.strokeOpacity = 0;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.cornerRadiusTopLeft = 10;
            let hoverState = series.columns.template.column.states.create("hover");
            hoverState.properties.cornerRadiusTopLeft = 0;
            hoverState.properties.cornerRadiusTopRight = 0;
            hoverState.properties.fillOpacity = 1;
            hoverState.properties.opacity = 0.1;
            hoverState.transitionDuration = 1500;
            series.columns.template.adapter.add("fill", function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });
            chart.cursor = new am4charts.XYCursor();
        }
    }

    isCurrentProductTypeChar() {
        this.showCurrentProductTypeChar = !this.showCurrentProductTypeChar;


        setTimeout(() => {
            this.loadCurrentProductTypeChar();
        }, 2000);

    }

    loadCurrentProductTypeChar() {
        if (this.showCurrentProductTypeChar) {

            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("productTypeChartDiv", am4charts.XYChart);
            chart.rtl = true;
            chart.data = this.type;
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "name";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 40;
            categoryAxis.fontSize = 11;
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.max = 15000;
            valueAxis.strictMinMax = true;
            valueAxis.renderer.minGridDistance = 30;
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryX = "name";
            series.dataFields.valueY = "all";
            series.columns.template.tooltipText = "{valueY.value}";
            series.columns.template.tooltipY = 0;
            series.columns.template.strokeOpacity = 0;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.cornerRadiusTopLeft = 10;
            let hoverState = series.columns.template.column.states.create("hover");
            hoverState.properties.cornerRadiusTopLeft = 0;
            hoverState.properties.cornerRadiusTopRight = 0;
            hoverState.properties.fillOpacity = 1;
            hoverState.properties.opacity = 0.1;
            hoverState.transitionDuration = 1500;
            series.columns.template.adapter.add("fill", function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });
            chart.cursor = new am4charts.XYCursor();
        }
    }

    isCurrentProductDegreeChar() {
        this.showCurrentProductDegreeChar = !this.showCurrentProductDegreeChar;


        setTimeout(() => {
            this.loadCurrentProductDegreeChar();
        }, 2000);

    }

    loadCurrentProductDegreeChar() {
        if (this.showCurrentProductDegreeChar) {

            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("productDegreeChartDiv", am4charts.XYChart);
            chart.rtl = true;
            chart.data = this.degree;
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "name";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 40;
            categoryAxis.fontSize = 11;
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.max = 15000;
            valueAxis.strictMinMax = true;
            valueAxis.renderer.minGridDistance = 30;
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryX = "name";
            series.dataFields.valueY = "all";
            series.columns.template.tooltipText = "{valueY.value}";
            series.columns.template.tooltipY = 0;
            series.columns.template.strokeOpacity = 0;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.cornerRadiusTopLeft = 10;
            let hoverState = series.columns.template.column.states.create("hover");
            hoverState.properties.cornerRadiusTopLeft = 0;
            hoverState.properties.cornerRadiusTopRight = 0;
            hoverState.properties.fillOpacity = 1;
            hoverState.properties.opacity = 0.1;
            hoverState.transitionDuration = 1500;
            series.columns.template.adapter.add("fill", function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });
            chart.cursor = new am4charts.XYCursor();
        }
    }

    isCurrentProductThicknessChar() {
        this.showCurrentProductThicknessChar = !this.showCurrentProductThicknessChar;


        setTimeout(() => {
            this.loadCurrentProductThicknessChar();
        }, 2000);

    }

    loadCurrentProductThicknessChar() {
        if (this.showCurrentProductThicknessChar) {

            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("productThicknessChartDiv", am4charts.XYChart);
            chart.rtl = true;
            chart.data = this.thickness;
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "name";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 40;
            categoryAxis.fontSize = 11;
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.max = 15000;
            valueAxis.strictMinMax = true;
            valueAxis.renderer.minGridDistance = 30;
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryX = "name";
            series.dataFields.valueY = "all";
            series.columns.template.tooltipText = "{valueY.value}";
            series.columns.template.tooltipY = 0;
            series.columns.template.strokeOpacity = 0;
            series.columns.template.column.cornerRadiusTopRight = 10;
            series.columns.template.column.cornerRadiusTopLeft = 10;
            let hoverState = series.columns.template.column.states.create("hover");
            hoverState.properties.cornerRadiusTopLeft = 0;
            hoverState.properties.cornerRadiusTopRight = 0;
            hoverState.properties.fillOpacity = 1;
            hoverState.properties.opacity = 0.1;
            hoverState.transitionDuration = 1500;
            series.columns.template.adapter.add("fill", function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
            });
            chart.cursor = new am4charts.XYCursor();
        }
    }

    plusMount() {

        if (this.mountNum > 0 && this.mountNum < 12) {
            this.mountNum++;
        } else {
            this.mountNum = 1
        }


        this.initiallData();


        // this.loadProductOrder();

    }

    prevMount() {

        if (this.mountNum > 1 && this.mountNum <= 12) {
            this.mountNum--;
        } else {
            this.mountNum = 12
        }


        this.initiallData();


        // this.loadProductOrder();

    }

    selectYear() {
        this.showYearInformationFlag = true;
        this.initiallData();
        // this.loadProductOrder();

    }
    setYear(year) {
        debugger;
        this.showYearInformationFlag = false;
        this.curentYear = year;
        this.initiallData();
        // this.showYearInformationFlag = true;
        // this.initiallData();
        // // this.loadProductOrder();

    }

    setMonth($event){
        debugger;
        this.mountNum = $event;
        this.initiallData();
    }

    submitFilter(form){
       debugger;
       console.log(form.value);
       this.curentYear = form.value.year;
       this.mountNum = form.value.month;
        this.initiallData();
    }


    selectMount() {
        this.mountNum = Number(jalaliMoment(new Date, 'MM').locale('fa').format('MM'));
        this.showYearInformationFlag = false;
        this.initiallData();
        // this.loadProductOrder();
    }

}
