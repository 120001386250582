<div class="m-3 mt-4">
  <ngb-tabset [destroyOnHide]="true">
    <ngb-tab title="برندها">
      <ng-template ngbTabContent>
        <app-activity-brand></app-activity-brand>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="زمینه‌های کاری">
      <ng-template ngbTabContent>
        <app-activity-type></app-activity-type>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="نحوه همکاری">
      <ng-template ngbTabContent>
        <app-activity-cooperation></app-activity-cooperation>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="صنایع مورد فعالیت">
      <ng-template ngbTabContent>
        <app-activity-industry></app-activity-industry>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="محصولات/خدمات">
      <ng-template ngbTabContent>
        <app-activity-product></app-activity-product>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="عناوین آدرس‌های پستی">
      <ng-template ngbTabContent>
        <app-address-title></app-address-title>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="متراژها">
      <ng-template ngbTabContent>
        <app-property-area></app-property-area>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="وضعیت‌های ملکی">
      <ng-template ngbTabContent>
        <app-property-status></app-property-status>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="انواع اطلاعات تماس">
      <ng-template ngbTabContent>
        <app-phone-type></app-phone-type>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="سمت‌ها">
      <ng-template ngbTabContent>
        <app-personnel-job></app-personnel-job>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="بانک‌ها">
      <ng-template ngbTabContent>
        <app-bank-name></app-bank-name>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="وضعیت‌های استعلام">
      <ng-template ngbTabContent>
        <app-inquiry-status></app-inquiry-status>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="انواع شبکه اجتماعی">
      <ng-template ngbTabContent>
        <app-social-network-type></app-social-network-type>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="مدرک تحصیلی">
      <ng-template ngbTabContent>
        <app-education-degree></app-education-degree>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="رشته‌های تحصیلی">
      <ng-template ngbTabContent>
        <app-education-field></app-education-field>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
