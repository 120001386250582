import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log(request);

    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("user_id");
    if (!request.headers.get("dt"))
      request = request.clone({
        headers: request.headers.set("content-type", "application/json"),
      });
    else {
      request = request.clone({
        headers: request.headers.delete("dt"),
      });
    }
    if (token) {
      request = request.clone({
        headers: request.headers.set("Authorization", `Token ${token}`),
      });
    }
    if (user_id) {
      console.log(user_id);

      request = request.clone({
        headers: request.headers.set("user-id", user_id),
      });
      console.log(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          localStorage.removeItem("token");
          this.router.navigate(["/auth"]);
        }

        if (error && error.status === 0) {
          // this.router.navigate(['/noInternet']);
        }

        if (error && error.status === 500) {
          this.router.navigate(["/500"]);
        }
        if (error) console.log(error);
        return throwError(error);
      })
    );
  }
}
