<div class="m-3 mt-4">
    <app-title title="لیست پالت ها"></app-title>
    <div class="list my-3">
        <div class="list-header bg-gray">
            <div class="list-header-item">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div class="list-header-item">تعداد ردیف:
                {{ pallets.length }}
            </div>
            <div class="list-header-item mr-auto">
                <i class="far fa-file-search"></i
                ><input
                    type="text"
                    class="form-control"
                    [formControl]="searchControl"
            />
            </div>
        </div>
        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col">
                    کد
                </th>
                <th scope="col">
                   ایجاد کننده
                </th>
                <th scope="col"
                >
                    وضعیت موجودی
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of pallets; let i = index">
                <th scope="row">{{ i + 1 | toFa }}</th>
                <!-- <td>{{ i + 1 | toFa }}</td> -->
                <td>
                    {{ el.code }}
                </td>
                <td>
                    {{el.creator}}
                </td>
                <td>
                    <i
                            class="fa fa-circle"
                            [class]="el.available ? 'text-success' : 'text-danger'"
                    ></i>
                </td>
            </tr>
            </tbody>
        </table>
        <div *ngIf="pallets.length==0"
             style="width: 100%;
    height: 30px;
    text-align: center;padding-top: 7px;">
            <h5>
                اطلاعاتی یافت نشد
            </h5>

        </div>
    </div>


</div>
