import {Component, ViewChild, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '../../../../auth/auth.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Config} from '../../../../app-config.service';
import * as jalaliMoment from 'jalali-moment';

@Component({
    selector: 'app-order-dashboard',
    templateUrl: './order-dashboard.component.html',
    styleUrls: ['./order-dashboard.component.scss']
})
export class OrderDashboardComponent implements OnInit {


    startDate = jalaliMoment.from(jalaliMoment(new Date, 'YYYY').locale('fa').format('YYYY') + "/01/01", 'fa', 'YYYY/MM/DD')
        .locale('en').format('YYYY-MM-DD').slice(0, 11) + "T00:00:00.000Z";

    user = null;
    referredOrder: any[] = [];
    orgReferredOrders: any[] = [];

    myOrder: any[] = [];
    orgMyOrders: any[] = [];

    checkOut: any[] = [];
    orgCheckout: any[] = [];

    dashboardOrderApi: string = Config.settings.api  + 'dashboard/order';

    constructor(private auth: AuthService, private router: Router,
                private modalService: NgbModal,
                private http: HttpClient) {

    }

    async ngOnInit(): Promise<void> {

        this.user = await this.auth.get_info();
        //

        this.loadOrder();

    }

    loadOrder() {
        this.orgReferredOrders = [];
        this.http.get(this.dashboardOrderApi).subscribe(
            (response: any) => {

                //region referredOrders
                if (this.user && !(this.user.role == 2 || this.user.role == 3)) {

                    this.orgReferredOrders.push({
                        id: 1,
                        name: 'جدید',
                        n_orders: response.referred_orders.new.n_orders,
                        n_sheets: response.referred_orders.new.n_sheets,
                        parm: this.setRequestReferredOrderParam(0,
                            new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3)
                            .toJSON()
                            .slice(0, 11) + "00:00:00.000Z", undefined)

                    });

                    this.orgReferredOrders.push({
                        id: 2,
                        name: 'تایید دریافت',
                        n_orders: response.referred_orders.receipt_approval.n_orders,
                        n_sheets: response.referred_orders.receipt_approval.n_sheets,
                        parm: this.setRequestReferredOrderParam(0, this.startDate
                            , 12)


                    });
                    this.orgReferredOrders.push({
                        id: 3,
                        name: 'عدم تایید دریافت',
                        n_orders: response.referred_orders.receipt_disapproval.n_orders,
                        n_sheets: response.referred_orders.receipt_disapproval.n_sheets,
                        parm: this.setRequestReferredOrderParam(0, this.startDate
                            , 13)

                    });
                    this.orgReferredOrders.push({
                        id: 4,
                        name: 'توافق نماینده',
                        n_orders: response.referred_orders.representative_agreement.n_orders,
                        n_sheets: response.referred_orders.representative_agreement.n_sheets,
                        parm: this.setRequestReferredOrderParam(0, this.startDate
                            , 14)

                    });
                    this.orgReferredOrders.push({
                        id: 5, name: 'عدم توافق نماینده',
                        n_orders: response.referred_orders.representative_disagreement.n_orders,
                        n_sheets: response.referred_orders.representative_disagreement.n_sheets,
                        parm: this.setRequestReferredOrderParam(0, this.startDate
                            , 15)

                    });
                    this.orgReferredOrders.push({
                        id: 6, name: 'تکمیل شده',
                        n_orders: response.referred_orders.completed.n_orders,
                        n_sheets: response.referred_orders.completed.n_sheets,
                        parm: this.setRequestReferredOrderParam(0, this.startDate
                            , "16,19")

                    });

                    this.referredOrder = this.orgReferredOrders;
                }
                //endregion

                //#region checkout
                this.orgCheckout.push({
                    id: 1,
                    name: 'عدم تسویه',
                    n_orders: response.checkout.not_checked_out.n_orders,
                    order_amount: response.checkout.not_checked_out.order_amount,
                    paid_amount: response.checkout.not_checked_out.paid_amount,
                    remaining_amount: response.checkout.not_checked_out.remaining_amount,
                    parm: {
                        start_date: this.startDate, state: '6,11', checkout_state: 1
                    }

                });
                this.orgCheckout.push({
                    id: 2,
                    name: 'در حال تسویه',
                    n_orders: response.checkout.checking_out.n_orders,
                    order_amount: response.checkout.checking_out.order_amount,
                    paid_amount: response.checkout.checking_out.paid_amount,
                    remaining_amount: response.checkout.checking_out.remaining_amount,
                    parm: {
                        start_date: this.startDate, state: '6,11', checkout_state: 2
                    }

                });
                this.orgCheckout.push({
                    id: 3,
                    name: 'تسویه شده اخیر',
                    n_orders: response.checkout.recently_checked_out.n_orders,
                    order_amount: response.checkout.recently_checked_out.order_amount,
                    paid_amount: response.checkout.recently_checked_out.paid_amount,
                    remaining_amount: response.checkout.recently_checked_out.remaining_amount,
                    parm: {
                        start_date: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3)
                            .toJSON()
                            .slice(0, 11) + "00:00:00.000Z", state: '6,11', checkout_state: 3
                    }

                });
                this.checkOut = this.orgCheckout;

                //#endregion

                //region myOrder
                this.orgMyOrders.push({
                    id: 1,
                    name: 'جدید',
                    n_orders: response.my_orders.new.n_orders,
                    n_sheets: response.my_orders.new.n_sheets,
                    router:'/userArea/order',
                    parm: this.setRequestMyOrderParam(1,
                        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3).toJSON()
                            .slice(0, 11) + "00:00:00.000Z"
                        , undefined)

                });

                this.orgMyOrders.push({
                    id: 2,
                    name: 'در انتظار تایید',
                    n_orders: response.my_orders.waiting.n_orders,
                    n_sheets: response.my_orders.waiting.n_sheets,
                    router:'/userArea/order',
                    parm: this.setRequestMyOrderParam(1, this.startDate
                        , 1)

                });

                this.orgMyOrders.push({
                    id: 3,
                    name: 'در حال بررسی',
                    n_orders: response.my_orders.checking.n_orders,
                    n_sheets: response.my_orders.checking.n_sheets,
                    router:'/userArea/order',
                    parm: this.setRequestMyOrderParam(1, this.startDate
                        , 2)

                });

                this.orgMyOrders.push({
                    id: 4,
                    name: 'تایید کارشناس',
                    n_orders: response.my_orders.expert_approval.n_orders,
                    n_sheets: response.my_orders.expert_approval.n_sheets,
                    router:'/userArea/order',
                    parm: this.setRequestMyOrderParam(1, this.startDate
                        , 3)

                });

                this.orgMyOrders.push({
                    id: 5,
                    name: 'لغو شده',
                    n_orders: response.my_orders.cancelled.n_orders,
                    n_sheets: response.my_orders.cancelled.n_sheets,
                    router:'/userArea/order',
                    parm: this.setRequestMyOrderParam(1, this.startDate
                        , "8,9,10")

                });

                this.orgMyOrders.push({
                    id: 6,
                    name: 'آماده سازی',
                    n_orders: response.my_orders.preparing.n_orders,
                    n_sheets: response.my_orders.preparing.n_sheets,
                    router:'/userArea/order',
                    parm: this.setRequestMyOrderParam(1, this.startDate
                        , 4)

                });

                this.orgMyOrders.push({
                    id: 7,
                    name: 'در حال ارسال',
                    n_orders: response.my_orders.sending.n_orders,
                    n_sheets: response.my_orders.sending.n_sheets,
                    router:'/userArea/order',
                    parm: this.setRequestMyOrderParam(1, this.startDate
                        , 6)

                });

                this.orgMyOrders.push({
                    id: 8,
                    name: 'تکمیل شده',
                    n_orders: response.my_orders.completed.n_orders,
                    n_sheets: response.my_orders.completed.n_sheets,
                    router:'/userArea/order',
                    parm: this.setRequestMyOrderParam(1, this.startDate
                        , 11)
                });

                this.orgMyOrders.push({
                    id: 9,
                    name: 'عدم تایید دریافت',
                    n_orders: response.my_orders.receipt_disapproval.n_orders,
                    n_sheets: response.my_orders.receipt_disapproval.n_sheets,
                    router:'/userArea/shipping',
                    parm: {
                        in_company:1,
                        order_start_date:this.startDate,
                        is_received:0
                    }

                });

                this.orgMyOrders.push({
                    id: 10,
                    name: 'تایید دریافت',
                    n_orders: response.my_orders.receipt_approval.n_orders,
                    n_sheets: response.my_orders.receipt_approval.n_sheets,
                    router:'/userArea/shipping',
                    parm: {
                        in_company:1,
                        order_start_date:this.startDate,
                        is_received:1
                    }

                });

                this.myOrder = this.orgMyOrders;

                //endregion

            },
            (error: HttpErrorResponse) => {
                // TODO: server errors
                console.log(error);
            }
        );
    }

    setRequestReferredOrderParam(name: number, department: any, statement: any) {
        let parm = {};
        if (this.user && this.user.role == 4) {
            parm = {start_date: department};

        } else {
            parm = Object.assign(parm, {"in_company": name});
            parm = Object.assign(parm, {start_date: department});

        }
        ;
        if (statement != undefined) {
            parm = Object.assign(parm, {state: statement});
        }
        console.log(parm);
        return parm;
    }

    setRequestMyOrderParam(name: number, department: any, statement: any) {
        let parm = {};
        if (this.user && this.user.role == 4) {
            parm = {start_date: department};

        } else {
            parm = Object.assign(parm, {"in_company": name});
            parm = Object.assign(parm, {start_date: department});

        }
        ;
        if (statement != undefined) {
            parm = Object.assign(parm, {state: statement});
        }
        console.log(parm);
        return parm;
    }

}
