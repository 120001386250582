<div class="m-3 ml-5 mt-4">
  <app-title title="جزئیات سفارش"></app-title>

  <div *ngIf="success" class="" role="">
    <div
      class="d-flex text-success justify-content-center align-items-center"
    >
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ msg }}
    </div>
  </div>
  <div *ngIf="isWrong" class="" role="">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ errorMsg }}
    </div>
  </div>
  <app-toolbar [toolbarItems]="toolbarItemList" (clickHandler)="itmeClickHandler($event)"></app-toolbar>

  <div class="order-header mt-5">
    <div class="row justify-content-between">
      <div class="item">
        شماره سفارش:{{ id ? (id.toString() | toFa) : null }}
      </div>
      <div class="item">تاریخ سفارش:{{ date.toISOString() | jDate }}</div>
      <div class="item">مخاطب:{{ name }}</div>
      <div class="item">
        ایجاد کننده:{{ creator_first_name + " " + creator_last_name }}
      </div>
      <div class="item">
        وضعیت:{{ func.order_states[state]
        }}{{
          orderd?.state == 7 || orderd?.state > 11
            ? " (" + orderd.representative_company_name + ")"
            : null
        }}
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="item">
        تعداد:{{ sheet_sum ? (sheet_sum.toString() | toFa) : null }}
      </div>
      <div class="item">
        تعداد ارسال شده:{{
          (sheet_sum - remaining_sheet_number)?.toString() | toFa
        }}
      </div>
      <div class="item">
        باقی مانده:{{ remaining_sheet_number?.toString() | toFa }}
      </div>
      <div class="item">مبلغ خالص سفارش: {{ (orderd?.debt_amount- orderd?.discount_amount).toString() | toR }}</div>
      <div class="item">وضعیت تسویه: {{ orderd?.checkout_state }}</div>
    </div>
  </div>
  <form #form="ngForm" class="w-100">
    <div class="box">
      <div class="row">
        <div class="form-group col-lg-3 col-md-6 col-12">
          <label class="mx-1 lead"> نحوه خرید </label>
          <select
            [ngModel]="method"
            name="method"
            class="custom-select custom-select-lg"
          >
            <option *ngFor="let el of methods" [ngValue]="el.id">
              {{ el.label }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-3 col-md-6 col-12">
          <label class="mx-1 lead"> نوع خرید </label>
          <select
            [ngModel]="type"
            name="type"
            class="custom-select custom-select-lg"
          >
            <option *ngFor="let el of types" [ngValue]="el.id">
              {{ el.label }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-3 col-md-6 col-12">
          <label class="mx-1 lead"> نحوه حمل </label>
          <select
            [ngModel]="transportation"
            name="transportation"
            class="custom-select custom-select-lg"
          >
            <option *ngFor="let el of transportations" [ngValue]="el.id">
              {{ el.label }}
            </option>
          </select>
        </div>
        <div
          class="form-group col-lg-3 col-md-6 col-12"
          *ngIf="can_see_company"
        >
          <label class="mx-1 lead"> شماره درخواست راهکاران </label>
          <input
            type="text"
            [(ngModel)]="request_number"
            name="request_number"
            class="form-control form-control-lg"
          />
        </div>
        <div class="form-group col-lg-3 col-md-6 col-12" *ngIf="canSeeWarehouse">

          <label class="mx-1 lead">محل تامین سفارش</label>
          <select
                  [ngModel]="source"
                  name="source"
                  [disabled]="state == 11"
                  class="custom-select custom-select-lg"
                  (change)="changeSource($event.target.value)"
          >

            <option *ngFor="let el of sources" [ngValue]="el.id">
              {{ el.label }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-3 col-md-6 col-12"  *ngIf="canSeeWarehouse" >
          <fieldset  [disabled]="showWarehouse">
            <label class="mx-1 lead"> عنوان انبار فروش </label>
            <select
                    [ngModel]="warehouse"
                    name="warehouse"
                    [disabled]="state == 11"
                    class="custom-select custom-select-lg"
                    (ngModelChange)="changeWarehouse($event)"

            >
              <option *ngFor="let el of warehouseList" [ngValue]="el.id">
                {{ el.label }}
              </option>
            </select>

            <!--          <app-select-->
            <!--                  [options]="warehouseList"-->
            <!--                  (selectChange)="changeWarehouse($event)"-->
            <!--                  [key]="warehouse"-->
            <!--                  req="true"-->
            <!--                  placeholder=" "-->
            <!--          ></app-select>-->
            <!--          <input-->
            <!--                  type="hidden"-->
            <!--                  value=""-->
            <!--                  [(ngModel)]="warehouse"-->
            <!--                  name="warehouse"-->
            <!--                  class="d-none"-->
            <!--                  required-->
            <!--          />-->
          </fieldset>
        </div>
<!--        <div class="form-group col-lg-3 col-md-6 col-12">-->
<!--          <label class="mx-1 lead"> مسئول انبار فروش </label>-->
<!--          <input-->
<!--                  name="warehouseResponsible"-->
<!--                  type="text"-->
<!--                  [readOnly]="true"-->
<!--                  [(ngModel)]="currentWarehouseOrder.warehouseResponsible"-->
<!--                  class="form-control form-control-lg"-->
<!--                  required-->

<!--          />-->
<!--        </div>-->


        <div
          class="form-group col-lg-3 col-md-6 col-12"
          *ngIf="can_see_company"
        >
          <label class="mx-1 lead"> شماره پیش فاکتور </label>
          <input
            type="text"
            [(ngModel)]="invoice_number"
            name="invoice_number"
            class="form-control form-control-lg"
          />
        </div>
        <div
          class="form-group col-lg-3 col-md-6 col-12"
          *ngIf="can_see_company"
        >
          <label class="mx-1 lead">نحوه تسویه حساب </label>
          <select
            [ngModel]="checkout_type"
            name="checkout_type"
            class="custom-select custom-select-lg"
          >
            <option *ngFor="let el of checkout_types" [ngValue]="el.id">
              {{ el.label }}
            </option>
          </select>
        </div>
        <div
          class="form-group col-lg-3 col-md-6 col-12"
          *ngIf="can_see_company"
        >
          <label class="mx-1 lead"> تاریخ تسویه </label>
          <div class="input-group">
            <input
              matInput
              [matDatepicker]="picker3"
              [(ngModel)]="checkout_date"
              name="checkout_date"
              class="form-control form-control-lg"
            />

            <span class="input-group-append" (click)="picker3.open()">
              <i class="fa fa-calculator input-group-text"></i>
            </span>
            <mat-datepicker #picker3></mat-datepicker>
          </div>
        </div>
        <div
          class="form-group col-lg-3 col-md-6 col-12"
          *ngIf="can_see_company"
        >
          <label class="mx-1 lead"> مبلغ ریالی سفارش </label>
          <input
            type="text"
            [(ngModel)]="debt_amount"
            name="debt_amount"
            (keydown)="func.numControl($event)"
            class="form-control form-control-lg"
          />
        </div>
        <div
                class="form-group col-lg-3 col-md-6 col-12"
                *ngIf="can_see_company"
        >
          <label class="mx-1 lead"> درصد کسورات </label>
          <input
                  type="number"
                  [(ngModel)]="discount_percent"
                  name="discount_percent"
                  max="100" min="0" step="0.5"
                  (change)="valid_change_percent()"
                  class="form-control form-control-lg"
          />
        </div>
        <div
                class="form-group col-lg-3 col-md-6 col-12"
                *ngIf="can_see_company"
        >
          <label class="mx-1 lead"> مبلغ ریالی کسورات </label>
          <input
                  type="text"
                  [(ngModel)]="discount_amount"
                  name="discount_amount"
                  (change)="valid_change_amount()"
                  (keydown)="func.numControl($event)"
                  class="form-control form-control-lg"
          />
        </div>
        <div
                class="form-group col-lg-3 col-md-3 col-3"
                *ngIf="can_see_company"
        >
          <label class="mx-1 lead"> مبلغ ریالی خالص سفارش </label>
          <input
                  type="text"
                  readonly="readonly"
                  [(ngModel)]="remaining_amount"
                  name="remaining_amount"
                  class="form-control form-control-lg font-weight-bold"
          />
        </div>
        <div
                class="form-group col-lg-9 col-md-9 col-9"
                *ngIf="can_see_company && canSeeWarehouse"
        >
          <label class="mx-1 lead"> به حروف مبلغ ریالی خالص سفارش </label>
          <input
                  type="text"
                  readonly="readonly"
                  [(ngModel)]="remaining_amount_word"
                  name="remaining_amount_word"
                  class="form-control form-control-lg font-weight-bold"
          />
        </div>
        <div
                class="form-group col-lg-12 col-md-12 col-12"
                *ngIf="can_see_company && !canSeeWarehouse"
        >
          <label class="mx-1 lead"> به حروف مبلغ ریالی خالص سفارش </label>
          <input
                  type="text"
                  readonly="readonly"
                  [(ngModel)]="remaining_amount_word"
                  name="remaining_amount_word"
                  class="form-control form-control-lg font-weight-bold"
          />
        </div>
        <div class="w-100 p-3"  *ngIf="can_see_company">
          <label class="mx-1 lead">توضیحات کسورات</label>
          <textarea
                  class="w-100 form-control"
                  [(ngModel)]="discount_detail"
                  name="detail"
                  (keydown)="permissionControl($event)"
          ></textarea>
        </div>
      </div>
    </div>
  </form>

  <div class="list mx-3">
    <table class="table table-bordered">
      <thead class="thead-light headerless">
        <tr>
          <th scope="col">#</th>
          <th scope="col">نام کالا</th>
          <th scope="col">تعداد ورق</th>
          <th scope="col">تعداد پالت</th>
          <th scope="col" class="d-lg-table-cell d-none">وزن تقریبی</th>
          <th scope="col" class="d-lg-table-cell d-none">
            واحد اندازه گیری
          </th>
          <th scope="col" *ngIf="showCountProduct">موجودی کالا </th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of order; let i = index">
          <th>{{ i + 1 }}</th>
          <td class="p-0">
            <select
              [(ngModel)]="el.product_id"
              (change)="changeProduct($event.target.value,i)"
              class="border-0 custom-select custom-select-lg"
            >
              <option *ngFor="let p of products" [value]="p.id">
                {{ p.label }}
              </option>
            </select>
          </td>

          <td class="p-0">
            <input
              type="number"
              class="border-0 form-control form-control-lg"
              [(ngModel)]="order[i].count"
              [ngModelOptions]="{ standalone: true }"
            />
          </td>
          <td>
            {{
              el.pindex != null && order[i].count !== null
                ? math.floor(
                    (1.0 * el.count) /
                      this.orgProducts[el.pindex].number_in_pallet
                  )
                : null
            }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{
              el.pindex != null && order[i].count !== null
                ? math.ceil(
                    order[i].count * orgProducts[el.pindex].weight
                  )
                : null
            }}
          </td>
          <td class="d-lg-table-cell d-none">
            {{ el.pindex != null ? orgProducts[el.pindex].unit : null }}
          </td>
          <td class="d-lg-table-cell d-none" *ngIf="showCountProduct">
            {{order[i].productSheetCount}}
          </td>
          <td><i class="fal fa-trash-alt" (click)="remove(i)"></i></td>
        </tr>
        <tr>
          <th class="border-left-0"></th>
          <td (click)="add()" class="border-right-0">
            <i class="fal fa-plus"></i>
          </td>
          <td>{{ count_sum }}</td>
          <td>{{ pallet_sum }}</td>
          <td class="d-lg-table-cell d-none">{{ weight_sum }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="w-100 p-3">
    <label class="mx-1 lead">توضیحات</label>
    <textarea
      class="w-100 form-control"
      [(ngModel)]="detail"
      name="detail"
      (keydown)="permissionControl($event)"
    ></textarea>
  </div>

  <div *ngIf="cancellation_reason" class="w-100 p-3">
    <label class="mx-1 lead">دلیل لغو</label>
    <textarea
      class="w-100 form-control"
      [(ngModel)]="cancellation_reason"
      name="cancellation_reason"
      (keydown)="$event.preventDefault()"
    ></textarea>
  </div>
  <!-- <div class="row justify-content-end m-3">
    <button
      [routerLink]="['../']"
      type="button"
      class="btn col-2 btn-gray mx-3"
    >
      لغو
    </button>
    <button (click)="register(form)" class="btn col-2 btn-success">
      ذخیره
    </button>
  </div> -->

  <div
    *ngIf="user && user.id === buyer"
    class="row justify-content-end m-3"
  >
    <div *ngIf="state === 1" class="row w-100 justify-content-end">
      <button
        [disabled]="disable_send"
        type="submit"
        class="btn mx-3 w-20 btn-success"
      >
        ذخیره تغییرات
      </button>

      <button
        type="button"
        [disabled]="disable_send"
        (click)="cancelOrder(cancel, 8)"
        class="btn mx-3 w-20 btn-danger"
      >
        لغو
      </button>
      <button
        type="button"
        [disabled]="disable_send"
        (click)="register(form, null, 2)"
        class="btn mx-3 w-20 btn-success"
      >
        تایید و ارسال نهایی
      </button>

      <button
        (click)="loc.back()"
        type="button"
        class="btn mx-3 w-20 btn-gray"
      >
        بازگشت
      </button>
    </div>

    <div *ngIf="state !== 1" class="row w-100 justify-content-end">
      <button
        *ngIf="(state >= 4 && state < 7) || state == 11"
        type="button"
        [routerLink]="['../../checkout', id]"
        class="btn mx-3 w-25 btn-success"
      >
        فرم پرداخت
      </button>
      <button
        (click)="loc.back()"
        type="button"
        class="btn mx-3 w-25 btn-secondary"
      >
        بازگشت
      </button>
    </div>
  </div>
  <div
    *ngIf="user && user.id !== buyer"
    class="row justify-content-end m-3"
  >
    <div
      *ngIf="state === 1 && can_send"
      class="row col-10 justify-content-end"
    >
      <button
        [disabled]="disable_send"
        (click)="register(form, datePicker)"
        class="btn mx-3 w-25 btn-success"
      >
        ذخیره تغییرات
      </button>

      <button
        type="button"
        (click)="register(form, datePicker, 2)"
        [disabled]="disable_send"
        class="btn mx-3 w-25 btn-success"
      >
        تایید و ارسال نهایی
      </button>
    </div>
    <div
      *ngIf="state === 2 && user.role != 4"
      class="row col-10 justify-content-end"
    >
      <button
        type="button"
        [disabled]="disable_send"
        (click)="confirm(form)"
        class="btn mx-3 w-20 btn-success"
      >
        تایید
      </button>
      <button
        type="button"
        [disabled]="disable_send"
        (click)="cancelOrder(cancel, 10)"
        class="btn mx-3 w-20 btn-danger"
      >
        عدم تایید (لغو)
      </button>
      <button
        *ngIf="can_change"
        type="button"
        [disabled]="disable_send"
        (click)="edit(form, 1)"
        class="btn mx-3 w-20 btn-warning"
      >
        بازگشت به مرحله قبل
      </button>
      <button
        *ngIf="can_edit"
        type="button"
        [disabled]="disable_send"
        (click)="register(form, datePicker, 2)"
        class="btn mx-3 w-20 btn-success"
      >
        ذخیره تغییرات
      </button>
    </div>
    <div
      *ngIf="state === 3 && user.role != 4"
      class="row col-10 justify-content-end"
    >
      <button
        *ngIf="can_change"
        type="button"
        [disabled]="disable_send"
        (click)="edit(form, 2)"
        class="btn mx-3 w-20 btn-warning"
      >
        اصلاح
      </button>
      <button
        *ngIf="can_edit"
        type="button"
        [disabled]="disable_send"
        (click)="register(form, datePicker, 3)"
        class="btn mx-3 w-20 btn-success"
      >
        ذخیره تغییرات
      </button>

      <button
        type="button"
        (click)="send_to_factory(form)"
        [disabled]="disable_send"
        class="btn mx-3 w-20 btn-success"
      >
        انتقال به سیستم مالی
      </button>
      <button
        type="button"
        [disabled]="disable_send"
        (click)="redirect(form)"
        class="btn mx-3 w-20 btn-success"
      >
        ارجاع به نمایندگی
      </button>
    </div>
    <div
      *ngIf="(state === 4 || state === 6) && user.role != 4"
      class="row col-10 justify-content-end"
    >
      <button
        *ngIf="can_edit"
        type="button"
        [disabled]="disable_send"
        (click)="register(form, datePicker, state)"
        class="btn mx-3 w-15 btn-success"
      >
        ذخیره تغییرات
      </button>
      <button
        type="button"
        [routerLink]="['../../shipping/new', id]"
        [disabled]="disable_send"
        class="btn mx-3 ml-3 w-15 btn-success"
      >
        اعلام ارسال بار
      </button>
      <button
        type="button"
        [routerLink]="['../../checkout', id]"
        class="btn mx-3 w-15 btn-success"
      >
        فرم پرداخت
      </button>
      <button
        *ngIf="remaining_sheet_number"
        type="button"
        [disabled]="disable_send"
        (click)="edit(form, 2)"
        class="btn mx-3 w-15 btn-warning"
      >
        اصلاح
      </button>
      <button
        *ngIf="state === 6"
        type="button"
        [disabled]="disable_send"
        (click)="edit(form, 11)"
        class="btn mx-3 w-15 btn-success"
      >
        تکمیل سفارش
      </button>
      <!-- <button
               type="button"
               (click)="evaluate(form)"
               class="btn ml-3 w-20 btn-success"
            >
               ارزیابی رضایت مشتری
            </button> -->
    </div>
    <div
      *ngIf="
        (state === 7 || state === 12 || state === 13 || state === 15) &&
        user.role != 4
      "
      class="row col-10 justify-content-end"
    >
      <button
        type="button"
        [disabled]="disable_send"
        (click)="edit(form, 3)"
        class="btn mx-3 w-20 btn-success"
      >
        برگشت به سیستم
      </button>
    </div>
    <div
      *ngIf="state === 7 && user.role === 4"
      class="row col-10 justify-content-end"
    >
      <button
        type="button"
        [disabled]="disable_send"
        (click)="edit(form, 12)"
        class="btn mx-3 w-20 btn-success"
      >
        تایید دریافت
      </button>
      <button
        type="button"
        [disabled]="disable_send"
        (click)="edit(form, 13)"
        class="btn mx-3 w-20 btn-danger"
      >
        عدم تایید (لغو)
      </button>
    </div>
    <div
      *ngIf="state === 12 && user.role === 4"
      class="row col-10 justify-content-end"
    >
      <button
        *ngIf="can_edit"
        type="button"
        [disabled]="disable_send"
        (click)="register(form, datePicker, state)"
        class="btn mx-3 w-20 btn-success"
      >
        ذخیره تغییرات
      </button>
      <button
        type="button"
        [disabled]="disable_send"
        (click)="edit(form, 14)"
        class="btn mx-3 w-20 btn-success"
      >
        توافق با مشتری و موافقت با سفارش
      </button>
      <button
        type="button"
        [disabled]="disable_send"
        (click)="edit(form, 15)"
        class="btn mx-3 w-20 btn-danger"
      >
        عدم توافق با مشتری
      </button>
    </div>
    <div
      *ngIf="state === 14 && user.role === 4"
      class="row col-10 justify-content-end"
    >
      <button
        type="button"
        [routerLink]="['../../shipping/new', id]"
        [disabled]="disable_send"
        class="btn mx-3 w-20 btn-success"
      >
        اعلام ارسال بار
      </button>
    </div>
    <div *ngIf="state === 11" class="row col-10 justify-content-end">
      <button
        type="button"
        [routerLink]="['../../checkout', id]"
        class="btn mx-3 w-25 btn-success"
      >
        فرم پرداخت
      </button>
      <button
        *ngIf="can_edit"
        [disabled]="disable_send"
        (click)="register(form, null, 6)"
        class="btn mx-3 w-25 btn-success"
      >
        ذخیره تغییرات
      </button>
    </div>
    <button
      (click)="loc.back()"
      type="button"
      class="btn col-2 btn-secondary"
    >
      بازگشت
    </button>
  </div>

  <app-title
    *ngIf="shippings.length"
    title="لیست بارهای ارسالی"
  ></app-title>

  <div *ngIf="shippings.length" class="list m-3">
    <table class="table table-bordered">
      <thead class="thead-light headerless">
        <tr>
          <th scope="col">#</th>
          <th scope="col">شرکت</th>
          <th scope="col">تاریخ دریافت</th>
          <th scope="col"  class="d-lg-table-cell d-none">سفارش</th>
          <th scope="col"  class="d-lg-table-cell d-none">فاکتور</th>
          <th scope="col">تعداد ورق</th>
          <th scope="col">نام ارسال کننده</th>
          <th scope="col"  class="d-lg-table-cell d-none">رضایت سنجی</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of shippings; let i = index">
          <th scope="row">
            {{ i + 1 | toFa }}
          </th>
          <td>{{ el.company_name }}</td>
          <td>{{ el.receiving_date | jDate | toFa }}</td>
          <td  class="d-lg-table-cell d-none">{{ el.order | toFa }}</td>
          <td  class="d-lg-table-cell d-none">{{ el.remittance_number }}</td>
          <td>{{ el.sheet_number | toFa }}</td>
          <td>
            {{ el.creator_first_name + " " + el.creator_last_name }}
          </td>
          <td  class="d-lg-table-cell d-none">
            <a
              *ngIf="!el.evaluation"
              [routerLink]="['../../evaluate', el.id]"
              >انجام نشده</a
            >
            <a
              *ngIf="el.evaluation"
              [routerLink]="['../../evaluation', el.evaluation]"
              >انجام شده</a
            >
          </td>
          <td
            class="cursor-pointer"
            [routerLink]="['../../shipping', el.id]"
          >
            <i class="fal fa-edit"></i>
          </td>
          <td
            class="cursor-pointer"
            (click)="can_del && delShipping(content, el.id)"
          >
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #datePicker let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      تاریخ سفارش را وارد کنید.
    </h4>
  </div>
  <div class="modal-body">
    <input
      matInput
      [matDatepicker]="dpicker"
      (click)="dpicker.open()"
      type="text"
      [(ngModel)]="date"
      name="receiving_date"
      class="input-text w-100 form-control"
      required
    />
    <label placeholder="تاریخ سفارش" alt="تاریخ سفارش"></label>
    <mat-datepicker #dpicker></mat-datepicker>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(date)"
    >
      تایید
    </button>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      آیا از حذف این رکورد اطمینان دارید؟
    </h4>
  </div>
  <div class="modal-body">
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(true)"
    >
      بله
    </button>
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(false)"
    >
      خیر
    </button>
  </div>
</ng-template>

<ng-template #cancel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">لغو سفارش</h4>
  </div>
  <div class="modal-body">
    <form #cancelForm="ngForm" class="w-100">
      <div class="row w-100 mb-3">
        <div class="col-md-6 mb-2" *ngFor="let el of cancellation">
          <label
            class="px-4 w-100 d-flex justify-content-lg-between align-items-center"
          >
            {{ el.value }}
            <input
              type="radio"
              ngModel
              name="cancellation"
              [value]="el.key"
            />
          </label>
        </div>
      </div>

      <label> دلیل لغو:</label>
      <textarea
        class="w-100"
        ngModel
        name="cancellation_reason"
      ></textarea>
    </form>

    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(cancelForm)"
    >
      تایید
    </button>
  </div>
</ng-template>
