import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { Config } from "src/app/app-config.service";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import * as Func from "src/pack/js/func";
import { untilDestroyed } from "ngx-take-until-destroy";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoadingService } from "src/app/loading/loading.service";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-shipping-list",
  templateUrl: "./shipping-list.component.html",
  styleUrls: ["./shipping-list.component.scss"],
})
export class ShippingListComponent implements OnInit {
  page_number: number = 1;
  page_count: number = 0;
  object_count: number = 0;
  page_size: number = 0;

  customers: any[] = [];
  isCollapsed = true;
  filter_items = [
    {
      name: "in_company",
      type: "hidden",
    },
    {
      name: "is_received",
      type: "hidden",
    },
    {
      name: "order_id",
      label: "شماره سفارش",
      type: "string",
    },
    // {
    //    name: 'order_request_number',
    //    label: 'شماره درخواست',
    //    type: 'string',
    // },
    {
      name: "remittance_number",
      label: "شماره فاکتور",
      type: "string",
    },
    {
      name: "company_name",
      label: "نام فروشگاه",
      type: "string",
    },
    {
      name: "reference",
      label: "نام فروشنده مادر",
      type: "string",
    },
    {
      name: "order_start_date",
      label: "تاریخ سفارش از",
      type: "date",
    },
    {
      name: "sending_start_date",
      label: "تاریخ ارسال از",
      type: "date",
    },
    {
      name: "receiving_start_date",
      label: "تاریخ دریافت از",
      type: "date",
    },
    {
      name: "order_end_date",
      label: "تاریخ سفارش تا",
      type: "date",
    },
    {
      name: "sending_end_date",
      label: "تاریخ ارسال تا",
      type: "date",
    },
    {
      name: "receiving_end_date",
      label: "تاریخ دریافت تا",
      type: "date",
    },
    // {
    //    name: 'company_name',
    //    label: 'نام فروشگاه',
    //    type: 'string',
    // },
    {
      name: "expert_name",
      label: "نام کارشناس",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "evaluation_state",
      label: "وضعیت رضایت‌سنجی",
      type: "list",
      options: [
        { id: null, label: "همه" },
        { id: true, label: "انجام شده" },
        { id: false, label: "انجام نشده" },
      ],
      res: null,
    },
    {
      name: "region_id",
      label: "منطقه",
      type: "list",
      options: [],
      res: null,
    }
    // {
    //    name: 'type',
    //    label: 'نوع خرید',
    //    type: 'string',
    // },
    // {
    //    name: 'method',
    //    label: 'نحوه خرید',
    //    type: 'string',
    // },
    // {
    //    name: 'state',
    //    label: 'وضعیت',
    //    type: 'string',
    // },
  ];

  shippings: any[] = [];
  orgShippings: any[] = [];
  searchControl = new FormControl();
  selected: boolean[] = [];
  shippingsApi: string =
    Config.settings.api + Config.settings.order.shipping;
  func = Func;
  searchTerm: any;
  sheet_sum: number = 0;
  constructor(
    private auth: AuthService,
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private loading: LoadingService
  ) {}

  user;
  async ngOnInit(): Promise<void> {
    this.loadRegion();
    this.user = await this.auth.get_info();

    this.route.queryParamMap.subscribe((f) => {
      let filter = {};
      f.keys.forEach((k) => (filter[k] = f.get(k)));
      console.log(filter);
      console.log(filter["in_company"]);

      if (this.user.role == 4 && filter["in_company"] == "0") {
        this.shippingsApi += "customer_load_reports/";
        delete filter["in_company"];
      } else if (
        this.user.role <= 4 &&
        this.user.role >= 2 &&
        filter["in_company"] == "1"
      ) {
        delete filter["in_company"];
      }

      this.filter_items.forEach(
        (el) => (el.res = filter[el.name] ? filter[el.name] : null)
      );
      this.search(filter);
    });

    this.searchControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((term) => this.search(term));

    this.loadExperts();

  }

  disable_load = false;
  usersApi: string = Config.settings.api + Config.settings.user.user;

  loadExperts() {
    let tc = [{ id: null, label: "همه" }];

    this.http.get(this.usersApi + "?q=5").subscribe(
      (response: any) => {
        console.log(response);
        response.forEach((element) => {
          if (element.name)
            tc.push({
              id: element.id,
              label: element.name,
            });
        });
        // this.filter_items[4].options = tc;
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "expert_name") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  loadRegion() {
    let tc = [{id: null, label: "همه"}];
    let regionApi: string =
        Config.settings.api + "information/region";
    this.http.get(regionApi).subscribe(
        (response: any) => {
          response.forEach((element) => {
            tc.push({
              id: element.id,
              label: element.name,
            });
          });
          // this.filter_items[7].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "region_id") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
    );
  }

  load(page = 1) {
    if (this.disable_load) return;
    this.disable_load = true;
    this.loading.add();

    this.orgShippings = [];
    this.http
      .get(
        this.shippingsApi +
          `?page=${page}&${this.searchTerm}&ordering=${this.sortcol}`
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.page_count = response.page_count;
          this.object_count = response.object_count;
          this.page_number = response.page_number;
          this.page_size = response.page_size;
          this.sheet_sum = response.sheet_sum;

          response.results.forEach((element) => {
            this.orgShippings.push(element);
          });
          this.shippings = this.orgShippings;
          this.disable_load = false;
          this.loading.remove();
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
          this.orgShippings = [];
          this.shippings = [];
          this.page_count = 0;
          this.page_number = 0;
          this.disable_load = false;
          this.loading.remove();
        }
      );
  }

  search(filter: any) {
    this.searchTerm = "";
    Object.keys(filter).forEach((el) => {
      this.searchTerm += el + "=" + filter[el] + "&";
    });

    if (this.searchTerm != "")
      this.searchTerm = this.searchTerm.slice(0, -1);

    console.log(this.searchTerm);
    let qp = filter;

    this.router.navigate([], {
      queryParams: qp,
      // queryParamsHandling: "merge",
    });
    this.load();
    this.isCollapsed = true;
  }

  edit(i = null) {
    if (i) {
      this.router.navigate(["/userArea/order/shipping", i]);
    }
    this.selected.forEach((el, i) => {
      if (el) {
        this.router.navigate(["/userArea/order/shipping", i]);
      }
    });
  }

  can_del = true;
  del(content, i) {
    if (!i) return;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http.delete(this.shippingsApi + i + "/").subscribe(
            (response: any) => {
              this.load();
            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
          );
        }
      });
  }

  sortcol: string = null;

  sort(col: string) {
    if (this.sortcol == col) this.sortcol = "-" + col;
    else this.sortcol = col;
    this.load();
  }

  ngOnDestroy() {}
}
