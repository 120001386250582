import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Config } from 'src/app/app-config.service';

@Component({
   selector: 'signup-form',
   templateUrl: './signup.component.html',
   styleUrls: ['./signup.component.scss'],
   host: {
      class: 'w-100',
   },
})
export class SignupComponent implements OnInit {
   isWrong = false;
   isValidating = false;
   errorMsg: string = '';
   trackingCode = 0;
   lstate = 'natural';
   state = 1;
   states: any[] = [];
   province = 2 ;
   provinces: any[] = [];

   change($event: any) {
      this.state = $event;
   }
   constructor(
      private auth: AuthService,
      private router: Router,
      private http: HttpClient
   ) {}

   ngOnInit(): void {
      let cityApi: string =
          Config.settings.api +
          Config.settings.add.citys +
          `?province=` + this.province;
      this.states = [];
      this.http.get(cityApi).subscribe(
          (response: any) => {
             response.forEach((element) => {
                this.states.push({
                   id: element.id,
                   label: element.name,
                });
             });
          },
          (error: HttpErrorResponse) => {
             console.log(error);
          }
      );
      let provinceApi : string = Config.settings.api + Config.settings.info.province;
      this.http.get(provinceApi).subscribe(
          (response: any) => {
             response.forEach((element) => {
                this.provinces.push({
                   id: element.id,
                   label: element.name,
                });
             });
          },
          (error: HttpErrorResponse) => {
             console.log(error);
          }
      );
   }

   register(form) {
      this.isWrong = false;
      console.log(form);
      //TODO: validation
      if (this.isValidating) {
         this.auth.phone_validate(form.value).subscribe(
            (response: any) => {
               this.auth.register(form.value).subscribe(
                  (response: any) => {
                     //TODO: navigate
                     localStorage.setItem('token', response.key);
                     this.router.navigate(['/userArea']);
                  },
                  (error: HttpErrorResponse) => {
                     console.log(error);
                     this.isWrong = true;
                     this.errorMsg = 'دوباره امتحان کنید.';
                     this.isValidating = false;
                  }
               );
            },
            (error: HttpErrorResponse) => {
               console.log(error);
               this.isWrong = true;
               this.errorMsg = 'دوباره امتحان کنید.';
               this.isValidating = false;
            }
         );
      } else {
         this.auth.phone_register(form.value.phone).subscribe(
            (response: any) => {
               this.isValidating = true;
               this.trackingCode = response.tracking_code;
            },
            (error: HttpErrorResponse) => {
               console.log(error);
               this.isWrong = true;
               this.errorMsg = 'شماره تلفن معتبر نمی‌باشد.';
            }
         );
      }
   }

   changeProvince($event){
      this.province = $event;
      let cityApi: string =
             Config.settings.api +
             Config.settings.add.citys +
             `?province=${$event}`;
      this.states = [];
      this.http.get(cityApi).subscribe(
             (response: any) => {
                response.forEach((element) => {
                   this.states.push({
                      id: element.id,
                      label: element.name,
                   });
                });
             },
             (error: HttpErrorResponse) => {
                console.log(error);
             }
         );
   }
}
