import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute, UrlSegment } from "@angular/router";
import { Config } from "src/app/app-config.service";
import { FormControl } from "@angular/forms";
import { debounceTime, throttleTime } from "rxjs/operators";
import * as Func from "src/pack/js/func";
import { untilDestroyed } from "ngx-take-until-destroy";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoadingService } from "src/app/loading/loading.service";

@Component({
  selector: "app-order-checkout-list",
  templateUrl: "./order-checkout-list.component.html",
  styleUrls: ["./order-checkout-list.component.scss"],
})
export class OrderCheckoutListComponent implements OnInit {
  page_number: number = 1;
  object_count: number = 0;
  page_count: number = 0;
  all_order_amounts: number = 0;
  all_paid_amounts: number = 0;

  usersApi: string = Config.settings.api + Config.settings.user.user;

  isCollapsed = true;
  filter_items = [
    {
      name: "searching_term",
      label: "عبارت جست‌وجو",
      type: "string",
    },
    {
      name: "id",
      label: "شماره سفارش",
      type: "string",
    },
    {
      name: "request_number",
      label: "شماره درخواست",
      type: "string",
    },
    {
      name: "invoice_number",
      label: "شماره پیش فاکتور",
      type: "string",
    },
    {
      name: "start_date",
      label: "تاریخ سفارش از",
      type: "date",
    },
    {
      name: "end_date",
      label: "تاریخ سفارش تا",
      type: "date",
    },
    {
      name: "company_name",
      label: "نام فروشگاه",
      type: "string",
    },
    {
      name: "reference",
      label: "نام فروشنده مادر",
      type: "string",
    },
    {
      name: "expert_name",
      label: "نام کارشناس",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "type",
      label: "نوع خرید",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "method",
      label: "نحوه خرید",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "state",
      label: "وضعیت سفارش",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "checkout_state",
      label: "وضعیت تسویه حساب",
      type: "list",
      options: [
        { id: null, label: "همه" },
        {
          id: 1,
          label: "عدم تسویه حساب",
        },
        {
          id: 2,
          label: "در حال تسویه حساب",
        },
        {
          id: 3,
          label: "تسویه حساب کامل",
        },
      ],
      res: null,
    },
  ];

  orders: any[] = [];
  orgOrders: any[] = [];
  searchControl = new FormControl();
  selected: boolean[] = [];
  ordersApi: string = Config.settings.api + Config.settings.order.order;
  func = Func;
  searchTerm: string;
  sheet_sum: number = 0;
  page_sheet_sum: number = 0;
  all_discount_amounts: number = 0;
  page_size: any;
  is_today: boolean;
  constructor(
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private loading: LoadingService
  ) {}

  can_del = true;

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((filter) => {
      this.search(filter);
      this.filter_items.forEach(
        (el) => (el.res = filter[el.name] ? filter[el.name] : null)
      );
    });

    if (this.router.url == "/userArea/order/my_customers")
      this.ordersApi += "customer_orders/";
    this.searchControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((term) => this.search(term));

    this.loadExperts();
    this.loadMethods();
    this.loadTypes();
    this.loadStates();
    this.load();
  }

  disable_load = false;
  load(page = 1) {
    if (this.disable_load) return;
    this.disable_load = true;
    this.loading.add();

    this.orgOrders = [];
    this.http
      .get(
        this.ordersApi +
          `?page=${page}&${this.searchTerm}&ordering=${this.sortcol}`
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.page_count = response.page_count;
          this.object_count = response.object_count;
          this.page_number = response.page_number;
          this.page_size = response.page_size;
          this.sheet_sum = response.sheet_sum;
          this.page_sheet_sum = response.page_sheet_sum;
          this.all_order_amounts = response.all_order_amounts;
          this.all_discount_amounts=response.all_discount_amounts;
          this.all_paid_amounts = response.all_paid_amounts;
          response.results.forEach((element) => {
            this.orgOrders.push(element);
          });
          this.orders = this.orgOrders;

          this.loading.remove();
          this.disable_load = false;
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
          this.orgOrders = [];
          this.orders = [];
          this.page_count = 0;
          this.page_number = 0;
          this.object_count = 0;
          this.sheet_sum = 0;
          this.page_sheet_sum = 0;
          this.loading.remove();

          this.disable_load = false;
        }
      );
  }

  loadExperts() {
    let tc = [{ id: null, label: "همه" }];

    this.http.get(this.usersApi + "?q=5").subscribe(
      (response: any) => {
        response.forEach((element) => {
          if (element.name)
            tc.push({
              id: element.id,
              label: element.name,
            });
        });
        // this.filter_items[4].options = tc;
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "expert_name") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  loadMethods() {
    let tc = [{ id: null, label: "همه" }];
    let methodApi: string =
      Config.settings.api + Config.settings.order.method;
    this.http.get(methodApi).subscribe(
      (response: any) => {
        console.log(response);
        response.forEach((element) => {
          tc.push({
            id: element.id,
            label: element.method,
          });
        });
        // this.filter_items[6].options = tc;
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "method") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  loadTypes() {
    let tc = [{ id: null, label: "همه" }];
    let typeApi: string =
      Config.settings.api + Config.settings.order.type;
    this.http.get(typeApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          tc.push({
            id: element.id,
            label: element.type,
          });
        });
        // this.filter_items[5].options = tc;
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "type") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  loadStates() {
    let tc = [{ id: null, label: "همه" }];
    let typeApi: string =
      Config.settings.api + Config.settings.order.state;
    this.http.get(typeApi).subscribe(
      (response: any) => {
        console.log(response);
        response.forEach((element) => {
          if (element.id != 12)
            tc.push({
              id: element.id,
              label: element.state,
            });
        });
        // this.filter_items[7].options = tc;
        this.filter_items.map((el) => {
          let ret = el;
          if (el.name == "state") ret.options = tc;
          return ret;
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    );
  }

  search(filter: any) {
    console.log(filter);

    this.searchTerm = "";
    Object.keys(filter).forEach((el) => {
      this.searchTerm += el + "=" + filter[el] + "&";
      // console.log(el);
    });

    if (this.searchTerm != "")
      this.searchTerm = this.searchTerm.slice(0, -1);

    let qp = filter;
    // qp['q'] = this.searchTerm;

    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: "merge",
    });
    this.load();
    this.isCollapsed = true;
  }

  edit(i = null) {
    if (i) {
      this.router.navigate(["/userArea/order/", i]);
    }
    this.selected.forEach((el, i) => {
      if (el) {
        this.router.navigate(["/userArea/order/", i]);
      }
    });
  }

  del(content, i) {
    if (!i) return;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http.delete(this.ordersApi + i + "/").subscribe(
            (response: any) => {
              this.load();
            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
          );
        }
      });
  }

  sortcol: string = null;

  sort(col: string) {
    if (this.sortcol == col) this.sortcol = "-" + col;
    else this.sortcol = col;
    this.load();
  }

  ngOnDestroy() {}
}
