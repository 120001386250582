import { Component, OnInit } from '@angular/core';
import * as Func from "../../../../../pack/js/func";
import {FormControl} from "@angular/forms";
import {Config} from "../../../../app-config.service";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {debounceTime} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";

@Component({
  selector: 'app-report-addressee-type',
  templateUrl: './report-addressee-type.component.html',
  styleUrls: ['./report-addressee-type.component.scss']
})
export class ReportAddresseeTypeComponent implements OnInit {

  show: boolean = false;
  func = Func;
  reportStatusList: any[] = [];
  orgbrands: any[] = [];
  searchControl = new FormControl();
  brandsApi: string = Config.settings.api + "report/report-addressee-type/";
  selected: boolean[] = [];

  constructor(
      private router: Router,
      private modalService: NgbModal,
      private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
        .pipe(debounceTime(300), untilDestroyed(this))
        .subscribe((term) => this.search(term));
    this.load();
  }

  load() {
    this.orgbrands = [];
    this.http.get(this.brandsApi).subscribe(
        (response: any) => {
          console.log(response);

          response.forEach((element) => {
            this.orgbrands.push(element);
          });
          this.reportStatusList = this.orgbrands;
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  search(value: string) {
    this.reportStatusList = this.orgbrands.filter((option) =>
        option["name"].includes(value)
    );
  }

  editing_brand;
  edit(content, i) {
    let el = this.reportStatusList[i];
    this.editing_brand = {
      id: el.id,
      name: el.name,
    };

    this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then((result) => {
      if (result) {
        this.http
            .patch(
                this.brandsApi + this.editing_brand.id + "/",
                this.editing_brand
            )
            .subscribe(
                (response: any) => {
                  this.load();
                },
                (error: HttpErrorResponse) => {
                  //TODO: server errors
                  console.log(error);
                }
            );
      }
    });
  }

  del(content, i) {
    if (!i) return;
    this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then((result) => {
      if (result) {
        this.http.delete(this.brandsApi + i + "/").subscribe(
            (response: any) => {
              this.load();
            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
        );
      }
    });
  }

  brand = {
    name: "",
  };

  register(content) {
    this.modalService
        .open(content, {ariaLabelledBy: 'modal-basic-title'})
        .result.then((result) => {
      if (result) {
        this.http.post(this.brandsApi, this.brand).subscribe(
            (response: any) => {
              console.log('shod');
              this.load();
              this.brand = {
                name: '',
              };
              this.show = false;
              window.scroll(0, 0);
            },
            (error: HttpErrorResponse) => {
              console.log(error);
            }
        );
      }
    });
  }

  ngOnDestroy() {}


}
