<div class="m-3 mt-4">
  <app-title title="لیست فرم‌های‌ ارزیابی"></app-title>

  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item cursor-pointer" [routerLink]="['new']">
        <i class="far fa-plus-square"></i>جدید
      </div>
      <div class="list-header-item cursor-pointer" (click)="load()">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
      <div class="list-header-item">
        تعداد ردیف: {{ surveys?.length.toString() | toFa }}
      </div>
      <div class="list-header-item mr-auto">
        <i class="far fa-file-search"></i
        ><input
          type="text"
          class="form-control"
          [formControl]="searchControl"
        />
      </div>
    </div>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col" (click)="func.sort(surveys, 'id')">کد فرم</th>
          <th scope="col" (click)="func.sort(surveys, 'date')">
            تاریخ ایجاد
          </th>
          <th scope="col" (click)="func.sort(surveys, 'qcount')">
            تعداد سوالات
          </th>
          <th scope="col" (click)="func.sort(surveys, 'load_count')">
            تعداد بارهای ارزیابی شده
          </th>
          <th scope="col" (click)="func.sort(surveys, 'total_score')">
            مجموع امتیاز
          </th>
          <th scope="col" (click)="func.sort(surveys, 'status')">
            وضعیت
          </th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let el of surveys; let i = index"
          (dblclick)="edit(el.id)"
        >
          <th scope="row">
            {{ i + 1 | toFa }}
          </th>
          <td>{{ el.id | toFa }}</td>
          <td>{{ el.date | jDate | toFa }}</td>
          <td>{{ el.qcount?.toString() | toFa }}</td>
          <td>{{ el.load_count?.toString() | toFa }}</td>
          <td>{{ el.total_score?.toString() | toFa }}</td>
          <td>{{ el.status ? "فعال" : "غیرفعال" }}</td>
          <td class="cursor-pointer" (click)="del(el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- <div class="mx-auto mb-3 mt-5 container" style="width: 70rem !important">
  <div class="w-100 mr-3 d-flex align-items-center">
    <p class="m-0 col-auto">لیست فرم‌های‌ ارزیابی</p>
    <div
      class="text-success col-auto"
      style="
        height: 0.01rem;
        background-color: #37ba9b;
        width: calc(100% - 11rem);
      "
    ></div>
  </div>
  <div class="row mr-3">
    <button
      [routerLink]="['new']"
      class="report-option d-flex align-items-center justify-content-between"
      style="width: 10%"
    >
      <i class="fas fa-plus"></i>
      <span style="font-weight: 500">جدید</span>
    </button>
    <button
      class="report-option d-flex align-items-center justify-content-between"
      style="width: 10%"
      (click)="del()"
    >
      <i class="fas fa-times"></i>
      <span style="font-weight: 500">حذف</span>
    </button>
    <button
      class="report-option d-flex align-items-center justify-content-between"
      style="width: 13%"
      (click)="edit()"
    >
      <i class="fas fa-pen"></i>
      <span style="font-weight: 500">ویرایش</span>
    </button>
    <button
      class="report-option d-flex align-items-center justify-content-between"
      style="width: 16%"
      (click)="load()"
    >
      <i class="fas fa-sync"></i>
      <span style="font-weight: 500">به‌روز رسانی</span>
    </button>
    <button
      class="report-option d-flex align-items-center justify-content-between"
      style="width: 12%"
    >
      <i class="fas fa-print"></i>
      <span style="font-weight: 500">گزارش</span>
    </button>
    <div
      class="search-field report-option d-flex align-items-center justify-content-between"
      style="width: 27%"
    >
      <i class="fas fa-search"></i>
      <input type="text" class="" [formControl]="searchControl" />
    </div>
  </div>
  <div class="row w-100 mt-4">
    <div class="mtable scroll mx-auto">
      <div class="thead">
        <div class="tr">
          <div class="th c-1" style="background-color: inherit"></div>
          <div class="th c-2">ردیف</div>
          <div class="th c-3" (click)="func.sort(surveys, 'id')">
            کد فرم
          </div>
          <div class="th c-4" (click)="func.sort(surveys, 'date')">
            تاریخ ایجاد
          </div>
          <div class="th c-5" (click)="func.sort(surveys, 'qcount')">
            تعداد سوالات
          </div>
          <div class="th c-6" (click)="func.sort(surveys, 'load_count')">
            تعداد بارهای ارزیابی شده
          </div>
          <div class="th c-7" (click)="func.sort(surveys, 'total_score')">
            مجموع امتیاز
          </div>

          <div class="th c-9" (click)="func.sort(surveys, 'status')">
            وضعیت
          </div>
        </div>
      </div>
      <div class="tbody">
        <div *ngFor="let el of surveys; let i = index" class="tr">
          <div class="th c-1">
            <input type="checkbox" [(ngModel)]="selected[el.id]" />
          </div>
          <div class="content" (dblclick)="edit(el.id)">
            <div class="th c-2">{{ i + 1 | toFa }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
