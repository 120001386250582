import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'jalali-moment';

@Pipe({
  name: "toFa",
})
export class ToFaPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (!value && value != "0") return null;
    let pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    let en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let cache: string = value.toString();
    for (var i = 0; i < 10; i++) {
      var regex_en = new RegExp(en[i], "g");
      cache = cache.replace(regex_en, pn[i]);
    }
    return cache;
  }
}

@Pipe({
  name: "toR",
})
export class ToRPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (!value && value != "0") return null;
    let pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    let en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let cache: string = value.toString();
    for (var i = 0; i < 10; i++) {
      var regex_en = new RegExp(en[i], "g");
      cache = cache.replace(regex_en, pn[i]);
    }
    var regex_en = new RegExp("/", "g");
    cache = cache.replace(regex_en, "");

    for (i = 0; i * 4 + 3 < cache.length; i++) {
      cache = cache.slice(0, -i * 4 - 3) + "/" + cache.slice(-i * 4 - 3);
    }
    return cache;
  }
}

@Pipe({
  name: "jDate",
})
export class JDatePipe implements PipeTransform {
  transform(value: string, args?: any): string {
    if (!value) return null;
    let MomentDate = moment(value);
    return MomentDate.locale("fa").format("YYYY/M/D");
  }
}
