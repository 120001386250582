<div class="m-3 ml-5 mt-4">
    <app-title title="ویرایش سفارش انبار فروش استانی"></app-title>
    <div class="order-header">
        <div class="row">
            <div class="col item">سفارش انبار: {{currentWarehouseOrder.id}} </div>
            <!--            <div class="col item">کد رهگیری انبار: {{currentWarehouseOrder.code}} </div>-->
            <div class="col item">تاریخ ثبت: {{currentWarehouseOrder.created_at | jDate | toFa}} </div>
            <!--            <div class="col item">تاریخ سفارش: {{currentWarehouseOrder.state_name}} </div>-->
            <div class="col item">تاریخ ارسال: {{currentWarehouseOrder.sending_date | jDate | toFa}} </div>
            <div class="col item">تاریخ دریافت: {{currentWarehouseOrder.receiving_date | jDate | toFa}} </div>
            <div class="col item">ایجاد کننده: {{currentWarehouseOrder.created_name}} </div>
            <div class="col item">وضعیت: {{currentWarehouseOrder.state_name}} </div>
        </div>

    </div>
    <form #form="ngForm" class="w-100">
        <div class="box">
            <div class="row">

                <div class="form-group col-lg-3 col-md-3 col-3">
                    <app-select
                            [options]="warehouseList"
                            (selectChange)="changeWarehouse($event)"
                            [key]="currentWarehouseOrder.warehouse"
                            req="true"
                            placeholder="عنوان انبار فروش *"
                    ></app-select>
                    <input
                            type="hidden"
                            value=""
                            [(ngModel)]="currentWarehouseOrder.warehouse"
                            name="warehouse"
                            class="d-none"
                            required
                    />
                </div>
                <div class="form-group col-lg-6 col-md-6 col-6">
                    <label class="mx-1 lead"> مسئول انبار فروش </label>
                    <input
                            name="warehouseResponsible"
                            type="text"
                            [readOnly]="true"
                            [(ngModel)]="currentWarehouseOrder.warehouseResponsible"
                            class="form-control form-control-lg"
                            required

                    />
                </div>

                <div class="form-group col-lg-3 col-md-3 col-3">
                    <label class="mx-1 lead"> کد رهگیری ( راهکاران ) </label>
                    <input
                            [ngModel]="currentWarehouseOrder.code"
                            name="code"
                            type="text"
                            class="form-control form-control-lg"
                            required

                    />
                </div>

                <div class="form-group col-lg-3 col-md-3 col-3">
                    <label class="mx-1 lead"> تاریخ سفارش *</label>
                    <input
                            matInput
                            [matDatepicker]="picker"
                            (focus)="picker.open()"
                            (click)="picker.open()"
                            type="text"
                            [(ngModel)]="currentWarehouseOrder.date"
                            name="date"
                            class="form-control-lg form-control"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                </div>

                <div class="form-group col-lg-9 col-md-9 col-9">
                    <label class="mx-1 lead"> توضیحات </label>
                    <textarea
                            [ngModel]="currentWarehouseOrder.detail"
                            class="form-control-lg form-control"
                            (keydown)="(1 == 1)"
                            name="detail"
                    ></textarea>
                </div>
            </div>
        </div>
    </form>

    <div class="list mx-3">
        <table class="table table-bordered">
            <thead class="thead-light headerless">
            <tr>
                <th scope="col">#</th>
                <th scope="col">نام کالا</th>
                <th scope="col">تعداد ورق</th>
                <th scope="col" class="d-lg-table-cell d-none">تعداد پالت</th>
                <th scope="col" class="d-lg-table-cell d-none">وزن تقریبی</th>
                <th scope="col" class="d-lg-table-cell d-none">
                    واحد اندازه گیری
                </th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of order; let i = index">
                <th>{{ i + 1 }}</th>
                <td class="p-0" dir="rtl">
                    <select
                            [(ngModel)]="el.product_id"
                            (ngModelChange)="changeProduct($event, i)"
                            class="border-0 custom-select custom-select-lg"
                    >
                        <option *ngFor="let p of products" [value]="p.id">
                            {{ p.label }}
                        </option>
                    </select>


                </td>
<!--                <td class="p-0" dir="rtl">-->
<!--                    <input-->
<!--                            type="text"-->
<!--                            class="form-control form-control-lg border-0"-->
<!--                            matInput-->
<!--                            [formControl]="controlorder"-->
<!--                            [matAutocomplete]="autoo"-->
<!--                    />-->

<!--                    <mat-autocomplete-->
<!--                        #autoo="matAutocomplete"-->
<!--                        (optionSelected)="changeProduct($event.option.value, i)"-->
<!--                        [displayWith]="showC"-->
<!--                    >-->
<!--                        <mat-option-->
<!--                                *ngFor="let el of filteredProducts | async"-->
<!--                                [value]="el"-->
<!--                        >-->
<!--                            {{ el.label }}-->
<!--                        </mat-option>-->
<!--                    </mat-autocomplete>-->
<!--                </td>-->

                <td class="p-0">
                    <input
                            type="number"
                            class="border-0 form-control form-control-lg"
                            [(ngModel)]="order[i].count"
                            [ngModelOptions]="{ standalone: true }"
                    />
                </td>
                <td class="d-lg-table-cell d-none">
                    {{
                    el.pindex != null && order[i].count !== null
                        ? math.floor(
                        (1.0 * el.count) /
                        this.orgProducts[el.pindex].number_in_pallet
                        )
                        : null
                    }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{
                    el.pindex != null && order[i].count !== null
                        ? math.ceil(
                        order[i].count * orgProducts[el.pindex].weight
                        )
                        : null
                    }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ el.pindex != null ? orgProducts[el.pindex].unit : null }}
                </td>
                <td><i class="fal fa-trash-alt" (click)="remove(i)"></i></td>
            </tr>
            <tr>
                <th class="border-left-0"></th>
                <td *ngIf="currentWarehouseOrder.state==1" (click)="add()" class="border-right-0">
                    <i class="fal fa-plus"></i>
                </td>
                <td *ngIf="currentWarehouseOrder.state!=1" class="border-right-0">

                </td>
                <td>{{ count_sum }}</td>
                <td>{{ pallet_sum }}</td>
                <td class="d-lg-table-cell d-none">{{ weight_sum }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="images.length" class="row box d-flex" style="z-index: inherit">
        <div *ngFor="let image of images; let i = index"
             class="d-block p-3 position-relative profile">
            <div class="img">
                <img
                        [src]="conf.settings.back + image"
                        style="width: 200px;height: 100px"
                />
            </div>
            <div (click)="removeImage(i)"
                 class="cursor-pointer img-remove">
                <i class="fa fa-times"></i>
            </div>
        </div>
    </div>
    <div *ngIf="currentWarehouseOrder.state === 1" class="row w-100 justify-content-end pt-2">
        <button
                type="button"
                [disabled]="disable_send"
                (click)="register(form, sendDatePicker, 2)"
                class="btn mx-3 col-2 btn-success" style="height: 30px;"
        >
            ارسال سفارش
        </button>
        <button
                [disabled]="disable_send"
                type="submit"
                class="btn col-2 btn-success" style="height: 30px;"
                (click)="register(form,null,1)"
        >
            ذخیره تغییرات
        </button>
        <!--        (click)="cancelOrder(cancel, 8)"-->
        <button
                type="button"
                [disabled]="disable_send"
                (click)="register(form,null,4)"
                class="btn mx-2 col-2 btn-danger" style="height: 30px;"
        >
            لغو
        </button>


        <label class="btn btn-success col-2 mx-2" style="height: 30px;">
            <input
                    type="file"
                    accept="image/*"
                    (change)="add_image($event)"
                    style="opacity: 0; display: none"/>
            ارسال تصویر
        </label>
        <button
                [routerLink]="['/userArea/warehouseOrderList']"
                type="button"
                class="btn  col-2 btn-gray" style="height: 30px;"
        >
            بازگشت
        </button>
    </div>
    <div *ngIf="currentWarehouseOrder.state === 2" class="row w-100 justify-content-end pt-2">


<!--        <button-->
<!--                *ngIf="can_change"-->
<!--                type="button"-->
<!--                [disabled]="disable_send"-->
<!--                style="height: 30px;"-->
<!--                (click)="edit(form, 2)"-->
<!--                class="btn  col-2 mx-2 btn-warning"-->
<!--        >-->
<!--            اصلاح-->
<!--        </button>-->
        <button
                *ngIf="can_edit"
                type="button"
                [disabled]="disable_send"
                style="height: 30px;"
                (click)="register(form, null, currentWarehouseOrder.state)"
                class="btn  col-2 mx-2 btn-success"
        >
            ذخیره تغییرات
        </button>
        <button
                *ngIf="can_edit"
                (click)="register(form, reciveDatePicker, 3)"
                type="button"
                class="btn col-2 btn-primary mx-2" style="height: 30px;"
        >
            دریافت سفارش
        </button>
        <label class="btn btn-success col-2 mx-2" style="height: 30px;" *ngIf="can_change">
            <input

                    type="file"
                    accept="image/*"
                    (change)="add_image($event)"
                    style="opacity: 0; display: none"/>
            ارسال تصویر
        </label>
        <button
                [routerLink]="['/userArea/warehouseOrderList']"
                type="button"
                class="btn  col-2 btn-secondary" style="height: 30px;"
        >
            بازگشت
        </button>
    </div>
    <div *ngIf="currentWarehouseOrder.state === 3" class="row w-100 justify-content-end pt-2">

        <button
                [routerLink]="['/userArea/warehouseOrderList']"
                type="button"
                class="btn  col-2 btn-secondary" style="height: 30px;"
        >
            بازگشت
        </button>
    </div>



</div>

<ng-template #sendDatePicker let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            تاریخ ارسال سفارش را وارد کنید.
        </h4>
    </div>
    <div class="modal-body">
        <input
                matInput
                [matDatepicker]="dpicker"
                (click)="dpicker.open()"
                type="text"
                [(ngModel)]="sendDate"
                name="receiving_date"
                class="input-text w-100 form-control"
                required
        />
        <label placeholder="تاریخ سفارش" alt="تاریخ سفارش"></label>
        <mat-datepicker #dpicker></mat-datepicker>
    </div>
    <div class="modal-footer">
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(date)"
        >
            تایید
        </button>
    </div>
</ng-template>

<ng-template #cancel let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">لغو سفارش</h4>
    </div>
    <div class="modal-body">
        <form #cancelForm="ngForm" class="w-100">
            <div class="row w-100 mb-3">
                <div class="col-md-6 mb-2" *ngFor="let el of cancellation">
                    <label
                            class="px-4 w-100 d-flex justify-content-lg-between align-items-center"
                    >
                        {{ el.value }}
                        <input
                                type="radio"
                                ngModel
                                name="cancellation"
                                [value]="el.key"
                        />
                    </label>
                </div>
            </div>

            <label> دلیل لغو:</label>
            <textarea
                    class="w-100"
                    ngModel
                    name="cancellation_reason"
            ></textarea>
        </form>

        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(cancelForm)"
        >
            تایید
        </button>
    </div>
</ng-template>


<ng-template #reciveDatePicker let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            تاریخ دریافت سفارش را وارد کنید.
        </h4>
    </div>
    <div class="modal-body">
        <input
                matInput
                [matDatepicker]="dpicker"
                (click)="dpicker.open()"
                type="text"
                [(ngModel)]="reciveDate"
                name="receiving_date"
                class="input-text w-100 form-control"
                required
        />
        <label placeholder="تاریخ سفارش" alt="تاریخ سفارش"></label>
        <mat-datepicker #dpicker></mat-datepicker>
    </div>
    <div class="modal-footer">
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(date)"
        >
            تایید
        </button>
    </div>
</ng-template>

