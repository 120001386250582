import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import * as Func from "../../../../pack/js/func";
import {FormControl} from "@angular/forms";
import {Config} from "../../../app-config.service";

@Component({
  selector: 'app-pallet',
  templateUrl: './pallet.component.html',
  styleUrls: ['./pallet.component.scss']
})
export class PalletComponent implements OnInit {

  constructor(
      private router: Router,
      private modalService: NgbModal,
      private http: HttpClient,
      public dialog: MatDialog
  ) {
  }

  func = Func;
  pallets: any[] = [];
  orgPallets: any[] = [];

  searchControl = new FormControl();
  selected: boolean[] = [];
  palletApi: string = Config.settings.api + "information/pallet/";

  ngOnInit(): void {
    this.load();
  }

  load(page = 0) {
    this.orgPallets = [];
    this.http.get(this.palletApi).subscribe(
        (response: any) => {
          response.forEach((element) => {
            this.orgPallets.push({
              id: element.id,
              code: element.code,
              available: element.available,
              creator : element.creator_first_name + ' ' + element.creator_last_name
            });
          });
          // this.selected = new Array(mx + 1).fill(false);
          this.pallets = this.orgPallets;

        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }


  ngOnDestroy() {
  }

}
