<!-- <nav class="navbar sticky-top p-0">
   <div class="d-flex align-items-center">
      <a
         class="fas fa-bars mr-4 h4 m-0"
         (click)="toggleSidebar(container, sidebar, main)"
      ></a>
      <h6 class="mr-4 m-0 d-none d-me">مـنـو کـاربـری</h6>
   </div>
   <a href="#" class="navbar-brand py-2 px-5 my-0 mx-auto">
      <img class="navbar-logo" src="../assets/logo-shafigh.png" />
   </a>
</nav> -->

<app-the-header (toggleSidebar)="toggleSidebar()"></app-the-header>

<div class="w-100 d-flex flex-column flex-sm-row">
   <div class="sidebar" [class.collapsed]="sidebarCollapsed">
      <app-the-sidebar></app-the-sidebar>
   </div>
   <div class="main">
      <router-outlet></router-outlet>
   </div>
</div>
