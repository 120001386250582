import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Config} from 'src/app/app-config.service';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {untilDestroyed} from 'ngx-take-until-destroy';
import * as Func from 'src/pack/js/func';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-product-thickness-list',
    templateUrl: './product-thickness-list.component.html',
    styleUrls: ['./product-thickness-list.component.scss'],
})
export class ProductThicknessListComponent implements OnInit {
    show: boolean = false;
    func = Func;
    thicknesses: any[] = [];
    orgthicknesses: any[] = [];
    searchControl = new FormControl();
    thicknessesApi: string =
        Config.settings.api + Config.settings.product.thickness;
    selected: boolean[] = [];

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(debounceTime(300), untilDestroyed(this))
            .subscribe((term) => this.search(term));
        this.load();
    }

    load() {
        this.orgthicknesses = [];
        this.http.get(this.thicknessesApi).subscribe(
            (response: any) => {
                console.log(response);

                response.forEach((element) => {
                    this.orgthicknesses.push(element);
                });
                this.thicknesses = this.orgthicknesses;
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    search(value: string) {
        this.thicknesses = this.orgthicknesses.filter((option) =>
            option['thickness'].includes(value)
        );
    }

    editing_thickness;

    edit(content, i) {
        let el = this.thicknesses[i];
        this.editing_thickness = {
            id: el.id,
            thickness: el.thickness,
        };

        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http
                    .patch(
                        this.thicknessesApi + this.editing_thickness.id + '/',
                        this.editing_thickness
                    )
                    .subscribe(
                        (response: any) => {
                            this.load();
                        },
                        (error: HttpErrorResponse) => {
                            //TODO: server errors
                            console.log(error);
                        }
                    );
            }
        });
    }

    del(content, i) {
        if (!i) {
            return;
        }
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.thicknessesApi + i + '/').subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    thickness = {
        thickness: '',
    };

    register(content) {
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.post(this.thicknessesApi, this.thickness).subscribe(
                    (response: any) => {
                        console.log('shod');
                        this.load();
                        this.thickness = {
                            thickness: '',
                        };
                        this.show = false;
                        window.scroll(0, 0);
                    },
                    (error: HttpErrorResponse) => {
                        console.log(error);
                    }
                );
            }
        });
    }

    ngOnDestroy() {
    }
}
