import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {WarehouseListComponent} from "../warehouse-list/warehouse-list.component";
import {Config} from "../../../../app-config.service";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Check} from "../../../orders/checkout/models";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-warehouse-new',
    templateUrl: './warehouse-new.component.html',
    styleUrls: ['./warehouse-new.component.scss']
})
export class WarehouseNewComponent implements OnInit {

    provinces: any[] = [];
    province: number = null;

    cities: any[] = [];
    city: number = null;

    warehouseApi: string = Config.settings.api + "warehouse/warehouse/";

    constructor(
        private modalService: NgbModal,
        private http: HttpClient,
        // public dialogRef: MatDialogRef<WarehouseListComponent>,
        // @Inject(MAT_DIALOG_DATA) public data: Object
    ) {
    }


    warehouseSupervisors: any[] = [];

    async ngOnInit(): Promise<void> {
        console.log("**********salam");
        let provinceApi: string =
            Config.settings.api + Config.settings.add.province;
        await this.http
            .get(provinceApi)
            .toPromise()
            .then((response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.provinces.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            });


        let peopleApi: string =
            Config.settings.api + Config.settings.user.user + "?q=2";
        console.log(peopleApi);
        this.http.get(peopleApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    this.warehouseSupervisors.push({
                        id: element.id,
                        label: element.first_name + " " + element.last_name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );

    }


    changeProvince($event, city = null) {
        this.province = $event;
        let cityApi: string =
            Config.settings.api +
            Config.settings.add.citys +
            `?province=${$event}`;
        this.cities = [];
        this.http.get(cityApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    this.cities.push({
                        id: element.id,
                        label: element.name,
                    });
                    this.city = city;
                });
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    changeCity($event) {
        this.city = $event;
    }

    warehouse = {
        name: "",

    };

    register() {
        this.http.post(this.warehouseApi, this.warehouse).subscribe(
            (response: any) => {
                console.log("shod");

                this.warehouse = {
                    name: "",
                };
                // this.dialogRef.close()
                window.scroll(0, 0);
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    onNoClick(): void {
        // this.dialogRef.close()
    }

}
