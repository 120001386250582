import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-information-lists',
  templateUrl: './customer-information-lists.component.html',
  styleUrls: ['./customer-information-lists.component.scss']
})
export class CustomerInformationListsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
