<div class="m-3 mt-4">
    <app-title title="لیست انبار های استانی"></app-title>
    <div class="list my-3">
        <div class="list-header bg-gray">
            <div class="list-header-item" (click)="new(newContent)">
                <i class="far fa-plus-square"></i>جدید
            </div>
            <div class="list-header-item">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div class="list-header-item">تعداد ردیف: {{ warehouses.length }}</div>
            <div class="list-header-item mr-auto">
                <i class="far fa-file-search"></i
                ><input
                    type="text"
                    class="form-control"
                    [formControl]="searchControl"
            />
            </div>
        </div>
        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col" (click)="sort( 'city_name')">
                    نام شهر
                </th>
                <th scope="col" (click)="sort( 'name')">
                    عنوان انبار
                </th>

                <th
                        scope="col"
                        (click)="sort( 'responsible_company_name')"
                >
                    مسئول انبار
                </th>

                <th
                        scope="col"
                        (click)="sort('creation_date')"
                >
                    تاریخ ایجاد
                </th>
                <th scope="col">ویرایش</th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of warehouses; let i = index">
                <th scope="row">{{ i + 1 | toFa }}</th>
                <!-- <td>{{ i + 1 | toFa }}</td> -->

                <td>
                    {{ el.city_name }}
                </td>
                <td>
                    {{ el.name }}
                </td>
                <td>
                    {{ el.responsible_fullName }}
                </td>
                <td>
                    {{ el.creation_date | jDate | toFa }}
                </td>
                <td class="cursor-pointer" (click)="edit(editcontent, i)">
                    <i class="fal fa-edit"></i>
                </td>
                <td class="cursor-pointer" (click)="del(content, el.id)">
                    <i class="fal fa-trash-alt"></i>
                </td>
            </tr>
            </tbody>
        </table>
        <div *ngIf="warehouses.length==0"
             style="width: 100%;
    height: 30px;
    text-align: center;padding-top: 7px;">
            <h5>
                اطلاعاتی یافت نشد
            </h5>

        </div>
    </div>
    <!--    <app-title-->
    <!--            (click)="show = !show"-->
    <!--            [attr.aria-expanded]="show"-->
    <!--            aria-controls="collapse"-->
    <!--            [class.open]="show"-->
    <!--            type="plus"-->
    <!--            title="اضافه کردن"-->
    <!--            class="cursor-pointer"-->
    <!--    ></app-title>-->

    <!--    <div id="collapse"  class="box">-->

    <!--    </div>-->
</div>

<ng-template #newContent let-modal>

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">ایجاد انبار</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-md-6 col-12">
                <label>عنوان انبار *</label>
                <input
                        type="text"
                        [(ngModel)]="warehouse.name"

                        ngModel required
                        name="name"
                        class="form-control form-control-lg"
                        required
                />
            </div>
            <div class="form-group col-md-6 col-12">
                <app-select
                        [options]="warehouseSupervisors"
                        placeholder="مسئول انبار"
                        (selectChange)="warehouse.responsible = $event"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-6 col-12">
                <app-select
                        [options]="provinces"
                        [key]="province"
                        placeholder="استان"
                        (selectChange)="changeProvince($event)"
                        req="true"
                ></app-select>
            </div>

            <div class="form-group col-md-6 col-12">
                <app-select
                        [options]="cities"
                        [key]="city"
                        placeholder="شهرستان *"
                        (selectChange)="changeCity($event)"

                        req="true"
                ></app-select>

            </div>
            <div class="form-group col-md-6 col-12">
                <label > تاریخ ایجاد</label>
                <input
                        matInput
                        [matDatepicker]="picker"
                        (focus)="picker.open()"
                        (click)="picker.open()"
                        type="text"
                        [(ngModel)]="warehouse.creation_date"
                        name="sending_date"
                        class="form-control-lg form-control"
                />
                <mat-datepicker #picker></mat-datepicker>
            </div>


            <div class="form-group col-md-6 col-12">
                <label class="mx-1 lead">توضیحات</label>
                <textarea
                        class="w-100 form-control"
                        [(ngModel)]="warehouse.details"
                        name="detail"
                        (keydown)="(1 == 1)"
                ></textarea>
            </div>


        </div>
        <div *ngIf="images.length" class="row m-2 box w-100 d-flex" style="z-index: inherit">
            <div *ngFor="let image of images; let i = index"
                 class="d-block p-3 position-relative profile">
                <div class="img">
                    <img
                            [src]="conf.settings.back + image"
                            style="width: 200px;height: 100px"
                    />
                </div>
                <div (click)="remove(i)"
                     class="cursor-pointer img-remove">
                    <i class="fa fa-times"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
                type="button"
                class="btn btn-success mx-2 w-20"
                (click)="modal.close(true)"
        >
            ثبت
        </button>

        <label class="btn btn-success mx-2  w-20">
            <input
                    type="file"
                    accept="image/*"
                    (change)="add_image($event)"
                    style="opacity: 0; display: none"/>
            ارسال تصویر
        </label>

        <button
                type="button"
                class="btn btn-outline-dark mx-2 w-20"
                (click)="modal.close(false)"
        >
            انصراف
        </button>
    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            آیا از حذف این رکورد اطمینان دارید؟
        </h4>
    </div>
    <div class="modal-body">
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(true)"
        >
            بله
        </button>
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(false)"
        >
            خیر
        </button>
    </div>
</ng-template>

<ng-template #editcontent let-modal >

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">ویرایش انبار</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-md-6 col-12">
                <label>عنوان انبار *</label>
                <input
                        type="text"
                        [(ngModel)]="editing_warehouse.name"

                        ngModel required
                        name="name"
                        class="form-control form-control-lg"
                        required
                />
            </div>
            <div class="form-group col-md-6 col-12">
                <app-select
                        [options]="warehouseSupervisors"
                        placeholder="مسئول انبار"
                        (selectChange)="editing_warehouse.responsible = $event"
                        [key]="editing_warehouse.responsible"
                        req="true"
                ></app-select>
            </div>
            <div class="form-group col-md-6 col-12">
                <app-select
                        [options]="provinces"
                        [key]="editing_warehouse.province"
                        placeholder="استان"
                        (selectChange)="changeProvince($event)"
                        req="true"
                ></app-select>
            </div>

            <div class="form-group col-md-6 col-12">
                <app-select
                        [options]="cities"
                        [key]="editing_warehouse.city"
                        placeholder="شهرستان *"
                        (selectChange)="changeCity($event)"

                        req="true"
                ></app-select>

            </div>
            <div class="form-group col-md-6 col-12">
                <label > تاریخ ایجاد</label>
                <input
                        matInput
                        [matDatepicker]="picker"
                        (focus)="picker.open()"
                        (click)="picker.open()"
                        type="text"
                        [(ngModel)]="editing_warehouse.creation_date"
                        name="sending_date"
                        class="form-control-lg form-control"
                />
                <mat-datepicker #picker></mat-datepicker>
            </div>


            <div class="form-group col-md-6 col-12">
                <label class="mx-1 lead">توضیحات</label>
                <textarea
                        class="w-100 form-control"
                        [(ngModel)]="editing_warehouse.details"
                        name="detail"
                        (keydown)="(1 == 1)"
                ></textarea>
            </div>


        </div>
        <div *ngIf="images.length" class="row m-2 box w-100 d-flex" style="z-index: inherit">
            <div *ngFor="let image of images; let i = index"
                 class="d-block p-3 position-relative profile">
                <div class="img">
                    <img
                            [src]="conf.settings.back + image"
                            style="width: 200px;height: 100px"
                    />
                </div>
                <div (click)="remove(i)"
                     class="cursor-pointer img-remove">
                    <i class="fa fa-times"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
                type="button"
                class="btn btn-success mx-2 w-20"
                (click)="modal.close(true)"
        >
            ثبت
        </button>

        <label class="btn btn-success mx-2  w-20">
            <input
                    type="file"
                    accept="image/*"
                    (change)="add_image($event)"
                    style="opacity: 0; display: none"/>
            ارسال تصویر
        </label>

        <button
                type="button"
                class="btn btn-outline-dark mx-2 w-20"
                (click)="modal.close(false)"
        >
            انصراف
        </button>
    </div>
</ng-template>

<!--<ng-template #editcontent let-modal>-->
<!--    <div class="modal-header">-->
<!--        <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>-->
<!--    </div>-->
<!--    <div class="modal-body">-->
<!--        <div class="row">-->
<!--            <div class="form-group col-md-6 col-12">-->
<!--                <label>عنوان منطقه</label>-->
<!--                <input-->
<!--                        type="text"-->
<!--                        [(ngModel)]="editing_warehouse.name"-->
<!--                        name="name"-->
<!--                        class="form-control form-control-lg"-->
<!--                        required-->
<!--                />-->
<!--            </div>-->

<!--            <div class="form-group col-md-6 col-12">-->
<!--                <app-select-->
<!--                        [options]="warehouseSupervisors"-->
<!--                       -->
<!--                        placeholder="سرپرست منطقه"-->
<!--                        (selectChange)="editing_warehouse.supervisor = $event"-->
<!--                        req="true"-->
<!--                ></app-select>-->
<!--            </div>-->
<!--            <div class="form-group col-md-6 col-12">-->
<!--                &lt;!&ndash;                <app-select&ndash;&gt;-->
<!--                &lt;!&ndash;                        [options]="marketingExperts"&ndash;&gt;-->
<!--                &lt;!&ndash;                        [key]="editing_warehouse.marketing_expert"&ndash;&gt;-->
<!--                &lt;!&ndash;                        placeholder="کارشناس بازاریابی"&ndash;&gt;-->
<!--                &lt;!&ndash;                        (selectChange)="editing_warehouse.marketing_expert = $event"&ndash;&gt;-->
<!--                &lt;!&ndash;                        req="true"&ndash;&gt;-->
<!--                &lt;!&ndash;                ></app-select>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="form-group col-md-6 col-12">-->
<!--                &lt;!&ndash;                <app-select&ndash;&gt;-->
<!--                &lt;!&ndash;                        [options]="saleExperts"&ndash;&gt;-->
<!--                &lt;!&ndash;                        [key]="editing_warehouse.sale_expert"&ndash;&gt;-->
<!--                &lt;!&ndash;                        placeholder="کارشناس فروش"&ndash;&gt;-->
<!--                &lt;!&ndash;                        (selectChange)="editing_warehouse.sale_expert = $event"&ndash;&gt;-->
<!--                &lt;!&ndash;                        req="true"&ndash;&gt;-->
<!--                &lt;!&ndash;                ></app-select>&ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="modal-footer">-->
<!--        <button-->
<!--                type="button"-->
<!--                class="btn btn-outline-dark mx-2"-->
<!--                (click)="modal.close(true)"-->
<!--        >-->
<!--            تایید-->
<!--        </button>-->
<!--        <button-->
<!--                type="button"-->
<!--                class="btn btn-outline-dark mx-2"-->
<!--                (click)="modal.close(false)"-->
<!--        >-->
<!--            انصراف-->
<!--        </button>-->
<!--    </div>-->
<!--</ng-template>-->
