<div class="m-3 mt-4">
  <!-- <app-title title="لیست پرسنل"></app-title> -->
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
    </div>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">نام</th>
          <th scope="col">نام خانوادگی</th>
          <th scope="col">سمت</th>
          <th scope="col">تاریخ شروع همکاری</th>
          <th scope="col">تاریخ تولد</th>
          <th scope="col">وضعیت</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of personnels; let i = index">
          <th scope="row">
            {{ i + 1 | toFa }}
          </th>
          <td>{{ el.first_name }}</td>
          <td>{{ el.last_name }}</td>
          <td>{{ el.job_name }}</td>
          <td>{{ el.start_date | jDate | toFa }}</td>
          <td>{{ el.date_of_birth | jDate | toFa }}</td>
          <td>
            <i
              class="fa fa-circle"
              [class]="el.is_active ? 'text-success' : 'text-danger'"
            ></i>
          </td>
          <td class="cursor-pointer" (click)="edit(editcontent, i)">
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-title
    (click)="show = !show"
    [attr.aria-expanded]="show"
    aria-controls="collapse"
    [class.open]="show"
    type="plus"
    title="اضافه کردن"
    class="cursor-pointer"
  ></app-title>

  <div id="collapse" [ngbCollapse]="!show" class="box">
    <div class="row">
      <div class="form-group col-md-6 col-12">
        <label>نام</label>
        <input
          type="text"
          [(ngModel)]="personnel.first_name"
          name="first_name"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-6 col-12">
        <label>نام خانوادگی</label>
        <input
          type="text"
          [(ngModel)]="personnel.last_name"
          name="last_name"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="jobs"
          placeholder="سمت"
          (selectChange)="personnel.job = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-4 col-12">
        <label class="mx-1 lead"> تاریخ شروع همکاری </label>
        <div class="input-group">
          <input
            matInput
            [matDatepicker]="picker3"
            [(ngModel)]="personnel.start_date"
            name="start_date"
            class="form-control form-control-lg"
          />

          <span class="input-group-append" (click)="picker3.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
          <mat-datepicker #picker3></mat-datepicker>
        </div>
      </div>

      <div class="form-group col-md-4 col-12">
        <label class="mx-1 lead"> تاریخ تولد </label>
        <div class="input-group">
          <input
                  matInput
                  [matDatepicker]="picker1"
                  [(ngModel)]="personnel.date_of_birth"
                  name="start_date"
                  class="form-control form-control-lg"
          />

          <span class="input-group-append" (click)="picker1.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
          <mat-datepicker #picker1></mat-datepicker>
        </div>
      </div>
      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="statuses"
          placeholder="وضعیت فعالیت"
          (selectChange)="personnel.is_active = $event"
          req="true"
        ></app-select>
      </div>

      <div class="row w-100 mb-3">
        <button
          (click)="register()"
          class="btn mr-auto ml-3 w-25 btn-success"
        >
          ثبت
        </button>
      </div>
    </div>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          آیا از حذف این رکورد اطمینان دارید؟
        </h4>
      </div>
      <div class="modal-body">
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(true)"
        >
          بله
        </button>
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(false)"
        >
          خیر
        </button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-6 col-12">
        <label>نام</label>
        <input
          type="text"
          [(ngModel)]="editing_personnel.first_name"
          name="first_name"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-6 col-12">
        <label>نام خانوادگی</label>
        <input
          type="text"
          [(ngModel)]="editing_personnel.last_name"
          name="last_name"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="jobs"
          [key]="editing_personnel.job"
          placeholder="سمت"
          (selectChange)="editing_personnel.job = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-4 col-12">
        <label class="mx-1 lead"> تاریخ شروع همکاری </label>
        <div class="input-group">
          <input
            matInput
            [matDatepicker]="picker3"
            [(ngModel)]="editing_personnel.start_date"
            name="start_date"
            class="form-control form-control-lg"
          />

          <span class="input-group-append" (click)="picker3.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
          <mat-datepicker #picker3></mat-datepicker>
        </div>
      </div>
      <div class="form-group col-md-4 col-12">
        <label class="mx-1 lead"> تاریخ تولد </label>
        <div class="input-group">
          <input
                  matInput
                  [matDatepicker]="picker1"
                  [(ngModel)]="editing_personnel.date_of_birth"
                  name="start_date"
                  class="form-control form-control-lg"
          />

          <span class="input-group-append" (click)="picker1.open()">
            <i class="fa fa-calculator input-group-text"></i>
          </span>
          <mat-datepicker #picker1></mat-datepicker>
        </div>
      </div>
      <div class="form-group col-md-4 col-12">
        <app-select
          [options]="statuses"
          [key]="editing_personnel.is_active"
          placeholder="وضعیت فعالیت"
          (selectChange)="editing_personnel.is_active = $event"
          req="true"
        ></app-select>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(true)"
      >
        تایید
      </button>
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>
