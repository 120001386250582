import {Component, OnInit} from "@angular/core";
import {HttpErrorResponse, HttpClient} from "@angular/common/http";
import * as Func from "src/pack/js/func";
import {Config} from "src/app/app-config.service";
import {AuthService} from "src/app/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {LoadingService} from "src/app/loading/loading.service";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";

@Component({
    selector: 'app-warehouse-order-new',
    templateUrl: './warehouse-order-new.component.html',
    styleUrls: ['./warehouse-order-new.component.scss']
})
export class WarehouseOrderNewComponent implements OnInit {

    isRepresentetiveOrder: boolean = false;
    isWrong: boolean;
    errorMsg: string;
    success: boolean;
    msg: string;
    date = new Date();
    conf = Config;
    warehouseList: any[] = [];
    currentWarehouseOrder = {
        id: 0,
        warehouse: 0,
        warehouseResponsible: '',
        code: '',
        label: '',
        state_name: '',
        created_at: null,
        sending_date: null,
        receiving_date: null,
        created_name: '',
        detail: ''

    };


    control = new FormControl();
    controlorder = new FormControl();
    buyer: number;

    credit: number = 0;
    credit_map = new Map();
    order: any[] = [];

    statuses: any[] = [
        {id: true, label: "فعال"},
        {id: false, label: "غیر فعال"},
    ];
    status: boolean = true;

    types: any[] = [];
    type = "";

    transportations: any[] = [];
    transportation = "";

    methods: any[] = [];
    method = "";

    customers: any[] = [{id: null, label: "در حال بارگذاری"}];
    filteredCustomers;
    filteredProducts;
    customer = "";

    availabilities: any[] = [
        {id: true, label: "موجود"},
        {id: false, label: "ناموجود"},
    ];
    availability: string = "";

    products: any[] = [];
    orgProducts: any[] = [];
    selected: boolean[] = [];
    productsApi: string =
        Config.settings.api + Config.settings.product.product;
    usersApi: string = Config.settings.api + Config.settings.user.user;

    func = Func;
    images: string[] = [];
    math = Math;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private router: Router,
        private loading: LoadingService,
        private route: ActivatedRoute
    ) {
    }


    user;

    private _filter(value: string): string[] {
        const filterValue = value;

        return this.customers.filter((option) =>
            option.label.includes(filterValue)
        );
    }

    private _filterP(value: string): string[] {
        const filterValue = value;

        return this.products.filter((option) =>
            option.label.includes(filterValue)
        );
    }

    async ngOnInit(): Promise<void> {
        await this.loadWarehouse();


        this.route.queryParams.subscribe((qp) => {
            if (qp["rep"]) this.isRepresentetiveOrder = true;
            else this.isRepresentetiveOrder = false;
        });

        this.add();
        try {
            this.user = await this.auth.get_info();

            this.credit = this.user.max_credit;
        } catch {
        }

        this.load();

    }

    async loadWarehouse() {
        let warehouseApi: string =
            Config.settings.api + "warehouse/warehouse/";
        await this.http
            .get(warehouseApi)
            .toPromise()
            .then((response: any) => {
                response.forEach((element) => {
                    this.warehouseList.push({
                        id: element.id,
                        label: element.name,
                        responsible: `${element.responsible_company_name} | ${element.responsible_first_name} ${element.responsible_last_name}`

                    });
                });
            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            });
    }


    changeWarehouse($event) {
        let warehouse = this.warehouseList[this.warehouseList.findIndex(x => x.id === $event)];
        this.currentWarehouseOrder.warehouse = warehouse.id;
        this.currentWarehouseOrder.warehouseResponsible = warehouse.responsible;

    }


    customerRef: Subject<void> = new Subject<void>();

    async load() {


        this.customers = [];
        this.loading.add();
        this.orgProducts = [];
        this.http.get(this.productsApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    this.orgProducts.push({
                        id: element.id,
                        name: element.name,
                        group: element.brand_name,
                        type: element.type_name,
                        status: element.is_active,
                        code: element.code,
                        unit: element.unit_name,
                        number_in_pallet: element.number_in_pallet,
                        weight: element.weight,
                    });
                    this.func.sort(this.orgProducts, "name");
                });
                let tc = [];
                this.orgProducts.forEach((element, i) => {
                    tc.push({
                        id: element.id,
                        label: element.name,
                    });
                });
                this.products = tc;
                this.filteredProducts = this.controlorder.valueChanges.pipe(
                    startWith(""),
                    map((value) => this._filterP(value))
                );
                this.loading.remove();
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                this.loading.remove();
                console.log(error);
            }
        );


        //=======
        this.order = [];
        this.route.params.subscribe((params) => {
            if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
                // this.router.navigate(["/404"]);
            } else {
                this.currentWarehouseOrder.id = params.id;
                let warehouseApi: string =
                    Config.settings.api + "warehouse/warehouse-input/" +
                    `${this.currentWarehouseOrder.id}/`;
                console.log(warehouseApi);
                this.http.get(warehouseApi).subscribe(
                    async (response: any) => {
                        console.log(response);

                        this.currentWarehouseOrder.code = response.code;
                        this.currentWarehouseOrder.detail = response.detail;
                        this.currentWarehouseOrder.warehouse = response.warehouse;
                        this.currentWarehouseOrder.warehouseResponsible = response.warehouse_responsible_company_name;
                        this.currentWarehouseOrder.state_name = response.state_name;
                        this.currentWarehouseOrder.created_at = response.created_at;
                        this.currentWarehouseOrder.sending_date = response.sending_date;
                        this.currentWarehouseOrder.receiving_date = response.receiving_date;
                        this.currentWarehouseOrder.created_name = `${response.creator_first_name} ${response.creator_last_name}`;

                        response.warehouse_input_details.forEach((el) =>
                            this.order.push({
                                pindex: el.id,
                                product_id: el.product,
                                count: el.sheet_number,
                                label: this.orgProducts[el.id].name
                            })
                        );
                        this.showC(this.order[0]);
                        console.log(this.order);

                        //         let el = response;
                        //         this.editing_check = new Check(
                        //             el.id,
                        //             el.check_number,
                        //             el.date,
                        //             el.amount,
                        //             el.card_number,
                        //             el.name,
                        //             el.manager_confirmation,
                        //             el.expert_confirmation,
                        //             el.customer_confirmation,
                        //             el.load_report,
                        //             el.check_state,
                        //             el.check_state_date,
                        //             el.detail,
                        //             el.image_url_1,
                        //             el.image_url_2,
                        //         );
                        //         console.log(el.check_state);
                        //         if(el.image_url_1 != null){
                        //             this.images.push(el.image_url_1);
                        //         }
                        //         if(el.image_url_2 != null){
                        //             this.images.push(el.image_url_2);
                        //         }
                        //         await this.load_loadreports(el.order_id);
                        //
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
        console.log(this.order);

        //======
        // this.http.get(this.usersApi + "?q=4").subscribe(
        //     (response: any) => {
        //         console.log(response);
        //         let tc = [];
        //         response.forEach((element) => {
        //             if (element.company_name)
        //                 tc.push({
        //                     id: element.id,
        //                     label: element.company_name,
        //                 });
        //             this.credit_map.set(element.id, element.max_credit);
        //         });
        //         this.customerRef.next();
        //         this.customers = tc;
        //         this.filteredCustomers = this.control.valueChanges.pipe(
        //             startWith(""),
        //             map((value) => this._filter(value))
        //         );
        //     },
        //     (error: HttpErrorResponse) => {
        //         //TODO: server errors
        //         console.log(error);
        //     }
        // );

    }


    changeProduct(el, i) {
        console.log(el, i);
        this.order[i].pindex = el.id;
        this.order[i].product_id = el.id;
    }

    changeCustomer(el) {
        this.customer = el.id;
        // this.credit = this.credit_map.get(el.id);
        this.http.get(this.usersApi + el.id).subscribe(
            (response: any) => {
                console.log(response);
                this.credit = response.max_credit;
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }

    showC(el) {
        return el && el.label;
    }

    _pallet_sum: number;
    _weight_sum: number;

    get count_sum() {
        let sum: number = 0;
        this.order.forEach((el) => {
            if (parseInt(el.count)) sum += parseInt(el.count);
        });
        return sum;
    }

    get weight_sum() {
        let sum: number = 0;
        this.order.forEach((el) => {
            sum +=
                el.pindex != null && el.count !== null
                    ? this.math.ceil(el.count * this.orgProducts[el.pindex].weight)
                    : 0;
        });
        return sum;
    }

    get pallet_sum() {
        let sum: number = 0;
        this.order.forEach((el) => {
            sum +=
                el.pindex != null && el.count !== null
                    ? (1.0 * el.count) /
                    this.orgProducts[el.pindex].number_in_pallet
                    : 0;
        });
        return Math.floor(sum);
    }

    changeType($event) {
        this.type = $event;
    }


    register(form) {
        let data = form.value;

        // data.buyer = this.isCustomer ? this.user.id : this.customer;
        // if (!this.isCustomer) data.buyer = this.customer;
        // // data.id = this.user.id;
        data.date = this.date.toISOString();
        data.state = 1;

        if (this.images[0]) {
            data.image_url_1 = this.images[0];
        } else {
            data.image_url_1 = null;
        }
        if (this.images[1]) {
            data.image_url_2 = this.images[1];
        } else {
            data.image_url_2 = null;
        }
        if (this.images[2]) {
            data.image_url_3 = this.images[2];
        } else {
            data.image_url_3 = null;
        }

        if (data.code == "") {
            data.code = null;
        }

        console.log(this.order);
        data.warehouse_input_details = [];
        this.order.forEach((el1) =>
            data.warehouse_input_details.push({
                product: el1.product_id,
                sheet_number: el1.count,
            })
        );
        console.log(data);
        let url = Config.settings.api + "warehouse/warehouse-input/";
        console.log(JSON.stringify(data));
        this.http.post(url, data).subscribe(
            (response: any) => {
                debugger;
                console.log("shod");
                console.log(response);
                this.success = true;
                this.isWrong = false;
                this.msg = `سفارش ذخیره شد.`;
                window.scroll(0, 0);
                this.router.navigate(["userArea", "warehouseOrder", `${response.id}`]);
            },
            (error: HttpErrorResponse) => {
                // this.router.navigate(["/userArea/warehouseOrderNew"]);
                this.isWrong = true;
                this.success = false;
                this.errorMsg = "دوباره امتحان کنید.";
                window.scroll(0, 0);
                console.log(error);
            }
        );
    }

    get isCustomer(): boolean {
        return (
            (this.user?.role == 2 ||
                this.user?.role == 3 ||
                this.user?.role == 4) &&
            !(this.user?.role == 4 && this.isRepresentetiveOrder)
        );
    }

    add() {
        this.order.push({
            pindex: null,
            product_id: null,
            unit_of_measure: null,
            number_in_pallet: null,
            count: 0,
        });
    }

    remove(i) {
        this.order.splice(i, 1);
    }

    add_image(e) {
        console.log(e);
        if (this.images.length < 3) {
            const url: string = Config.settings.api + Config.settings.info.file;
            const formData: FormData = new FormData();
            formData.append(
                "file",
                e.target.files.item(0),
                e.target.files.item(0).name
            );
            console.log(formData);
            const headers = {dt: "yes"};
            this.http.post(url, formData, {headers}).subscribe(
                (res: any) => {
                    this.images.push(res.url.slice(1));
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    window.scroll(0, 0);
                }
            );
        } else {
            this.success = false;
            this.isWrong = true;
            this.errorMsg = "تعداد عکس ها بیشتر از 3 عدد نمی تواند باشد!";
            window.scroll(0, 0);
        }

    }

    removeImage(i) {
        this.images.splice(i, 1);
    }
}
