export class UserPermissions {
  personnel: boolean;
  personnel_list: boolean;
  personnel_create: boolean;
  personnel_edit: boolean;
  personnel_delete: boolean;

  customer: boolean;
  customer_list: boolean;
  customer_create: boolean;
  customer_edit: boolean;
  customer_delete: boolean;

  order: boolean;
  order_list: boolean;
  order_create: boolean;
  order_edit: boolean;
  order_edit_status: boolean;
  order_delete: boolean;

  shipping: boolean;
  shipping_list: boolean;
  shipping_create: boolean;
  shipping_edit: boolean;
  shipping_edit_status: boolean;
  shipping_delete: boolean;

  evaluation: boolean;
  evaluation_list: boolean;
  evaluation_create: boolean;
  evaluation_edit: boolean;
  evaluation_edit_status: boolean;
  evaluation_delete: boolean;

  survey: boolean;
  survey_list: boolean;
  survey_create: boolean;
  survey_edit: boolean;
  survey_edit_status: boolean;
  survey_delete: boolean;
  showWarehouse: boolean;
  showDashboardWarehouse: boolean;

  information: boolean;
  reporter: boolean;
  reporterNew: boolean;
  reporterEdit: boolean;
  reporterDelete: boolean;
}
