<div class="m-3 ml-5 mt-4">
  <app-title title="ثبت حساب کاربری مشتریان"></app-title>

  <div *ngIf="success" class="" role="">
    <div
      class="d-flex text-success justify-content-center align-items-center"
    >
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ msg }}
    </div>
  </div>
  <div *ngIf="isWrong" class="" role="">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ errorMsg }}
    </div>
  </div>

  <form
    #form="ngForm"
    (ngSubmit)="register(form)"
    class="d-flex flex-column align-items-center"
  >
    <div
      class="box w-100"
      *ngIf="
        user && user.role !== 2 && user.role !== 3 && user.role !== 4
      "
    >
      <div class="row">
        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="user_types"
            placeholder="نوع مخاطب"
            (selectChange)="changeUserType($event)"
            req="true"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="user_type"
            name="role"
            class="d-none"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>کد مالی مشتری</label>
          <input
            (keydown)="func.numControl($event)"
            type="text"
            ngModel
            name="financial_code"
            class="form-control form-control-lg"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="references"
            placeholder="فروشنده مادر"
            [key]="credit"
            (selectChange)="reference = $event"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="reference"
            name="reference"
            class="d-none"
            required
          />
        </div>
        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="statuses"
            placeholder="وضعیت مشتری"
            [key]="true"
            (selectChange)="changeStatus($event)"
            req="true"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="status"
            name="is_active"
            class="d-none"
            required
          />
        </div>
        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="credits"
            placeholder="وضعیت اعتباری مشتری"
            [key]="credit"
            (selectChange)="changeCredit($event)"
            req="true"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="credit"
            name="credit_state"
            class="d-none"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>سقف اعتبار مشتری به صورت ریالی</label>
          <input
            type="text"
            [(ngModel)]="max_credit"
            name="max_credit"
            (keydown)="func.numControl($event)"
            class="form-control form-control-lg"
            required
            [disabled]="!credit"
          />
        </div>
      </div>
    </div>

    <div class="box w-100">
      <div class="row">
        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="states"
            placeholder="شخصیت"
            (selectChange)="changeState($event)"
            req="true"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="state"
            name="type"
            class="d-none"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>نام فروشگاه</label>
          <input
            type="text"
            ngModel
            name="company_name"
            class="form-control form-control-lg"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>شناسه ملی</label>
          <input
            type="text"
            ngModel
            name="company_code"
            class="form-control form-control-lg"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label class="mx-1 lead"> تاریخ تاسیس </label>
          <div class="input-group">
            <input
              matInput
              [matDatepicker]="picker2"
              [(ngModel)]="date"
              name="establishment_date"
              class="form-control form-control-lg"
            />

            <span class="input-group-append" (click)="picker3.open()">
              <i class="fa fa-calculator input-group-text"></i>
            </span>
            <mat-datepicker #picker2></mat-datepicker>
          </div>
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="provinces"
            placeholder="استان"
            (selectChange)="changeProvince($event)"
            req="true"
          ></app-select>
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="cities"
            placeholder="شهرستان"
            (selectChange)="changeCity($event)"
            req="true"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="city"
            name="city"
            class="d-none"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>نام مدیرعامل</label>
          <input
            type="text"
            ngModel
            name="first_name"
            class="form-control form-control-lg"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>نام‌خانوادگی مدیرعامل</label>
          <input
            type="text"
            ngModel
            name="last_name"
            class="form-control form-control-lg"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="genders"
            placeholder="جنسیت"
            (selectChange)="gender = $event"
            req="true"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="gender"
            name="gender"
            class="d-none"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label class="mx-1 lead"> تاریخ تولد </label>
          <div class="input-group">
            <input
              matInput
              [matDatepicker]="picker3"
              [(ngModel)]="date"
              name="date_of_birth"
              class="form-control form-control-lg"
            />

            <span class="input-group-append" (click)="picker3.open()">
              <i class="fa fa-calculator input-group-text"></i>
            </span>
            <mat-datepicker #picker3></mat-datepicker>
          </div>
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>محل تولد</label>
          <input
            type="text"
            ngModel
            name="city_of_birth"
            class="form-control form-control-lg"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>نام پدر</label>
          <input
            type="text"
            ngModel
            name="father_name"
            class="form-control form-control-lg"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>کد ملی مشتری</label>
          <input
            (keydown)="func.numControl($event)"
            type="text"
            value=""
            maxlength="10"
            ngModel
            name="national_code"
            class="form-control form-control-lg"
            required
          />
        </div>
        <!-- <div class="form-group col-lg-4 col-md-6 col-12">
               <input
                  type="text"
                  value=""
                  ngModel
                  name="name"
                  class="form-control form-control-lg"
                  required
               />
               <label placeholder="سمت" alt="سمت"></label>
            </div> -->
        <!-- <div class="form-group col-lg-4 col-md-6 col-12">
          <label>تلفن ثابت</label>
          <input
            type="text"
            placeholder="××××××××-××۰"
            [(ngModel)]="landing_number"
            name="landing_number"
            class="form-control form-control-lg"
            maxlength="12"
            required
            (keydown)="func.numControl($event)"
          />
        </div> -->

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>تلفن همراه</label>
          <input
            type="text"
            ngModel
            name="phone_number"
            class="form-control form-control-lg"
            maxlength="11"
            (keydown)="func.numControl($event)"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="degrees"
            placeholder="مدرک تحصیلی"
            (selectChange)="degree = $event"
            req="true"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="degree"
            name="degree"
            class="d-none"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="fields"
            placeholder="رشته‌ی تحصیلی"
            (selectChange)="field = $event"
            req="true"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="field"
            name="field"
            class="d-none"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <app-select
            [options]="jobs"
            placeholder="سمت سازمانی"
            (selectChange)="job = $event"
            req="true"
          ></app-select>

          <input
            type="hidden"
            value=""
            [(ngModel)]="job"
            name="job"
            class="d-none"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>وب سایت</label>
          <input
            type="text"
            ngModel
            name="website_url"
            class="form-control form-control-lg"
            required
          />
        </div>

        <!-- <div class="form-group col-lg-4 col-md-6 col-12">
          <label>آدرس ایمیل</label>
          <input
            type="text"
            ngModel
            name="email"
            class="form-control form-control-lg"
            required
          />
        </div>

        

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>آی‌دی تلگرام</label>
          <input
            type="text"
            ngModel
            name="telegram_id"
            class="form-control form-control-lg"
            required
          />
        </div>

        <div class="form-group col-lg-4 col-md-6 col-12">
          <label>آدرس اینستاگرام</label>
          <input
            type="text"
            ngModel
            name="instagram_id"
            class="form-control form-control-lg"
            required
          />
        </div> 

        <div class="form-group col-12">
          <label>آدرس پستی</label>
          <input
            type="text"
            value=""
            ngModel
            name="company_address"
            class="form-control form-control-lg"
            required
          />
        </div>-->
      </div>
    </div>

    <div class="box w-100">
      <div class="row">
        <div class="form-group col-12 col-md-6">
          <label>نام‌کاربری</label>
          <input
            type="text"
            ngModel
            name="username"
            class="form-control form-control-lg"
            required
          />
        </div>

        <div class="form-group col-12 col-md-6">
          <label>رمزعبور</label>
          <input
            type="text"
            ngModel
            name="password"
            class="form-control form-control-lg"
            required
          />
        </div>
      </div>
    </div>

    <div class="row w-100">
      <button type="submit" class="btn mr-auto ml-3 w-25 btn-success">
        ثبت
      </button>
      <button
        [routerLink]="['../']"
        type="button"
        class="btn ml-5 w-25 btn-secondary"
      >
        بازگشت
      </button>
    </div>
  </form>
</div>
