export class Product {

    private id;
    private name: string = null;
    private stockList: any[] = [];
    private sumStockList = 0;


    constructor(id, name: string, stockList: any[], sumStockList: number) {
        this.id = id;
        this.name = name;
        this.stockList = stockList;
        this.sumStockList = sumStockList;
    }
}
