import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Config } from 'src/app/app-config.service';
import * as Func from 'src/pack/js/func';
import { ToRPipe } from 'src/app/fa.pipe';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ToolbarItemModel} from '../../../../models/toolbar.model';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss'],
})
export class CustomerEditComponent implements OnInit {
  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;
  conf = Config;

  id: number;
  customer;
  username = '';
  date;
  imgUrl: string = null;
  user_types: any[] = [
    { id: 2, label: 'مشتری' },
    { id: 3, label: 'عامل فروش' },
    { id: 4, label: 'نمایندگی' },
  ];
  financial_code;
  user_type: number = null;

  nameTitles: any[] = [
    { id: 'آقای', label: 'آقا' },
    { id: 'خانم', label: 'خانم' },
  ];

  nameTitle: number = null;
  statuses: any[] = [
    { id: true, label: 'فعال' },
    { id: false, label: 'غیر فعال' },
  ];
  status = true;

  references: any[] = [];
  reference: number = null;

  credits: any[] = [
    { id: true, label: 'دارد' },
    { id: false, label: 'ندارد' },
  ];
  credit = false;

  provinces: any[] = [];
  province: number = null;

  addressTitle: number = null;
  addressTitles: any[] = [];

  states: any[] = [
    { id: 'natural', label: 'حقیقی' },
    { id: 'juridical', label: 'حقوقی' },
  ];
  state: string = null;
  showTitle = false;
  natureName: string = null;

  genders: any[] = [
    { id: 'male', label: 'آقا' },
    { id: 'female', label: 'خانم' },
  ];
  gender: string = null;

  degrees: any[] = [];
  degree: number = null;

  cities: any[] = [];
  city: number = null;

  fields: any[] = [];
  field: number = null;
  familiarityList: any[] = [];
  familiarity: number = null;
  showIntroducer = false;
  jobs: any[] = [];
  job: number = null;
  toolbarItemList: ToolbarItemModel[] = [
    {
      name: 'save',
      icon: 'fas fa-save',
      text: 'ذخیره',
      type: 'button',
      btn_type: 'primary'
    },
    {
      name: 'saveClose',
      icon: 'fas fa-window-close',
      text: 'ذخیره و بستن',
      type: 'button',
      btn_type: 'danger'
    },
    {
      name: 'saveNew',
      icon: 'fas fa-sync',
      text: 'ذخیره و جدید',
      type: 'button',
      btn_type: 'success'
    },
    {
      name: 'space',
      text: 'space',
      type: 'space',
    },
    {
      name: 'cancel',
      text: 'لغو',
      type: 'icon-button',
      icon: 'fal fa-times-circle fa-2x text-danger',
    },
    {
      name: 'print',
      text: 'چاپ',
      type: 'icon-button',
      icon: 'fal fa-print fa-2x text-info',
    },
    {
      name: 'excel',
      text: 'خروجی اکسل',
      type: 'icon-button',
      icon: 'fal fa-file-excel fa-2x text-success',
    },
    {
      name: 'space',
      text: 'space',
      type: 'space',
    },
    {
      name: 'operations',
      text: 'عملیات',
      icon: 'fas fa-cogs',
      type: 'menu',
      btn_type: 'primary',
      items: [
        {
          name: 'cancel',
          text: 'لغو',
          type: 'icon-button',
          icon: 'fal fa-times-circle',
        },
        {
          name: 'print',
          text: 'چاپ',
          type: 'icon-button',
          icon: 'fal fa-print ',
        },
        {
          name: 'excel',
          text: 'خروجی اکسل',
          type: 'icon-button',
          icon: 'fal fa-file-excel',
        }
      ]
    }
  ];
  @ViewChild('form')
  form: ElementRef;
  _max_credit: number = null;
  get max_credit() {
    if (!this._max_credit) { return null; }
    return this.toR.transform(this._max_credit.toString());
  }
  set max_credit(e: string) {
    this._max_credit = Func.toNumber(e);
  }

  func = Func;
  toR: ToRPipe = new ToRPipe();

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  user = null;

  @Input() user_id = 0;

  async ngOnInit(): Promise<void> {
    this.user = await this.auth.get_info();

    this.setAddressTitles();

    const provinceApi: string =
      Config.settings.api + Config.settings.add.province;
    await this.http
      .get(provinceApi)
      .toPromise()
      .then((response: any) => {
        response.forEach((element) => {
          this.provinces.push({
            id: element.id,
            label: element.name,
          });
        });
      })
      .catch((error: HttpErrorResponse) => {
        // TODO: server errors
        console.log(error);
      });

    const jobApi: string = Config.settings.api + 'information/job/';
    this.http.get(jobApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.jobs.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        // TODO: server errors
        console.log(error);
      }
    );

    this.http.get(Config.settings.api + 'information/familiarity-type/').subscribe(
        (response: any) => {
          response.forEach((element) => {
            this.familiarityList.push({
              id: element.id,
              label: element.name,
            });
          });
        },
        (error: HttpErrorResponse) => {
          // TODO: server errors
          console.log(error);
        }
    );
    const degreeApi: string = Config.settings.api + 'information/degree/';
    this.http.get(degreeApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.degrees.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        // TODO: server errors
        console.log(error);
      }
    );

    const fieldApi: string = Config.settings.api + 'information/field/';
    this.http.get(fieldApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.fields.push({
            id: element.id,
            label: element.name,
          });
        });
      },
      (error: HttpErrorResponse) => {
        // TODO: server errors
        console.log(error);
      }
    );

    const referenceApi: string =
      Config.settings.api + Config.settings.user.user + '?q=3';
    this.http.get(referenceApi).subscribe(
      (response: any) => {
        const tc = [{ id: null, label: 'بدون فروشنده مادر' }];
        response.forEach((element) => {
          if (element.role !== 2) {
            tc.push({
              id: element.id,
              label: element.company_name,
            });
          }
        });
        this.references = tc;
      },
      (error: HttpErrorResponse) => {
        // TODO: server errors
        console.log(error);
      }
    );

    this.load();
  }

  load(){
    this.route.params.subscribe((params) => {
      if (
          this.user_id === 0 &&
          (isNaN(params.id) || !Number.isInteger(params.id / 1))
      ) {
        console.log(this.user_id);

        this.router.navigate(['/404']).then(r => '/404');
      } else {
        if (this.user_id !== 0) {
          this.id = this.user_id;
        } else {
          this.id = params.id;
        }
        const userApi: string =
            Config.settings.api + Config.settings.user.user + `${this.id}/`;
        this.http.get(userApi).subscribe(
            (response: any) => {
              this.customer = response;
              // this.load_ref(response.reference);
              this.reference = response.reference;
              this.user_type = response.role;
              this.date = response.date_of_birth;
              this.status = response.is_active;
              this.credit = response.credit_state;
              this._max_credit = response.max_credit;
              this.addressTitle = response.address_title;
              this.state = response.type;
              if (this.state === 'juridical') {
                this.showTitle = true;
              }
              this.job = response.job;
              this.financial_code = response.financial_code;
              this.field = response.field;
              this.gender = response.gender;
              this.degree = response.degree;
              this.nameTitle = response.name_title;
              this.natureName = response.nature_name;
              this.changeProvince(response.province, response.city);
              this.familiarity = response.familiarity_type;
              this.showIntroducer = this.familiarity === 1;
              if (response.is_set) { this.username = response.username; }
            },
            (error: HttpErrorResponse) => {
              // TODO: server errors
              console.log(error);
            }
        );
      }
    });

  }

  setAddressTitles(){
    debugger;
    const addressTitle: string =
        Config.settings.api + 'information/address-title/';
    this.http.get(addressTitle).subscribe(
        (response: any) => {
          response.forEach((element) => {
            this.addressTitles.push({
              id: element.id,
              label: element.name,
            });
          });
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
    );
  }



  changeUserType($event) {
    console.log($event);

    this.user_type = $event;
  }

  chaneNameTitle($event) {
    this.nameTitle = $event;
  }

  changeStatus($event) {
    this.status = $event;
  }

  changeReference($event) {
    this.reference = $event;
  }

  changeProvince($event, city = null) {
    this.province = $event;
    const cityApi: string =
      Config.settings.api +
      Config.settings.add.citys +
      `?province=${$event}`;
    this.cities = [];
    this.http.get(cityApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.cities.push({
            id: element.id,
            label: element.name,
          });
          this.city = city;
        });
      },
      (error: HttpErrorResponse) => {
        // TODO: server errors
        console.log(error);
      }
    );
  }

  changeTitleAddress($event){
    this.addressTitle = $event;
  }

  changeState($event) {
    this.showTitle = $event === 'juridical';
    this.state = $event;
  }

  changeCity($event) {
    this.city = $event;
  }
  changeCredit($event) {
    this.credit = $event;
  }

  register(form) {
    if (this.user.role == 2 || this.user.role == 3 || this.user.role == 4) {
      form.value.reference = this.user.id;
    }

    form.value.max_credit = this._max_credit;
    if (form.value.introducer_code === ''){
      form.value.introducer_code = null;
    }
    form.value.familiarity_type = this.familiarity;
    // form.national_code = parseInt(form.national_code);

    form.value.image_url = this.imgUrl;
    form.value.address_title = this.addressTitle;
    console.log(form.value);
    const url =
      Config.settings.api +
      Config.settings.user.user +
      `${this.customer.id}/`;
    this.http.patch(url, form.value).subscribe(
      (response: any) => {
        console.log('shod');
        this.success = true;
        this.isWrong = false;
        this.msg = `ویرایش شد.`;
        window.scroll(0, 0);
        this.load();
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = 'دوباره امتحان کنید.';
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }

  change_image(e) {
    const url: string = Config.settings.api + Config.settings.info.file;
    const formData: FormData = new FormData();
    formData.append(
      'file',
      e.target.files.item(0),
      e.target.files.item(0).name
    );
    console.log(formData);
    const headers = { dt: 'yes' };
    this.http.post(url, formData, { headers }).subscribe(
      (res: any) => {
        this.imgUrl = res.url.slice(1);
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = 'دوباره امتحان کنید.';
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
      }
    );
  }

  changeFamiliarity(event){
    this.showIntroducer = event === 1;
    this.familiarity = event;
  }

  itmeClickHandler(event) {
    console.log(event);
    switch (event.name) {
      case 'save':
        this.register(this.form);
        break;
      case 'saveClose':
        this.register(this.form);
        this.router.navigate(['userArea/customer']);
        break;
      case 'cancel':
        this.router.navigate(['userArea/customer']);
        break;
    }
  }

}
