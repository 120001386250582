<div class="m-3 ml-5 mt-4">
    <app-title title="ثبت سفارش جدید انبار فروش استانی"></app-title>
    <div class="order-header">
        <div class="row">
            <div class="col item">سفارش انبار: {{currentWarehouseOrder.id}} </div>
<!--            <div class="col item">کد رهگیری انبار: {{currentWarehouseOrder.code}} </div>-->
            <div class="col item"> ثبت: {{currentWarehouseOrder.created_at | jDate | toFa}} </div>
<!--            <div class="col item">تاریخ سفارش: {{currentWarehouseOrder.state_name}} </div>-->
            <div class="col item">تاریخ ارسال: {{currentWarehouseOrder.sending_date | jDate | toFa}} </div>
            <div class="col item">تاریخ دریافت: {{currentWarehouseOrder.receiving_date | jDate | toFa}} </div>
            <div class="col item">ایجاد کننده: {{currentWarehouseOrder.created_name}} </div>
            <div class="col item">وضعیت: {{currentWarehouseOrder.state_name}} </div>
        </div>

    </div>
    <form #form="ngForm" class="w-100">
        <div class="box">
            <div class="row">

                <div class="form-group col-lg-3 col-md-3 col-3">
                    <app-select
                            [options]="warehouseList"
                            (selectChange)="changeWarehouse($event)"
                            [key]="currentWarehouseOrder.warehouse"
                            req="true"
                            placeholder="عنوان انبار فروش *"
                    ></app-select>
                    <input
                            type="hidden"
                            value=""
                            [(ngModel)]="currentWarehouseOrder.warehouse"
                            name="warehouse"
                            class="d-none"
                            required
                    />
                </div>
                <div class="form-group col-lg-6 col-md-6 col-6">
                    <label class="mx-1 lead"> مسئول انبار فروش </label>
                    <input
                            name="warehouseResponsible"
                            type="text"
                            [readOnly]="true"
                            [(ngModel)]="currentWarehouseOrder.warehouseResponsible"
                            class="form-control form-control-lg"
                            required

                    />
                </div>

                <div class="form-group col-lg-3 col-md-3 col-3">
                    <label class="mx-1 lead"> کد رهگیری ( راهکاران ) </label>
                    <input
                            [ngModel]="currentWarehouseOrder.code"
                            name="code"
                            type="text"
                            class="form-control form-control-lg"
                            required

                    />
                </div>

                <div class="form-group col-lg-3 col-md-3 col-3">
                    <label class="mx-1 lead"> تاریخ سفارش *</label>
                    <input
                            matInput
                            [matDatepicker]="picker"
                            (focus)="picker.open()"
                            (click)="picker.open()"
                            type="text"
                            [(ngModel)]="date"
                            name="date"
                            class="form-control-lg form-control"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                </div>

                <div class="form-group col-lg-9 col-md-9 col-9">
                    <label class="mx-1 lead"> توضیحات </label>
                    <textarea
                            [ngModel]="currentWarehouseOrder.detail"
                            class="form-control-lg form-control"
                            (keydown)="(1 == 1)"
                            name="detail"
                    ></textarea>
                </div>
            </div>
        </div>
    </form>

    <div class="list mx-3">
        <table class="table table-bordered">
            <thead class="thead-light headerless">
            <tr>
                <th scope="col">#</th>
                <th scope="col">نام کالا</th>
                <th scope="col">تعداد ورق</th>
                <th scope="col" class="d-lg-table-cell d-none">تعداد پالت</th>
                <th scope="col" class="d-lg-table-cell d-none">وزن تقریبی</th>
                <th scope="col" class="d-lg-table-cell d-none">
                    واحد اندازه گیری
                </th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of order; let i = index">
                <th>{{ i + 1 }}</th>
                <td class="p-0" dir="rtl">


                    <input
                            type="text"
                            class="form-control form-control-lg border-0"
                            matInput
                            [formControl]="controlorder"
                            [matAutocomplete]="autoo"
                    />

                    <mat-autocomplete
                        #autoo="matAutocomplete"
                        (optionSelected)="changeProduct($event.option.value, i)"

                        [displayWith]="showC"
                    >
                        <mat-option
                                *ngFor="let el of filteredProducts | async"
                                [value]="el"
                        >
                            {{ el.label }}
                        </mat-option>
                    </mat-autocomplete>
                </td>

                <td class="p-0">
                    <input
                            type="number"
                            class="border-0 form-control form-control-lg"
                            [(ngModel)]="order[i].count"
                            [ngModelOptions]="{ standalone: true }"
                    />
                </td>
                <td class="d-lg-table-cell d-none">
                    {{
                    el.pindex != null && order[i].count !== null
                        ? math.floor(
                        (1.0 * el.count) /
                        this.orgProducts[el.pindex].number_in_pallet
                        )
                        : null
                    }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{
                    el.pindex != null && order[i].count !== null
                        ? math.ceil(
                        order[i].count * orgProducts[el.pindex].weight
                        )
                        : null
                    }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ el.pindex != null ? orgProducts[el.pindex].unit : null }}
                </td>
                <td><i class="fal fa-trash-alt" (click)="remove(i)"></i></td>
            </tr>
            <tr>
                <th class="border-left-0"></th>
                <td (click)="add()" class="border-right-0">
                    <i class="fal fa-plus"></i>
                </td>
                <td>{{ count_sum }}</td>
                <td>{{ pallet_sum }}</td>
                <td class="d-lg-table-cell d-none">{{ weight_sum }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="images.length" class="row box d-flex" style="z-index: inherit">
        <div *ngFor="let image of images; let i = index"
             class="d-block p-3 position-relative profile">
            <div class="img">
                <img
                        [src]="conf.settings.back + image"
                        style="width: 200px;height: 100px"
                />
            </div>
            <div (click)="removeImage(i)"
                 class="cursor-pointer img-remove">
                <i class="fa fa-times"></i>
            </div>
        </div>
    </div>

    <div class="row justify-content-end m-3">

<!--        <button-->
<!--                readonly="true"-->
<!--                type="button"-->
<!--                class="btn col-2 btn-gray mx-2"-->
<!--        >-->
<!--            دریافت سفارش-->
<!--        </button>-->
<!--        <button-->
<!--                readonly="true"-->
<!--                type="button"-->
<!--                class="btn col-2 btn-gray mx-2"-->
<!--        >-->
<!--            ارسال سفارش-->
<!--        </button>-->
<!--        <button-->
<!--                [routerLink]="['../']"-->
<!--                type="button"-->
<!--                class="btn col-2 btn-gray"-->
<!--                readonly="true"-->
<!--        >-->
<!--            ویرایش-->
<!--        </button>-->
        <button
                [routerLink]="['/userArea/warehouseOrderList']"
                type="button"
                class="btn col-2 btn-gray mx-2 " style="height: 30px;"
        >
            لغو
        </button>
        <button (click)="register(form)" class="btn col-2 btn-primary" style="height: 30px;">
            ذخیره
        </button>
        <label class="btn btn-success col-2 mx-2" style="height: 30px;">
            <input
                    type="file"
                    accept="image/*"
                    (change)="add_image($event)"
                    style="opacity: 0; display: none"/>
            ارسال تصویر
        </label>
    </div>
</div>
