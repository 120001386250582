<nav>
   <ul class="pagination">
      <li class="page-item">
         <span class="page-link" (click)="changePage.emit(1)"><<</span>
      </li>
      <li class="page-item" [class.disabled]="page_number == 1">
         <span class="page-link" (click)="changePage.emit(page_number - 1)"
            ><</span
         >
      </li>
      <li
         *ngFor="let page of array()"
         class="page-item active"
         [class.active]="page_number == page"
      >
         <span class="page-link" (click)="changePage.emit(page)">{{
            page | toFa
         }}</span>
      </li>
      <li class="page-item" [class.disabled]="page_number == page_count">
         <span class="page-link" (click)="changePage.emit(page_number + 1)"
            >></span
         >
      </li>
      <li class="page-item">
         <span class="page-link" (click)="changePage.emit(page_count)">>></span>
      </li>
   </ul>
</nav>
