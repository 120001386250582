import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Config } from 'src/app/app-config.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as Func from 'src/pack/js/func';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from 'src/app/loading/loading.service';
import { ToolbarItemModel } from 'src/app/models/toolbar.model';

@Component({
   selector: 'app-personnels-list',
   templateUrl: './personnels-list.component.html',
   styleUrls: ['./personnels-list.component.scss'],
   host: { class: 'w-100' },
})
export class PersonnelsListComponent implements OnInit {
   func = Func;
   personnels: any[] = [];
   orgPersonnels: any[] = [];
   selected: boolean[] = [];
   searchControl = new FormControl();
   personnelsApi: string =
      Config.settings.api + Config.settings.user.user + '?q=2';
   searchTerm: any;
   status = 1;
   success: boolean;
   msg: string;
   toolbarItemList: ToolbarItemModel[] = [
      {
         name: "new",
         icon: "fas fa-plus",
         text: "جدید",
         type: "button",
         btn_type: "info"
      },
      {
         name: "refresh",
         icon: "fas fa-sync",
         text: "به‌روزرسانی",
         type: "button",
         btn_type: "primary"
      },
      {
         name: "multiple",
         icon: "fal fa-ballot-check",
         text: "انتخاب چند گانه",
         type: "button",
         btn_type: "primary"
      },
      {
         name: "space",
         text: "space",
         type: "space",
      },
      {
         name:"cancel",
         text: "لغو",
         type:"icon-button",
         icon: "fal fa-times-circle fa-2x text-danger",
      },
      {
         name:"print",
         text: "چاپ",
         type:"icon-button",
         icon: "fal fa-print fa-2x text-info",
      },
      {
         name:"excel",
         text: "خروجی اکسل",
         type:"icon-button",
         icon: "fal fa-file-excel fa-2x text-success",
      },
      {
         name: "space",
         text: "space",
         type: "space",
      },
      {
         name:"operations",
         text:"عملیات",
         icon: "fas fa-cogs",
         type:"menu",
         btn_type:"primary",
         items: [
            {
               name:"cancel",
               text: "لغو",
               type:"icon-button",
               icon: "fal fa-times-circle",
            },
            {
               name:"print",
               text: "چاپ",
               type:"icon-button",
               icon: "fal fa-print ",
            },
            {
               name:"excel",
               text: "خروجی اکسل",
               type:"icon-button",
               icon: "fal fa-file-excel",
            }
         ]
      }
   ];
   constructor(
      private router: Router,
      private http: HttpClient,
      private modalService: NgbModal,
      private route: ActivatedRoute,
      private loading: LoadingService
   ) { }

   ngOnInit(): void {
      this.route.queryParamMap.subscribe((param) => {
         this.searchTerm = param.get('q');
      });
      this.searchControl.valueChanges
         .pipe(debounceTime(300), untilDestroyed(this))
         .subscribe((term) => this.search(term));
      this.load();
   }

   load() {
      this.loading.add();
      this.http.get(this.personnelsApi).subscribe(
         (response: any) => {
            this.personnels = response;

            console.log(this.personnels);
            this.loading.remove();

            // this.search(this.searchTerm);
         },
         (error: HttpErrorResponse) => {
            //TODO: server errors
            console.log(error);
            this.loading.remove();
         }
      );
   }

   search(value: string) {
      if (!value) {
         this.personnels = this.orgPersonnels;
         this.router.navigate([]);
         return;
      }
      this.router.navigate([], {
         queryParams: { q: this.searchTerm },
      });
      value = Func.toEn(value);
      this.personnels = this.orgPersonnels.filter((option) => {
         return (
            option['name'].includes(value) ||
            option['personnel_code'].includes(value) ||
            option['unit'].includes(value) ||
            option['role'].includes(value)
         );
      });
   }

   edit(i = null) {
      if (i) {
         this.router.navigate(['/userArea/personnel/', i]);
      }
      this.selected.forEach((el, i) => {
         if (el) {
            this.router.navigate(['/userArea/personnel/', i]);
         }
      });
   }

   del(content, i) {
      if (!i) return;
      this.modalService
         .open(content, { ariaLabelledBy: 'modal-basic-title' })
         .result.then((result) => {
            if (result) {
               this.http
                  .delete(
                     Config.settings.api + Config.settings.user.user + i + '/'
                  )
                  .subscribe(
                     (response: any) => {
                        this.load();
                     },
                     (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                     }
                  );
            }
         });
   }
   itmeClickHandler(event) {
      console.log(event);
      switch (event.name) {
         case 'new':
            this.router.navigate(['userArea/personnel/new']);
            break;
         case 'refresh':
            this.load();
            break;
      }
   }
   ngOnDestroy() { }

   downloadFile(){
      if (this.status === 1) {
         this.status = 0 ;
         this.http.get(Config.settings.api + `user/user/download/?q=2`).subscribe(
             (response: any) => {
                this.status = 1 ;
                this.success = false;
                this.msg = " ";
                window.open(Config.settings.download + response.link, "_blank");
             },
             (error: HttpErrorResponse) => {
                console.log(error);
                this.status = 1 ;
             }
         );
      } else {
         this.success = true;
         this.msg = "فایل در حال دانلود می باشد...";
      }

   }
}
