import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Config} from '../../../app-config.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {L10n, setCulture} from "@syncfusion/ej2-base";
import * as EJ2_LOCALE from '@syncfusion/ej2-locale/src/fa.json';
import {map, startWith} from "rxjs/operators";
import {ToolbarItemModel} from "../../../models/toolbar.model";


@Component({
    selector: 'app-report-new',
    templateUrl: './report-new.component.html',
    styleUrls: ['./report-new.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReportNewComponent implements OnInit  {
    @ViewChild("form")
    form:ElementRef;
    flag;
    isWrong: boolean;
    errorMsg: string;
    success: boolean;
    msg: string;
    showAddresseeType: boolean;
    showUser: boolean;
    conf = Config;
    type: number;
    addresseeType: number;
    status = 1;
    types: any[] = [];
    users: any[] = [];
    activityType: any[] = [];
    actionTypes: any[] = [];
    addresseeTypes: any[] = [];
    images: any[] = [];
    files: any[] = [];
    allFile: any[] = [];
    productsApi: string =
        Config.settings.api + Config.settings.product.product;
    public dateValueStart: Date;
    public dateValueEnd: Date;
    control = new FormControl();
    userList: any[] = [];
    filteredUsers;
    addressee;
    disabled = false;
    addresseeId;
    usersApi: string = Config.settings.api + Config.settings.user.user;
    toolbarItemList: ToolbarItemModel[] = [

        {
            name: "save",
            icon: "fas fa-save",
            text: "ذخیره و ویرایش",
            type: "button",
            btn_type: "primary"
        },
        {
            name: "saveNew",
            icon: "fas fa-save",
            text: "ذخیره و جدید",
            type: "button",
            btn_type: "primary"
        },
        {
            name: "saveClose",
            icon: "fas fa-save",
            text: "ذخیره و بستن",
            type: "button",
            btn_type: "primary"
        },
        {
            name: "space",
            text: "space",
            type: "space",
        },
        {
            name: "cancel",
            text: "لغو",
            type: "icon-button",
            icon: "fal fa-times-circle fa-2x text-danger",
        },
        {
            name: "print",
            text: "چاپ",
            type: "icon-button",
            icon: "fal fa-print fa-2x text-info",
        },
        {
            name: "excel",
            text: "خروجی اکسل",
            type: "icon-button",
            icon: "fal fa-file-excel fa-2x text-success",
        },
        {
            name: "space",
            text: "space",
            type: "space",
        },
        {
            name: "operations",
            text: "عملیات",
            icon: "fas fa-cogs",
            type: "menu",
            btn_type: "primary",
            items: [
                {
                    name: "cancel",
                    text: "لغو",
                    type: "icon-button",
                    icon: "fal fa-times-circle",
                },
                {
                    name: "print",
                    text: "چاپ",
                    type: "icon-button",
                    icon: "fal fa-print ",
                },
                {
                    name: "excel",
                    text: "خروجی اکسل",
                    type: "icon-button",
                    icon: "fal fa-file-excel",
                }
            ]
        }
    ]

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
    }

    user;

    changStartDate(e) {
        this.dateValueStart = e.value;
    }

    changEndDate(e) {
        this.dateValueEnd = e.value;
    }

    async ngOnInit(): Promise<void> {

        this.loadType();
        this.loadAddresseeType();
        this.loadAddresseeId();

        const activityTypeUrl =
            Config.settings.api + 'report/report-activity-type/';
        this.http.get(activityTypeUrl).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.activityType.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
        const actionTypeUrl =
            Config.settings.api + 'report/report-action-type/';
        this.http.get(actionTypeUrl).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.actionTypes.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );

        L10n.load(EJ2_LOCALE.fa);
        setCulture('fa');


    }

    loadAddresseeType() {
        const addresseeTypeUrl =
            Config.settings.api + 'report/report-addressee-type/';
        this.http.get(addresseeTypeUrl).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.addresseeTypes.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    loadType() {
        const typeApi =
            Config.settings.api + 'report/report-type/';
        this.http.get(typeApi).subscribe(
            (response: any) => {
                console.log(response);
                response.forEach((element) => {
                    this.types.push({
                        id: element.id,
                        label: element.name,
                    });
                });
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    loadAddresseeId() {
        this.addresseeId = localStorage.getItem('addressee_id');
        if (this.addresseeId !== undefined && this.addresseeId != null) {
            this.disabled = true;
            this.type = 2;
            this.changeType(this.type);
            this.http.get(this.usersApi + this.addresseeId).subscribe(
                (response: any) => {
                    debugger;
                    this.addresseeType = response.role;
                    this.changeAddressee(this.addresseeType);
                    this.control.setValue({
                        id: response.id,
                        label: response.company_name
                    });
                    this.addressee = this.addresseeId;
                },
                (error: HttpErrorResponse) => {
                    debugger;
                    //TODO: server errors
                    console.log(error);
                }
            );
        }

    }

    changeType($event) {
        this.type = $event;
        // @ts-ignore
        this.showAddresseeType = this.type === 2;
        this.showUser = this.type === 2;
    }


    changeAddressee($event) {
        this.users = [];
        let addresseeUrl = Config.settings.api;
        const addresseeId = $event;
        switch (addresseeId) {
            case 1:
                addresseeUrl = addresseeUrl + 'user/user/summary/?q=2';
                break;
            case 2:
                addresseeUrl = addresseeUrl + 'user/user/summary/?role=2';
                break;
            case 3:
                addresseeUrl = addresseeUrl + 'user/user/summary/?role=3';
                break;
            case 4:
                addresseeUrl = addresseeUrl + 'user/user/summary/?role=4';
                break;
        }
        this.showUser = true;
        this.http.get(addresseeUrl).subscribe(
            (response: any) => {
                let tc = [];
                response.results.forEach((element) => {
                    tc.push({
                        id: element.id,
                        // tslint:disable-next-line:max-line-length
                        label: element.name ,
                    });
                });
                this.userList = [];
                this.userList = tc;
                this.filteredUsers = this.control.valueChanges.pipe(
                    startWith(""),
                    map((value) => this._filterP(value))
                );
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );
    }

    private _filterP(value: string): string[] {
        const filterValue = value;

        return this.userList.filter((option) =>
            option.label.includes(filterValue)
        );
    }

    register(form) {
        if (this.status === 1) {
            this.files.forEach(value => {
                this.allFile.push(value);
            });
            this.images.forEach(value => {
                this.allFile.push(value);
            });
            let data = form.value;
            if (this.addressee != null) {
                data.addressee = this.addressee;
                this.isWrong = false;
            } else {
                if (this.showAddresseeType) {
                    this.isWrong = true;
                    this.errorMsg = 'لطفا نام مخاطب را انتخاب نمایید !';
                    return;
                }
            }
            data.state = 1;
            if (this.dateValueStart !== null) {
                data.activity_start_time = this.dateValueStart;
            }
            if (this.dateValueEnd !== null) {
                data.activity_end_time = this.dateValueEnd;
            }
            if (this.allFile.length) {
                if (this.allFile[0] == null) {
                    data.image_url_1 = null;
                } else {
                    data.image_url_1 = this.allFile[0];
                }
                if (this.allFile[1] == null) {
                    data.image_url_2 = null;
                } else {
                    data.image_url_2 = this.allFile[1];
                }
                if (this.allFile[2] == null) {
                    data.image_url_3 = null;
                } else {
                    data.image_url_3 = this.allFile[2];
                }
                if (this.allFile[3] == null) {
                    data.image_url_4 = null;
                } else {
                    data.image_url_4 = this.allFile[3];
                }
                if (this.allFile[4] == null) {
                    data.image_url_5 = null;
                } else {
                    data.image_url_5 = this.images[4];
                }
                if (this.allFile[5] == null) {
                    data.image_url_6 = null;
                } else {
                    data.image_url_6 = this.allFile[5];
                }
            }
            const url = Config.settings.api + 'report/report/';
            console.log(JSON.stringify(data));
            this.status = 0;
            this.http.post(url, data).subscribe(
                (response: any) => {
                    this.status = 1;
                    console.log('shod');
                    console.log(response);
                    this.success = true;
                    this.isWrong = false;
                    this.msg = `گزارش ذخیره شد.`;
                    switch (this.flag){
                        case 1:
                            this.router.navigate(['userArea/reportEdit' , `${response.id}`]).then(r => '/404');
                            break;
                        case 2:
                            location.reload();

                            // this.router.navigate(['/userArea/reportNew']).then(r => 500);
                            // this.ngOnInit().then(r => '/404');
                            break;
                        case 3:
                            if (this.addresseeId !== undefined && this.addresseeId != null) {
                                this.router.navigate(['userArea/customer/' + this.addresseeId]).then(r => '/404');
                            }else {
                                this.router.navigate(['/userArea/reportList']).then(r => '/404');
                            }
                    }
                },
                (error: HttpErrorResponse) => {
                    this.status = 1;
                    console.log(error);
                    this.isWrong = true;
                    this.success = false;
                    this.errorMsg = 'دوباره امتحان کنید.';
                    window.scroll(0, 0);
                    if (error.error.message) {
                        this.errorMsg = error.error.message;
                    }
                }
            );
        } else {
            this.success = true;
            this.isWrong = false;
            this.msg = `لطفا صبر کنید!`;
        }
    }


    add_file(e) {
        if ((this.images.length + this.files.length) < 6) {
            const url: string = Config.settings.api + Config.settings.info.file;
            const formData: FormData = new FormData();
            formData.append(
                'file',
                e.target.files.item(0),
                e.target.files.item(0).name
            );
            console.log(formData);
            const headers = {dt: 'yes'};
            this.http.post(url, formData, {headers}).subscribe(
                (res: any) => {
                    debugger;
                    if (res.url.search('.pdf') === -1) {
                        this.images.push(res.url.slice(1));
                    } else {
                        this.files.push(res.url.slice(1));
                    }
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    window.scroll(0, 0);
                }
            );
        } else {
            this.success = false;
            this.isWrong = true;
            this.errorMsg = 'تعداد عکس ها بیشتر از 6 عدد نمی تواند باشد!';
            window.scroll(0, 0);
        }
    }

    remove(i) {
        this.images.splice(i, 1);
    }

    remove_file(i) {
        this.files.splice(i, 1);
    }

    goToLink(url: string, i) {
        const fileUrl = this.files[i];
        window.open(url + fileUrl, "_blank");
    }

    showC(el) {
        return el && el.label;
    }

    addresseeChange(el) {
        this.addressee = null;
        this.addressee = el.id;
    }

    itmeClickHandler(event) {
        console.log(event);
        switch (event.name) {
            case 'cancel':
                if (this.addresseeId !== undefined && this.addresseeId != null) {
                    this.router.navigate(['userArea/customer/' + this.addresseeId]).then(r => '/404');
                }else {
                    this.router.navigate(['/userArea/reportList']).then(r => '/404');
                }
                break;
            case 'save':
                this.flag = 1 ;
                this.register(this.form);
                break;
            case 'saveNew':
                this.flag = 2;
                this.register(this.form);
                break;
            case 'saveClose':
                this.flag = 3;
                this.register(this.form);
                break;
            case 'excel':

        }
    }


    // ngOnDestroy(): void {
    //     localStorage.removeItem("addressee_id");
    // }
}
