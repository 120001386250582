import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import * as Func from "src/pack/js/func";
import { Config } from "src/app/app-config.service";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToRPipe } from "src/app/fa.pipe";
import { ThrowStmt } from "@angular/compiler";
import { DateAdapter } from "@angular/material/core";
import { LoadingService } from "src/app/loading/loading.service";

@Component({
  selector: "app-survey-new",
  templateUrl: "./survey-new.component.html",
  styleUrls: ["./survey-new.component.scss"],
})
export class SurveyNewComponent implements OnInit {
  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;
  sendDate = new Date();
  date = new Date();
  survayDate = new Date();
  purchaseDate = null;
  name: string;
  remittance_number: string;
  imgUrl: string;
  conf = Config;
  parseInt = parseInt;
  order: any;

  get score(): number {
    let res = 0.0;
    this.questions.forEach((el, i) => {
      res += el.value * this.responses[i].answer;
    });

    return res;
  }

  get pscore(): number {
    return this.perfect_score
      ? Number(((this.score * 100.0) / this.perfect_score).toFixed(2))
      : 0;
  }

  get perfect_score(): number {
    let sum = 0;
    this.questions.forEach((el, i) => {
      sum += el.value;
    });

    sum *= 10;

    return sum;
  }

  score_name: string[] = [
    "خیلی ضعیف",
    "ضعیف",
    "متوسط",
    "خوب",
    "بسیار خوب",
    "بسیار خوب",
  ];
  surveys: any[] = [];
  survey: number;

  responses: { question: number; answer: number; detail: string }[] = [];
  questions: any[] = [];
  func = Func;
  math = Math;
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private loading: LoadingService
  ) {}

  id: number;

  toR: ToRPipe = new ToRPipe();

  user;
  buyer: number;
  state: number = 0;
  async ngOnInit(): Promise<void> {
    this.user = await this.auth.get_info();
    this.load();
    this.load_survays();
  }

  load() {
    this.route.params.subscribe((params) => {
      if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
        this.router.navigate(["/404"]);
      } else {
        this.id = parseInt(params.id);
        let shippingApi: string =
          Config.settings.api +
          Config.settings.order.shipping +
          `${this.id}/`;
        this.loading.add();
        this.http.get(shippingApi).subscribe(
          (response: any) => {
            console.log(response);
            this.name =
              response.buyer_first_name + " " + response.buyer_last_name;
            this.date = new Date(response.order_date);
            this.sendDate = new Date(response.sending_date);
            this.survayDate = new Date();
            this.remittance_number = response.remittance_number;
            this.order = response.order;
            this.loading.remove();
            // let orderApi: string =
            //    Config.settings.api +
            //    Config.settings.order.order +
            //    `${response.order}/`;
            // this.http.get(orderApi).subscribe(
            //    (response: any) => {
            //       console.log(response);
            //       this.state = response.state;
            //       this.buyer = response.buyer;

            //    },
            //    (error: HttpErrorResponse) => {
            //       //TODO: server errors
            //       console.log(error);
            //    }
            // );
          },
          (error: HttpErrorResponse) => {
            //TODO: server errors
            this.loading.remove();
            console.log(error);
          }
        );
      }
    });
  }

  load_survays() {
    let evaluationApi: string =
      Config.settings.api + Config.settings.evaluation.evaluation;
    this.loading.add();
    this.http.get(evaluationApi).subscribe(
      (response: any) => {
        response.forEach((el) => {
          if (el.is_active)
            this.surveys.push({
              id: el.id,
              label: el.id.toString(),
            });
        });
        this.changeSurvey(this.surveys[0].id);
        this.loading.remove();
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
        this.loading.remove();
      }
    );
  }

  register(form) {
    let data = form.value;
    data.evaluation = this.survey;
    data.date = this.survayDate;
    data.load_report = this.id;
    data.responses = this.responses;
    data.responses.map((el) => {
      el.answer = parseInt(el.answer);
      return el;
    });
    console.log(data);
    let url =
      Config.settings.api + Config.settings.evaluation.shipping_report;
    console.log(JSON.stringify(data));
    this.loading.add();
    this.http.post(url, data).subscribe(
      (response: any) => {
        console.log("shod");
        this.success = true;
        this.isWrong = false;
        window.scroll(0, 0);
        this.msg = `فرم رضایت‌سنجی ذخیره شد.`;
        this.loading.remove();
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        window.scroll(0, 0);
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
        this.loading.remove();
      }
    );
  }

  changeSurvey(survey: number) {
    console.log(this.surveys);
    console.log(survey);

    this.survey = survey;
    this.responses = [];
    let evaluationApi: string =
      Config.settings.api +
      Config.settings.evaluation.evaluation +
      `${survey}/`;
    this.http.get(evaluationApi).subscribe(
      (response: any) => {
        console.log(response);
        this.questions = response.questions;
        this.questions.forEach((el) => {
          this.add(el.id);
        });
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  add(q = null, a = 0, d = null) {
    this.responses.push({
      question: q,
      answer: a,
      detail: d,
    });
  }

  deny(e): void {
    e.preventDefault();
  }
}
