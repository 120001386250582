<div class="m-3 mt-4">
  <app-title title="لیست پرسنل"></app-title>
  <app-toolbar [toolbarItems]="toolbarItemList" (clickHandler)="itmeClickHandler($event)"></app-toolbar>
  <div class="list my-3">
    <!-- <mat-toolbar>
      <button class="btn btn-outline-dark ml-2" [routerLink]="['new']" type="button"><i class="far fa-plus-square ml-2"></i>جدید</button>
      <button class="btn btn-outline-primary" (click)="load()" type="button"><i class="far fa-sync-alt ml-2"></i>به‌روز رسانی</button>
    </mat-toolbar> -->

    <div class="list-header bg-gray">
      <div class="list-header-item">
        تعداد ردیف: {{ personnels.length }}
      </div>
    </div>
    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">نام</th>
          <th scope="col">نام خانوادگی</th>
          <th scope="col">واحد فعالیت</th>
          <th scope="col" class="d-lg-table-cell d-none">سمت</th>
          <th scope="col" class="d-lg-table-cell d-none">تلفن همراه</th>
          <th scope="col" class="d-lg-table-cell d-none">تلفن ثابت</th>
          <th scope="col" class="d-lg-table-cell d-none">کد پرسنل</th>
          <th scope="col">وضعیت</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let el of personnels; let i = index"
          (dblclick)="edit(el.id)"
        >
          <th scope="row">{{ i + 1 | toFa }}</th>
          <td>{{ el.first_name }}</td>
          <td>{{ el.last_name }}</td>
          <td>{{ el.unit_name }}</td>
          <td class="d-lg-table-cell d-none">{{ el.role_name }}</td>
          <td class="d-lg-table-cell d-none">{{ el.phone_number | toFa }}</td>
          <td class="d-lg-table-cell d-none">{{ el.landing_number | toFa }}</td>
          <td class="d-lg-table-cell d-none">{{ el.personnel_code | toFa }}</td>
          <td>
            <i
              class="fa fa-circle"
              [class]="el.is_active ? 'text-success' : 'text-danger'"
            ></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      آیا از حذف این رکورد اطمینان دارید؟
    </h4>
  </div>
  <div class="modal-body">
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(true)"
    >
      بله
    </button>
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(false)"
    >
      خیر
    </button>
  </div>
</ng-template>
