export function toEn(e) {
  var pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  var en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  var an = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  var cache = e;
  // console.log(e);
  for (var i = 0; i < 10; i++) {
    var regex_fa = new RegExp(pn[i], "g");
    var regex_ar = new RegExp(an[i], "g");
    cache = cache.replace(regex_fa, en[i]);
    cache = cache.replace(regex_ar, en[i]);
  }

  return cache;
}

export function toNumber(e): number {
  var pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  var en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  var an = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  var cache = e;
  for (var i = 0; i < 10; i++) {
    var regex_fa = new RegExp(pn[i], "g");
    var regex_ar = new RegExp(an[i], "g");
    cache = cache.replace(regex_fa, en[i]);
    cache = cache.replace(regex_ar, en[i]);
  }

  var regex_en = new RegExp("/", "g");
  cache = cache.replace(regex_en, "");

  var regex_en = new RegExp("-", "g");
  cache = cache.replace(regex_en, "");
  return parseInt(cache);
}

export function toFa(e) {
  var pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  var en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  var cache = e;
  for (var i = 0; i < 10; i++) {
    var regex_en = new RegExp(en[i], "g");
    cache = cache.replace(regex_en, pn[i]);
  }
  return cache;
}

export function numControl(e) {
  var cache = toEn(e.key);
  if (cache.replace(/[\D]/i, "") == "") e.preventDefault();
}

export function sort(data, key) {
  data.sort((a, b) => (a[key] > b[key] ? 1 : -1));
}

export let order_states: string[] = [
  "در حال ثبت",
  "در انتظار تایید مشتری",
  "در دست بررسی",
  "تایید کارشناس",
  "آماده سازی سفارش",
  "ارسال شده",
  "در حال ارسال",
  "ارجاع به نمایندگی",
  "لغو مشتری",
  "لغو مدیر",
  "لغو کارشناس",
  "تکمیل شده",
  "تایید دریافت نماینده",
  "عدم تایید نماینده",
  "توافق ارجاع",
  "عدم توافق نماینده",
  "تکمیل توسط نماینده",
  "ثبت  توسط نماینده",
  "لغو شده",
  "تکمیل شده",
];

export let cancellation = new Map([
  [0, "عدم تائید سفارش توسط مدیریت"],
  [1, "انصراف مشتری از درخواست خرید"],
  [2, "عدم توافق به دلیل قیمت بالای محصولات"],
  [3, "عدم توافق به دلیل شرایط اعتباری فروش شرکت"],
  [4, "عدم توافق به علت نداشتن سقف اعتبار لازم"],
  [5, "عدم توافق به دلیل حضور سایر نمایندگی شرکت در بازار"],
  [6, "عدم تایید به دلیل داشتن چک برگشتی از مشتری"],
  [7, "سایر موارد"],
  [100, "انصراف از درخواست خرید در حال حاضر"],
  [101, "عدم توافق به دلیل قیمت بالای محصولات"],
  [102, "عدم توافق به دلیل شرایط اعتباری فروش شرکت"],
  [103, "عدم توافق به علت نداشتن سقف اعتبار لازم"],
  [104, "عدم توافق به دلیل حضور سایر نمایندگی شرکت در بازار"],
  [105, "سایر موارد"],
]);

export let account_types = new Map([
  [1, "جاری"],
  [2, "پس‌انداز"],
  [3, "سپرده‌گذاری کوتاه مدت"],
]);

export let check_states = new Map([
  [1, "چک وصول شد"],
  [2, "چک برگشت شد"],
  [3, "چک تعویض شد"],
  [4, "در حال پیگیری"],
  [5,"عودت  شده"],
]);
