import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import { Config } from "src/app/app-config.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {untilDestroyed} from 'ngx-take-until-destroy';
import {debounceTime} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import * as Func from "src/pack/js/func";

@Component({
  selector: 'app-familiarity-type',
  templateUrl: './familiarity-type.component.html',
  styleUrls: ['./familiarity-type.component.scss']
})
export class FamiliarityTypeComponent implements OnInit {

  show: boolean = false;
  func = Func;
  familiarityList: any[] = [];
  orgFamiliarityList: any[] = [];
  searchControl = new FormControl();
  familiarityApi: string = Config.settings.api + "information/familiarity-type/";
  selected: boolean[] = [];

  constructor(
      private router: Router,
      private modalService: NgbModal,
      private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
        .pipe(debounceTime(300), untilDestroyed(this))
        .subscribe((term) => this.search(term));
    this.load();
  }

  load() {
    this.orgFamiliarityList = [];
    this.http.get(this.familiarityApi).subscribe(
        (response: any) => {
          console.log(response);

          response.forEach((element) => {
            this.orgFamiliarityList.push(element);
          });
          this.familiarityList = this.orgFamiliarityList;
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  search(value: string) {
    this.familiarityList = this.orgFamiliarityList.filter((option) =>
        option["name"].includes(value)
    );
  }

  editing_brand;
  edit(content, i) {
    let el = this.familiarityList[i];
    this.editing_brand = {
      id: el.id,
      name: el.name,
    };

    this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then((result) => {
      if (result) {
        this.http
            .patch(
                this.familiarityApi + this.editing_brand.id + "/",
                this.editing_brand
            )
            .subscribe(
                (response: any) => {
                  this.load();
                },
                (error: HttpErrorResponse) => {
                  //TODO: server errors
                  console.log(error);
                }
            );
      }
    });
  }

  del(content, i) {
    if (!i) return;
    this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then((result) => {
      if (result) {
        this.http.delete(this.familiarityApi + i + "/").subscribe(
            (response: any) => {
              this.load();
            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
        );
      }
    });
  }

  familiarity = {
    name: "",
  };

  register(content) {
      this.modalService
          .open(content, {ariaLabelledBy: 'modal-basic-title'})
          .result.then((result) => {
          if (result) {
              this.http.post(this.familiarityApi, this.familiarity).subscribe(
                  (response: any) => {
                      console.log('shod');
                      this.load();
                      this.familiarity = {
                          name: '',
                      };
                      this.show = false;
                      window.scroll(0, 0);
                  },
                  (error: HttpErrorResponse) => {
                      console.log(error);
                  }
              );
          }
      });
  }

  ngOnDestroy() {}

}
