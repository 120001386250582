import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {Config} from '../../../app-config.service';
import {Product} from './Product';
import * as jalaliMoment from 'jalali-moment';

@Component({
    selector: 'app-warehouse-dashboard',
    templateUrl: './warehouse-dashboard.component.html',
    styleUrls: ['./warehouse-dashboard.component.scss']
})
export class WarehouseDashboardComponent implements OnInit {
    id = 0;
    nOrderSum = 0;
    nOrderSumOutPut = 0;
    sheetSum = 0;
    sheetSumOutPut = 0;
    amountSum = 0;
    paidAmountSum = 0;
    product;
    productValue;
    // tslint:disable-next-line:new-parens
    mountNum = Number(jalaliMoment(new Date, 'MM').locale('fa').format('MM'));
    // tslint:disable-next-line:new-parens
    currentYear = jalaliMoment(new Date, 'YYYY').locale('fa').format('YYYY');

    inputWarehouseList: any[] = [];
    outPutWarehouseList: any[] = [];
    stockList: any[] = [];
    isCollapsed = true;
    isFilter = false;
    showStartDate;
    showEndDate;
    stockWarehouse;
    productStock: any[] = [];
    productStockList: any[] = [];
    warehouseNames: string [] = [];
    warehouseStockSum: any [] = [];
    productSum = 0;
    inputWarehouses: any[] = [];
    outPutWarehouses: any[] = [];
    dashboardWarehouseApi: string = Config.settings.api + 'dashboard/warehouse';
    user;
    // tslint:disable-next-line:max-line-length
    currentStartDate = jalaliMoment.from(this.currentYear + '/' + this.mountNum + '/01',
        // tslint:disable-next-line:max-line-length
        'fa', 'YYYY/MM/DD').locale('en').format('YYYY-MM-DD').slice(0, 11) + 'T00:00:00.000Z';

    // tslint:disable-next-line:max-line-length
    currentEndDate = jalaliMoment.from(this.currentYear + '/' + this.mountNum + '/29',
        // tslint:disable-next-line:max-line-length
        'fa', 'YYYY/MM/DD').locale('en').format('YYYY-MM-DD').slice(0, 11) + 'T00:00:00.000Z';

    constructor(
        public auth: AuthService,
        private http: HttpClient,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.user = await this.auth.get_info();
        this.id = this.user.id;
        // tslint:disable-next-line:max-line-length
        await this.loadShowDate(this.currentStartDate, this.currentEndDate);
        this.loadWarehouse(this.dashboardWarehouseApi + `?warehouse_input_start_date=${this.currentStartDate}&warehouse_input_end_date=${this.currentEndDate}&warehouse_output_start_date=${this.currentStartDate}&warehouse_output_end_date=${this.currentEndDate}`);
    }


    async initialDate(form) {
        this.isFilter = true;
        const data = form.value;
        console.log(data);
        let apiWarehouseUrl;
        let apiWarehouse;
        let startDate;
        let endDate;
        if (data.from_date !== '' && data.from_date !== null) {
            startDate = new Date(data.from_date).toISOString();
        }
        if (data.to_date !== '' && data.to_date !== null) {
            endDate = new Date(data.to_date).toISOString();
        }
        if (startDate !== undefined && endDate !== undefined) {
            apiWarehouse = `?warehouse_input_start_date=${startDate}&warehouse_input_end_date=${endDate}&warehouse_output_start_date=${startDate}&warehouse_output_end_date=${endDate}`;
            apiWarehouseUrl = this.dashboardWarehouseApi + apiWarehouse;
        } else if (startDate !== undefined && endDate === undefined) {
            apiWarehouse = `?warehouse_input_start_date=${startDate}&warehouse_output_start_date=${startDate}`;
            apiWarehouseUrl = this.dashboardWarehouseApi + apiWarehouse;
        } else if (endDate !== undefined) {
            apiWarehouse = `?warehouse_input_end_date=${endDate}&warehouse_output_end_date=${endDate}`;
            apiWarehouseUrl = this.dashboardWarehouseApi + apiWarehouse;
        }
        if (apiWarehouseUrl === undefined) {
            apiWarehouseUrl = this.dashboardWarehouseApi + `?warehouse_input_start_date=${this.currentStartDate}&warehouse_input_end_date=${this.currentEndDate}&warehouse_output_start_date=${this.currentStartDate}&warehouse_output_end_date=${this.currentEndDate}`;
        }
        this.removeDate();
        this.loadWarehouse(apiWarehouseUrl);
        await this.loadShowDate(startDate, endDate);

    }

    loadWarehouse(apiUrl) {
        console.log(apiUrl);
        this.http.get(apiUrl).subscribe(
            (response: any) => {
                debugger;
                console.log(response);
                response.warehouse_input_table.forEach((element) => {
                    this.inputWarehouseList.push(element);
                });
                response.warehouse_output_table.forEach((e) => {
                    this.outPutWarehouseList.push(e);
                });
                this.stockWarehouse = response.warehouse_stock_table;

                this.stockWarehouse.warehouse_names.forEach(value => {
                    this.warehouseNames.push(value);
                });

                // tslint:disable-next-line:max-line-length
                this.stockWarehouse.warehouse_stock_sum.forEach(value => {
                    this.warehouseStockSum.push(value);
                    this.productSum = this.productSum + value;
                });
                this.stockWarehouse.product_stock.forEach(value => {
                    this.productStock.push(value);
                });
                this.productStock.forEach(value => {
                    this.product = value;
                    let sumStock = 0;
                    this.product.stock_list.forEach(e => {
                        this.stockList.push(e);
                    });
                    this.stockList.forEach(value1 => {
                        sumStock = sumStock + value1;
                    });
                    // tslint:disable-next-line:max-line-length
                    this.productValue = new Product(value.product_id, value.product_name, this.stockList, sumStock);
                    this.stockList = [];
                    this.productStockList.push(this.productValue);
                });
                console.log(this.productStock);
                this.inputWarehouses = this.inputWarehouseList;
                this.outPutWarehouses = this.outPutWarehouseList;

                this.inputWarehouses.forEach(value => {
                    this.nOrderSum = this.nOrderSum + value.n_orders;
                    this.sheetSum = this.sheetSum + value.sheet_sum;
                });

                this.outPutWarehouses.forEach(value => {
                    // tslint:disable-next-line:max-line-length
                    this.amountSum = this.amountSum + (value.order_amount_sum - value.discount_amount_sum);
                    // tslint:disable-next-line:max-line-length
                    this.nOrderSumOutPut = this.nOrderSumOutPut + value.n_orders;
                    // tslint:disable-next-line:max-line-length
                    this.sheetSumOutPut = this.sheetSumOutPut + value.sheet_sum;
                    // tslint:disable-next-line:max-line-length
                    this.paidAmountSum = this.paidAmountSum + value.paid_amount_sum;
                });

            });
    }

    async loadShowDate(startDate , endDate) {
        if (startDate !== undefined && endDate !== undefined) {
            this.showStartDate = startDate;
            this.showEndDate = endDate ;
        }else if (startDate === undefined && endDate === undefined) {
            this.showStartDate = this.currentStartDate;
            this.showEndDate = this.currentEndDate;
        } else if (startDate === undefined) {
            // tslint:disable-next-line:max-line-length
            this.showStartDate = jalaliMoment.from(this.currentYear + '/' + '01' + '/01',
                // tslint:disable-next-line:max-line-length
                'fa', 'YYYY/MM/DD').locale('en').format('YYYY-MM-DD').slice(0, 11) + 'T00:00:00.000Z';
            this.showEndDate = endDate;
        } else {
            this.showStartDate = startDate;
            this.showEndDate = jalaliMoment.from(this.currentYear + '/' + '12' + '/29',
                // tslint:disable-next-line:max-line-length
                'fa', 'YYYY/MM/DD').locale('en').format('YYYY-MM-DD').slice(0, 11) + 'T00:00:00.000Z';
        }

    }

    removeDate() {
        this.productValue = null;
        this.productStockList = [];
        this.inputWarehouseList = [];
        this.outPutWarehouseList = [];
        this.stockWarehouse = null;
        this.warehouseNames = [];
        this.warehouseStockSum = [];
        this.inputWarehouses = [];
        this.outPutWarehouses = [];
        this.nOrderSum = 0;
        this.sheetSum = 0;
        this.nOrderSumOutPut = 0;
        this.sheetSumOutPut = 0;
        this.amountSum = 0;
        this.paidAmountSum = 0;
        this.productSum = 0;
        this.productStock = [];
        this.product = null;
    }

}
