import {Component, OnInit} from '@angular/core';
import {Config} from "../../../../app-config.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../../auth/auth.service";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormControl} from "@angular/forms";
import * as Func from "../../../../../pack/js/func";
import {debounceTime} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {Subject} from "rxjs";
import {LoadingService} from "../../../../loading/loading.service";


@Component({
  selector: 'app-warehouse-product-order',
  templateUrl: './warehouse-product-order.component.html',
  styleUrls: ['./warehouse-product-order.component.scss']
})
export class WarehouseProductOrderComponent implements OnInit {

  page_number: number = 1;
  object_count: number = 0;
  page_count: number = 0;
  total_input_sheet_sum: number = 0;
  total_input_output_sheet_sum: number = 0;
  total_output_sheet_sum: number = 0;
  page_input_sheet_sum: number = 0;
  page__output_sheet_sum: number = 0;

  usersApi: string = Config.settings.api + Config.settings.user.user;

  isCollapsed = true;

  warehouseStock: any[] = [];
  orgWarehouseStock: any[] = [];
  searchControl = new FormControl();
  selected: boolean[] = [];
  warehouseStockApi: string = Config.settings.api + "warehouse/warehouse-stock";
  province_change: Subject<void> = new Subject<void>();
  func = Func;
  searchTerm: string;
  sheet_sum: number = 0;
  page_sheet_sum: number = 0;
  page_size: any;
  is_today: boolean;

  filter_items = [
    {
      name: "warehouse_id",
      label: "عنوان انبار فروش",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "loadWarehouse_responsible",
      label: "مسئول انبار",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "product_id",
      label: "نام محصول",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "product_type_id",
      label: "نوع محصول",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "product_brand_id",
      label: "برند محصول",
      type: "list",
      options: [],
      res: null,
    },

    {
      name: "product_degree_id",
      label: "درجه‌ی محصول",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "product_thickness_id",
      label: "ضخامت محصول",
      type: "list",
      options: [],
      res: null,
    },
    {
      name: "product_dimension_id",
      label: "ابعاد محصول",
      type: "list",
      options: [],
      res: null,
    }
  ];

  constructor(
      private auth: AuthService,
      private router: Router,
      private http: HttpClient,
      private modalService: NgbModal,
      private route: ActivatedRoute,
      private loading: LoadingService
  ) {
  }

  can_del = true;
  user;

  async ngOnInit(): Promise<void> {
    this.user = await this.auth.get_info();

    this.route.queryParamMap.subscribe((f) => {
      let filter = {};
      f.keys.forEach((k) => (filter[k] = f.get(k)));

      this.filter_items.forEach(
          (el) => (el.res = filter[el.name] ? filter[el.name] : null)
      );
      this.search(filter);
    });

    this.searchControl.valueChanges
        .pipe(debounceTime(300), untilDestroyed(this))
        .subscribe((term) => this.search(term));




    this.loadProduct();
    this.loadProductType();
    this.loadProductBrand();
    this.loadProductDegree();
    this.loadProductThickness();
    this.loadProductDimension();

    this.loadWarehouse_responsible();
    this.loadWarehouse();
    //
    // this.load_pro();
    // this.load_reg();

    // this.changeProvince();
    // this.province_change.subscribe((pro) => this.changeProvince(pro));

  }

  disable_load = false;

  load(page = 1) {
    if (this.disable_load) return;
    this.disable_load = true;
    this.loading.add();

    console.log(this.warehouseStockApi);

    this.orgWarehouseStock = [];
    this.http
        .get(
            this.warehouseStockApi +
            `?page=${page}&${this.searchTerm}&ordering=${this.sortcol}`
        )
        .subscribe(
            (response: any) => {
              console.log(response);
              this.page_count = response.page_count;
              this.object_count = response.object_count;
              this.page_number = response.page_number;
              this.page_size = response.page_size;
              this.total_input_sheet_sum = response.total_input_sheet_sum;
              this.page_input_sheet_sum = response.page_input_sheet_sum;
              this.page__output_sheet_sum = response.page_output_sheet_sum;
              this.total_output_sheet_sum = response.total_output_sheet_sum;
              this.total_input_output_sheet_sum = response.total_input_sheet_sum - response.total_output_sheet_sum;
              //
              response.results.forEach((element) => {
                this.orgWarehouseStock.push(element);
              });
              this.warehouseStock = this.orgWarehouseStock;

              this.loading.remove();
              this.disable_load = false;
            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
              this.orgWarehouseStock = [];
              this.warehouseStock = [];
              this.page_count = 0;
              this.page_number = 0;
              this.object_count = 0;
              this.loading.remove();

              this.disable_load = false;
            }
        );
  }

  loadProduct() {
    let tc = [{id: null, label: "همه"}];

    this.http.get(Config.settings.api + "information/product").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.name)
              tc.push({
                id: element.id,
                label: element.name,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "product_id") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  loadProductType() {
    let tc = [{id: null, label: "همه"}];

    this.http.get(Config.settings.api + "information/product-type").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.name)
              tc.push({
                id: element.id,
                label: element.name,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "product_type_id") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  loadProductBrand() {
    let tc = [{id: null, label: "همه"}];

    this.http.get(Config.settings.api + "information/product-brand").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.name)
              tc.push({
                id: element.id,
                label: element.name,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "product_brand_id") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  loadProductDegree() {
    let tc = [{id: null, label: "همه"}];

    this.http.get(Config.settings.api + "information/product-degree").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.degree)
              tc.push({
                id: element.id,
                label: element.degree,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "product_degree_id") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  loadProductThickness() {
    let tc = [{id: null, label: "همه"}];

    this.http.get(Config.settings.api + "information/product-thickness").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.thickness)
              tc.push({
                id: element.id,
                label: element.thickness,
              });
          });
          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "product_thickness_id") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  loadProductDimension() {
    let tc = [{id: null, label: "همه"}];

    this.http.get(Config.settings.api + "information/product-dimension").subscribe(
        (response: any) => {
          response.forEach((element) => {
            if (element.length )
              tc.push({
                id: element.id,
                label: element.length + ' * '  + element.width
              });
          });

          // this.filter_items[4].options = tc;
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == "product_dimension_id") ret.options = tc;
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          //TODO: server errors
          console.log(error);
        }
    );
  }

  //
  // async load_pro() {
  //   let tc = [{id: null, label: "همه"}];
  //   // let tc = [{ id: null, label: 'همه' }];
  //
  //   let provinceApi: string =
  //       Config.settings.api + Config.settings.info.province;
  //   this.http.get(provinceApi).subscribe(
  //       (response: any) => {
  //         response.forEach((element) => {
  //           tc.push({
  //             id: element.id,
  //             label: element.name,
  //           });
  //         });
  //         this.filter_items.map((el) => {
  //           let ret = el;
  //           if (el.name == "province_id") ret.options = tc;
  //           return ret;
  //         });
  //       },
  //       (error: HttpErrorResponse) => {
  //         //TODO: server errors
  //         console.log(error);
  //       }
  //   );
  // }

  loadWarehouse_responsible() {
    let tc = [{id: null, label: 'همه'}];
    let api: string =
        Config.settings.api +"user/user/?role=4";
    this.http.get(api).subscribe(
        (response: any) => {
          response.forEach((element) => {
            tc.push({
              id: element.id,
              label: `${element.first_name} ${element.last_name}`,
            });
          });
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == 'warehouse_responsible_id') {
              ret.options = tc;
            }
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
    );
  }

  loadWarehouse() {
    //
    let tc = [{id: null, label: 'همه'}];
    let warehouseApi: string =
        Config.settings.api + 'warehouse/warehouse/';
    this.http.get(warehouseApi).subscribe(
        (response: any) => {
          response.forEach((element) => {
            tc.push({
              id: element.id,
              label: element.name,
            });
          });
          this.filter_items.map((el) => {
            let ret = el;
            if (el.name == 'warehouse_id') {
              ret.options = tc;
            }
            return ret;
          });
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
    );
  }
  //
  // async load_reg() {
  //   let tc = [{id: null, label: "همه"}];
  //   // let tc = [{ id: null, label: 'همه' }];
  //
  //   let regionApi: string =
  //       Config.settings.api + Config.settings.add.region;
  //   this.http.get(regionApi).subscribe(
  //       (response: any) => {
  //         response.forEach((element) => {
  //           tc.push({
  //             id: element.id,
  //             label: element.name,
  //           });
  //         });
  //         this.filter_items.map((el) => {
  //           let ret = el;
  //           if (el.name == "region_id") ret.options = tc;
  //           return ret;
  //         });
  //       },
  //       (error: HttpErrorResponse) => {
  //         //TODO: server errors
  //         console.log(error);
  //       }
  //   );
  // }

  province_id = null;

  changeProvince($event = null) {
    // this.province_id = $event;
    //
    // let tc = [{ id: null, label: "همه" }];
    // // let tc = [{ id: null, label: 'همه' }];
    // if (this.province_id == null) {
    //     this.filter_items.map((el) => {
    //         let ret = el;
    //         if (el.name == "city") ret.options = tc;
    //         if (el.name == "city") ret.res = null;
    //         return ret;
    //     });
    //     return;
    // }
    //
    // let cityApi: string =
    //     Config.settings.api +
    //     Config.settings.add.citys +
    //     `?province=${$event}`;
    // this.http.get(cityApi).subscribe(
    //     (response: any) => {
    //         response.forEach((element) => {
    //             tc.push({
    //                 id: element.id,
    //                 label: element.name,
    //             });
    //         });
    //         this.filter_items.map((el) => {
    //             let ret = el;
    //             if (el.name == "city") ret.options = tc;
    //             return ret;
    //         });
    //     },
    //     (error: HttpErrorResponse) => {
    //         //TODO: server errors
    //         console.log(error);
    //     }
    // );
    console.log("salam");
  }


  search(filter: any) {
    console.log(filter);

    this.searchTerm = "";
    Object.keys(filter).forEach((el) => {
      this.searchTerm += el + "=" + filter[el] + "&";
      // console.log(el);
    });

    if (this.searchTerm != "")
      this.searchTerm = this.searchTerm.slice(0, -1);

    let qp = filter;
    // qp['q'] = this.searchTerm;

    this.router.navigate([], {
      queryParams: qp,
      // queryParamsHandling: "merge",
    });
    this.load();
    this.isCollapsed = true;
  }



  sortcol: string = null;

  sort(col: string) {
    if (this.sortcol == col) this.sortcol = "-" + col;
    else this.sortcol = col;
    this.load();
  }

  ngOnDestroy() {
  }
}

