import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Config} from "../../../app-config.service";
import {map, startWith} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {FormControl} from "@angular/forms";

@Component({
   selector: 'app-filter',
   templateUrl: './filter.component.html',
   styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
   @Input() isCollapsed: boolean = true;
   // tslint:disable-next-line:variable-name
   @Input() filter_items: any[] = [];
   @Output() changeFilter = new EventEmitter();
   date = new Date();
   users: any[] = [];
   userList: any[] = [];
   filteredUsers;
   control = new FormControl();


   constructor(private http: HttpClient) {}

   ngOnInit(): void {}

   submit(form) {
      let q = {};
      this.filter_items.forEach((el) => {
         if (!this.is_empty(el.res)) {
            // tslint:disable-next-line:max-line-length
            if (el.type === 'date') {
               q[el.name] = new Date(el.res).toISOString();
            } else  if (el.name === 'addressee_id') {
               if (el.res.id !== null) {
                  q[el.name] = el.res.id;
               }
            } else {
               q[el.name] = el.res;
            }
         }
      });
      this.changeFilter.next(q);
   }

   is_empty(x) {
      return x == null || x == 'null' || x == '' || x == undefined;
   }

   changeUser(value , name){
         if (name === 'addressee_type_id') {
            this.userList = [];
            this.filteredUsers = null;
            let addresseeUrl = Config.settings.api;
            const addresseeId = value;
            switch (addresseeId) {
               case '1':
                  addresseeUrl = addresseeUrl + 'user/user/?q=2';
                  break;
               case '2':
                  addresseeUrl = addresseeUrl + 'user/user/?role=2';
                  break;
               case '3':
                  addresseeUrl = addresseeUrl + 'user/user/?role=3';
                  break;
               case '4':
                  addresseeUrl = addresseeUrl + 'user/user/?role=4';
                  break;
            }
            this.http.get(addresseeUrl).subscribe(
                (response: any) => {
                   const tc = [{id: null, label: 'همه'}];
                   response.forEach((element) => {
                      if (addresseeId === '1') {
                         tc.push({
                            id: element.id,
                            // tslint:disable-next-line:max-line-length
                            label: element.first_name + ' ' + element.last_name,
                         });
                      } else if (addresseeId === '2')  {
                         if (element.company_name !== '') {
                            tc.push({
                               id: element.id,
                               label: element.company_name,
                            });
                         } else {
                            tc.push({
                               id: element.id,
                               label: element.first_name + ' ' + element.last_name,
                            });
                         }
                      } else {
                         tc.push({
                            id: element.id,
                            label: element.company_name,
                         });
                      }
                   });
                   this.userList = tc;
                   this.filteredUsers = this.control.valueChanges.pipe(
                       startWith(""),
                       map((value) => this._filterP(value))
                   );
                },
                (error: HttpErrorResponse) => {
                   console.log(error);
                }
            );
         }
   }

   private _filterP(value: string): string[] {
      const filterValue = value;

      return this.userList.filter((option) =>
          option.label.includes(filterValue)
      );
   }

   showC(el) {
      return el && el.label;
   }
}
