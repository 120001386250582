export class Nature {

    private id;
    private name: string = null;
    private numberList: any[] = [];

    constructor(id, name: string, numberList: any[]) {
        this.id = id;
        this.name = name;
        this.numberList = numberList;
    }
}