import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Config } from "src/app/app-config.service";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";
import * as Func from "src/pack/js/func";
import { LoadingService } from "src/app/loading/loading.service";

@Component({
  selector: "app-survey-list",
  templateUrl: "./survey-list.component.html",
  styleUrls: ["./survey-list.component.scss"],
})
export class SurveyListComponent implements OnInit {
  page_number: number = 1;
  object_count: number = 0;
  page_count: number = 0;
  page_size: number;

  func = Func;
  reports: any[] = [];
  orgReports: any[] = [];
  searchControl = new FormControl();
  selected: boolean[] = [];
  reportsApi: string =
    Config.settings.api + Config.settings.evaluation.shipping_report;

  constructor(
    private router: Router,
    private http: HttpClient,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((term) => this.search(term));
    this.load();
  }

  load(page = 1) {
    this.orgReports = [];
    this.loading.add();
    this.http.get(this.reportsApi + `?page=${page}`).subscribe(
      (response: any) => {
        this.page_count = response.page_count;
        this.object_count = response.object_count;
        this.page_number = response.page_number;
        this.page_size = response.page_size;

        response.results.forEach((element) => {
          this.orgReports.push(element);
        });
        this.reports = this.orgReports;
        console.log(this.reports);
        this.loading.remove();
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
        this.loading.remove();
      }
    );
  }

  search(value: string) {
    value = Func.toEn(value);
    this.reports = this.orgReports.filter(
      (option) =>
        option["load_report"].toString().includes(value) ||
        option["company_name"].includes(value)
    );
  }

  edit(i = null) {
    if (i) {
      this.router.navigate(["/userArea/evaluation/", i]);
    }
    this.selected.forEach((el, i) => {
      if (el) {
        this.router.navigate(["/userArea/evaluation/", i]);
      }
    });
  }

  del() {
    this.selected.forEach((el, i) => {
      if (el) {
        this.http.delete(this.reportsApi + i + "/").subscribe(
          (response: any) => {
            this.load();
          },
          (error: HttpErrorResponse) => {
            //TODO: server errors
            console.log(error);
          }
        );
      }
    });
  }

  update(el) {
    let url =
      Config.settings.api + Config.settings.product.product + `${el.id}/`;
    let data = {
      is_active: el.status,
    };
    this.http.patch(url, data).subscribe(
      (response: any) => {
        console.log("shod");
      },
      (error: HttpErrorResponse) => {}
    );
  }

  ngOnDestroy() {}
}
