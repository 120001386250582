import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Config } from "src/app/app-config.service";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";
import * as Func from "src/pack/js/func";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-activity-type",
  templateUrl: "./activity-type.component.html",
  styleUrls: ["./activity-type.component.scss"],
})
export class ActivityTypeComponent implements OnInit {
  show: boolean = false;
  func = Func;
  types: any[] = [];
  orgtypes: any[] = [];
  searchControl = new FormControl();
  typesApi: string = Config.settings.api + "information/activity-type/";
  selected: boolean[] = [];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((term) => this.search(term));
    this.load();
  }

  load() {
    this.orgtypes = [];
    this.http.get(this.typesApi).subscribe(
      (response: any) => {
        console.log(response);

        response.forEach((element) => {
          this.orgtypes.push(element);
        });
        this.types = this.orgtypes;
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  search(value: string) {
    this.types = this.orgtypes.filter((option) =>
      option["name"].includes(value)
    );
  }

  editing_type;
  edit(content, i) {
    let el = this.types[i];
    this.editing_type = {
      id: el.id,
      name: el.name,
    };

    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http
            .patch(
              this.typesApi + this.editing_type.id + "/",
              this.editing_type
            )
            .subscribe(
              (response: any) => {
                this.load();
              },
              (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
              }
            );
        }
      });
  }

  del(content, i) {
    if (!i) return;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then((result) => {
        if (result) {
          this.http.delete(this.typesApi + i + "/").subscribe(
            (response: any) => {
              this.load();
            },
            (error: HttpErrorResponse) => {
              //TODO: server errors
              console.log(error);
            }
          );
        }
      });
  }

  type = {
    name: "",
  };

  register(content) {
    this.modalService
        .open(content, {ariaLabelledBy: 'modal-basic-title'})
        .result.then((result) => {
      if (result) {
        this.http.post(this.typesApi, this.type).subscribe(
            (response: any) => {
              console.log('shod');
              this.load();
              this.type = {
                name: "",
              };
              this.show = false;
              window.scroll(0, 0);
            },
            (error: HttpErrorResponse) => {
              console.log(error);
            }
        );
      }
    });
  }
  ngOnDestroy() {}
}
