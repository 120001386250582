import { Component, OnInit } from '@angular/core';
import * as Func from '../../../../../pack/js/func';
import {Config} from '../../../../app-config.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';

@Component({
  selector: 'app-product-new',
  templateUrl: './product-new.component.html',
  styleUrls: ['./product-new.component.scss']
})
export class ProductNewComponent implements OnInit {
    isWrong: boolean;
    errorMsg: string;
    success: boolean;
    msg: string;

    func = Func;
  unit_of_measures = [];
  brands = [];
  types = [];
  degrees = [];
  dimensions = [];
  suppliers = [];
  thicknesses = [];
  productsApi: string =
      Config.settings.api + Config.settings.product.product;
  product = {
    brand: 1,
    degree: 1,
    name: "",
    code: "",
    number_in_pallet: "",
    weight: 1,
    supplier: 1,
    thickness: 1,
    type: 1,
    unit: 1,
    dimension: 1,
  };
  constructor(
      private router: Router,
      private modalService: NgbModal,
      private http: HttpClient,
      public loc: Location
) {}

  ngOnInit(): void {
      this.load();
  }

  load(){
      let brandApi: string =
          Config.settings.api + Config.settings.product.brand;
      this.http.get(brandApi).subscribe(
          (response: any) => {
              response.forEach((element) => {
                  this.brands.push({
                      id: element.id,
                      label: element.name,
                  });
              });
          },
          (error: HttpErrorResponse) => {
              console.log(error);
          }
      );
      let typeApi: string =
          Config.settings.api + Config.settings.product.type;
      this.http.get(typeApi).subscribe(
          (response: any) => {
              response.forEach((element) => {
                  this.types.push({
                      id: element.id,
                      label: element.name,
                  });
              });
          },
          (error: HttpErrorResponse) => {
              console.log(error);
          }
      );

      let degreeApi: string =
          Config.settings.api + Config.settings.product.degree;
      this.http.get(degreeApi).subscribe(
          (response: any) => {
              response.forEach((element) => {
                  this.degrees.push({
                      id: element.id,
                      label: element.degree,
                  });
              });
          },
          (error: HttpErrorResponse) => {
              console.log(error);
          }
      );

      let dimensionApi: string =
          Config.settings.api + Config.settings.product.dimension;
      this.http.get(dimensionApi).subscribe(
          (response: any) => {
              response.forEach((element) => {
                  this.dimensions.push({
                      id: element.id,
                      label: element.length + "×" + element.width,
                  });
              });
          },
          (error: HttpErrorResponse) => {
              console.log(error);
          }
      );

      let supplierApi: string =
          Config.settings.api + Config.settings.product.supplier;
      this.http.get(supplierApi).subscribe(
          (response: any) => {
              response.forEach((element) => {
                  this.suppliers.push({
                      id: element.id,
                      label: element.name,
                  });
              });
          },
          (error: HttpErrorResponse) => {
              console.log(error);
          }
      );

      let thicknessApi: string =
          Config.settings.api + Config.settings.product.thickness;
      this.http.get(thicknessApi).subscribe(
          (response: any) => {
              response.forEach((element) => {
                  this.thicknesses.push({
                      id: element.id,
                      label: element.thickness,
                  });
              });
          },
          (error: HttpErrorResponse) => {
              console.log(error);
          }
      );

      let unitApi: string =
          Config.settings.api + Config.settings.product.unit;
      this.http.get(unitApi).subscribe(
          (response: any) => {
              console.log(response);
              response.forEach((element) => {
                  this.unit_of_measures.push({
                      id: element.id,
                      label: element.unit,
                  });
              });
          },
          (error: HttpErrorResponse) => {
              console.log(error);
          }
      );
  }
  register() {
    this.http.post(this.productsApi, this.product).subscribe(
        (response: any) => {
          this.product = {
            brand: 1,
            degree: 1,
            name: "",
            code: "",
            number_in_pallet: "",
            weight: 1,
            supplier: 1,
            thickness: 1,
            type: 1,
            unit: 1,
            dimension: 1,
          };
          this.isWrong = false;
          this.success = true;
          this.msg = "عملیات با موفقیت انجام شد";
          this.load();
        },
        (error: HttpErrorResponse) => {
            this.success = false;
            this.isWrong = true;
            this.errorMsg = "لطفا دوباره امتحان کنید";
            this.load();
            console.log(error);
        }
    );
  }
}
