<div class="w-100">
  <div *ngIf="isWrong" class="alert" role="alert">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-exclamation-circle ml-1"></i>
      {{ errorMsg }}
    </div>
  </div>
  <form #form="ngForm" (ngSubmit)="register(form)">
    <!-- <div class="row my-3">
      <div class="d-flex align-items-center mx-2 mr-0">
        <label class="m-0 mx-2" for="natural">ثبت‌نام اشخاص حقیقی</label>
        <input
          type="radio"
          id="natural"
          [(ngModel)]="lstate"
          value="natural"
          name="lstate"
          [checked]="true"
        />
      </div>
      <div class="d-flex align-items-center mx-2">
        <label class="m-0 mx-2" for="juridical">حقوقی</label>
        <input
          type="radio"
          id="juridical"
          value="juridical"
          [(ngModel)]="lstate"
          name="lstate"
        />
      </div>
    </div> -->

    <div class="m-0 mt-3 mb-2 d-flex justify-content-around w-100">
      <div
        class="w-50 p-1 ml-1 text-center text-white cursor-pointer signup-bg-gray-light"
        [class.signup-bg-gray]="lstate === 'natural'"
        (click)="lstate = 'natural'"
      >
        حقیقی
      </div>
      <div
        class="w-50 p-1 mr-1 text-center text-white cursor-pointer signup-bg-gray-light"
        [class.signup-bg-gray]="lstate === 'juridical'"
        (click)="lstate = 'juridical'"
      >
        حقوقی
      </div>
    </div>

    <div *ngIf="lstate === 'juridical'" class="row">
      <div class="col">
        <label class="mx-1 lead"> نام شرکت </label>
        <input
          type="text"
          ngModel
          name="companyName"
          class="w-100 form-control form-control-lg"
          required
        />
      </div>
    </div>
    <div class="row my-3">
      <div class="col-md-5 px-3">
        <label class="mx-1 lead"> نام </label>
        <input
          type="text"
          ngModel
          name="firstname"
          class="w-100 form-control form-control-lg"
          required
        />
      </div>
      <div class="col-md-7 pr-md-0 px-3">
        <label class="mx-1 lead"> نام‌خانوادگی </label>
        <input
          type="text"
          ngModel
          name="lastname"
          class="w-100 form-control form-control-lg"
          required
        />
      </div>
    </div>

    <div class="row my-3">
      <div class="col">
        <label class="mx-1 lead"> تلفن همراه </label>
        <input
          type="text"
          ngModel
          name="phone"
          class="w-100 form-control form-control-lg"
          required
        />
      </div>
    </div>
    <div class="row my-3">
      <div class="col">
        <label class="mx-1 lead"> استان </label>
        <select (ngModelChange)="changeProvince($event)"
                name="province"
                [ngModel]="province"
                class="custom-select custom-select-lg"
         >
          <option *ngFor="let el of provinces" [ngValue]="el.id">
            {{ el.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="row my-3">
      <div class="col">
        <label class="mx-1 lead"> شهرستان </label>

        <select
          name="state"
          [ngModel]="state"
          class="custom-select custom-select-lg"
        >
          <option *ngFor="let el of states" [ngValue]="el.id">
            {{ el.label }}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="!isValidating" class="row">
      <div class="col">
        <button type="submit" class="btn btn-lg btn-success btn-block">
          ارسال کد تایید
        </button>
      </div>
    </div>
    <div *ngIf="isValidating" class="row">
      <div class="col">
        <input
          type="text"
          ngModel
          name="validationCode"
          class="w-100 form-control form-control-lg mb-3"
          [ngClass]="{ 'd-none': !isValidating }"
        />
        <label
          *ngIf="isValidating"
          placeholder="کد تایید"
          alt="کد تایید"
        ></label>
      </div>
    </div>
    <input
      type="hidden"
      class="d-none"
      [(ngModel)]="trackingCode"
      name="trackingCode"
    />
    <div *ngIf="isValidating" class="row">
      <div class="col">
        <button type="submit" class="btn btn-lg btn-success btn-block">
          ارسال درخواست
        </button>
      </div>
    </div>
  </form>
</div>
