<div class="m-3 mt-4">
    <ngb-tabset [destroyOnHide]="true">
        <ngb-tab title="وضعیت گزارش">
            <ng-template ngbTabContent>
                <app-report-state></app-report-state>
            </ng-template>/
        </ngb-tab>
        <ngb-tab title="نوع مخاطب گزارش">
            <ng-template ngbTabContent>
                <app-report-addressee-type></app-report-addressee-type>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="نوع گزارش">
            <ng-template ngbTabContent>
                <app-report-type></app-report-type>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="نوع فعالیت گزارش">
            <ng-template ngbTabContent>
                <app-report-activity-type></app-report-activity-type>
<!--                <app-activity-industry></app-activity-industry>-->
            </ng-template>
        </ngb-tab>
        <ngb-tab title="نوع اقدام گزارش">
            <ng-template ngbTabContent>
                <app-report-action-type></app-report-action-type>
<!--                <app-activity-product></app-activity-product>-->
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>
