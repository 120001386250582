import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Config } from "src/app/app-config.service";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";
import * as Func from "src/pack/js/func";
import { LoadingService } from "src/app/loading/loading.service";

@Component({
  selector: "app-survey-form-list",
  templateUrl: "./survey-form-list.component.html",
  styleUrls: ["./survey-form-list.component.scss"],
})
export class SurveyFormListComponent implements OnInit {
  func = Func;
  surveys: any[] = [];
  orgSurveys: any[] = [];
  searchControl = new FormControl();
  selected: boolean[] = [];
  surveysApi: string =
    Config.settings.api + Config.settings.evaluation.evaluation;

  constructor(
    private router: Router,
    private http: HttpClient,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((term) => this.search(term));
    this.load();
  }

  load() {
    this.orgSurveys = [];
    this.loading.add();

    this.http.get(this.surveysApi).subscribe(
      (response: any) => {
        console.log(response);

        response.forEach((element) => {
          this.orgSurveys.push({
            id: element.id,
            qcount: element.questions.length,
            load_count: element.evaluation_load_reports?.length,
            type: element.type_name,
            date: element.date,
            total_score: element.total_score,
            status: element.is_active,
          });
        });
        this.surveys = this.orgSurveys;
        console.log(this.surveys);
        this.loading.remove();
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
        this.loading.remove();
      }
    );
  }

  search(value: string) {
    value = Func.toEn(value);
    this.surveys = this.orgSurveys.filter((option) =>
      option["id"].includes(value)
    );
  }

  edit(i = null) {
    if (i) {
      this.router.navigate(["/userArea/survey/", i]);
    }
    this.selected.forEach((el, i) => {
      if (el) {
        this.router.navigate(["/userArea/survey/", i]);
      }
    });
  }

  del(id) {
    this.http.delete(this.surveysApi + id + "/").subscribe(
      (response: any) => {
        this.load();
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  update(el) {
    let url =
      Config.settings.api + Config.settings.product.product + `${el.id}/`;
    let data = {
      is_active: el.status,
    };
    this.http.patch(url, data).subscribe(
      (response: any) => {
        console.log("shod");
      },
      (error: HttpErrorResponse) => {}
    );
  }

  ngOnDestroy() {}
}
