<div class="m-3 mt-4">
  <!-- <app-title title="لیست آدرس‌های پستی"></app-title> -->
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
    </div>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">عنوان</th>
          <th scope="col">شهر</th>
          <th scope="col">آدرس پستی</th>
          <th scope="col">کد پستی</th>
          <th scope="col">متراژ</th>
          <th scope="col">وضعیت ملکی</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of addresses; let i = index">
          <th scope="row">
            {{ i + 1 | toFa }}
          </th>
          <td>{{ el.address_title_name }}</td>
          <td>{{ el.city }}</td>
          <td>{{ el.address }}</td>
          <td>{{ el.postal_code | toFa }}</td>
          <td>{{ el.area_name }}</td>
          <td>{{ el.property_status_name }}</td>
          <td class="cursor-pointer" (click)="edit(editcontent, i)">
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-title
    (click)="show = !show"
    [attr.aria-expanded]="show"
    aria-controls="collapse"
    [class.open]="show"
    type="plus"
    title="اضافه کردن"
    class="cursor-pointer"
  ></app-title>

  <div id="collapse" [ngbCollapse]="!show" class="box">
    <div class="row">
      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="address_titles"
          placeholder="عنوان"
          (selectChange)="address.address_title = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <label>شهر</label>
        <input
          type="text"
          [(ngModel)]="address.city"
          name="city"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="areas"
          placeholder="متراژ"
          (selectChange)="address.area = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="property_statuses"
          placeholder="وضعیت ملکی"
          (selectChange)="address.property_status = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <label>کد پستی</label>
        <input
          type="text"
          [(ngModel)]="address.postal_code"
          name="postal_code"
          (keydown)="func.numControl($event)"
          class="form-control form-control-lg"
          maxlength="11"
          required
        />
      </div>

      <div class="form-group col-md-9 col-12">
        <label>آدرس</label>
        <input
          type="text"
          [(ngModel)]="address.address"
          name="address"
          class="form-control form-control-lg"
          required
        />
      </div>
      <div class="row w-100 mb-3">
        <button
          (click)="register()"
          class="btn mr-auto ml-3 w-25 btn-success"
        >
          ثبت
        </button>
      </div>
    </div>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          آیا از حذف این رکورد اطمینان دارید؟
        </h4>
      </div>
      <div class="modal-body">
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(true)"
        >
          بله
        </button>
        <button
          type="button"
          class="btn btn-outline-dark mx-2"
          (click)="modal.close(false)"
        >
          خیر
        </button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="address_titles"
          placeholder="عنوان"
          [key]="editing_address.address_title"
          (selectChange)="editing_address.address_title = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <label>شهر</label>
        <input
          type="text"
          [(ngModel)]="editing_address.city"
          name="city"
          class="form-control form-control-lg"
          required
        />
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="areas"
          placeholder="متراژ"
          [key]="editing_address.area"
          (selectChange)="editing_address.area = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <app-select
          [options]="property_statuses"
          placeholder="وضعیت ملکی"
          [key]="editing_address.property_status"
          (selectChange)="editing_address.property_status = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-3 col-12">
        <label>کد پستی</label>
        <input
          type="text"
          [(ngModel)]="editing_address.postal_code"
          name="postal_code"
          (keydown)="func.numControl($event)"
          class="form-control form-control-lg"
          maxlength="11"
          required
        />
      </div>

      <div class="form-group col-md-9 col-12">
        <label>آدرس</label>
        <input
          type="text"
          [(ngModel)]="editing_address.address"
          name="address"
          class="form-control form-control-lg"
          required
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(true)"
      >
        تایید
      </button>
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>
