<div class="m-3 mt-4">
    <app-title title="لیست انبار های استانی"></app-title>
        <div class="list my-3">
            <div class="list-header bg-gray">
                <div class="list-header-item cursor-pointer"
                     aria-controls="collapse"
                     type="plus"
                     title="اضافه کردن"
                     (click)="openDialog()"
                >
                    <i class="far fa-plus-square"></i>جدید
                </div>
                <div class="list-header-item">
                    <i class="far fa-sync-alt"></i>به‌روز رسانی
                </div>
                <div class="list-header-item">تعداد ردیف:
                    {{ warehouses.length }}
                </div>
                <div class="list-header-item mr-auto">
                    <i class="far fa-file-search"></i
                    ><input
                        type="text"
                        class="form-control"
                        [formControl]="searchControl"
                />
                </div>
            </div>
            <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col" (click)="func.sort(warehouses, 'city_name')">
                        نام شهر
                    </th>
                    <th scope="col" (click)="func.sort(warehouses, 'name')">
                        عنوان انبار
                    </th>

                    <th
                            scope="col"
                            (click)="func.sort(warehouses, 'responsible_company_name')"
                    >
                        مسئول انبار
                    </th>

                    <th
                            scope="col"

                    >
                        تاریخ ایجاد
                    </th>
                    <th scope="col">ویرایش</th>
                    <th scope="col">x</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let el of warehouses; let i = index">
                    <th scope="row">{{ i + 1 | toFa }}</th>
                    <!-- <td>{{ i + 1 | toFa }}</td> -->

                    <td>
                        {{ el.city_name }}
                    </td>
                    <td>
                        {{ el.name }}
                    </td>
                    <td>
                        {{ el.responsible_fullName }}
                    </td>
                    <td>
                        {{ el.creation_date | jDate | toFa }}
                    </td>
                    <td class="cursor-pointer" >
                        <i class="fal fa-edit"></i>
                    </td>
                    <td class="cursor-pointer" >
                        <i class="fal fa-trash-alt"></i>
                    </td>
                </tr>
                </tbody>
            </table>
            <div *ngIf="warehouses.length==0"
                 style="width: 100%;
    height: 30px;
    text-align: center;padding-top: 7px;">
                <h5>
                    اطلاعاتی یافت نشد
                </h5>

            </div>
        </div>


</div>
