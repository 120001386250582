
<div class="m-3 mt-4">
    <app-title title="پرونده مشتری / مخاطب"></app-title>
     <div style="margin-top: 10px"></div>
    <ngb-tabset id="customers-tab" [destroyOnHide]="true">
        <ngb-tab title="پرونده مشتری">
            <ng-template ngbTabContent>
                <app-customer-profile class="mt-1" [id]="user_id"></app-customer-profile>
            </ng-template>
        </ngb-tab>
         <ngb-tab title="سفارشات">
            <ng-template ngbTabContent>
                <app-order-list [customer_id]="user_id" [is_title]="false"></app-order-list>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="محصولات">
            <ng-template ngbTabContent>
                <app-product-order [customer_id]="user_id"></app-product-order>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="ارزیابی رضایت">
            <ng-template ngbTabContent>
<!--                <app-survey-list></app-survey-list>-->
            </ng-template>
        </ngb-tab>
        <ngb-tab title="گزارشات">
            <ng-template ngbTabContent>
                <app-report-list [addressee_id]="user_id"></app-report-list>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="زیرمجموعه ها">
            <ng-template ngbTabContent>
<!--                <app-customer-bank-account-list></app-customer-bank-account-list>-->
            </ng-template>
        </ngb-tab> 
    </ngb-tabset>
</div>
