<div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordion"
     style="padding-top: 5px;">
    <!--                class="collapse show"-->
    <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6">
            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                <h5
                        style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                    ماهیت مشتریان
                </h5>
                <table style="width: 100%;margin: 0px;">
                    <thead class="thead-light"
                           style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                    <tr >
                        <th scope="col" style="border-left: 1px solid gray;">ماهیت مشتری</th>
                        <th scope="col" style="border-left: 1px solid gray;" *ngFor="let el of columns; let i = index">{{el}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                    <tbody>
                    <tr style="text-align: center;border-top: 1px solid gray;" *ngFor="let el of natureValueList; let i = index">
                        <th scope="row" style="border-left: 1px solid gray;">
                            {{ el.name}}
                        </th>
                        <!-- <td>{{ i + 1 | toFa }}</td> -->
                        <td style="border-left: 1px solid gray;" *ngFor="let nb of el.numberList ; let i = index">
                            {{ nb }}
                        </td>
                    </tr>

                    </tbody>
                </table>
<!--                <div *ngIf="cashPayment.length==0" style="width: 100%;-->
<!--height: 100%;-->
<!--text-align: center;">اطلاعاتی یافت نشد-->
<!--                </div>-->
                <!--                        class="k-grid-norecords-content"-->
            </div>
        </div>
    </div>
</div>
