import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpClient} from '@angular/common/http';
import * as Func from 'src/pack/js/func';
import {Config} from 'src/app/app-config.service';
import {AuthService} from 'src/app/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import {ToRPipe} from 'src/app/fa.pipe';
import {LoadingService} from 'src/app/loading/loading.service';
import {math, number} from '@amcharts/amcharts4/core';
import Num2persian from 'num2persian';
import {ToolbarItemModel} from "../../../../models/toolbar.model";

@Component({
    selector: 'app-order-edit',
    templateUrl: './order-edit.component.html',
    styleUrls: ['./order-edit.component.scss'],
})
export class OrderEditComponent implements OnInit {
    isWrong: boolean;
    errorMsg: string;
    success: boolean;
    msg: string;
    date = new Date();
    jdate = null;
    name: string;
    credit: number = 0;

    request_number;
    invoice_number;

    order: any[] = [];
    checkout_date;
    productSheetCount:number ;
    warehouseStockApi: string = Config.settings.api + "warehouse/warehouse-stock";
    toolbarItemList: ToolbarItemModel[] = [

        {
            name: "save",
            icon: "fas fa-save",
            text: "ذخیره و ویرایش",
            type: "button",
            btn_type: "primary"
        },
        {
            name: "saveNew",
            icon: "fas fa-save",
            text: "ذخیره و جدید",
            type: "button",
            btn_type: "primary"
        },
        {
            name: "saveClose",
            icon: "fas fa-save",
            text: "ذخیره و بستن",
            type: "button",
            btn_type: "primary"
        },
        {
            name: "space",
            text: "space",
            type: "space",
        },
        {
            name: "cancel",
            text: "لغو",
            type: "icon-button",
            icon: "fal fa-times-circle fa-2x text-danger",
        },
        {
            name: "print",
            text: "چاپ",
            type: "icon-button",
            icon: "fal fa-print fa-2x text-info",
        },
        {
            name: "excel",
            text: "خروجی اکسل",
            type: "icon-button",
            icon: "fal fa-file-excel fa-2x text-success",
        },
        {
            name: "space",
            text: "space",
            type: "space",
        },
        {
            name: "operations",
            text: "عملیات",
            icon: "fas fa-cogs",
            type: "menu",
            btn_type: "primary",
            items: [
                {
                    name: "cancel",
                    text: "لغو",
                    type: "icon-button",
                    icon: "fal fa-times-circle",
                },
                {
                    name: "print",
                    text: "چاپ",
                    type: "icon-button",
                    icon: "fal fa-print ",
                },
                {
                    name: "excel",
                    text: "خروجی اکسل",
                    type: "icon-button",
                    icon: "fal fa-file-excel",
                }
            ]
        }
    ]

    statuses: any[] = [
        {id: true, label: 'فعال'},
        {id: false, label: 'غیر فعال'},
    ];
    status: boolean = true;

    checkout_types: any[] = [
        {id: 1, label: 'قبل از ارسال بار'},
        {id: 2, label: 'بعد از ارسال بار'},
        {id: 3, label: 'ترکیبی قبل و بعد از ارسال بار'},
    ];
    checkout_type: number = null;

    cancellation = [];

    types: any[] = [];
    type: string = '';

    transportations: any[] = [];
    transportation: string = '';

    sources: any[] = [
        {id: 1, label: 'کارخانه'},
        {id: 2, label: 'انبار های استانی'},
    ];
    source: number = null;

    warehouseList: any[] = [];
    warehouse: number = null;
    showWarehouse: boolean = false;
    showCountProduct:boolean = false;
    methods: any[] = [];
    method: number = null;

    products: any[] = [];
    orgProducts: any[] = [];
    selected: boolean[] = [];
    productsApi: string =
        Config.settings.api + Config.settings.product.product;

    detail: string;
    remaining_amount_word: string;
    discount_detail: string;
    func = Func;
    math = Math;
    sheet_sum: any;
    remaining_sheet_number: any;
    shippings: any[] = [];
    cancellation_reason: any;
    orderd: any;
    @ViewChild("form")
    form:ElementRef;
    flag;
    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        public loc: Location,
        private loading: LoadingService
    ) {
    }

    id: number;
    user_id;
    user;
    buyer: number;
    creator: number;
    creator_first_name: string;
    creator_last_name: string;
    state: number = 0;

    async ngOnInit(): Promise<void> {
        this.user_id = localStorage.getItem("customer_id");

        this.loading.add();

        this.user = await this.auth.get_info();
        await this.load_products();
        this.loading.remove();
        this.load();





    }

    optimum_transport: string = '';

    load() {
        this.order = [];
        this.optimum_transport = '';
        this.shippings = [];
        this.route.params.subscribe((params) => {
            if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
                this.router.navigate(['/404']);
            } else {
                this.id = params.id;
                let roleApi: string =
                    Config.settings.api +
                    Config.settings.order.order +
                    `${this.id}/`;

                this.loading.add();
                this.http.get(roleApi).subscribe(
                    (response: any) => {
                        console.log(response);
                        this.orderd = response;
                        this.state = response.state;
                        this.buyer = response.buyer;
                        this.detail = response.detail;
                        this.discount_detail = response.discount_detail;
                        this.sheet_sum = response.sheet_sum;
                        this.remaining_sheet_number = response.remaining_sheet_number;
                        this.request_number = response.request_number;
                        this.invoice_number = response.invoice_number;
                        this.checkout_date = response.checkout_date
                            ? new Date(response.checkout_date)
                            : null;
                        this.checkout_type = response.checkout_type;
                        debugger;
                        if(response.source === null){
                            this.source = 1 ;
                        } else {
                            this.source = response.source;
                        }
                        if (response.source == 2) {
                            this.warehouse = response.warehouse;
                            this.showWarehouse = false;
                            this.showCountProduct = true;
                        } else {
                            this.warehouse = null;
                            this.showWarehouse = true;
                        }
                        this._debt_amount = response.debt_amount;

                        this._discount_amount = response.discount_amount;
                        this._remaining_amount = response.debt_amount - response.discount_amount;
//hamed
                        this.valid_change_amount();

                        if (response.cancellation) {
                            this.cancellation_reason =
                                this.func.cancellation.get(response.cancellation) +
                                (response.cancellation_reason
                                    ? ':\n' + response.cancellation_reason
                                    : '');
                        } else if (response.cancellation_reason) {
                            this.cancellation_reason = response.cancellation_reason;
                        }

                        response.load_reports.forEach((element) => {
                            this.shippings.push(element);
                        });
                        console.log(this.shippings);

                        this.creator_first_name = response.creator_first_name;
                        this.creator_last_name = response.creator_last_name;
                        this.creator = response.creator;
                        this.date = new Date(response.date);
                        response.optimum_transportations.forEach((el, i) => {
                            if (i !== 0) {
                                this.optimum_transport += '،\n';
                            }
                            this.optimum_transport += el.name;
                        });
                        this.load_combo(
                            response.method,
                            response.type,
                            response.transportation
                        );
                        response.order_details.forEach((el) => {
                            this.orgProducts.forEach((p, i) => {
                                if (el.product === p.id) {
                                    if( this.warehouse == null){
                                        this.add(i, el.product, el.sheet_number,0 );
                                    } else {
                                        this.sheetCountProductAdd(i , el.product , el.sheet_number, this.warehouse);
                                    }

                                }
                            });
                        });
                        this.name = response.buyer_company_name;

                        this.loading.remove();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        if (error.status === 404) {
                            this.router.navigate(['/404']);
                        }
                        console.log(error);
                        this.loading.remove();
                    }
                );
            }
        });
    }

    load_combo(met, type, trans) {
        let methodApi: string =
            Config.settings.api + Config.settings.order.method;
        this.http.get(methodApi).subscribe(
            (response: any) => {
                this.methods = [];
                response.forEach((element) => {
                    this.methods.push({
                        id: element.id,
                        label: element.method,
                    });
                });
                this.method = met;
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );

        let typeApi: string =
            Config.settings.api + Config.settings.order.type;
        this.http.get(typeApi).subscribe(
            (response: any) => {
                this.types = [];
                response.forEach((element) => {
                    this.types.push({
                        id: element.id,
                        label: element.type,
                    });
                });
                this.type = type;
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );

        let transportationApi: string =
            Config.settings.api + Config.settings.order.transportation;
        this.http.get(transportationApi).subscribe(
            (response: any) => {
                this.transportations = [];
                response.forEach((element) => {
                    this.transportations.push({
                        id: element.id,
                        label: element.transportation,
                    });
                });
                this.transportation = trans;
            },
            (error: HttpErrorResponse) => {
                console.log(error);
            }
        );


        let warehouseApi: string =
            Config.settings.api + 'warehouse/warehouse/';
        this.http
            .get(warehouseApi)
            .toPromise()
            .then((response: any) => {
                this.warehouseList = [];
                response.forEach((element) => {
                    this.warehouseList.push({
                        id: element.id,
                        label: element.name,
                        responsible: `${element.responsible_company_name} | ${element.responsible_first_name} ${element.responsible_last_name}`

                    });
                });
            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            });


    }

    async load_products() {
        await this.http
            .get(this.productsApi)
            .toPromise()
            .then((response: any) => {
                this.orgProducts = [];
                response.forEach((element) => {
                    this.orgProducts.push({
                        id: element.id,
                        name: element.name,
                        group: element.brand_name,
                        type: element.type_name,
                        status: element.is_active,
                        code: element.code,
                        unit: element.unit_name,
                        number_in_pallet: element.number_in_pallet,
                        weight: element.weight,
                    });
                    this.func.sort(this.orgProducts, 'name');
                });
                debugger;
                console.log(this.orgProducts);
                this.orgProducts.forEach((element, i) => {
                    this.products.push({
                        id: element.id,
                        label: element.name,
                    });
                });

            })
            .catch((error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            });
    }

    changeTransportation($event) {
        this.transportation = $event;
    }

    changeStatus($event) {
        this.status = $event;
    }

    changeMethod($event) {
        this.method = $event;
    }

    changeProduct($event, i) {
        debugger;
        this.order[i].product_id = $event;
        // this.order[i].pindex = $event;
        this.sheetCountProduct(i , $event);
    }

    sheetCountProduct(i , productId ) {
        debugger;
         this.http
            .get(
                this.warehouseStockApi +
                `?warehouse_id=${this.warehouse}&product_id=${productId}`
            ).subscribe(
                (response: any) => {
                    this.productSheetCount = 0 ;
                    response.forEach((element) => {
                         this.productSheetCount = element.input_sheet_sum  -  element.output_sheet_sum ;
                    });

                    this.order[i].productSheetCount = this.productSheetCount;
                },
                (error: HttpErrorResponse) => {
                    //TODO: server errors

                }
            );
    }
    sheetCountProductAdd(i, productId , sheetNumber ,  warehouseId ) {
        debugger;
        this.http
            .get(
                this.warehouseStockApi +
                `?warehouse_id=${warehouseId}&product_id=${productId}`
            ).subscribe(
            (response: any) => {
                this.productSheetCount = 0 ;
                response.forEach((element) => {
                    this.productSheetCount = element.input_sheet_sum  -  element.output_sheet_sum ;
                });
                this.add(i, productId , sheetNumber , this.productSheetCount );
            },
            (error: HttpErrorResponse) => {
                //TODO: server errors

            }
        );
    }


    _pallet_sum: number;
    _weight_sum: number;

    get count_sum() {
        let sum: number = 0;
        this.order.forEach((el) => {
            if (parseInt(el.count)) {
                sum += parseInt(el.count);
            }
        });
        return sum;
    }

    get weight_sum() {
        let sum: number = 0;
        this.order.forEach((el) => {
            sum +=
                el.pindex != null && el.count !== null
                    ? this.math.ceil(el.count * this.orgProducts[el.pindex].weight)
                    : 0;
        });
        return sum;
    }

    get pallet_sum() {
        let sum: number = 0;
        this.order.forEach((el) => {
            sum +=
                el.pindex != null && el.count !== null
                    ? (1.0 * el.count) /
                    this.orgProducts[el.pindex].number_in_pallet
                    : 0;
        });
        return Math.floor(sum);
    }

    changeType($event) {
        this.type = $event;
    }

    async register(form, content = null, state = 1) {

        if (content == null) {
            this._is_sending = true;
            let data = form.value;
            console.log(data);
            data.date = this.date.toISOString();
            data.state = state;
            data.order_details = [];
            data.debt_amount = this._debt_amount;
            data.discount_amount = this._discount_amount;
            this.order.forEach((el) =>
                data.order_details.push({
                    product: el.product_id,
                    sheet_number: el.count,
                })
            );
            data.detail = this.detail;
            data.discount_detail = this.discount_detail;
            let url =
                Config.settings.api + Config.settings.order.order + `${this.id}/`;
            await this.http
                .patch(url, data)
                .toPromise()
                .then((response: any) => {
                    this._is_sending = false;
                    this.success = true;
                    this.isWrong = false;
                    this.load();
                    this.msg = `سفارش ذخیره شد.`;
                })
                .catch((error: HttpErrorResponse) => {
                    console.log(error);
                    this._is_sending = false;
                    this.isWrong = true;
                    this.success = false;
                    this.errorMsg = 'دوباره امتحان کنید.';
                    window.scroll(0, 0);
                    if (error.error.message) {
                        this.errorMsg = error.error.message;
                    }
                });
            return;
        }
        await this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then(async (result) => {
                this._is_sending = true;
                let data = form.value;
                data.date = this.date.toISOString();
                data.state = state;
                data.debt_amount = this._debt_amount;
                data.discount_amount = this._discount_amount;
                data.order_details = [];
                this.order.forEach((el) =>
                        data.order_details.push({
                        product: el.product_id,
                        sheet_number: el.count,
                    })
                );
                data.detail = this.detail;
                data.discount_detail = this.discount_detail;
                let url =
                    Config.settings.api +
                    Config.settings.order.order +
                    `${this.id}/`;
                await this.http
                    .patch(url, data)
                    .toPromise()
                    .then((response: any) => {
                        this._is_sending = false;
                        this.success = true;
                        this.isWrong = false;
                        this.load();
                        this.msg = `سفارش ذخیره شد.`;
                    })
                    .catch((error: HttpErrorResponse) => {
                        console.log(error);
                        this._is_sending = false;
                        this.isWrong = true;
                        this.success = false;
                        this.errorMsg = 'دوباره امتحان کنید.';
                        window.scroll(0, 0);
                        if (error.error.message) {
                            this.errorMsg = error.error.message;
                        }
                    });
            });
    }

    deny(form) {
        this._is_sending = true;
        let url =
            Config.settings.api + Config.settings.order.order + `${this.id}/`;
        let data: any = {state: 10, detail: this.detail};
        this.http.patch(url, data).subscribe(
            (response: any) => {
                this._is_sending = false;
                this.success = true;
                this.isWrong = false;
                this.msg = `سفارش رد شد.`;
                this.load();
            },
            (error: HttpErrorResponse) => {
                console.log(error);
                this.isWrong = true;
                this._is_sending = false;
                this.success = false;
                this.errorMsg = 'دوباره امتحان کنید.';
                window.scroll(0, 0);
                if (error.error.message) {
                    this.errorMsg = error.error.message;
                }
            }
        );
    }

    confirm(form) {
        this._is_sending = true;
        let url =
            Config.settings.api + Config.settings.order.order + `${this.id}/`;
        let data: any = {state: 3, detail: this.detail};
        console.log(data);
        this.http.patch(url, data).subscribe(
            (response: any) => {
                this.success = true;
                this._is_sending = false;
                this.isWrong = false;
                this.msg = `سفارش ثبت شد.`;
                this.load();
            },
            (error: HttpErrorResponse) => {
                console.log(error);
                this.isWrong = true;
                this._is_sending = false;
                this.success = false;
                this.errorMsg = 'دوباره امتحان کنید.';
                window.scroll(0, 0);
                if (error.error.message) {
                    this.errorMsg = error.error.message;
                }
            }
        );
    }

    edit(form, i) {
        this._is_sending = true;
        let url =
            Config.settings.api + Config.settings.order.order + `${this.id}/`;
        let data: any = {state: i, detail: this.detail};
        this.http.patch(url, data).subscribe(
            (response: any) => {
                this.success = true;
                this._is_sending = false;
                this.isWrong = false;
                this.msg = `سفارش ذخیره شد.`;
                this.load();
            },
            (error: HttpErrorResponse) => {
                console.log(error);
                this.isWrong = true;
                this._is_sending = false;
                this.success = false;
                this.errorMsg = 'دوباره امتحان کنید.';
                window.scroll(0, 0);
                if (error.error.message) {
                    this.errorMsg = error.error.message;
                }
            }
        );
    }

    send_to_factory(form) {
        this._is_sending = true;
        let url =
            Config.settings.api + Config.settings.order.order + `${this.id}/`;
        let data: any = {state: 4, detail: this.detail};
        this.http.patch(url, data).subscribe(
            (response: any) => {
                this._is_sending = false;
                this.success = true;
                this.isWrong = false;
                this.msg = `سفارش به سیستم مالی منتقل شد.`;
                this.load();
            },
            (error: HttpErrorResponse) => {
                console.log(error);
                this.isWrong = true;
                this._is_sending = false;
                this.success = false;
                this.errorMsg = 'دوباره امتحان کنید.';
                window.scroll(0, 0);
                if (error.error.message) {
                    this.errorMsg = error.error.message;
                }
            }
        );
    }

    redirect(form) {
        this._is_sending = true;
        let url =
            Config.settings.api + Config.settings.order.order + `${this.id}/`;

        let userApi = Config.settings.api + Config.settings.user.user;

        this.http.get(userApi + this.buyer + '/').subscribe(
            (response: any) => {
                console.log(response);
                if (response.reference) {
                    let data: any = {
                        state: 7,
                        representative: response.reference,
                        detail: this.detail,
                        discount_detail: this.discount_detail,
                    };
                    this.http.patch(url, data).subscribe(
                        (r: any) => {
                            this._is_sending = false;
                            this.success = true;
                            this.isWrong = false;
                            this.msg = `سفارش ارجاع داده شد.`;
                            this.load();
                        },
                        (error: HttpErrorResponse) => {
                            console.log(error);
                            this.isWrong = true;
                            this._is_sending = false;
                            this.success = false;
                            this.errorMsg = 'دوباره امتحان کنید.';
                            window.scroll(0, 0);
                            if (error.error.message) {
                                this.errorMsg = error.error.message;
                            }
                        }
                    );
                } else {
                    this.isWrong = true;
                    this.success = false;
                    this.errorMsg = 'مشتری فروشنده مادر ندارد.';
                    window.scroll(0, 0);
                }
                this._is_sending = false;
            },
            (error: HttpErrorResponse) => {
                console.log(error);
                this.isWrong = true;
                this._is_sending = false;
                this.success = false;
                this.errorMsg = 'دوباره امتحان کنید.';
                window.scroll(0, 0);
                if (error.error.message) {
                    this.errorMsg = error.error.message;
                }
            }
        );
    }

    ship(form) {
    }

    evaluate(form) {
    }

    add(pi = null, pro = null, c = 0 , p= 0 ) {
        this.order.push({
            pindex: pi,
            product_id: pro,
            count: c,
            productSheetCount: p ,
        });
    }

    remove(i) {
        this.order.splice(i, 1);
    }

    get can_send() {
        return (
            this.user.id === this.creator ||
            this.user.id === this.buyer ||
            this.user.role == 1 ||
            this.user.role == 5 ||
            this.user.role == 10
        );
    }

    get can_edit() {
        return (
            (this.user?.permission_codes.includes(132) && this.state < 2) ||
            this.user?.permission_codes.includes(133)
        );
    }

    get can_change() {
        // return 1;
        // return this.state == 1 && this.can_send;
        return this.user?.permission_codes.includes(135);
    }

    permissionControl(e): void {
        if (!this.can_edit) {
            e.preventDefault();
        }
    }

    _is_sending = false;

    get disable_send() {
        return this._is_sending;
    }

    editShipping(i = null) {
        if (i) {
            this.router.navigate(['/userArea/order/shipping', i]);
        }
        this.selected.forEach((el, i) => {
            if (el) {
                this.router.navigate(['/userArea/order/shipping', i]);
            }
        });
    }

    shippingsApi: string =
        Config.settings.api + Config.settings.order.shipping;

    can_del = true;

    delShipping(content, i) {
        if (!i) {
            return;
        }
        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((result) => {
            if (result) {
                this.http.delete(this.shippingsApi + i + '/').subscribe(
                    (response: any) => {
                        this.load();
                    },
                    (error: HttpErrorResponse) => {
                        //TODO: server errors
                        console.log(error);
                    }
                );
            }
        });
    }

    cancelOrder(content, i) {
        this.cancellation = [];
        this.func.cancellation.forEach((v, k) => {
            if ((i == 10 && k < 100) || (i == 8 && k >= 100)) {
                this.cancellation.push({
                    value: v,
                    key: k,
                });
            }
        });

        this.modalService
            .open(content, {ariaLabelledBy: 'modal-basic-title'})
            .result.then((form) => {
            console.log(form.value);

            let url =
                Config.settings.api +
                Config.settings.order.order +
                `${this.id}/`;
            let data: any = form.value;
            data.state = i;
            data.detail = this.detail;
            data.discount_detail = this.discount_detail;
            this.http.patch(url, data).subscribe(
                (response: any) => {
                    console.log('shod');
                    this.success = true;
                    this.isWrong = false;
                    this.msg = `سفارش لغو شد.`;
                    this.load();
                },
                (error: HttpErrorResponse) => {
                    console.log(error);
                    this.isWrong = true;
                    this.success = false;
                    this.errorMsg = 'دوباره امتحان کنید.';
                    window.scroll(0, 0);
                    if (error.error.message) {
                        this.errorMsg = error.error.message;
                    }
                }
            );
        });
    }

    _debt_amount: number = null;
    _remaining_amount: number = null;
    _discount_amount: number = null;
    _discount_percent: number = null;

    get debt_amount() {
        if (!this._debt_amount) {
            return null;
        }
        return this.toR.transform(this._debt_amount.toString());
    }

    set debt_amount(e: string) {
        this._debt_amount = Func.toNumber(e);
        this.valid_change_amount();
    }

    get discount_percent() {
        if (!this._discount_percent) {
            return null;
        }
        return this._discount_percent;
    }

    set discount_percent(e: number) {

        this._discount_percent = e;

        this.valid_change_percent();
    }

    get discount_amount() {
        if (!this._discount_amount) {
            return null;
        }
        return this.toR.transform(this._discount_amount.toString());
    }

    set discount_amount(e: string) {
        this._discount_amount = Func.toNumber(e);
        this.valid_change_amount();
    }

    get remaining_amount() {
        if (!this._remaining_amount) {
            return null;
        }
        this.remaining_amount_word = `${Num2persian(this._remaining_amount)} ریال`;
        return this.toR.transform(this._remaining_amount.toString());
    }

    set remaining_amount(e: string) {
        this._remaining_amount = Func.toNumber(e);
    }


    toR: ToRPipe = new ToRPipe();

    valid_change_amount() {

        let dis_amou: number = 0;
        if (this._discount_amount <= this._debt_amount) {
            dis_amou = this._discount_amount;
        } else if (this._discount_amount > this._debt_amount) {
            dis_amou = this._debt_amount;
            alert('مبلغ کسور نمیتواند از مبلغ سفارش بیشتر باشد');
        }

        this._discount_amount = dis_amou;

        this._remaining_amount = this._debt_amount - dis_amou;

        //
        this._discount_percent = ((dis_amou * 100) / this._debt_amount);


    }

    valid_change_percent() {

        console.log(this._discount_percent);
        let dis_per: number = 0;
        if (this._discount_percent <= 100) {
            dis_per = this._discount_percent;
        } else if (this._discount_percent > 100) {
            dis_per = 100;
            alert('دصد کسور نمیتواند از 100 بیشتر باشد');
        }
        this._discount_percent = dis_per;

        this._discount_amount = (this._debt_amount * (dis_per / 100));
        this._remaining_amount = this._debt_amount - (this._debt_amount * (dis_per / 100));
    }

    get can_see_company(): boolean {
        return (
            this.user?.permission_codes.includes(133) &&
            this.state >= 4 &&
            this.state <= 11 &&
            this.state != 10 &&
            this.state != 7
        );
    }

    get canSeeWarehouse(): boolean {

        return (
            (this.orderd?.state === 4 ||this.orderd?.state === 6 || this.orderd?.state === 11)
            && (this.user.role !== 2 ||
                this.user.role !== 3 ||
                this.user.role !== 4 )
        );

    }


    changeWarehouse($event) {
        debugger;
        let currentWarehouse = this.warehouseList[this.warehouseList.findIndex(x => x.id === $event)];
        this.warehouse = currentWarehouse.id;
        console.log(this.warehouse);
        this.order.forEach((value, i)  => {
            this.sheetCountProduct(i, value.product_id);
        });
    }

    changeSource(sourceId: number) {
        if (sourceId[0] == 0) {
            this.warehouse = null;
            this.showWarehouse = true;
            this.showCountProduct = false;
        } else {
            this.showWarehouse = false;
            this.showCountProduct = true;
        }
    }

    itmeClickHandler(event) {
        console.log(event);
        switch (event.name) {
            case 'cancel':
                if (this.user_id !== undefined && this.user_id != null) {
                    this.router.navigate(['userArea/customer/' + this.user_id]).then(r => '/404');
                }else {
                    this.router.navigate(['/userArea/order']).then(r => '/404');
                }
                break;
            case 'save':
                this.flag = 1 ;
                // this.register(this.form);
                break;
            case 'saveNew':
                this.flag = 2;
                // this.register(this.form);
                break;
            case 'saveClose':
                this.flag = 3;
                // this.register(this.form);
                break;
            case 'excel':

        }
    }

}
