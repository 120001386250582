import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { SigninComponent } from "./components/auth/signin/signin.component";
import { AuthComponent } from "./auth/auth.component";
import { RequestInterceptor } from "./auth/request.interceptor";
import { SignupComponent } from "./components/auth/signup/signup.component";
import { PhoneSigninComponent } from "./components/auth/signin/phone-signin/phone-signin.component";
import { UsernameSigninComponent } from "./components/auth/signin/username-signin/username-signin.component";
import { PageNotFoundComponent } from "./components/misc/errorPages/page-not-found/page-not-found.component";
import { InternalServerErrorComponent } from "./components/misc/errorPages/internal-server-error/internal-server-error.component";
import { UserAreaComponent } from "./components/user-area/user-area.component";
import { APP_INITIALIZER } from "@angular/core";
import { Config } from "./app-config.service";
import { NgSelect2Module } from "ng-select2";
import { SelectComponent } from "./components/misc/select/select.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ReactiveFormsModule } from "@angular/forms";
import { AutofocusDirective } from "./autofocus.directive";
import { DashboardComponent } from "./components/user-area/dashboard/dashboard.component";
import {
  DateAdapter,
  MatOptionModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import {
  MaterialPersianDateAdapter,
  PERSIAN_DATE_FORMATS,
} from "./shared/material.persian-date.adapter";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { ToFaPipe, ToRPipe, JDatePipe } from "./fa.pipe";
import { NoCacheInterceptor } from "./interceptors/no-cache.interceptor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PaginationComponent } from "./components/misc/pagination/pagination.component";
import { FilterComponent } from "./components/misc/filter/filter.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { TheHeaderComponent } from "./components/user-area/the-header/the-header.component";
import { TheSidebarComponent } from "./components/user-area/the-sidebar/the-sidebar.component";
// import { LoadingComponent } from './components/misc/loading/loading.component';
import { LoadingComponent } from "./loading/loading.component";

import { PersonnelsListComponent } from "./components/users/personnels/personnels-list/personnels-list.component";
import { PersonnelsNewComponent } from "./components/users/personnels/personnels-new/personnels-new.component";
import { TitleComponent } from "./components/misc/title/title.component";
import { CustomerListComponent } from "./components/users/Customers/customer-list/customer-list.component";
import { OrderListComponent } from "./components/orders/orders/order-list/order-list.component";
import { ShippingListComponent } from "./components/orders/shipping/shipping-list/shipping-list.component";
import { OrderNewComponent } from "./components/orders/orders/order-new/order-new.component";
import { OrderEditComponent } from "./components/orders/orders/order-edit/order-edit.component";
import { ShippingNewComponent } from "./components/orders/shipping/shipping-new/shipping-new.component";
import { CheckoutEditComponent } from "./components/orders/checkout/checkout-edit/checkout-edit.component";
import { SurveyNewComponent } from "./components/orders/satisfaction/survey-new/survey-new.component";
import { SurveyListComponent } from "./components/orders/satisfaction/survey-list/survey-list.component";
import { SurveyWatchComponent } from "./components/orders/satisfaction/survey-watch/survey-watch.component";
import { SurveyFormNewComponent } from "./components/orders/satisfaction/survey-form-new/survey-form-new.component";
import { SurveyFormEditComponent } from "./components/orders/satisfaction/survey-form-edit/survey-form-edit.component";
import { SurveyFormListComponent } from "./components/orders/satisfaction/survey-form-list/survey-form-list.component";
import { PersonnelsEditComponent } from "./components/users/personnels/personnels-edit/personnels-edit.component";
import { CustomerNewComponent } from "./components/users/Customers/customer-new/customer-new.component";
import { CheckoutCheckListComponent } from "./components/orders/checkout/checkout-check-list/checkout-check-list.component";
import { CheckoutCashListComponent } from "./components/orders/checkout/checkout-cash-list/checkout-cash-list.component";
import { ContactListComponent } from "./components/users/Customers/contact-list/contact-list.component";
import { PostalAddressListComponent } from "./components/users/Customers/postal-address-list/postal-address-list.component";
import { CPersonnelListComponent } from "./components/users/Customers/cpersonnel-list/cpersonnel-list.component";
import { WorkBackgroundListComponent } from "./components/users/Customers/work-background-list/work-background-list.component";
import { CustomerEditComponent } from "./components/users/Customers/customer-edit/customer-edit.component";
import { CustomerBankAccountListComponent } from "./components/users/Customers/customer-bank-account-list/customer-bank-account-list.component";
import { SocialNetworkListComponent } from "./components/users/Customers/social-network-list/social-network-list.component";
import { OrderCheckoutListComponent } from "./components/orders/orders/order-checkout-list/order-checkout-list.component";
import { CityListComponent } from "./components/information/cities/city-list/city-list.component";
import { ProvinceListComponent } from "./components/information/provinces/province-list/province-list.component";
import { UnitListComponent } from "./components/information/units/unit-list/unit-list.component";
import { RoleListComponent } from "./components/information/roles/role-list/role-list.component";
import { RegionListComponent } from "./components/information/regions/region-list/region-list.component";
import { BankAccountListComponent } from "./components/information/BackAcounts/bank-account-list/bank-account-list.component";
import { ProductListComponent } from "./components/information/Product/product-list/product-list.component";
import { ProductTypeListComponent } from "./components/information/Product/information/product-type-list/product-type-list.component";
import { ProductDegreeListComponent } from "./components/information/Product/information/product-degree-list/product-degree-list.component";
import { ProductBrandListComponent } from "./components/information/Product/information/product-brand-list/product-brand-list.component";
import { ProductDimensionListComponent } from "./components/information/Product/information/product-dimension-list/product-dimension-list.component";
import { ProductUnitListComponent } from "./components/information/Product/information/product-unit-list/product-unit-list.component";
import { ProductThicknessListComponent } from "./components/information/Product/information/product-thickness-list/product-thickness-list.component";
import { ProductSupplierListComponent } from "./components/information/Product/information/product-supplier-list/product-supplier-list.component";
import { ProductInformationListsComponent } from "./components/information/Product/product-information-lists/product-information-lists.component";
import { CustomerProfileComponent } from "./components/users/Customers/customer-profile/customer-profile.component";
import { ActivityTypeComponent } from "./components/information/CustomerInformation/activity-type/activity-type.component";
import { ActivityProductComponent } from "./components/information/CustomerInformation/activity-product/activity-product.component";
import { ActivityBrandComponent } from "./components/information/CustomerInformation/activity-brand/activity-brand.component";
import { ActivityIndustryComponent } from "./components/information/CustomerInformation/activity-industry/activity-industry.component";
import { AddressTitleComponent } from "./components/information/CustomerInformation/address-title/address-title.component";
import { PropertyStatusComponent } from "./components/information/CustomerInformation/property-status/property-status.component";
import { PropertyAreaComponent } from "./components/information/CustomerInformation/property-area/property-area.component";
import { PhoneTypeComponent } from "./components/information/CustomerInformation/phone-type/phone-type.component";
import { PersonnelJobComponent } from "./components/information/CustomerInformation/personnel-job/personnel-job.component";
import { BankNameComponent } from "./components/information/CustomerInformation/bank-name/bank-name.component";
import { InquiryStatusComponent } from "./components/information/CustomerInformation/inquiry-status/inquiry-status.component";
import { SocialNetworkTypeComponent } from "./components/information/CustomerInformation/social-network-type/social-network-type.component";
import { CustomerInformationListsComponent } from './components/information/CustomerInformation/customer-information-lists/customer-information-lists.component';
import { ShippingEditComponent } from './components/orders/shipping/shipping-edit/shipping-edit.component';
import { ActivityCooperationComponent } from './components/information/CustomerInformation/activity-cooperation/activity-cooperation.component';
import { EducationFieldComponent } from './components/information/CustomerInformation/education-field/education-field.component';
import { EducationDegreeComponent } from './components/information/CustomerInformation/education-degree/education-degree.component';
// import { SaleDashboardComponent } from './components/user-area/dashboard/sale-dashboard/sale-dashboard.component';
import { OrderDashboardComponent } from './components/user-area/dashboard/order-dashboard/order-dashboard.component';
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import { ProductOrderComponent } from './components/orders/product-order/product-order.component';
// import { ProductOrderDashboardComponent } from './components/user-area/dashboard/product-order-dashboard/product-order-dashboard.component';
import {MatTabsModule} from "@angular/material/tabs";
import {CheckoutCheckEditComponent} from './components/orders/checkout/checkout-check-edit/checkout-check-edit.component';
import { WarehouseOrdersListComponent } from './components/orders/warehouse-orders/warehouse-orders-list/warehouse-orders-list.component';
import { WarehouseOrderNewComponent } from './components/orders/warehouse-orders/warehouse-order-new/warehouse-order-new.component';
import { WarehouseProductOrderComponent } from './components/orders/warehouse-orders/warehouse-product-order/warehouse-product-order.component';
import { CheckOutCashEditComponent } from './components/orders/checkout/chekout-chash-edit/check-out-cash-edit.component';
import { WarehouseListComponent } from './components/information/warehouse/warehouse-list/warehouse-list.component';
import { WarehouseNewComponent } from './components/information/warehouse/warehouse-new/warehouse-new.component';
import {MatDialogModule} from "@angular/material/dialog";
import { WarehouseComponent } from './components/information/warehouse/warehouse/warehouse.component';
import { WarehouseOrderEditComponent } from './components/orders/warehouse-orders/warehouse-order-edit/warehouse-order-edit.component';
import { ProductNewComponent } from './components/information/Product/product-new/product-new.component';
import { FamiliarityTypeComponent } from './components/information/familiarity-type/familiarity-type.component';
import { WarehouseDashboardComponent } from './components/user-area/warehouse-dashbord/warehouse-dashboard.component';
import { ReportInformationListComponent } from './components/information/report/report-information-list/report-information-list.component';
import { ReportStateComponent } from './components/information/report/report-state/report-state.component';
import { ReportAddresseeTypeComponent } from './components/information/report/report-addressee-type/report-addressee-type.component';
import { ReportTypeComponent } from './components/information/report/report-type/report-type.component';
import { ReportActivityTypeComponent } from './components/information/report/report-activity-type/report-activity-type.component';
import { ReportActionTypeComponent } from './components/information/report/report-action-type/report-action-type.component';
import { ReportNewComponent } from './components/reports/report-new/report-new.component';
import { ReportListComponent } from './components/reports/report-list/report-list.component';
import { ReportEditComponent } from './components/reports/report-edit/report-edit.component';
import {TimePickerModule} from '@syncfusion/ej2-angular-calendars';
import { PalletComponent } from './components/information/pallet/pallet.component';
import { SheetComponent } from './components/information/sheet/sheet.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";

import {MatToolbarModule} from '@angular/material/toolbar';
import { ToolbarComponent } from "./components/ui-component/toolbar/toolbar.component";
import {MatMenuModule} from "@angular/material/menu";
import { PanelBoxModule } from "./components/ui-component/panel-box/panel-box.module";
import { CustomerDashboardComponent } from './components/user-area/customer-dashboard/customer-dashboard.component';
import { CustomerTabsComponent } from './components/users/Customers/customer-tabs/customer-tabs.component';

export function initializeApp(config: Config) {
  return () => config.load();
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    SigninComponent,
    SignupComponent,
    PhoneSigninComponent,
    UsernameSigninComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent,
    UserAreaComponent,
    SelectComponent,
    DashboardComponent,
    ToFaPipe,
    ToRPipe,
    JDatePipe,
    PaginationComponent,
    FilterComponent,
    TheHeaderComponent,
    TheSidebarComponent,
    LoadingComponent,
    PersonnelsListComponent,
    PersonnelsNewComponent,
    TitleComponent,
    CustomerListComponent,
    OrderListComponent,
    ShippingListComponent,
    OrderNewComponent,
    OrderEditComponent,
    ShippingNewComponent,
    CheckoutEditComponent,
    SurveyNewComponent,
    SurveyListComponent,
    SurveyWatchComponent,
    SurveyFormNewComponent,
    SurveyFormEditComponent,
    SurveyFormListComponent,
    PersonnelsEditComponent,
    CustomerNewComponent,
    CheckoutCheckListComponent,
    CheckoutCashListComponent,
    ContactListComponent,
    PostalAddressListComponent,
    CPersonnelListComponent,
    WorkBackgroundListComponent,
    CustomerEditComponent,
    CustomerBankAccountListComponent,
    SocialNetworkListComponent,
    OrderCheckoutListComponent,
    CityListComponent,
    ProvinceListComponent,
    UnitListComponent,
    RoleListComponent,
    RegionListComponent,
    BankAccountListComponent,
    ProductListComponent,
    ProductTypeListComponent,
    ProductDegreeListComponent,
    ProductBrandListComponent,
    ProductDimensionListComponent,
    ProductUnitListComponent,
    ProductThicknessListComponent,
    ProductSupplierListComponent,
    ProductInformationListsComponent,
    CustomerProfileComponent,
    ActivityTypeComponent,
    ActivityProductComponent,
    ActivityBrandComponent,
    ActivityIndustryComponent,
    AddressTitleComponent,
    PropertyStatusComponent,
    PropertyAreaComponent,
    PhoneTypeComponent,
    PersonnelJobComponent,
    BankNameComponent,
    InquiryStatusComponent,
    SocialNetworkTypeComponent,
    CustomerInformationListsComponent,
    ShippingEditComponent,
    ActivityCooperationComponent,
    EducationFieldComponent,
    EducationDegreeComponent,
    // SaleDashboardComponent,
    OrderDashboardComponent,
    ProductOrderComponent,
    CheckoutCheckEditComponent,
    WarehouseOrdersListComponent,
    WarehouseOrderNewComponent,
    WarehouseProductOrderComponent,
    CheckOutCashEditComponent,
    WarehouseListComponent,
    WarehouseNewComponent,
    WarehouseComponent,
    WarehouseOrderEditComponent,
    ProductNewComponent,
    FamiliarityTypeComponent,
    WarehouseDashboardComponent,
    ReportInformationListComponent,
    ReportStateComponent,
    ReportAddresseeTypeComponent,
    ReportTypeComponent,
    ReportActivityTypeComponent,
    ReportActionTypeComponent,
    ReportNewComponent,
    ReportListComponent,
    ReportEditComponent,
    PalletComponent,
    SheetComponent,
    ToolbarComponent,
    CustomerDashboardComponent,
    CustomerTabsComponent,
    // ProductOrderDashboardComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgSelect2Module,
    BrowserAnimationsModule,
    ScrollingModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatOptionModule,
    MatAutocompleteModule,
    NgbModule,
    MatTabsModule,
    MatDialogModule,
    TimePickerModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    NgMultiSelectDropDownModule,
    MatToolbarModule,
    MatMenuModule,
    PanelBoxModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    Config,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Config],
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MaterialPersianDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: PERSIAN_DATE_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
