<div class="m-3 mt-4">
  <!-- <app-title title="لیست تلفن‌های تماس"></app-title> -->
  <div class="list my-3 mx-auto">
    <div class="list-header">
      <div class="list-header-item">
        <i class="far fa-sync-alt"></i>به‌روز رسانی
      </div>
    </div>

    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">نوع</th>
          <th scope="col">آدرس</th>
          <th scope="col">شماره تلفن</th>
          <th scope="col">پرسنل</th>
          <th scope="col">سمت</th>
          <th scope="col">ویرایش</th>
          <th scope="col">x</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of contacts; let i = index">
          <th scope="row">
            {{ i + 1 | toFa }}
          </th>
          <td>{{ el.phone_type_name }}</td>
          <td>
            {{
              el.postal_address_address_title +
                ": " +
                el.postal_address_address
            }}
          </td>
          <td>{{ el.phone_number | toFa }}</td>
          <td>
            {{ el.personnel_first_name + " " + el.personnel_last_name }}
          </td>
          <td>{{ el.personnel_job_name }}</td>
          <td class="cursor-pointer" (click)="edit(editcontent, i)">
            <i class="fal fa-edit"></i>
          </td>
          <td class="cursor-pointer" (click)="del(content, el.id)">
            <i class="fal fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-title
    (click)="show = !show"
    [attr.aria-expanded]="show"
    aria-controls="collapse"
    [class.open]="show"
    type="plus"
    title="اضافه کردن"
    class="cursor-pointer"
  ></app-title>

  <div id="collapse" [ngbCollapse]="!show" class="box">
    <div class="row">
      <div class="form-group col-md-6 col-12">
        <app-select
          [options]="phone_types"
          placeholder="نوع"
          (selectChange)="contact.phone_type = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-6 col-12">
        <app-select
          [options]="personnels"
          placeholder="پرسنل"
          (selectChange)="contact.personnel = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-6 col-12">
        <label>شماره تلفن</label>
        <input
          type="text"
          [(ngModel)]="contact.phone_number"
          name="phone_number"
          (keydown)="func.numControl($event)"
          class="form-control form-control-lg"
          maxlength="11"
          required
        />
      </div>

      <div class="form-group col-md-6 col-12">
        <app-select
          [options]="postal_addresses"
          placeholder="آدرس"
          (selectChange)="contact.postal_address = $event"
          req="true"
        ></app-select>
      </div>

      <div class="row w-100 mb-3">
        <button
          (click)="register()"
          class="btn mr-auto ml-3 w-25 btn-success"
        >
          ثبت
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      آیا از حذف این رکورد اطمینان دارید؟
    </h4>
  </div>
  <div class="modal-body">
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(true)"
    >
      بله
    </button>
    <button
      type="button"
      class="btn btn-outline-dark mx-2"
      (click)="modal.close(false)"
    >
      خیر
    </button>
  </div>
</ng-template>

<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">ویرایش</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-6 col-12">
        <app-select
          [options]="phone_types"
          [key]="editing_contact.phone_type"
          placeholder="نوع"
          (selectChange)="editing_contact.phone_type = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-6 col-12">
        <app-select
          [options]="personnels"
          [key]="editing_contact.personnel"
          placeholder="پرسنل"
          (selectChange)="editing_contact.personnel = $event"
          req="true"
        ></app-select>
      </div>

      <div class="form-group col-md-6 col-12">
        <label>شماره تلفن</label>
        <input
          type="text"
          [(ngModel)]="editing_contact.phone_number"
          name="phone_number"
          (keydown)="func.numControl($event)"
          class="form-control form-control-lg"
          maxlength="11"
          required
        />
      </div>

      <div class="form-group col-md-6 col-12">
        <app-select
          [options]="postal_addresses"
          placeholder="آدرس"
          [key]="editing_contact.postal_address"
          (selectChange)="editing_contact.postal_address = $event"
          req="true"
        ></app-select>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(true)"
      >
        تایید
      </button>
      <button
        type="button"
        class="btn btn-outline-dark mx-2"
        (click)="modal.close(false)"
      >
        انصراف
      </button>
    </div>
  </div>
</ng-template>
