import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
   selector: 'app-pagination',
   templateUrl: './pagination.component.html',
   styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
   @Input() page_number: number = 1;
   @Input() page_count: number = 0;
   @Output() changePage = new EventEmitter();

   constructor() {}

   ngOnInit(): void {}

   array(): number[] {
      if (this.page_count <= 10)
         return [...Array(this.page_count).keys()].map((i) => i + 1);
      return [...Array(11).keys()].map(
         (i) =>
            i +
            Math.min(this.page_count - 10, Math.max(this.page_number - 5, 1))
      );
   }
}
