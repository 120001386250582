<div class="border rounded" style="height:auto;padding: 5px; margin-top: 20px;margin-left: 20px;">
    <div *ngIf="user && (user.role !=2 && user.role !=3 && user.role !=4)"
         class="row border rounded" style="margin:5px;padding: 5px;">
        <div class="col-lg-2 col-md-2 col-sm-2">
            <div class="ibox color-white widget-stat border border-white rounded"
                 style="height:80px;background-color: #17b4df;color:white;cursor: pointer;">
                <div class="ibox-body text-center row"
                     [routerLink]="['/userArea/customer']" [queryParams]="{ has_reference:1 ,role: 2}">
                    <div class="col-lg-3 col-md-3 col-sm-3"><i
                            class="fas fa-cogs fa-2x " style="padding-right: 10px;padding-top: 15px;"></i></div>
                    <div class="col-lg-9 col-md-9 col-sm-9" style="padding-top: 15px;">
                        <h4 class="m-b-5">{{headerNumber.n_referred_customers}}</h4>
                        <h5 class="m-b-5 font-strong">زیر مجموعه</h5>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-2">
            <div class="ibox color-white widget-stat  border border-white rounded"
                 style="height:80px;background-color: #f26d7e;color:white;cursor: pointer;">
                <div class="ibox-body text-center row" [routerLink]="['/userArea/customer']"
                     [queryParams]="{ has_reference:0 ,role: 2}"
                     routerLinkActive="text-primary"
                     [routerLinkActiveOptions]="{ exact: true }">
                    <div class="col-lg-3 col-md-3 col-sm-3"><i
                            class="far fa-person-carry fa-2x " style="padding-right: 10px;padding-top: 15px;"></i></div>
                    <div class="col-lg-9 col-md-9 col-sm-9" style="padding-top: 15px;">
                        <h4 class="m-b-5">{{headerNumber.n_customers}}</h4>
                        <h5 class="m-b-5 font-strong">مشتریان</h5>
                    </div>
                    <div></div>
                </div>


            </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2">
            <div class="ibox color-white widget-stat  border border-white rounded"
                 style="height:80px;background-color: #fbae5c ;color:white;cursor: pointer;">

                <div class="ibox-body text-center row" [routerLink]="['/userArea/customer']" [queryParams]="{ role: 3 }"
                     routerLinkActive="text-primary"
                     [routerLinkActiveOptions]="{ exact: true }">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <i
                                class="far fa-person-dolly-empty fa-2x "
                                style="padding-right: 10px;padding-top: 15px;"></i></div>
                    <div class="col-lg-9 col-md-9 col-sm-9" style="padding-top: 15px;">
                        <h4 class="m-b-5">{{headerNumber.n_agents}}</h4>
                        <h5 class="m-b-5 font-strong">عامل ها</h5>
                    </div>
                    <div></div>
                </div>

            </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2">
            <div class="ibox color-white widget-stat  border border-white rounded"
                 style="height:80px;background-color:#7cc576;color:white;cursor: pointer; ">

                <div class="ibox-body text-center row" [routerLink]="['/userArea/customer']" [queryParams]="{ role: 4 }"
                     routerLinkActive="text-primary"
                     [routerLinkActiveOptions]="{ exact: true }">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <i
                                class="far fa-person-sign fa-2x " style="padding-right: 10px;padding-top: 15px;"></i>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9" style="padding-top: 15px;">
                        <h4 class="m-b-5">{{headerNumber.n_representatives}}</h4>
                        <h5 class="m-b-5 font-strong">نمایندگی ها</h5>
                    </div>
                    <div></div>
                </div>

            </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2">
            <div class="ibox color-white widget-stat  border border-white rounded"
                 style="height:80px;background-color: #a764a9;color:white;cursor: pointer;">

                <div class="ibox-body text-center row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <i
                                class="far fa-people-carry fa-2x " style="padding-right: 10px;padding-top: 15px;"></i>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9" style="padding-top: 15px;">
                        <h4 class="m-b-5">{{headerNumber.n_new_customers}}</h4>
                        <h5 class="m-b-5 font-strong">مشتریان جدید</h5>
                    </div>
                    <div></div>
                </div>

            </div>
        </div>
    </div>

    <div class="row border rounded" style="margin:5px;padding: 5px;">
        <!--        <button id="btnPrevMount" class="btn btn-lg mx-auto btn-warning m-2 w-50" type="submit" (click)="prevMount()"-->
        <!--                [disabled]="btnPrevReadOnlyFlag">-->
        <!--            مشاهده ماه قبل-->
        <!--        </button>-->
        <!--            <div class="btn btn-lg mx-auto btn-warning m-2 w-50">-->
        <!--                <label class="mx-1 lead"> سال </label>-->
        <!--            </div>-->
        <form #form="ngForm"
              (ngSubmit)="submitFilter(form)" class="d-flex flex-column align-items-center w-100">
            <div class="box  w-100">
                <div class="row">

                    <div class="form-input col-lg-4 col-md-8 col-16  w-50">
                        <label class="mx-1 lead"> انتخاب سال </label>
                        <select
                                name="year"
                                class="custom-select custom-select-lg"
                                [ngModel]="curentYear"
                        >
                            <option *ngFor="let el of years" [value]="el.id">
                                {{ el.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-input col-lg-4 col-md-8 col-16  w-50">
                        <label class="mx-1 lead"> انتخاب ماه </label>
                        <select
                                name="month"
                                class="custom-select custom-select-lg"
                                [ngModel]="mountNum"

                        >
                            <option *ngFor="let p of month" [value]="p.id">
                                {{ p.label }}
                            </option>
                        </select>
                    </div>
                    <button
                            class="btn btn-primary w-20" style="height: 50px;margin-top: 20px" type="submit">فیلتر
                    </button>

                </div>
                <div style="margin-top: 10px"></div>
            </div>
        </form>
        <!--        <button id="btnNextMount" class="btn btn-lg mx-auto btn-success m-2 w-50" type="submit" (click)="plusMount()"-->
        <!--                [disabled]="btnNextReadOnlyFlag">-->
        <!--            مشاهده ماه بعد-->
        <!--        </button>-->
        <ngb-accordion [closeOthers]="true" activeIds="static-1">
            <ngb-panel id="static-1">
                <ng-template ngbPanelTitle id="headingOne" class="font-strong">
                    <h5>
                        داشبورد فروش
                    </h5>
                </ng-template>
                <ng-template ngbPanelContent>

                    <!--                    <app-sale-dashboard></app-sale-dashboard>-->


                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                                <div class="row" style="margin: 0px;">
                                    <div class="col-sm-11 col-md-11 col-lg-11"><h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        {{currentMonthSaleCaption}}
                                    </h5></div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <i *ngIf="!showCurrentMonthSaleChart" class="fas fa-chart-pie cursor-pointer"
                                           (click)="isCurrentMonthSaleChart()" aria-hidden="true"></i>
                                        <i *ngIf="showCurrentMonthSaleChart" class="fal fa-table cursor-pointer"
                                           (click)="isCurrentMonthSaleChart()" aria-hidden="true"></i>
                                    </div>
                                </div>

                                <div *ngIf="showCurrentMonthSaleChart" id="currentMonthSaleChartDiv"
                                     style="width: 95%;"></div>
                                <table *ngIf="!showCurrentMonthSaleChart" style="width: 100%;margin: 0px;">
                                    <thead class="thead-light"
                                           style="text-align: center;
                       background-color: #b8bfc9;width: 100%;margin: 0px;">
                                    <tr>
                                        <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            عنوان
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            سفارشات
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            ورق
                                        </th>
                                        <th
                                                scope="col" style="border-left: 1px solid gray;"
                                        >
                                            مبلغ ( ریال )
                                        </th>
                                        <th class="d-lg-table-cell d-none" scope="col"
                                            style="border-left: 1px solid gray;">پرداختی ( ریال
                                            )
                                        </th>
                                        <!--                                        <th scope="col">مشاهده</th>-->
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let el of currentMonthSale; let i = index"
                                        style="text-align: center;border-top: 1px solid gray;">
                                        <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                        <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;">{{ el.name }}</td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.n_orders | number }}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.n_sheets  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.order_amount  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.paid_amount  | number}}
                                        </td>
                                        <!--                                        <td>-->
                                        <!--                                            <div class="fa fa-eye" class="cursor-pointer"-->
                                        <!--                                                 [routerLink]="['/userArea/order']"-->
                                        <!--                                                 [queryParams]=el.parm-->
                                        <!--                                                 routerLinkActive="text-primary"-->
                                        <!--                                                 [routerLinkActiveOptions]="{ exact: true }">-->
                                        <!--                                                <i class="fa fa-eye"></i>-->
                                        <!--                                            </div>-->
                                        <!--                                        </td>-->
                                    </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="currentMonthSale.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                                <div class="row" style="margin: 0px;">
                                    <div class="col-sm-11 col-md-11 col-lg-11"><h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        وضعیت فروش سال  | {{ curentYear }}
                                    </h5></div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <i *ngIf="!showCurrentYearSaleChart" class="fas fa-chart-pie cursor-pointer"
                                           (click)="isCurrentYearSaleChart()" aria-hidden="true"></i>
                                        <i *ngIf="showCurrentYearSaleChart" class="fal fa-table cursor-pointer"
                                           (click)="isCurrentYearSaleChart()" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div *ngIf="showCurrentYearSaleChart" id="currentYearSaleChartDiv"></div>
                                <table *ngIf="!showCurrentYearSaleChart" style="width: 100%;margin: 0px;">
                                    <thead class="thead-light"
                                           style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                                    <tr>
                                        <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            عنوان
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            سفارشات
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            ورق
                                        </th>
                                        <th
                                                scope="col" style="border-left: 1px solid gray;"
                                        >
                                            مبلغ ( ریال )
                                        </th>
                                        <th class="d-lg-table-cell d-none" scope="col"
                                            style="border-left: 1px solid gray;">پرداختی ( ریال
                                            )
                                        </th>
                                        <!--                                        <th scope="col">مشاهده</th>-->

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let el of currentYearSale; let i = index"
                                        style="text-align: center;border-top: 1px solid gray;">
                                        <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                        <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;">{{ el.name }}</td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.n_orders  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.n_sheets  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.order_amount  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.paid_amount  | number}}
                                        </td>
                                        <!--                                        <td>-->
                                        <!--                                            <div class="fa fa-eye" class="cursor-pointer"-->
                                        <!--                                                 [routerLink]="['/userArea/order']"-->
                                        <!--                                                 [queryParams]=el.parm-->
                                        <!--                                                 routerLinkActive="text-primary"-->
                                        <!--                                                 [routerLinkActiveOptions]="{ exact: true }">-->
                                        <!--                                                <i class="fa fa-eye"></i>-->
                                        <!--                                            </div>-->
                                        <!--                                        </td>-->
                                    </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="currentYearSale.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="user && !(user.role ==2 || user.role ==3 )" class="row" style="margin-top: 5px;">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;text-align:center;">
                                <div class="row" style="margin: 0px;">
                                    <div class="col-sm-11 col-md-11 col-lg-11"><h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        {{currentMonthBestSellerCustomerCaption}}
                                    </h5></div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <i *ngIf="!showCurrentMonthBestSellerCustomerChart"
                                           class="fas fa-chart-line cursor-pointer"
                                           (click)="isCurrentMonthBestSellerCustomerChart()" aria-hidden="true"></i>
                                        <i *ngIf="showCurrentMonthBestSellerCustomerChart"
                                           class="fal fa-table cursor-pointer"
                                           (click)="isCurrentMonthBestSellerCustomerChart()" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div *ngIf="showCurrentMonthBestSellerCustomerChart"
                                     id="currentMonthBestSellerCustomerChartDiv"
                                     style=" height: 300px;width: 100%;padding: 5px;margin: 5px;"></div>
                                <table *ngIf="!showCurrentMonthBestSellerCustomerChart"
                                       style="width: 100%;margin: 0px;">
                                    <thead class="thead-light"
                                           style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                                    <tr>
                                        <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            عنوان
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            سفارشات
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            ورق
                                        </th>
                                        <th
                                                scope="col" style="border-left: 1px solid gray;"
                                        >
                                            مبلغ ( ریال )
                                        </th>
                                        <th class="d-lg-table-cell d-none" scope="col"
                                            style="border-left: 1px solid gray;">پرداختی ( ریال
                                            )
                                        </th>
                                        <th scope="col">مشاهده</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let el of currentMonthBestSellerCustomer; let i = index"
                                        style="text-align: center;border-top: 1px solid gray;">
                                        <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                        <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;">{{ el.company_name }}</td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.n_orders  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.n_sheets  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.order_amount  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.paid_amount  | number}}
                                        </td>
                                        <td class="cursor-pointer">
                                            <div class="fa fa-eye" class="cursor-pointer"
                                                 [routerLink]="['/userArea/order']"
                                                 [queryParams]=el.parm
                                                 routerLinkActive="text-primary"
                                                 [routerLinkActiveOptions]="{ exact: true }">
                                                <i class="fa fa-eye"></i>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="currentMonthBestSellerCustomer.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                                <div class="row" style="margin: 0px;">
                                    <div class="col-sm-11 col-md-11 col-lg-11"><h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        {{ currentMonthBestSellerProvinceCaption}}
                                    </h5></div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <i *ngIf="!showCurrentMonthBestSellerProvinceChart"
                                           class="fas fa-chart-line cursor-pointer"
                                           (click)="isCurrentMonthBestSellerProvinceChart()" aria-hidden="true"></i>
                                        <i *ngIf="showCurrentMonthBestSellerProvinceChart"
                                           class="fal fa-table cursor-pointer"
                                           (click)="isCurrentMonthBestSellerProvinceChart()" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div *ngIf="showCurrentMonthBestSellerProvinceChart"
                                     id="currentMonthBestSellerProvinceChartDiv"
                                     style="height: 300px;width: 100%;padding: 5px;margin: 5px;"></div>
                                <table *ngIf="!showCurrentMonthBestSellerProvinceChart"
                                       style="width: 100%;margin: 0px;">
                                    <thead class="thead-light"
                                           style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                                    <tr>
                                        <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            عنوان
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            سفارشات
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            ورق
                                        </th>
                                        <th
                                                scope="col" style="border-left: 1px solid gray;"
                                        >
                                            مبلغ ( ریال )
                                        </th>
                                        <th class="d-lg-table-cell d-none" scope="col"
                                            style="border-left: 1px solid gray;">پرداختی ( ریال
                                            )
                                        </th>
                                        <th scope="col">مشاهده</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let el of currentMonthBestSellerProvince; let i = index"
                                        style="text-align: center;border-top: 1px solid gray;">
                                        <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                        <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;">{{ el.province_name }}</td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.n_orders  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.n_sheets  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.order_amount  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.paid_amount  | number}}
                                        </td>
                                        <td>
                                            <div class="fa fa-eye" class="cursor-pointer"
                                                 [routerLink]="['/userArea/order']"
                                                 [queryParams]=el.parm
                                                 routerLinkActive="text-primary"
                                                 [routerLinkActiveOptions]="{ exact: true }">
                                                <i class="fa fa-eye"></i>
                                            </div>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="currentMonthBestSellerProvince.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-template>
            </ngb-panel>
            <!---->
            <ngb-panel id="static-2" *ngIf="user && user.role !=14">
                <ng-template ngbPanelTitle class="font-strong">
                    <h5>
                        داشبورد محصولات
                    </h5>
                </ng-template>
                <ng-template ngbPanelContent>
                    <!--                    <app-product-order-dashboard></app-product-order-dashboard>-->


                    <button id="btnProductOrderYear" class="btn btn-lg mx-auto btn-warning m-2 w-50" type="submit"
                            (click)="selectMount()"
                    >
                        ماهانه
                    </button>
                    <button id="btnProductOrderMount" class="btn btn-lg mx-auto btn-success m-2 w-50" type="submit"
                            (click)="selectYear()"
                    >
                        سالانه
                    </button>

                    <!--<ngb-tabset justify="justified"   >-->

                    <!--    <ngb-tab >-->
                    <!--        <ng-template ngbTabTitle>-->
                    <!--            <h5>ماهانه</h5>-->
                    <!--        </ng-template>-->
                    <!--        <ng-template ngbTabContent>-->
                    <div class="row" style="margin-top: 5px;">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                                <!--            <div style="background-color: red"></div>-->
                                <!--            <h5-->
                                <!--                    style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">-->
                                <!--                {{currentDimensionCaption}}-->

                                <!--            </h5>-->
                                <div class="row" style="margin: 0px;">
                                    <div class="col-sm-11 col-md-11 col-lg-11"><h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        {{currentDimensionCaption}}
                                    </h5></div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <i *ngIf="!showCurrentProductDimensionsChar"
                                           class="fas fa-chart-line cursor-pointer"
                                           (click)="isCurrentProductDimensionsChar()" aria-hidden="true"></i>
                                        <i *ngIf="showCurrentProductDimensionsChar" class="fal fa-table cursor-pointer"
                                           (click)="isCurrentProductDimensionsChar()" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div *ngIf="showCurrentProductDimensionsChar" id="productDimensionsChartDiv"></div>
                                <table *ngIf="!showCurrentProductDimensionsChar" style="width: 100%;margin: 0px;">
                                    <thead class="thead-light"
                                           style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                                    <tr>
                                        <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            ابعاد ورق
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            جمع کل تعداد
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            درصد سهم
                                        </th>
                                        <th class="d-lg-table-cell d-none"
                                            scope="col" style="border-left: 1px solid gray;"
                                        >
                                            تعداد منطقه‌ی ۱
                                        </th>
                                        <th class="d-lg-table-cell d-none" scope="col"
                                            style="border-left: 1px solid gray;">
                                            تعداد منطقه‌ی ۲
                                        </th>
                                        <th scope="col">مشاهده</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let el of dimension; let i = index"
                                        style="text-align: center;border-top: 1px solid gray;">
                                        <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                        <!-- <td>{{ i + 1 | toFa }}</td> -->
                                        <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;width: 80px;">{{ el.name }}</td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.all  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.percentage  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.region_1  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.region_2  | number}}
                                        </td>
                                        <td>
                                            <div class="fa fa-eye" class="cursor-pointer"
                                                 [routerLink]="['/userArea/order/product']"
                                                 [queryParams]=el.parm
                                                 routerLinkActive="text-primary"
                                                 [routerLinkActiveOptions]="{ exact: true }">

                                                <i class="fa fa-eye"></i>

                                            </div>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="dimension.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                                <div class="row" style="margin: 0px;">
                                    <div class="col-sm-11 col-md-11 col-lg-11"><h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        {{currentTypeCaption}}
                                    </h5></div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <i *ngIf="!showCurrentProductTypeChar" class="fas fa-chart-line cursor-pointer"
                                           (click)="isCurrentProductTypeChar()" aria-hidden="true"></i>
                                        <i *ngIf="showCurrentProductTypeChar" class="fal fa-table cursor-pointer"
                                           (click)="isCurrentProductTypeChar()" aria-hidden="true"></i>
                                    </div>
                                </div>

                                <div *ngIf="showCurrentProductTypeChar" id="productTypeChartDiv"></div>
                                <table *ngIf="!showCurrentProductTypeChar" style="width: 100%;margin: 0px;">
                                    <thead class="thead-light"
                                           style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                                    <tr>
                                        <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            ابعاد ورق
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            جمع کل تعداد
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            درصد سهم
                                        </th>
                                        <th class="d-lg-table-cell d-none"
                                            scope="col" style="border-left: 1px solid gray;"
                                        >
                                            تعداد منطقه‌ی ۱
                                        </th>
                                        <th class="d-lg-table-cell d-none" scope="col"
                                            style="border-left: 1px solid gray;">
                                            تعداد منطقه‌ی ۲
                                        </th>
                                        <th scope="col">مشاهده</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let el of type; let i = index"
                                        style="text-align: center;border-top: 1px solid gray;">
                                        <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                        <!-- <td>{{ i + 1 | toFa }}</td> -->
                                        <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;width: 80px;">{{ el.name }}</td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.all  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.percentage  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.region_1  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.region_2  | number}}
                                        </td>
                                        <td>
                                            <div class="fa fa-eye" class="cursor-pointer"
                                                 [routerLink]="['/userArea/order/product']"
                                                 [queryParams]=el.parm
                                                 routerLinkActive="text-primary"
                                                 [routerLinkActiveOptions]="{ exact: true }">

                                                <i class="fa fa-eye"></i>

                                            </div>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="dimension.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                                <div class="row" style="margin: 0px;">
                                    <div class="col-sm-11 col-md-11 col-lg-11"><h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        {{currentDegreeCaption}}
                                    </h5></div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <i *ngIf="!showCurrentProductDegreeChar"
                                           class="fas fa-chart-line cursor-pointer"
                                           (click)="isCurrentProductDegreeChar()" aria-hidden="true"></i>
                                        <i *ngIf="showCurrentProductDegreeChar" class="fal fa-table cursor-pointer"
                                           (click)="isCurrentProductDegreeChar()" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div *ngIf="showCurrentProductDegreeChar" id="productDegreeChartDiv"></div>
                                <table *ngIf="!showCurrentProductDegreeChar" style="width: 100%;margin: 0px;">
                                    <thead class="thead-light"
                                           style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                                    <tr>
                                        <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            ابعاد ورق
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            جمع کل تعداد
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            درصد سهم
                                        </th>
                                        <th class="d-lg-table-cell d-none"
                                            scope="col" style="border-left: 1px solid gray;"
                                        >
                                            تعداد منطقه‌ی ۱
                                        </th>
                                        <th class="d-lg-table-cell d-none" scope="col"
                                            style="border-left: 1px solid gray;">
                                            تعداد منطقه‌ی ۲
                                        </th>
                                        <th scope="col">مشاهده</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let el of degree; let i = index"
                                        style="text-align: center;border-top: 1px solid gray;">
                                        <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                        <!-- <td>{{ i + 1 | toFa }}</td> -->
                                        <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;width: 80px;">{{ el.name }}</td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.all  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.percentage  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.region_1  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.region_2  | number}}
                                        </td>
                                        <td>
                                            <div class="fa fa-eye" class="cursor-pointer"
                                                 [routerLink]="['/userArea/order/product']"
                                                 [queryParams]=el.parm
                                                 routerLinkActive="text-primary"
                                                 [routerLinkActiveOptions]="{ exact: true }">

                                                <i class="fa fa-eye"></i>

                                            </div>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="dimension.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                                <div class="row" style="margin: 0px;">
                                    <div class="col-sm-11 col-md-11 col-lg-11"><h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        {{currentThicknessCaption}}
                                    </h5></div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <i *ngIf="!showCurrentProductThicknessChar"
                                           class="fas fa-chart-line cursor-pointer"
                                           (click)="isCurrentProductThicknessChar()" aria-hidden="true"></i>
                                        <i *ngIf="showCurrentProductThicknessChar" class="fal fa-table cursor-pointer"
                                           (click)="isCurrentProductThicknessChar()" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div *ngIf="showCurrentProductThicknessChar" id="productThicknessChartDiv"></div>
                                <table *ngIf="!showCurrentProductThicknessChar" style="width: 100%;margin: 0px;">
                                    <thead class="thead-light"
                                           style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                                    <tr>
                                        <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            ابعاد ورق
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            جمع کل تعداد
                                        </th>
                                        <th scope="col" style="border-left: 1px solid gray;">
                                            درصد سهم
                                        </th>
                                        <th class="d-lg-table-cell d-none"
                                            scope="col" style="border-left: 1px solid gray;"
                                        >
                                            تعداد منطقه‌ی ۱
                                        </th>
                                        <th class="d-lg-table-cell d-none" scope="col"
                                            style="border-left: 1px solid gray;">
                                            تعداد منطقه‌ی ۲
                                        </th>
                                        <th scope="col">مشاهده</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let el of thickness; let i = index"
                                        style="text-align: center;border-top: 1px solid gray;">
                                        <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                        <!-- <td>{{ i + 1 | toFa }}</td> -->
                                        <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;width: 80px;">{{ el.name }}</td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.all  | number}}
                                        </td>
                                        <td style="border-left: 1px solid gray;">
                                            {{ el.percentage  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.region_1  | number}}
                                        </td>
                                        <td class="d-lg-table-cell d-none" style="border-left: 1px solid gray;">
                                            {{ el.region_2  | number}}
                                        </td>
                                        <td>
                                            <div class="fa fa-eye" class="cursor-pointer"
                                                 [routerLink]="['/userArea/order/product']"
                                                 [queryParams]=el.parm
                                                 routerLinkActive="text-primary"
                                                 [routerLinkActiveOptions]="{ exact: true }">

                                                <i class="fa fa-eye"></i>

                                            </div>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="dimension.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--        </ng-template>-->
                    <!--    </ngb-tab>-->
                    <!--    <ngb-tab>-->
                    <!--        <ng-template ngbTabTitle>-->
                    <!--         <h5>   سالانه</h5>-->
                    <!--        </ng-template>-->
                    <!--        <ng-template ngbTabContent>-->
                    <!--        </ng-template>-->
                    <!--    </ngb-tab>-->

                    <!--</ngb-tabset>-->


                </ng-template>
            </ngb-panel>
            <!---->
            <ngb-panel id="static-3">
                <ng-template ngbPanelTitle class="font-strong">
                    <h5>
                        داشبورد سفارش
                    </h5>
                </ng-template>
                <ng-template ngbPanelContent>

                    <app-order-dashboard></app-order-dashboard>

                </ng-template>
            </ngb-panel>

            <ngb-panel id="static-4">
                <ng-template ngbPanelTitle>
                    <h5>
                        داشبورد پرداخت ها
                    </h5>

                </ng-template>
                <ng-template ngbPanelContent>

                    <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordion"
                         style="padding-top: 5px;">
                        <!--                class="collapse show"-->
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                                    <h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        وضعیت پرداخت های نقدی
                                    </h5>
                                    <table style="width: 100%;margin: 0px;">
                                        <thead class="thead-light"
                                               style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                                        <tr>
                                            <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                            <th scope="col" style="border-left: 1px solid gray;">
                                                عنوان
                                            </th>
                                            <th scope="col" style="border-left: 1px solid gray;">
                                                تعداد پرداخت ها
                                            </th>
                                            <th scope="col" style="border-left: 1px solid gray;">
                                                مبلغ واریز
                                            </th>

                                            <th scope="col">مشاهده</th>

                                        </tr>

                                        </thead>
                                        <tbody>


                                        </tbody>
                                        <tbody>
                                        <tr *ngFor="let el of cashPayment; let i = index"
                                            style="text-align: center;border-top: 1px solid gray;">
                                            <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                            <!-- <td>{{ i + 1 | toFa }}</td> -->
                                            <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;width: 120px;">{{ el.name }}</td>
                                            <td style="border-left: 1px solid gray;">
                                                {{ el.number  | number}}
                                            </td>
                                            <td style="border-left: 1px solid gray;">
                                                {{ el.amount | number}}
                                            </td>
                                            <td>
                                                <div class="fa fa-eye" class="cursor-pointer"
                                                     [routerLink]="['/userArea/checkout/cashes']"
                                                     [queryParams]=el.parm
                                                     routerLinkActive="text-primary"
                                                     [routerLinkActiveOptions]="{ exact: true }">

                                                    <i class="fa fa-eye"></i>

                                                </div>

                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                    <div *ngIf="cashPayment.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                    </div>
                                    <!--                        class="k-grid-norecords-content"-->
                                </div>

                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div style="padding-top: 5px;border: 1px solid #1fae9f;border-radius:5px;">
                                    <h5
                                            style="height: auto;background-color: white;color: #1fae9f;text-align: center;vertical-align: top">
                                        وضعیت پیگیری های چک ها

                                    </h5>
                                    <table style="width: 100%;margin: 0px;">
                                        <thead class="thead-light"
                                               style="text-align: center;background-color: #b8bfc9;width: 100%;margin: 0px;">
                                        <tr>
                                            <th scope="col" style="border-left: 1px solid #bdbbbb;">#</th>
                                            <th scope="col" style="border-left: 1px solid gray;">
                                                عنوان
                                            </th>
                                            <th scope="col" style="border-left: 1px solid gray;">
                                                تعداد چک ها
                                            </th>
                                            <th scope="col" style="border-left: 1px solid gray;">
                                                مبلغ چک ها
                                            </th>

                                            <th scope="col">مشاهده</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let el of checkPayment; let i = index"
                                            style="text-align: center;border-top: 1px solid gray;">
                                            <th scope="row" style="border-left: 1px solid gray;">{{ i + 1 | toFa }}</th>
                                            <!-- <td>{{ i + 1 | toFa }}</td> -->
                                            <td style="border-left: 1px solid gray;text-align: right;padding-right: 2px;width: 150px;">{{ el.name }}</td>
                                            <td style="border-left: 1px solid gray;">
                                                {{ el.number | number}}
                                            </td>
                                            <td style="border-left: 1px solid gray;">
                                                {{ el.amount | number}}
                                            </td>

                                            <td>
                                                <div class="fa fa-eye" class="cursor-pointer"
                                                     [routerLink]="['/userArea/checkout/checks']"
                                                     [queryParams]=el.parm
                                                     routerLinkActive="text-primary"
                                                     [routerLinkActiveOptions]="{ exact: true }">

                                                    <i class="fa fa-eye"></i>

                                                </div>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div *ngIf="checkPayment.length==0" style="width: 100%;
height: 100%;
text-align: center;">اطلاعاتی یافت نشد
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>

            <ngb-panel id="static-5" *ngIf="auth.permissions.showDashboardWarehouse">
                <ng-template ngbPanelTitle>
                    <h5>
                        داشبورد انبارهای فروش استانی
                    </h5>

                </ng-template>
                <ng-template ngbPanelContent>
                    <app-warehouse-dashboard></app-warehouse-dashboard>
                </ng-template>
            </ngb-panel>

            <ngb-panel id="static-6">
                <ng-template ngbPanelTitle>
                    <h5>
                        داشبورد ماهیت مشتری
                    </h5>
                </ng-template>
                <ng-template ngbPanelContent>
                    <app-customer-dashboard></app-customer-dashboard>
                </ng-template>
            </ngb-panel>

        </ngb-accordion>

    </div>
</div>

