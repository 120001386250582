import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
   selector: 'app-the-header',
   templateUrl: './the-header.component.html',
   styleUrls: ['./the-header.component.scss'],
})
export class TheHeaderComponent implements OnInit {
   @Output() toggleSidebar = new EventEmitter();
   user = null;
   today = new Date();

   constructor(public auth: AuthService) {}

   async ngOnInit(): Promise<void> {
      this.user = await this.auth.get_info();
   }
}
