import {Component, OnInit} from '@angular/core';
import * as Func from "../../../../../pack/js/func";
import {Config} from "../../../../app-config.service";
import {FormControl} from "@angular/forms";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {WarehouseNewComponent} from "../warehouse-new/warehouse-new.component";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-warehouse-list',
    templateUrl: './warehouse-list.component.html',
    styleUrls: ['./warehouse-list.component.scss']
})
export class WarehouseListComponent implements OnInit {

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private http: HttpClient,
        public dialog: MatDialog
    ) {
    }

    func = Func;
    warehouses: any[] = [];
    orgWarehouses: any[] = [];

    searchControl = new FormControl();
    selected: boolean[] = [];
    warehouseApi: string = Config.settings.api + "warehouse/warehouse/";

    ngOnInit(): void {
        this.load();
    }

    load() {
        console.log(this.warehouseApi);
        this.orgWarehouses = [];
        this.http.get(this.warehouseApi).subscribe(
            (response: any) => {
                response.forEach((element) => {
                    this.orgWarehouses.push({
                        id: element.id,
                        city_name: element.city_name,
                        name: element.name,
                        responsible_fullName: `${element.responsible_company_name} |  ${element.responsible_first_name} ${element.responsible_last_name}`,
                        creation_date: element.creation_date
                    });
                });
                // this.selected = new Array(mx + 1).fill(false);
                this.warehouses = this.orgWarehouses;

            },
            (error: HttpErrorResponse) => {
                //TODO: server errors
                console.log(error);
            }
        );
    }


    openDialog(): void {
        const dialogRef = this.dialog.open(WarehouseNewComponent, {
            data: {name: "hamed"}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            // this.animal = result;
        });
    }


    ngOnDestroy() {
    }

}
