<div class="toolbar">
  <ng-container *ngFor="let item of toolbarItems">
    <div class="toolbar-item" *ngIf="item.type === 'button'">
      <button
        type="button"
        (click)="itemClickHandler(item)"
        class="btn btn-{{ item.btn_type }} {{ item.cssClass }}"
      >
        <span
          *ngIf="item.icon !== undefined"
          class="{{ item.icon }} ml-2"
          style="vertical-align: middle"
        ></span
        >{{ item.text }}
      </button>
    </div>
    <div class="toolbar-item-icon" *ngIf="item.type === 'icon-button'">
      <a [routerLink]="" (click)="itemClickHandler(item)">
        <i class="{{ item.icon }}"></i>
      </a>
    </div>
    <div class="toolbar-item" *ngIf="item.type === 'link'">
      <a
        type="button"
        (click)="itemClickHandler(item)"
        class="{{ item.cssClass }}"
      >
      </a>
    </div>
    <div class="toolbar-item" *ngIf="item.type === 'space'">
      <div class="space-item"></div>
    </div>
    <div class="toolbar-menu" *ngIf="item.type === 'menu'">
        <button
        type="button"
        class="btn btn-{{ item.btn_type }} {{ item.cssClass }}" [matMenuTriggerFor]="menu"
      >
        <i class="ml-2 align-middle {{ item.icon }}"></i>{{ item.text }}<i class="mr-2 align-middle fas fa-angle-down"></i>
      </button>
      <mat-menu dir="rtl" id="{{item.id}}" #menu="matMenu">
        <button mat-menu-item class="menu-item" *ngFor="let menuItem of item.items ">
            <i class="{{ menuItem.icon }} ml-2"></i><span >{{menuItem.text }}</span>
        </button>
    
      </mat-menu>
    </div>
    <div class="toolbar-item" *ngElse>
      <button
        type="button"
        (click)="itemClickHandler(item)"
        class="  btn btn-{{ item.btn_type }} {{ item.cssClass }}"
      >
        <i class="{{ item.icon }}"></i>{{ item.text }}
      </button>
    </div>
  </ng-container>
</div>
