import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Config } from "src/app/app-config.service";
import * as Func from "src/pack/js/func";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { Location } from "@angular/common";

import { Subject } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { ToolbarItemModel } from "src/app/models/toolbar.model";

@Component({
  selector: "app-personnels-edit",
  templateUrl: "./personnels-edit.component.html",
  styleUrls: ["./personnels-edit.component.scss"],
})
export class PersonnelsEditComponent implements OnInit {
  isWrong: boolean;
  errorMsg: string;
  success: boolean;
  msg: string;
  @ViewChild("form")
  form:ElementRef;
  user: any;
  can_edit: boolean;

  roleRef: Subject<void> = new Subject<void>();

  imgUrl = null;

  id: number = null;
  first_name: string = null;
  last_name: string = null;
  personnel_code: string = null;
  degree: string = null;
  city_of_birth: string = null;
  father_name: string = null;
  financial_code: string = null;
  phone_number: string = null;
  _landing_number: string = null;

  get landing_number() {
    if (!parseInt(this._landing_number)) return null;
    if (this._landing_number.length > 3)
      return (
        this._landing_number.slice(0, 3) +
        "-" +
        this._landing_number.slice(3)
      );
    else return this._landing_number;
  }

  set landing_number(e) {
    var regex_en = new RegExp("-", "g");
    e = e.replace(regex_en, "");
    this._landing_number = e;
  }

  internal_number: string = null;
  username: string = null;
  password: string = null;
  email: string = null;
  telegram_id: string = null;

  unit: number = null;
  role: number = null;
  status: boolean = true;
  units: any[] = [];
  degrees: any[] = [];
  roles: any[] = [];
  date;

  conf = Config;
  statuses: any[] = [
    { id: true, label: "فعال" },
    { id: false, label: "غیر فعال" },
  ];

  name_titles: any[] = [
    { id: "سرکار خانم", label: "سرکار خانم" },
    { id: "جناب آقای", label: "جناب آقای" },
    { id: "جناب آقای مهندس", label: "جناب آقای مهندس" },
  ];
  name_title: string = null;

  func = Func;
  toolbarItemList: ToolbarItemModel[] = [
    {
      name: "save",
      icon: "fas fa-save",
      text: "ذخیره",
      type: "button",
      btn_type: "primary"
    },
    {
      name: "saveClose",
      icon: "fas fa-window-close",
      text: "ذخیره و بستن",
      type: "button",
      btn_type: "danger"
    },
    {
      name: "saveNew",
      icon: "fas fa-sync",
      text: "ذخیره و جدید",
      type: "button",
      btn_type: "success"
    },
    {
      name: "space",
      text: "space",
      type: "space",
    },
    {
      name: "cancel",
      text: "لغو",
      type: "icon-button",
      icon: "fal fa-times-circle fa-2x text-danger",
    },
    {
      name: "print",
      text: "چاپ",
      type: "icon-button",
      icon: "fal fa-print fa-2x text-info",
    },
    {
      name: "excel",
      text: "خروجی اکسل",
      type: "icon-button",
      icon: "fal fa-file-excel fa-2x text-success",
    },
    {
      name: "space",
      text: "space",
      type: "space",
    },
    {
      name: "operations",
      text: "عملیات",
      icon: "fas fa-cogs",
      type: "menu",
      btn_type: "primary",
      items: [
        {
          name: "cancel",
          text: "لغو",
          type: "icon-button",
          icon: "fal fa-times-circle",
        },
        {
          name: "print",
          text: "چاپ",
          type: "icon-button",
          icon: "fal fa-print ",
        },
        {
          name: "excel",
          text: "خروجی اکسل",
          type: "icon-button",
          icon: "fal fa-file-excel",
        }
      ]
    }
  ]
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    public loc: Location
  ) { }

  getUnits(unit, role) {
    let unitsApi: string =
      Config.settings.api + Config.settings.info.unit;
    this.http.get(unitsApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.units.push({
            id: element.id,
            label: element.name,
          });
        });
        this.changeUnit(unit, role);
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }
  getDegrees() {
    let degreesApi: string = Config.settings.api + "information/degree/";
    this.http.get(degreesApi).subscribe(
      (response: any) => {
        let tc = [{ id: null, label: "خالی" }];
        response.forEach((element) => {
          tc.push({
            id: element.id,
            label: element.name,
          });
        });
        this.degrees = tc;
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }

  async ngOnInit(): Promise<void> {
    this.getDegrees();

    try {
      this.user = await this.auth.get_info();

      this.can_edit = this.user.permission_codes.includes(112);
    } catch {
      this.router.navigate(["/500"]);
    }
    this.route.params.subscribe((params) => {
      if (isNaN(params.id) || !Number.isInteger(params.id / 1)) {
        this.router.navigate(["/404"]);
      } else {
        this.id = params.id;
        let userApi: string =
          Config.settings.api + Config.settings.user.user + `${this.id}/`;
        this.http.get(userApi).subscribe(
          (response: any) => {
            console.log(response);
            this.getUnits(response.unit, response.role);
            this.first_name = response.first_name;
            this.last_name = response.last_name;
            this.personnel_code = response.personnel_code;
            this.internal_number = response.internal_number;
            this.email = response.email;
            this.telegram_id = response.telegram_id;
            this.username = response.username;
            this.phone_number = response.phone_number;
            this._landing_number = response.landing_number;
            this.imgUrl = response.image_url;
            this.date = response.date_of_birth;
            this.name_title = response.name_title;
            this.degree = response.degree;
            this.city_of_birth = response.city_of_birth;
            this.father_name = response.father_name;
            this.financial_code = response.financial_code;
          },
          (error: HttpErrorResponse) => {
            //TODO: server errors
            console.log(error);
          }
        );
      }
    });
  }

  changeUnit($event, role = null) {
    this.unit = $event;
    let roleApi: string =
      Config.settings.api + Config.settings.info.role + `?unit=${$event}`;
    this.roles = [];
    this.http.get(roleApi).subscribe(
      (response: any) => {
        response.forEach((element) => {
          this.roles.push({
            id: element.id,
            label: element.name,
          });
        });
        this.role = role;
      },
      (error: HttpErrorResponse) => {
        //TODO: server errors
        console.log(error);
      }
    );
  }
  changeRole($event) {
    this.role = $event;
  }

  changeStatus($event) {
    this.status = $event;
  }

  changeNameTitle($event) {
    this.name_title = $event;
  }

  register(form) {
    form.value.landing_number = this._landing_number;

    if (form.value.password == "" || form.value.password == null) {
      delete form.value.password;
    }
    let url =
      Config.settings.api + Config.settings.user.user + `${this.id}/`;
    if (this.imgUrl) form.value.image_url = this.imgUrl;
    this.http.patch(url, form.value).subscribe(
      (response: any) => {
        console.log("shod");
        this.success = true;
        this.isWrong = false;
        this.msg = `کاربر ویرایش شد.`;
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
        window.scroll(0, 0);
      }
    );
  }

  permissionControl(e): void {
    if (!this.can_edit && this.user.role != 1) e.preventDefault();
  }

  change_image(e) {
    const url: string = Config.settings.api + Config.settings.info.file;
    const formData: FormData = new FormData();
    formData.append(
      "file",
      e.target.files.item(0),
      e.target.files.item(0).name
    );
    const headers = { dt: "yes" };
    this.http.post(url, formData, { headers }).subscribe(
      (res: any) => {
        this.imgUrl = res.url.slice(1);
        window.scroll(0, 0);
      },
      (error: HttpErrorResponse) => {
        console.log(error.error);
        this.isWrong = true;
        this.success = false;
        this.errorMsg = "دوباره امتحان کنید.";
        if (error.error.message) {
          this.errorMsg = error.error.message;
        }
        window.scroll(0, 0);
      }
    );
  }
  itmeClickHandler(event) {
    console.log(event);
    switch (event.name) {
      case 'save':
        this.register(this.form);
        break;
      case 'saveClose':
        this.register(this.form);
        this.router.navigate(['userArea/personnel']);
        break;
      case 'saveNew':
        this.register(this.form);
        this.router.navigate(['userArea/personnel/new']);
        break;
      case 'cancel':
        this.router.navigate(['userArea/personnel']);
        break;
    }
  }
}
