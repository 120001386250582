<div class="m-3 mt-4">
    <app-title title="لیست سفارشات انبار فروش استانی"></app-title>
    <div class="list my-3 mx-auto">
        <div class="list-header">
            <div class="list-header-item cursor-pointer" [routerLink]="['/userArea/warehouseOrderNew' ]">
                <i class="far fa-plus-square"></i>جدید
            </div>
            <div class="list-header-item cursor-pointer" (click)="load()">
                <i class="far fa-sync-alt"></i>به‌روز رسانی
            </div>
            <div class="list-header-item">
                تعداد ردیف:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 400"
                >
                    {{ object_count.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item">
                تعداد ورق صفحه:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 400"
                >
                    {{ page_sheet_sum.toString() | toFa }}
                </div>
            </div>
            <div class="list-header-item">
                تعداد ورق کل:&nbsp;
                <div
                        class="text-success"
                        style="font-size: 1.3rem; font-weight: 400"
                >
                    {{ total_sheet_sum.toString() | toFa }}
                </div>
            </div>

            <div
                    class="list-header-item mr-auto cursor-pointer"
                    (click)="isCollapsed = !isCollapsed"
            >
                <i class="far fa-file-search"></i>فیلتر
            </div>
        </div>

        <app-filter
                [isCollapsed]="isCollapsed"
                [filter_items]="filter_items"
                (changeFilter)="search($event)"
        ></app-filter>

        <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col" (click)="sort('id')">
                    سفارش
                </th>

                <!--                <th-->
                <!--                        scope="col"-->
                <!--                        class="d-lg-table-cell d-none"-->
                <!--                        (click)="sort('id')"-->
                <!--                >-->
                <!--                    سفارش انبار-->
                <!--                </th>-->
                <th scope="col" (click)="sort('date')">تاریخ سفارش</th>
                <th scope="col" (click)="sort('sending_date')">تاریخ ارسال</th>
                <th scope="col"  class="d-lg-table-cell d-none" (click)="sort('receiving_date')">تاریخ دریافت</th>
                <th scope="col" (click)="sort('buyer_company_name')">عنوان انبار</th>
                <th scope="col" class="d-lg-table-cell d-none"  (click)="sort('code')"  >کد رهگیری</th>
                <th scope="col" class="d-lg-table-cell d-none" (click)="sort('pallet_sum')">تعداد</th>
                <th scope="col" class="d-lg-table-cell d-none">ایجاد کننده</th>
                <th
                        scope="col"
                        class="d-lg-table-cell d-none"
                        (click)="sort('creator_first_name')"
                >
                    وضعیت
                </th>
                <!--                <th-->
                <!--                        scope="col"-->
                <!--                        class="d-lg-table-cell d-none"-->
                <!--                        (click)="sort('sale_expert_last_name')"-->
                <!--                >-->
                <!--                    کارشناس-->
                <!--                </th>-->
                <!--                <th scope="col" (click)="sort('state')">وضعیت سفارش</th>-->
                <!--                <th scope="col">وضعیت تسویه</th>-->

                <th scope="col">ویرایش</th>
                <th scope="col">x</th>
            </tr>
            </thead>
            <tbody>
            <tr
                    *ngFor="let el of warehouseOrders; let i = index"
            >
                <th scope="row">
                    {{ (page_number - 1) * page_size + i + 1 | toFa }}
                </th>
                <td>{{ el.id }}</td>
                <td>{{ el.date | jDate | toFa }}</td>
                <td>{{ el.sending_date | jDate | toFa }}</td>
                <td class="d-lg-table-cell d-none" >{{ el.receiving_date | jDate | toFa }}</td>
                <!--                <td>{{ el.sum | toFa }}</td>-->
                <td >
                    {{ el.warehouse_name }}
                </td>

                <td class="d-lg-table-cell d-none">
                    {{ el.code }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ el.sum  }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ el.creator_first_name + ' ' + el.creator_last_name }}
                </td>
                <td class="d-lg-table-cell d-none">
                    {{ el.state_name  }}
                </td>
                <td class="cursor-pointer" [routerLink]="['/userArea/warehouseOrder/' + el.id]"
                >
                    <i class="fal fa-edit"></i>
                </td>
                <td class="cursor-pointer" (click)="del(content, el.id)">
                    <i class="fal fa-trash-alt"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="warehouseOrders.length==0"
         style="width: 100%;
    height: 30px;
    text-align: center;padding-top: 7px;">
        <h5>
            اطلاعاتی یافت نشد
        </h5>

    </div>
    <div *ngIf="warehouseOrders.length!=0" class="d-flex justify-content-center">
        <app-pagination
                [page_count]="page_count"
                [page_number]="page_number"
                (changePage)="load($event)"
        ></app-pagination>
    </div>


</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            آیا از حذف این رکورد اطمینان دارید؟
        </h4>
    </div>
    <div class="modal-body">
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(true)"
        >
            بله
        </button>
        <button
                type="button"
                class="btn btn-outline-dark mx-2"
                (click)="modal.close(false)"
        >
            خیر
        </button>
    </div>
</ng-template>
